/* You can add global styles to this file, and also import other style files */

//Bootstrap
@import '~src/assets/bootstrap';

@import '~src/assets/abstracts/variables';
@import '~src/assets/abstracts/mixins';
//@import "~src/assets/fonts/fonts";

//Amg Components
@import "~node_modules/amg-ng-components/dist/carousel/style/amg-carousel-bundled";
@import "~node_modules/amg-ng-components/dist/card-fixture/style/amg-card-event-bundled";
@import "~node_modules/amg-ng-components/dist/card-fixture/style/amg-card-fixture-bundled";
@import "~node_modules/amg-ng-components/dist/card-image/style/amg-card-image-bundled";
@import "~node_modules/amg-ng-components/dist/card-text/style/amg-card-text-bundled";
@import "~node_modules/amg-ng-components/dist/card-video/style/amg-card-video-bundled";
@import "~node_modules/amg-ng-components/dist/collapsible/style/amg-collapsible-bundled";
@import "~node_modules/amg-ng-components/dist/video-player/style/amg-video-player-bundled";
@import "~node_modules/amg-ng-components/dist/countdown/style/amg-countdown-bundled";
@import "~node_modules/amg-ng-components/dist/grid/style/amg-grid-bundled";
@import "~node_modules/amg-ng-wordpress/dist/wordpress-components/style/main";



// Leeds Styling
@import "~src/assets/general";
@import "~src/assets/components/components";
@import "~src/assets/layout/header";
@import "~src/assets/layout/footer";
@import "~src/assets/pages/pages";



