.amg-calendar {
  width: 100%;

  .amg-carousel-calendar {
    .amg-carousel {
      .amg-carousel-container {
        overflow: hidden;
        //padding-left: 30px !important;
        //padding-right: 30px !important;

        .col {
          .year {
            order: 2;
            font-size: 15px;
          }
          .monthText {
            font-family: futura-pt-condensed, serif;
            font-size: 28px;
            text-transform: uppercase;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            order: 3;
          }
          &:hover {
            cursor: pointer;
          }

          &.selected {
            .amg-card-text {
              background-color: $greyLight;
            }
            .monthText {
              color: $beigeLight;
            }
          }
          &:not(.active) {
            display: none;
          }
        }
        .amg-card-text {
          background-color: $whiteMed;
        }
      }
      .amg-carousel-nav-container {
        bottom: initial;

        .amg-carousel-nav {
          button {
            position: absolute;
            top: 0;
            bottom: 0;
            pointer-events: auto;
            border: 0;
            color: transparent;
            background: url("~src/assets/images/chevron-right-blue.png") center no-repeat;
            background-size: 16pt auto;

            &[hidden] {
              display: block !important;
              pointer-events: none;
              opacity: 0;
            }
          }

          .prev {
            height: 80px;
            left: 0;
            padding-left: 10pt;
            margin-top: -85px;
            transform: rotate(180deg);
          }

          .next {
            height: 80px;
            padding-right: 10pt;
            margin-top: -85px;
            right: 0;
          }
        }
      }
    }
  }
}
