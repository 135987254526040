footer {
  font-family: 'Oswald', serif;
  overflow: hidden;
  font-size: 14px;


  > .amg-footer {

    &,
    .amg-section,
    .amg-section-children,
    .amg-section-child,
    .amg-action-link {
      display: block;
      height: auto;
    }


    > .amg-section {
      > * {
        @include make-container-amg();
      }
    }
  }

  .advertisement-container {

    .amg-section-children {
      max-width: 1300px;
      margin: 0 auto;

      .amg-section-child {
        text-align: center;
      }

      .amg-icon-image {
        padding: 16px 0;
        max-width: 100%;
        max-height: none;
      }
    }

  }

  .sponsor-container {

    background: $blueDark;

    > .amg-section-children {
      flex-direction: column;

      > .amg-section-child {
        padding-top: 0;
        padding-bottom: 0;
      }
    }

    .amg-icon-group {

      flex-wrap: wrap;
      //width: 100%;

      .amg-icon {
        //flex-grow: 1;
        padding: 0 16px;
        text-align: center;

        .amg-icon-image {
          max-width: 140px;
        }
      }
    }
  }

  .navigation-footer-container {

    > .amg-section-children > .amg-section-child > .amg-sections > .amg-section {
      padding: 16px 0;
    }

    .logo-container {
      //padding: 5px 16px;

      .amg-section-child {
        text-align: center;
      }

      .amg-icon {
        position: relative;

        .amg-icon-image {
          max-width: 200px;
        }

        .amg-icon-label {
          position: absolute;
          top: 100%;
          left: 0;
          margin: 10px 0 0;
          font-size: 14px;
        }
      }
    }

    .social-links-container {
      display: flex;

      .amg-section-children {
        display: flex;
        margin: auto;
      }
    }

    .navigation-links-container {
      flex-grow: 1;

      > .amg-section-children {
        display: flex;

      }

      .amg-section-child {
        width: auto;
        padding: 0 16px;
      }

      .amg-text-links {

        flex-direction: column;
        white-space: nowrap;

        .nav-link {
          font-family: 'Oswald', serif;
          text-align: left;
          color: $secondary-text-color;
          font-size: 14px;
          padding: 5px 0;
        }
      }
    }
  }

  .privacy-container {

    .amg-section-children {
      padding-top: 16px;
    }

    .amg-section-child {
      align-items: flex-end;
    }

    .amg-action-link {
      .amg-button-container {

        text-align: right;

        .amg-button {


          border-radius: 5px 5px 0 0;
          padding: 5px 20px;
        }
      }
    }
  }

  .copyrights-container {
    padding: 0 16px;

    .amg-section-title {
      font-weight: normal;
      color: $secondary-text-color;
      font-size: 14px;
    }
  }


  @include media-breakpoint-down(sm) {
    .navigation-footer-container {
      > .amg-section-children {
        > .amg-section-child {
          > .amg-sections {
            flex-direction: column;

            > .amg-section {
              //padding: 5px 0;
            }

            .social-links-container {

              > .amg-section-children > .amg-section-child {
                width: auto;
                padding: 0 10px;
              }
            }
          }
        }
      }
    }
  }
  @include media-breakpoint-up(sm) {

    font-size: 16px;

    .navigation-footer-container {

      //padding: 20px 20px 10px;
    }
  }
  @include media-breakpoint-up(md) {

    .sponsor-container {
      .amg-icon-group {
        .amg-icon {
          // padding: 10px 20px;

          .amg-icon-image {
            max-width: 170px;
          }
        }
      }
    }

    .copyrights-container {
      //padding: 0 20px;

      .amg-section-title {
        font-size: 16px;
        text-align: left;
      }
    }
  }
  @include media-breakpoint-up(lg) {
    .navigation-footer-container {
      .logo-container {
        .amg-icon {
          .amg-icon-image {
            max-width: 250px;
          }
        }
      }

      .navigation-links-container {


        .amg-section-child {
          //padding: 0 20px;
        }

        .amg-text-links {
          .nav-link {
            font-size: 16px;
            //padding: 10px;
          }
        }
      }
    }
  }

}


