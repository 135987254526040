.page-faq {
  background: $blueMed;

  &,
  .amg-section,
  .amg-section-children,
  .amg-section-child,
  .amg-input-field-container {
    display: block;
    height: auto;
  }

  .amg-input-field-container {
    .amg-input-field, .amg-text-area, .amg-select-field {
      border: none;
    }

    .amg-text-area {
      height: 150px;
    }

  }

  > *:not(:first-child) {

    padding-top: 20px;
    padding-bottom: 20px;


    .amg-section-title {
      color: $primary-text-color;
      font-size: 23px;
      margin-top: 20px;
      text-align: left;
    }

    .amg-section-description {
      color: $primary-text-color;
      font-size: 18px;
      text-align: left;
    }

  }

  .faq-container {

    > .amg-section-children {
      flex-direction: column;
      height: auto;


      > .amg-section-child {
        height: auto;
      }

      .amg-collapsible {
        margin: 10px 0 0;
        width: 100%;

        &.opened {
          .card-header {
            border-bottom: $card-border-width solid $card-border-color;

            button:after {
              transform: rotate(90deg);
            }
          }
        }

        .card-header {
          border-bottom: none;
          padding: 30px 15px;

          .amg-collapsible-titles {
            width: calc(100% - 42px);

          }

          .amg-collapsible-title {
            font-size: 18px;
          }

          button {
            text-indent: -9000px;
            position: relative;
            width: 32px;
            height: 32px;
            padding: 5px;

            &:before, &:after {
              content: '';
              position: absolute;
              background-color: $beigeLight;;
            }

            &:before {
              height: 2px;
              width: 20px;
              left: 5px;
              top: calc(50% - 1px);
            }

            &:after {
              height: 20px;
              width: 2px;
              transition: all .5s;
              transform: rotate(0deg);
              top: 5px;
              left: calc(50% - 1px);
            }

          }
        }

        > .card-body {
          padding: 10px;
          font-size: 13px;

          > div {
            margin: 0 0 10px;

            &:last-child {
              margin: 0;
            }
          }

          .amg-card-text {
            border: none;


            .card-body {
              padding: 0;
            }
          }
        }
      }
    }
  }

  .form-container {
    font-size: 18px;
    padding-bottom: 60px;

    .amg-section-description {
      margin-bottom: 20px;
    }

    > .amg-section-children > .amg-section-child {
      justify-content: start;
    }

    .amg-form-group {
      form {
        background-color: $blueLight;
        border-radius: 5px;
        height: auto;
      }

      .amg-form-message,
      .amg-form-input-group {
        padding: 18px;
        margin: 0;


        .amg-input-field-container {
          align-items: normal;

          > * {
            display: block;
            width: 100%;
          }

          .amg-input-field-label {
            color: $primary-text-color;
          }
        }
      }

      .amg-form-message {
        .message-error,
        .message-success {
          width: auto;
          color: $secondary-text-color;
        }

        .message-success:before {
          content: "Form has been submitted...";
        }

        .message-error:before {
          content: "Error while submitting form...";
        }
      }

      .amg-form-button-container {
        padding: 0 18px 18px;
        text-align: left;

        button {
          text-transform: uppercase;
          border: none;
          background-color: $beigeLight;
          font-size: 15px;
          border-radius: 20px;
        }

      }
    }
  }

  @include media-breakpoint-up(md) {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    > .navigation-container {
      width: 100%;
    }

    > *:not(:first-child) {
      padding-bottom: 60px;
      width: 50%;


      .amg-section-title {
        font-size: 28px;
      }

      .amg-section-description {
        font-size: 20px;
      }

    }

    .faq-container {
      padding-right: $grid-gutter-width / 4;

      .amg-section-title {
        margin-bottom: 24px;
      }
    }

    .form-container {
      padding-left: $grid-gutter-width / 4;
    }
  }

  @include media-breakpoint-up(HD) {
    #page-container & {
      .faq-container {
        padding-left: 140px;
        padding-right: 70px;

        > .amg-section-children .amg-collapsible > .card-body {
          font-size: 15px;
        }
      }

      .form-container {
        padding-right: 140px;
        padding-left: 70px;
      }
    }
  }

  @include media-breakpoint-up(FullHD) {

    .faq-container {

      > .amg-section-children .amg-collapsible {
        .card-header .amg-collapsible-title {
          font-size: 22px;
        }

        > .card-body {
          font-size: 18px;
        }
      }
    }

    .amg-input-field-container .amg-text-area {
      height: 250px;
    }

   /* > *:not(:first-child) {

      padding-left: 140px;
      padding-right: 140px;
    }*/
  }
}
