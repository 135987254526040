app-privacy-notice,
app-cookie-policy,
app-terms-and-conditions {
  max-width: #{map-get($grid-breakpoints, HD)};
  margin: 0 auto;
  padding-top: $grid-gutter-width;
  padding-bottom: $grid-gutter-width;

  &,
  .amg-section,
  .amg-section-children,
  .amg-section-child {
    display: block;
    height: auto;
  }

  .amg-section-title {
    color: $blueMed;
    font-size: 1.5em;
    margin: 0 0 30px;
  }

  .amg-section-description {
    text-align: left;

    > * {
      margin: $grid-padding-width 0;
      text-align: left;
      font-size: .9em;
    }

    h1,
    h2,
    h3,
    h4,
    h5 {
      margin-top: 30px;
      font-size: 1.2em;
    }

    ul {
      margin-left: $grid-padding-width * 2;
      padding: 0;

      /*    li {
            padding: 0 0 $grid-padding-width;
          }*/
    }

    td {
      padding: 5px;
    }

  }


}
