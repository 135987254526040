$thumbnailPlayIconSize: 37px;
$transitionSpeed: 0.333s;

.amg-card-video {
  border: 0;
  cursor: pointer;
  text-transform: uppercase;

  &:hover {
    &.card-video {
      .card-img-top {
        background-size: 120% auto;
      }
    }
  }

  &.card-video {
    .card-img-top {
      position: relative;
      background-size: 100% auto;
      transition: background-size $transitionSpeed ease-in-out;

      &:before {
        pointer-events: none;
        content: "";
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background: url('~src/assets/images/card-video-play.svg') center no-repeat transparent;
        background-position: 10px calc(100% - 10px);
        background-size: $thumbnailPlayIconSize;
      }
    }

  }

  .card-body {
    padding: 0;
    color: $greenDark;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;


    .categories,
    .entitlement,
    .runtime {
      line-height: 24px;
    }


    .categories {
      font-weight: 800;
      font-size: 14px;
      color: $blueMed;
      flex-grow: 1;
      //padding: 2px 0;

      .category {
        + .category {
          &:before {
            content: ", "
          }
        }
      }
    }


    .entitlement {
      margin-top: 2px;
      margin-right: 5px;

      > * {
        display: block;
        color: transparent;
        background: center no-repeat transparent;
        background-size: contain;
        width: 32px;
        height: 16px;
        background-image: url('~src/assets/images/card-video-paid.svg');
      }

      .entitlement-Registered,
      .entitlement-registered,
      [class="entitlement entitlement-*"] {
        background-image: url('~src/assets/images/card-video-registered.svg');
      }

      .entitlement-Free,
      .entitlement-free,
      .entitlement- {
        background-image: url('~src/assets/images/card-video-free.svg');
        background-size: auto 42px;
      }

    }

    div.runtime {
      font-size: 12px;
      color: $blueMed;
      position: relative;
      padding: 0 0 0 14px;

      &:before {
        position: absolute;
        content: "";
        top: 50%;
        transform: translate(0, -50%);
        left: 0;
        width: 0;
        height: 0;
        vertical-align: top;
        border-style: solid;
        border-width: 6px 0 6px 11px;
        border-color: transparent transparent transparent $blueMed;
        margin: 2px 3px 0 0;
      }

      .runtime {
        @each $i in (0, 1, 2, 3, 4, 5, 6, 7, 8, 9) {
          .hours[data-hours="0#{$i}"],
          .minutes[data-minutes="0#{$i}"],
          .seconds[data-seconds="0#{$i}"] {
            &:before {
              content: "0";
            }
          }
        }

        .hours,
        .minutes {
          &:after {
            content: ":";
          }
        }

        .hours[data-hours="00"] {
          display: none;
        }
      }
    }


    .title {
      width: 100%;
      font-weight: bold;
      text-transform: uppercase;
      font-size: 18px;
      padding-top: 5px;
    }

    .released {
      width: 100%;
      padding-top: 5px;
      font-size: 12px;
      color: $blueLight;
      display: flex;
      flex-direction: row;

      > * {
        &:after {
          content: "";
          margin-right: 4px;
        }
      }

      .days {
        order: 1;
      }

      .months {
        order: 2;
        color: transparent;

        &[data-months]:before {
          content: attr(data-month-name);
          color: $blueLight;
          text-transform: capitalize;
        }

        &:after {
          margin-right: 0;
        }
      }

      .years {
        order: 3;
        margin-left: -12px;
        position: relative;

        &:after {
          margin-right: 8px;
        }

        &:before {
          content: '';
          position: absolute;
          background-color: $blueLight;
          width: 1px;
          height: 70%;
          right: 3px;
          top: 50%;
          transform: translate(0, -50%);
        }
      }

      @each $i in (0, 1, 2, 3, 4, 5, 6, 7, 8, 9) {
        .hours[data-hours="0#{$i}"],
        .minutes[data-minutes="0#{$i}"],
        .seconds[data-seconds="0#{$i}"] {
          &:before {
            content: "0";
            margin-right: -2px;
          }
        }
      }

      .hours,
      .minutes,
      .seconds {
        text-align: right;
      }

      .hours {
        order: 4;

        &:after {
          content: ":";
          margin-right: 0;
          margin-left: -3px;
        }
      }

      .minutes {
        order: 5;
      }

      .seconds {
        display: none;
      }
    }
  }

}

.amg-card-video {
  .card-body {
    .released .years {
      margin-left: -6px;
    }
  }
}

.invert {
  .amg-card-video {
    background: transparent;

    .card-body {
      .released .years:after {
        border-right-color: $whiteLight;
      }

      &,
      .categories,
      .runtime,
      .released,
      .released .months:before {
        color: $whiteLight;
      }

      .entitlement {

        &.entitlement-null,
        &.entitlement-null {
          background-color: $whiteLight;
        }
      }

      div.runtime {
        &:before {
          border-left-color: $whiteLight;
        }
      }
    }
  }
}
