$items: 3.25;
$hoverItems: 3.25;
$itemMargin: ($grid-gutter-width / 2);
$borderRadius: 0;

.leeds-video-carousel {

  .amg-carousel-nav-container {
    bottom: initial;
  }

  .amg-card-video {
    display: block;
    padding-right: #{$itemMargin};

    &,
    >* {
      border-radius: #{$borderRadius};
    }

    .card-body {
      transition: opacity #{$transitionSpeed} ease-in-out;
    }
  }
}
