$menu-element-border: 1px solid #bd9b60;


#menu-container {
  background-color: #161616;
  color: #FFFFFF;
  text-transform: uppercase;
  border-top: $menu-element-border;

  .close-menu {
    display: none;
  }

  h1 {
    @include make-container-amg();
    margin: 0;
    font-size: 32px;
    font-weight: bold;
    padding: 25px 30px;

    border-bottom: $menu-element-border;
  }

  ul {
    list-style-type: none;
    padding-inline-start: 0;

    li {
      padding: 10px 20px;

      border-bottom: $menu-element-border;

      &.selected {
        background-color: #2b5697;
      }

      a {
        display: block;
        color: #FFFFFF;
        position: relative;
        padding: 15px 10px 15px 45px;
        // font-size: 18px;

        &:hover {
          text-decoration: none;
        }


        &:before {
          content: "";
          position: absolute;
          left: 0;
          width: 42px;
          height: 42px;
          top: 50%;
          transform: translateY(-50%);
          background-position: center;
          background-repeat: no-repeat;
          background-size: contain;
        }
      }


      // Navigation Icons
      &.your-profile a:before {
        background-image: url($image-path + "payments/myaccount-my-profile.svg");
      }

      &.billing-details a:before {
        background-image: url($image-path + "payments/myaccount-billing-details.svg");
      }

      &.active-subscriptions a:before {
        background-image: url($image-path + "payments/myaccount-active-subscriptions.svg");
      }

      &.recent-transactions a:before {
        background-image: url($image-path + "payments/myaccount-recent-transactions.svg");
      }
    }
  }
}

@include media-breakpoint-down(md) {
  html.menu-opened {
    overflow: hidden;
    height: 100%;
    width: 100%;
  }
  #menu-container {
    transition: all .7s;
    position: absolute;
    top: $header-height;
    left: 0;
    right: 0;
    height: 0;
    overflow: hidden;
    z-index: 300;

    &.opened {
      overflow: auto;
    }

    .menu-opened & {
      height: calc(100% - #{$header-height});
      border-bottom: none;
    }

    .close-menu {
      transform: rotate(-90deg);
      cursor: pointer;
      display: block;
      position: absolute;
      right: 20px;
      top: 30px;
      background-image: url($image-path + "payments/myaccount-arrow-menu.svg");
      background-repeat: no-repeat;
      background-position: center;
      background-size: 12px 20px;
      width: 40px;
      height: 40px;
    }
  }
}

/*

@include media-breakpoint-between(lg, xl) {
  #menu-container {
    h1 {
      font-size: 32px;
    }
  }
}
*/

@include media-breakpoint-up(lg) {
  #menu-container {
    width: 30%;

    h1, ul li {
      padding-right: 40px;
    }
  }
}

@include media-breakpoint-up(xl) {

  #menu-container {
    ul {
      li {
        padding-left: 90px;
      }
    }
  }

}
