.page-live {

  &,
  .amg-section,
  .amg-section-children,
  .amg-section-child,
  .amg-input-field-container,
  amg-wp-subscribe-packages {
    display: block;
    height: auto;
  }


  .amg-fixtures {
    .amg-message-container {
      color: $secondary-text-color;
    }
  }

  .navigation-container {
    .amg-section-description,
    .amg-section-title {
      font-weight: bold;
      color: $whiteLight;
      text-align: center;
    }
  }

  .whats-next-container,
  .next-live-container {
    margin-top: 2em !important;

    .amg-section-children,
    .amg-section-description,
    .amg-section-title {
      padding-left: 0 !important;
      padding-right: 0 !important;
    }

    .amg-section-description,
    .amg-section-title {
      text-align: left;
      font-size: 1em !important;
    }
  }

  .next-live-container {
    .amg-section-title {
      color: $greenDark !important;
      margin-bottom: 0.8em;
    }
  }

  .whats-next-container {
    position: relative;

    .amg-section-title {
      color: $beigeLight;
      font-size: 14pt;
      font-weight: bold;
    }

    .amg-section-description {
      color: $greenDark !important;
      margin-bottom: 0.8em;
      font-weight: bold;
    }

    .amg-section-children {
      flex-direction: column;

      .amg-section-child:first-child {
        order: 2;

        .amg-button-container{
          margin: 0 auto;
        }
      }

      .amg-section-child:last-child {
        margin-bottom: 0.8em;
        order: 1;
      }
    }
  }

  .subscription-packages-container {
    .amg-payment-fields-group {
      width: 100%;

      .amg-subscription-packages {
        margin-bottom: 0;

        .amg-subscription-package {
          background: url("~src/assets/images/backgournd-video-player-300x300.png") no-repeat;
          background-size: 100% 100%;

          .amg-icon-placeholder {
            background-size: 50px 50px;
            background: url("~src/assets/images/icon-video-white.svg") no-repeat center;
            color: white;
            height: 45px;
            width: 80px;
            position: absolute;
            top: 5%;
            left: 50%;
            margin-left: -40px;
          }

          .amg-subscription-package-inner {
            background-color: transparent;
          }

          .amg-title,
          .amg-amount,
          .amg-interval-group,
          .amg-body,
          .amg-button {
            color: $whiteLight !important;
          }
        }
      }
    }
  }

  > * {
    &:not(.navigation-container) {


      margin-bottom: $grid-padding-width;
    }
  }

  .card-subscription {
    margin-bottom: #{$grid-gutter-width / 2};

    &.card-watch {
      .amg-icon-image {
        width: 42pt;
        height: 31pt;
      }
    }

    &.card-listen {
      .amg-icon-image {
        width: 40pt;
        height: 40pt;
      }
    }

    .amg-icon-image {
      font-weight: bold;
    }

    .amg-section-children {
      padding-top: $grid-gutter-width / 2;

      > .amg-section-child {
        &:nth-child(1) {
          height: auto;
        }
      }

      .amg-button {
        color: $whiteLight;
      }
    }
  }

  @include media-breakpoint-up(md) {
    /* display: flex;
     flex-direction: row;
     flex-wrap: wrap;*/
    /*

    > * {
      flex: 0 0 100%;
      width: 100%;
    }
    */

    .card-subscription {
      flex: 0 0 calc(50% - #{$grid-gutter-width});
      width: 50%;

      &:nth-child(3) {
        margin-right: #{$grid-gutter-width / 4};
      }

      &:nth-child(4) {
        margin-left: #{$grid-gutter-width / 4};
      }
    }
  }

  @include media-breakpoint-up(FullHD) {
    .amg-subscription-packages .amg-subscription-package {
      &:nth-child(odd) {
        margin-right: 70px;
      }

      &:nth-child(even) {
        margin-left: 70px;
      }

    }
  }


  @include media-breakpoint-down(sm) {

    .whats-next-container {

      .amg-section-children {
        display: flex;
      }
    }

  }

  // Medium devices (tablets, 768px and up)
  @include media-breakpoint-up(md) {

    .whats-next-container {
      .amg-section-children {
        flex-direction: column;

        .amg-section-child:first-child {
          position: absolute;
          width: auto;
          height: auto;
          top: 0;
          right: $grid-padding-width;
          order: 1;
        }

        .amg-section-child:last-child {
          order: 2;
        }
      }
    }

  }

  @include media-breakpoint-up(HD) {

    .whats-next-container {
      .amg-section-children {
        .amg-section-child:first-child {
          right: 140px;
        }
      }
    }
  }
}
