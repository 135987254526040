$transitionSpeed: 0.333s;
$title-font-size: 18px;

@import "carousel-mixin";

.leeds-carousel {
  position: relative;
  display: block;
  padding-top: 50px;
  padding-bottom: 50px;
  width: 100%;

  .amg-section-children {
    display: block;
    > .amg-section-child {
      height: 0;
      display: block;
      &:last-child {
        height: auto;
      }
    }
  }

  amg-wp-carousel {
    width: 100%;
  }

  .amg-section-title {
    font-weight: bold;
    margin-bottom: 50px;
    font-size: $title-font-size;
    text-align: left;
    text-transform: uppercase;
  }

  .amg-action-link {
    position: absolute;
    top: 43px;
    right: 32px;
  }

  .amg-card-video {
    background: transparent;

    .card-body {
      padding-bottom: 0;
    }
  }

  .amg-carousel-nav-container {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    pointer-events: none;

    .amg-carousel-nav {
      width: 100%;
      height: 100%;

      button {
        pointer-events: auto;
        border: 0;
        color: transparent;
        background: url("~src/assets/images/chevron-right.png") center no-repeat
          transparentize($color: $blueDark, $amount: 0.25);
        background-size: 16pt auto;

        &:hover {
          background-color: transparentize($color: $blueDark, $amount: 0.25);
        }

        &[hidden] {
          display: block !important;
          pointer-events: none;
          opacity: 0;
        }
      }

      .prev {
        transform: rotate(180deg);
      }
    }
  }

  .amg-side-details {
    > div {
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
  }

  @include media-breakpoint-up(md) {
    $title-font-size: 22px;

    .amg-section-title {
      //margin-bottom: $title-font-size;
      font-size: $title-font-size;
    }
    .amg-action-link {
      top: 45px;
    }
  }

  @include media-breakpoint-up(FourK) {
    padding-top: 100px;
    padding-bottom: 100px;

    .amg-section-title {
      margin-bottom: 100px;
      font-size: 42px;
    }

    .amg-action-link {
      top: 90px;

      .btn {
        font-size: 30px;
        padding: 15px 30px;
      }
    }
  }

  &.invert {
    background: $blueDark;
  }
}

@import "carousel-nav";
@import "carousel-profile";
@import "carousel-boxsets";
@import "carousel-playlist";
@import "carousel-video";
