.dropdown {
  .btn {
    background-color: $whiteLight;
    border-color: $blueMed;
    color: $blueMed;
    outline: none;

    &.show {
      background-color: $blueMed;
      color: $whiteLight;
    }
  }
  .dropdown-menu {
    padding: 0;
    overflow: hidden;
  }
}
