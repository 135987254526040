$itemMargin: 16px;
$borderRadius: 16px;
$transitionSpeed: 0.333s;

.leeds-playlist-carousel,
.leeds-grid-playlist,
.grid-playlist {
  .amg-card-image,
  .amg-card-video {
    border: 0;
    border-radius: $borderRadius;
    cursor: pointer;
    overflow: hidden;

    .card-body {
      display: block;

      >* {
        display: none;
      }
    }

    .card-img-top,
    .card-body {

      .title {
        display: block;
      }

      >.title {
        color: $whiteLight;
        background: transparentize($color: #081A2A, $amount: .24);
        text-align: center;
        font-size: 17px;
        padding: 10px;
        bottom: 10px;

        @include media-breakpoint-up(md) {
          font-size: 23px;
        }
      }
    }
  }

  .amg-card-video {
    .card-img-top {
      &:before {
        display: none;
      }
    }

    .card-body {
      >.title {
        position: absolute;
      }
    }
  }
}
