/* You can add global styles to this file, and also import other style files */
/* set the overriding variables */
.amg-carousel-outer {
  display: flex;
  flex-direction: row; }
  .amg-carousel-outer.vertical {
    flex-direction: column-reverse; }

.amg-carousel {
  overflow: hidden;
  position: relative;
  user-select: none;
  width: 100%; }
  .amg-carousel .amg-carousel-dots-container + .amg-carousel-nav-container {
    bottom: 30px; }
  .amg-carousel .amg-carousel-container {
    width: 100%;
    margin: 0;
    overflow: hidden;
    overflow-x: scroll;
    /* has to be scroll, not auto */
    -webkit-overflow-scrolling: touch;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    -ms-overflow-style: none;
    scrollbar-width: none; }
    .amg-carousel .amg-carousel-container::-webkit-scrollbar {
      display: none; }
  .amg-carousel .col {
    padding: 0; }
    .amg-carousel .col > div {
      height: 100%; }

.amg-carousel-nav {
  display: flex;
  justify-content: space-between;
  align-content: stretch; }

.amg-carousel-dots {
  display: flex;
  justify-content: center;
  list-style: none;
  padding: 0;
  margin: 0 auto; }
  .amg-carousel-dots li {
    margin: 0 8px; }
    .amg-carousel-dots li,
    .amg-carousel-dots li button {
      width: 30px;
      height: 30px; }
    .amg-carousel-dots li button {
      background: transparent;
      border-width: 0;
      font-size: 52px;
      line-height: .5; }
  .amg-carousel-dots + .amg-carousel-nav {
    bottom: 30px; }

.amg-card-event .amg-card-event-details,
.amg-card-event .amg-card-event-meta {
  padding-top: 20px;
  padding-bottom: 20px; }

.amg-card-event .amg-card-event-details {
  padding-bottom: 10px; }

.amg-card-event .amg-card-event-meta {
  padding-top: 10px;
  text-align: center; }
  .amg-card-event .amg-card-event-meta .amg-card-calendar-fixture-sponsor {
    max-width: 100%; }

.amg-card-event .amg-card-event-info {
  display: flex;
  flex-direction: row;
  justify-content: space-between; }
  .amg-card-event .amg-card-event-info > * {
    flex: 0 0 33.333%;
    width: 33.333%; }
    .amg-card-event .amg-card-event-info > *:nth-child(1) {
      text-align: left; }
    .amg-card-event .amg-card-event-info > *:nth-child(2) {
      text-align: center; }
    .amg-card-event .amg-card-event-info > *:nth-child(3) {
      text-align: right; }
  .amg-card-event .amg-card-event-info .amg-date:nth-child(1) .time {
    display: none; }
  .amg-card-event .amg-card-event-info .amg-date:nth-child(2) .date {
    display: none; }

.amg-card-event .date .day:after,
.amg-card-event .date .month:after {
  content: "."; }

.amg-card-event .hour:after {
  content: ":"; }

.amg-card-event .amg-date .hour[data-date-hour="0"]:before,
.amg-card-event .amg-date .minute[data-date-minute="0"]:before,
.amg-card-event .amg-date .day[data-date-day="0"]:before,
.amg-card-event .amg-date .month[data-date-month="0"]:before {
  content: "0"; }

.amg-card-event .amg-date .hour[data-date-hour="1"]:before,
.amg-card-event .amg-date .minute[data-date-minute="1"]:before,
.amg-card-event .amg-date .day[data-date-day="1"]:before,
.amg-card-event .amg-date .month[data-date-month="1"]:before {
  content: "0"; }

.amg-card-event .amg-date .hour[data-date-hour="2"]:before,
.amg-card-event .amg-date .minute[data-date-minute="2"]:before,
.amg-card-event .amg-date .day[data-date-day="2"]:before,
.amg-card-event .amg-date .month[data-date-month="2"]:before {
  content: "0"; }

.amg-card-event .amg-date .hour[data-date-hour="3"]:before,
.amg-card-event .amg-date .minute[data-date-minute="3"]:before,
.amg-card-event .amg-date .day[data-date-day="3"]:before,
.amg-card-event .amg-date .month[data-date-month="3"]:before {
  content: "0"; }

.amg-card-event .amg-date .hour[data-date-hour="4"]:before,
.amg-card-event .amg-date .minute[data-date-minute="4"]:before,
.amg-card-event .amg-date .day[data-date-day="4"]:before,
.amg-card-event .amg-date .month[data-date-month="4"]:before {
  content: "0"; }

.amg-card-event .amg-date .hour[data-date-hour="5"]:before,
.amg-card-event .amg-date .minute[data-date-minute="5"]:before,
.amg-card-event .amg-date .day[data-date-day="5"]:before,
.amg-card-event .amg-date .month[data-date-month="5"]:before {
  content: "0"; }

.amg-card-event .amg-date .hour[data-date-hour="6"]:before,
.amg-card-event .amg-date .minute[data-date-minute="6"]:before,
.amg-card-event .amg-date .day[data-date-day="6"]:before,
.amg-card-event .amg-date .month[data-date-month="6"]:before {
  content: "0"; }

.amg-card-event .amg-date .hour[data-date-hour="7"]:before,
.amg-card-event .amg-date .minute[data-date-minute="7"]:before,
.amg-card-event .amg-date .day[data-date-day="7"]:before,
.amg-card-event .amg-date .month[data-date-month="7"]:before {
  content: "0"; }

.amg-card-event .amg-date .hour[data-date-hour="8"]:before,
.amg-card-event .amg-date .minute[data-date-minute="8"]:before,
.amg-card-event .amg-date .day[data-date-day="8"]:before,
.amg-card-event .amg-date .month[data-date-month="8"]:before {
  content: "0"; }

.amg-card-event .amg-date .hour[data-date-hour="9"]:before,
.amg-card-event .amg-date .minute[data-date-minute="9"]:before,
.amg-card-event .amg-date .day[data-date-day="9"]:before,
.amg-card-event .amg-date .month[data-date-month="9"]:before {
  content: "0"; }

.amg-card-fixture .amg-card-fixture-teams,
.amg-card-fixture .amg-card-fixture-meta {
  padding-top: 20px;
  padding-bottom: 20px; }

.amg-card-fixture .amg-card-fixture-teams {
  padding-bottom: 10px; }

.amg-card-fixture .amg-card-fixture-meta {
  padding-top: 10px;
  text-align: center; }

.amg-card-fixture .amg-card-fixture-info {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 8px 16px;
  background: grey; }
  .amg-card-fixture .amg-card-fixture-info > * {
    flex: 0 0 50%;
    width: 50%; }
    .amg-card-fixture .amg-card-fixture-info > *:nth-child(1) {
      text-align: left; }
    .amg-card-fixture .amg-card-fixture-info > *:nth-child(2) {
      text-align: right; }
  .amg-card-fixture .amg-card-fixture-info .amg-date .time {
    display: none; }

.amg-card-fixture .amg-card-fixture-teams {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around; }
  .amg-card-fixture .amg-card-fixture-teams > * {
    flex: 0 0 33.333%;
    width: 33.333%; }
  .amg-card-fixture .amg-card-fixture-teams .amg-card-fixture-team .amg-card-fixture-team-icon {
    max-width: 48px;
    margin-right: 8px; }
  .amg-card-fixture .amg-card-fixture-teams .amg-card-fixture-team-home {
    order: 1; }
  .amg-card-fixture .amg-card-fixture-teams .t {
    order: 2;
    text-align: center; }
    .amg-card-fixture .amg-card-fixture-teams .t .amg-card-fixture-sponsor {
      max-width: 100%; }
    .amg-card-fixture .amg-card-fixture-teams .t .date {
      display: none; }
  .amg-card-fixture .amg-card-fixture-teams .amg-card-fixture-team-away {
    order: 3; }

.amg-card-fixture .date .day:after,
.amg-card-fixture .date .month:after {
  content: "."; }

.amg-card-fixture .hour:after {
  content: ":"; }

.amg-card-fixture .hour[data-date-hour="0"]:before,
.amg-card-fixture .minute[data-date-minute="0"]:before,
.amg-card-fixture .day[data-date-day="0"]:before,
.amg-card-fixture .month[data-date-month="0"]:before {
  content: "0"; }

.amg-card-fixture .hour[data-date-hour="1"]:before,
.amg-card-fixture .minute[data-date-minute="1"]:before,
.amg-card-fixture .day[data-date-day="1"]:before,
.amg-card-fixture .month[data-date-month="1"]:before {
  content: "0"; }

.amg-card-fixture .hour[data-date-hour="2"]:before,
.amg-card-fixture .minute[data-date-minute="2"]:before,
.amg-card-fixture .day[data-date-day="2"]:before,
.amg-card-fixture .month[data-date-month="2"]:before {
  content: "0"; }

.amg-card-fixture .hour[data-date-hour="3"]:before,
.amg-card-fixture .minute[data-date-minute="3"]:before,
.amg-card-fixture .day[data-date-day="3"]:before,
.amg-card-fixture .month[data-date-month="3"]:before {
  content: "0"; }

.amg-card-fixture .hour[data-date-hour="4"]:before,
.amg-card-fixture .minute[data-date-minute="4"]:before,
.amg-card-fixture .day[data-date-day="4"]:before,
.amg-card-fixture .month[data-date-month="4"]:before {
  content: "0"; }

.amg-card-fixture .hour[data-date-hour="5"]:before,
.amg-card-fixture .minute[data-date-minute="5"]:before,
.amg-card-fixture .day[data-date-day="5"]:before,
.amg-card-fixture .month[data-date-month="5"]:before {
  content: "0"; }

.amg-card-fixture .hour[data-date-hour="6"]:before,
.amg-card-fixture .minute[data-date-minute="6"]:before,
.amg-card-fixture .day[data-date-day="6"]:before,
.amg-card-fixture .month[data-date-month="6"]:before {
  content: "0"; }

.amg-card-fixture .hour[data-date-hour="7"]:before,
.amg-card-fixture .minute[data-date-minute="7"]:before,
.amg-card-fixture .day[data-date-day="7"]:before,
.amg-card-fixture .month[data-date-month="7"]:before {
  content: "0"; }

.amg-card-fixture .hour[data-date-hour="8"]:before,
.amg-card-fixture .minute[data-date-minute="8"]:before,
.amg-card-fixture .day[data-date-day="8"]:before,
.amg-card-fixture .month[data-date-month="8"]:before {
  content: "0"; }

.amg-card-fixture .hour[data-date-hour="9"]:before,
.amg-card-fixture .minute[data-date-minute="9"]:before,
.amg-card-fixture .day[data-date-day="9"]:before,
.amg-card-fixture .month[data-date-month="9"]:before {
  content: "0"; }

.amg-card-image.card-image .card-img-top {
  background-image: none;
  background-position: center center;
  background-repeat: no-repeat;
  background-color: transparent;
  background-size: cover;
  padding-top: 100%;
  position: relative;
  pointer-events: none; }
  .amg-card-image.card-image .card-img-top > img {
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0; }
  .amg-card-image.card-image .card-img-top > .title {
    background: rgba(255, 255, 255, 0.5);
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0; }

.amg-card-text.card-text .card-img-top {
  background-image: none;
  background-position: center center;
  background-repeat: no-repeat;
  background-color: transparent;
  background-size: cover;
  padding-top: 100%;
  position: relative; }
  .amg-card-text.card-text .card-img-top > img {
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0; }
  .amg-card-text.card-text .card-img-top .title {
    background: rgba(255, 255, 255, 0.5);
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0; }

.amg-card-video.card-video .card-img-top {
  background-image: none;
  background-position: center center;
  background-repeat: no-repeat;
  background-color: transparent;
  background-size: cover;
  padding-top: 56.25%;
  position: relative;
  pointer-events: none; }
  .amg-card-video.card-video .card-img-top > img {
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0; }

.amg-collapsible .amg-collapsible-titles > *:empty {
  display: none; }

.amg-collapsible .amg-collapsible-titles > *:last-child {
  margin-bottom: 0; }

.amg-collapse-bottom .collapse {
  display: block;
  overflow: hidden;
  transition: all 333ms ease-in-out; }
  .amg-collapse-bottom .collapse.show {
    max-height: 999px; }
  .amg-collapse-bottom .collapse:not(.show) {
    max-height: 0;
    padding-top: 0;
    padding-bottom: 0; }

.amg-video-player-component .amg-video-thumbnail-wrapper, .amg-video-player-component .amg-play-btn-wrapper, .amg-toggler .slider, .amg-thumbnail-overlay-text, amg-playlist-card .amg-playlist-thumbnail-wrapper .amg-thumbnail-overlay, .amg-video-player-component .amg-video-player > * {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0; }

.amg-video-player-component .amg-play-btn-wrapper {
  display: flex;
  justify-content: center;
  align-items: center; }

.amg-video-player-component {
  display: block;
  width: 100%;
  padding: 0;
  margin: 0;
  max-width: none; }
  .amg-video-player-component.player-locked {
    position: fixed;
    z-index: 9999;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0; }
  .amg-video-player-component amg-video-player {
    display: inline-block;
    width: 100%; }
  .amg-video-player-component .amg-video-player {
    min-height: 180px;
    position: relative;
    overflow: hidden; }
  .amg-video-player-component .amg-video-thumbnail-wrapper {
    overflow: hidden; }
    .amg-video-player-component .amg-video-thumbnail-wrapper img {
      max-width: 100%; }
  .amg-video-player-component amg-login-overlay {
    z-index: 99;
    position: absolute;
    background: rgba(0, 0, 0, 0.65);
    color: #fff;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    justify-content: center;
    align-content: center; }
  .amg-video-player-component .amg-collapse-toggler {
    display: inline-block;
    -webkit-touch-callout: none;
    /* iOS Safari */
    -webkit-user-select: none;
    /* Safari */
    -khtml-user-select: none;
    /* Konqueror HTML */
    -moz-user-select: none;
    /* Firefox */
    -ms-user-select: none;
    /* Internet Explorer/Edge */
    user-select: none;
    cursor: pointer; }
    .amg-video-player-component .amg-collapse-toggler.rotate45cw {
      transform: translateX(14%) translateY(2%) rotate(45deg); }
  .amg-video-player-component .amg-player-content-wrapper {
    width: 100%; }
  .amg-video-player-component .amg-video-player-component.amg-live-player .amg-fixture-thumbnail {
    display: none; }

.amg-countdown .amg-countdown-progress {
  display: flex; }

.amg-grid > .row {
  margin: 0 auto; }
  .amg-grid > .row > .col {
    padding: 0; }

.amg-grid .amg-filter-dropdown .ng-dropdown-panel {
  background-color: #fff;
  border: 1px solid #ccc;
  box-shadow: 0 1px 0 rgba(0, 0, 0, 0.06);
  left: 0; }

/**
  * Helper classes to use
  *  xs:  > 575px           (portrait phones)
  *  sm:  576px - 767px     (landscape phones)
  *  md:  768px - 991px     (tablets)
  *  lg:  992px - 1199px    (desktops)
  *  xl:  1200px <          (large desktops)
  */
.desktop-only {
  display: flex; }

.mobile-only {
  display: none; }

@media (max-width: 768px) {
  .desktop-only {
    display: none; }
  .mobile-only {
    display: flex; } }

.amg-header,
.amg-footer,
.amg-page {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  height: 100%; }

.amg-overlay {
  width: 100%;
  height: 100%;
  display: none; }

.amg-section {
  display: flex;
  flex-direction: column; }
  .amg-section .amg-section-title,
  .amg-section .amg-section-description,
  .amg-section .amg-section-child,
  .amg-section .amg-section-children {
    width: 100%; }
  .amg-section .amg-section-child,
  .amg-section .amg-section-children {
    height: 100%;
    display: flex;
    align-content: center;
    align-items: center;
    justify-content: center; }
  .amg-section .amg-section-child {
    flex-direction: column; }
  .amg-section .amg-section-children {
    flex-direction: row; }

.amg-sections {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%; }

.basic-button, .amg-action-link .amg-button-container .amg-button, .amg-form-group .amg-form-button-container .amg-form-button, .amg-login-button-group .amg-login-btn,
.amg-login-button-group .amg-logout-btn,
.amg-login-button-group .amg-register-btn,
.amg-login-button-group .amg-subscribe-btn, amg-auth-modal .amg-template amg-form-builder form button, amg-template-builder .amg-template amg-form-builder form button {
  color: #FFFFFF;
  background-color: #0E2A51;
  border-color: #FFFFFF; }
  .basic-button:hover, .amg-action-link .amg-button-container .amg-button:hover, .amg-form-group .amg-form-button-container .amg-form-button:hover, .amg-login-button-group .amg-login-btn:hover,
  .amg-login-button-group .amg-logout-btn:hover,
  .amg-login-button-group .amg-register-btn:hover,
  .amg-login-button-group .amg-subscribe-btn:hover, amg-auth-modal .amg-template amg-form-builder form button:hover, amg-template-builder .amg-template amg-form-builder form button:hover {
    background-color: #16417c; }

.amg-action-link {
  display: flex;
  align-items: baseline; }
  .amg-action-link .amg-link-container .amg-nav-link {
    cursor: pointer; }
    .amg-action-link .amg-link-container .amg-nav-link:hover {
      text-decoration: none; }

.amg-icon.amg-icon-hover {
  cursor: pointer; }

.amg-icon .amg-icon-image {
  max-width: 250px;
  max-height: 150px; }

.amg-input-field-container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column; }
  .amg-input-field-container .amg-select-field,
  .amg-input-field-container .amg-text-area,
  .amg-input-field-container .amg-input-field {
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    line-height: 1.5;
    background-color: #FFFFFF;
    background-clip: padding-box;
    border: 1px solid #495057;
    border-radius: 0.25rem; }

.amg-content-projection {
  width: 100%;
  height: 100%; }

.amg-navigation-bar {
  display: flex; }
  .amg-navigation-bar div {
    display: flex;
    align-items: center; }
    .amg-navigation-bar div .navbar-nav {
      display: flex;
      flex-direction: row;
      list-style: none;
      width: 100%; }
      .amg-navigation-bar div .navbar-nav .nav-item {
        margin: 0.25em 1em; }
    .amg-navigation-bar div .amg-navbar-extra,
    .amg-navigation-bar div .navbar-toggler {
      display: none; }

/* Alterations for Mobile */
.amg-navigation-bar div {
  /* Small devices (landscape phones, 576px and up) (sm) */
  /* Medium devices (tablets, 768px and up) The navbar toggle appears at this breakpoint (md) */
  /* Large devices (desktops, 992px and up) (lg) */
  /* Extra large devices (large desktops, 1200px and up) (xl) */ }
  @media (max-width: 768px) {
    .amg-navigation-bar div .navbar-nav {
      flex-direction: column; }
    .amg-navigation-bar div .amg-navbar-extra,
    .amg-navigation-bar div .navbar-toggler {
      display: flex; } }

.amg-social-links {
  display: flex;
  flex-direction: row;
  height: 100%;
  align-items: center;
  margin-top: 0.1em; }
  .amg-social-links * {
    display: block;
    margin: 0 0.2em; }
  .amg-social-links .amg-icon .amg-icon-image {
    max-height: 30px;
    max-width: 30px; }

.amg-advertisement {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center; }

.amg-icon-group {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row; }

.amg-form-group {
  width: 100%;
  height: 100%; }
  .amg-form-group .amg-form-input-group {
    margin-bottom: 1rem; }
  .amg-form-group .amg-form-button-container {
    text-align: right; }

.amg-text-links {
  width: 100%;
  display: flex;
  flex-direction: row; }
  .amg-text-links .amg-action-link {
    margin-left: 0.3em;
    margin-right: 0.3em; }

.amg-login-button-group {
  display: flex;
  align-items: center;
  flex-direction: row; }

.amg-payment-fields-group {
  display: flex;
  align-items: center; }

amg-auth-modal .amg-template {
  padding: 1em; }
  amg-auth-modal .amg-template .amg-logo-wrapper {
    text-align: center; }
  amg-auth-modal .amg-template amg-form-builder form {
    margin-top: 2em; }
    amg-auth-modal .amg-template amg-form-builder form .amg-form-field {
      margin-top: 0.5em;
      width: 100%;
      padding: 0.375rem 0.75rem;
      font-size: 1rem;
      line-height: 1.5;
      background-color: #FFFFFF;
      background-clip: padding-box;
      border: 1px solid #495057;
      border-radius: 0.25rem; }
    amg-auth-modal .amg-template amg-form-builder form button {
      margin-top: 0.5em;
      display: inline-block;
      font-weight: 400;
      text-align: center;
      vertical-align: middle;
      user-select: none;
      padding: 0.375rem 0.75rem;
      font-size: 1rem;
      line-height: 1.5;
      border-radius: 0.25rem;
      transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  amg-auth-modal .amg-template .amg-cta-btn-wrapper {
    margin-top: 0.5em; }

body {
  font-family: "futura-pt", sans-serif;
  font-size: 18pt;
  line-height: 1.2; }

.nav-link {
  font-weight: normal;
  text-align: center;
  color: #FFFFFF;
  font-size: 16px; }
  .nav-link:hover {
    color: #FFFFFF; }

.amg-section-title {
  font-weight: bold;
  text-align: center;
  color: #2F2F2F;
  font-size: 22.5pt; }
  @media (min-width: 768px) {
    .amg-section-title {
      font-size: 40pt; } }

.amg-section-description {
  text-align: center;
  color: #2F2F2F;
  font-size: 18px;
  line-height: initial; }
  @media (min-width: 768px) {
    .amg-section-description {
      font-size: 22px; } }

.amg-action-link:not(.btn-my-account) .amg-button-container .amg-button {
  border-radius: 50pt;
  color: #FFFFFF;
  border-color: #2B5697;
  background-color: #2B5697; }
  @media (min-width: 3840px) {
    .amg-action-link:not(.btn-my-account) .amg-button-container .amg-button {
      font-size: 26pt;
      padding: 14pt 24pt; } }

.invert .amg-section-title,
.invert .amg-section-description,
.navigation-container .amg-section-title,
.navigation-container .amg-section-description {
  color: #FFFFFF; }

.invert .amg-action-link .amg-button-container .amg-button,
.navigation-container .amg-action-link .amg-button-container .amg-button {
  color: #2B5697;
  border-color: #FFFFFF;
  background-color: #FFFFFF; }

.amg-logo > img.amg-icon-image {
  max-width: 155px;
  max-height: 62px; }

.amg-login-btn {
  background: url("~src/assets/images/icon-user-white.svg") 12px center no-repeat transparent;
  padding-left: 34px;
  margin-right: 5px; }

.show-logged-in,
.show-logged-out {
  display: none !important; }

[is-logged-in="false"] .show-logged-out,
[is-logged-in="true"] .show-logged-in {
  display: block !important; }

.amg-video-player-component .amg-video-thumbnail-wrapper, .amg-video-player-component .amg-play-btn-wrapper, .amg-toggler .slider, .amg-thumbnail-overlay-text, amg-playlist-card .amg-playlist-thumbnail-wrapper .amg-thumbnail-overlay, .amg-video-player-component .amg-video-player > * {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0; }

.mobile-only {
  display: block; }

.amg-section-bg-image {
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat; }

@media (min-width: 769px) {
  .mobile-only {
    display: none; } }

@media (max-width: 767.98px) {
  /* .desktop-only {
     display: none;
   }
   .mobile-only {
     display: block;
   }*/
  .home-highlight .amg-sections {
    flex-direction: column; } }

.banner {
  background: #BD9B60; }
  .banner,
  .banner .amg-sections,
  .banner .amg-section,
  .banner .amg-section-children,
  .banner .amg-section-child,
  .banner .amg-icon,
  .banner .amg-action-link {
    display: block;
    height: auto; }
  .banner,
  .banner .amg-section-title,
  .banner .amg-section-description,
  .banner .amg-icon {
    color: #FFFFFF;
    text-align: left; }
  .banner .amg-action-link {
    display: block;
    text-align: right; }
    .banner .amg-action-link .amg-button-container .amg-button {
      border-radius: 50pt;
      color: #2F2F2F;
      border-color: #FFFFFF;
      background: #FFFFFF; }
  .banner > .amg-section-children {
    padding-top: 16px;
    padding-bottom: 16px; }
    .banner > .amg-section-children .amg-section-children {
      height: auto;
      flex-wrap: wrap; }
      .banner > .amg-section-children .amg-section-children > .amg-section-child {
        flex: auto; }
        .banner > .amg-section-children .amg-section-children > .amg-section-child:not(:last-child) {
          margin: 16px 0; }
        .banner > .amg-section-children .amg-section-children > .amg-section-child:nth-child(1) .amg-icon, .banner > .amg-section-children .amg-section-children > .amg-section-child:nth-child(2) .amg-icon {
          display: flex;
          flex-direction: row-reverse;
          align-items: center;
          color: #2F2F2F; }
          .banner > .amg-section-children .amg-section-children > .amg-section-child:nth-child(1) .amg-icon .amg-icon-image, .banner > .amg-section-children .amg-section-children > .amg-section-child:nth-child(2) .amg-icon .amg-icon-image {
            max-height: 42pt;
            margin-right: 8px; }
          .banner > .amg-section-children .amg-section-children > .amg-section-child:nth-child(1) .amg-icon > *, .banner > .amg-section-children .amg-section-children > .amg-section-child:nth-child(2) .amg-icon > * {
            margin-bottom: 0; }
        .banner > .amg-section-children .amg-section-children > .amg-section-child:nth-child(3) {
          align-items: flex-start; }
  @media (min-width: 768px) {
    .banner > .amg-section-children .amg-section {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-template-rows: 1fr 1fr;
      grid-template-areas: "a c" "b c";
      width: 100%; }
      .banner > .amg-section-children .amg-section .amg-section-title {
        grid-area: a; }
      .banner > .amg-section-children .amg-section .amg-section-description {
        grid-area: b; }
      .banner > .amg-section-children .amg-section .amg-section-children {
        grid-area: c;
        flex-wrap: nowrap; }
        .banner > .amg-section-children .amg-section .amg-section-children > .amg-section-child {
          flex: auto;
          width: auto !important;
          margin-left: 8px !important; } }

.banner .amg-section-title {
  font-size: 21pt; }

.banner .amg-section-description {
  font-size: 14pt; }

.banner .amg-icon {
  font-size: 16pt; }

.banner .btn {
  white-space: nowrap; }

@media (min-width: 768px) {
  .banner .amg-section-title {
    font-size: 24pt; }
  .banner .amg-section-description {
    font-size: 23pt; }
  .banner .amg-icon {
    font-size: 20pt; } }

.banner-free-access .amg-action-link {
  width: 100%; }

@media (min-width: 768px) {
  .banner-free-access > .amg-section-children .amg-section .amg-section-title,
  .banner-free-access > .amg-section-children .amg-section .amg-section-description {
    width: 140%; }
  .banner-free-access > .amg-section-children .amg-section .amg-section-children {
    width: 60%;
    margin-left: 40%; } }

.banner-match-pass {
  /*@include media-breakpoint-up(md) {
    > .amg-section-children {
      .amg-section {
        .amg-section-children {
          > .amg-section-child {
            flex: auto;
            width: auto !important;
            margin-left: $grid-gutter-width / 4 !important;
          }
        }
      }
    }
  }*/ }
  .banner-match-pass > .amg-section-children .amg-section-children {
    display: flex; }
    .banner-match-pass > .amg-section-children .amg-section-children > .amg-section-child {
      display: flex;
      width: auto;
      align-items: flex-start; }
      .banner-match-pass > .amg-section-children .amg-section-children > .amg-section-child:nth-child(1), .banner-match-pass > .amg-section-children .amg-section-children > .amg-section-child:nth-child(2) {
        width: 50%; }
      .banner-match-pass > .amg-section-children .amg-section-children > .amg-section-child:nth-child(3) {
        width: 100%;
        align-items: flex-start; }

.leeds-boxset-carousel .amg-card-image,
.leeds-boxset-carousel .amg-card-video {
  cursor: pointer;
  color: #FFFFFF;
  position: relative;
  z-index: 0; }
  .leeds-boxset-carousel .amg-card-image, .leeds-boxset-carousel .amg-card-image:after, .leeds-boxset-carousel .amg-card-image:before,
  .leeds-boxset-carousel .amg-card-image .card-img-top:after,
  .leeds-boxset-carousel .amg-card-video,
  .leeds-boxset-carousel .amg-card-video:after,
  .leeds-boxset-carousel .amg-card-video:before,
  .leeds-boxset-carousel .amg-card-video .card-img-top:after {
    transition: transform 0.333s ease-in-out 0s; }
  .leeds-boxset-carousel .amg-card-image, .leeds-boxset-carousel .amg-card-image:before, .leeds-boxset-carousel .amg-card-image:after,
  .leeds-boxset-carousel .amg-card-video,
  .leeds-boxset-carousel .amg-card-video:before,
  .leeds-boxset-carousel .amg-card-video:after {
    box-shadow: 0pt 3pt 6pt 0pt rgba(0, 0, 0, 0.16); }
  .leeds-boxset-carousel .amg-card-image:before, .leeds-boxset-carousel .amg-card-image:after,
  .leeds-boxset-carousel .amg-card-video:before,
  .leeds-boxset-carousel .amg-card-video:after {
    display: block;
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    pointer-events: none; }
  .leeds-boxset-carousel .amg-card-image:after,
  .leeds-boxset-carousel .amg-card-video:after {
    background: #2B5697;
    top: -10px;
    left: 10px;
    right: -10px;
    bottom: 10px;
    z-index: -1; }
  .leeds-boxset-carousel .amg-card-image:before,
  .leeds-boxset-carousel .amg-card-video:before {
    background: #0E2A51;
    top: -20px;
    left: 20px;
    right: -20px;
    bottom: 20px;
    z-index: -2; }
  .leeds-boxset-carousel .amg-card-image,
  .leeds-boxset-carousel .amg-card-image .card-img-top,
  .leeds-boxset-carousel .amg-card-video,
  .leeds-boxset-carousel .amg-card-video .card-img-top {
    background-color: transparent;
    border-radius: 0; }
  .leeds-boxset-carousel .amg-card-image .card-img-top,
  .leeds-boxset-carousel .amg-card-video .card-img-top {
    position: relative;
    background-color: #588BBC;
    padding-top: 100%; }
    .leeds-boxset-carousel .amg-card-image .card-img-top:before,
    .leeds-boxset-carousel .amg-card-video .card-img-top:before {
      display: block;
      content: "";
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      background: url("~src/assets/images/card-video-play.svg") center no-repeat transparent; }
  .leeds-boxset-carousel .amg-card-image .title,
  .leeds-boxset-carousel .amg-card-video .title {
    display: none; }
  .leeds-boxset-carousel .amg-card-image .card-body,
  .leeds-boxset-carousel .amg-card-video .card-body {
    display: none; }

.leeds-boxset-carousel:not(.leeds-sidebar-carousel) .amg-card-image .card-body,
.leeds-boxset-carousel:not(.leeds-sidebar-carousel) .amg-card-video .card-body {
  display: block; }
  .leeds-boxset-carousel:not(.leeds-sidebar-carousel) .amg-card-image .card-body > *,
  .leeds-boxset-carousel:not(.leeds-sidebar-carousel) .amg-card-video .card-body > * {
    display: none; }

.leeds-boxset-carousel:not(.leeds-sidebar-carousel) .amg-card-image .card-img-top .title,
.leeds-boxset-carousel:not(.leeds-sidebar-carousel) .amg-card-image .card-body .title,
.leeds-boxset-carousel:not(.leeds-sidebar-carousel) .amg-card-video .card-img-top .title,
.leeds-boxset-carousel:not(.leeds-sidebar-carousel) .amg-card-video .card-body .title {
  display: block; }

.leeds-boxset-carousel:not(.leeds-sidebar-carousel) .amg-card-image .card-img-top > .title,
.leeds-boxset-carousel:not(.leeds-sidebar-carousel) .amg-card-image .card-body > .title,
.leeds-boxset-carousel:not(.leeds-sidebar-carousel) .amg-card-video .card-img-top > .title,
.leeds-boxset-carousel:not(.leeds-sidebar-carousel) .amg-card-video .card-body > .title {
  color: #FFFFFF;
  background: rgba(8, 26, 42, 0.76);
  text-align: center;
  font-size: 17px;
  padding: 10px;
  bottom: 10px; }
  @media (min-width: 768px) {
    .leeds-boxset-carousel:not(.leeds-sidebar-carousel) .amg-card-image .card-img-top > .title,
    .leeds-boxset-carousel:not(.leeds-sidebar-carousel) .amg-card-image .card-body > .title,
    .leeds-boxset-carousel:not(.leeds-sidebar-carousel) .amg-card-video .card-img-top > .title,
    .leeds-boxset-carousel:not(.leeds-sidebar-carousel) .amg-card-video .card-body > .title {
      font-size: 23px; } }

.leeds-boxset-carousel:not(.leeds-sidebar-carousel) .amg-card-video .card-body > .title {
  position: absolute; }

.amg-fixture.calendar-card {
  width: 100%; }

.amg-card-calendar-fixture {
  width: 100%;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #F7F7F7;
  flex-direction: column;
  border-bottom: #588BBC solid 1px; }
  .amg-card-calendar-fixture .amg-card-calendar-fixture-info {
    background-color: #E6E7E8;
    display: flex;
    flex-direction: row;
    width: 100%;
    padding: 5px 5%;
    position: relative; }
    .amg-card-calendar-fixture .amg-card-calendar-fixture-info .amg-card-calendar-fixture-sponsor {
      flex: 1 1 10%;
      display: flex;
      align-items: center; }
      .amg-card-calendar-fixture .amg-card-calendar-fixture-info .amg-card-calendar-fixture-sponsor .amg-card-calendar-fixture-sponsor-img {
        margin-right: 0.5em;
        width: 35px;
        height: 35px; }
    .amg-card-calendar-fixture .amg-card-calendar-fixture-info .amg-date {
      display: inline-block;
      font-size: 1.1em;
      flex: 1 1 30%;
      align-self: center; }
      .amg-card-calendar-fixture .amg-card-calendar-fixture-info .amg-date .time {
        display: none; }
      .amg-card-calendar-fixture .amg-card-calendar-fixture-info .amg-date .date {
        font-family: futura-pt-condensed, serif;
        font-size: 25px;
        color: #2B5697; }
        .amg-card-calendar-fixture .amg-card-calendar-fixture-info .amg-date .date .year:before {
          content: '.'; }
        .amg-card-calendar-fixture .amg-card-calendar-fixture-info .amg-date .date .month:before {
          content: '.'; }
    .amg-card-calendar-fixture .amg-card-calendar-fixture-info .amg-card-calendar-fixture-stadium {
      color: #588BBC;
      display: block;
      flex: 1 1 50%;
      font-size: 0.8em;
      align-self: center;
      text-align: right;
      max-width: 100%; }
  .amg-card-calendar-fixture .amg-card-calendar-fixture-teams {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin: 8% auto;
    position: relative;
    font-size: 0.7em; }
    .amg-card-calendar-fixture .amg-card-calendar-fixture-teams .amg-card-calendar-fixture-team {
      text-align: center;
      min-width: 100px;
      padding: 16px;
      width: 50%; }
    .amg-card-calendar-fixture .amg-card-calendar-fixture-teams .amg-card-calendar-fixture-team-icon {
      width: 10vw;
      max-width: 65px;
      height: auto;
      margin-bottom: 3px; }
    .amg-card-calendar-fixture .amg-card-calendar-fixture-teams .amg-card-calendar-fixture-team-home {
      padding-right: 40px; }
    .amg-card-calendar-fixture .amg-card-calendar-fixture-teams .amg-card-calendar-fixture-team-away {
      padding-left: 40px; }
    .amg-card-calendar-fixture .amg-card-calendar-fixture-teams .amg-date {
      font-size: 1.5em;
      left: 0;
      position: absolute;
      width: 100%;
      text-align: center;
      font-weight: bold; }
      .amg-card-calendar-fixture .amg-card-calendar-fixture-teams .amg-date .date {
        display: none; }
      .amg-card-calendar-fixture .amg-card-calendar-fixture-teams .amg-date .time .minute:before {
        content: ':'; }
  .amg-card-calendar-fixture .amg-card-calendar-fixture-meta {
    display: flex;
    flex-direction: row;
    align-items: center; }
    .amg-card-calendar-fixture .amg-card-calendar-fixture-meta .btn {
      width: 100pt;
      height: 33pt;
      padding: 0 1px;
      margin: 0 0.8em 2em 0.8em;
      flex-direction: column;
      align-content: center;
      position: relative;
      text-align: center;
      align-items: center;
      font-size: 14px;
      border-radius: 50px; }
      .amg-card-calendar-fixture .amg-card-calendar-fixture-meta .btn .placeholder-2:before {
        content: "";
        border-radius: 100%;
        width: 5pt;
        height: 5pt;
        display: inline-block;
        vertical-align: text-top;
        margin-top: 2pt; }
      .amg-card-calendar-fixture .amg-card-calendar-fixture-meta .btn[disabled] {
        border: #B5B5B5 2pt solid;
        background-color: #D5D5D5; }
        .amg-card-calendar-fixture .amg-card-calendar-fixture-meta .btn[disabled] .placeholder-2:before {
          background-color: #4A4848; }
      .amg-card-calendar-fixture .amg-card-calendar-fixture-meta .btn:not([disabled]) {
        border: #BD9B60 2pt solid;
        background-color: #F7F7F7; }
        .amg-card-calendar-fixture .amg-card-calendar-fixture-meta .btn:not([disabled]) .placeholder-2:before {
          background-color: #FF0000; }
      .amg-card-calendar-fixture .amg-card-calendar-fixture-meta .btn.amg-audio-button .placeholder-1 {
        background-image: url("~src/assets/images/audio-icon-black.png");
        background-size: 21pt 21pt;
        background-repeat: no-repeat;
        background-position: center;
        width: 21pt;
        height: 21pt; }
      .amg-card-calendar-fixture .amg-card-calendar-fixture-meta .btn.amg-video-button .placeholder-1 {
        background-image: url("~src/assets/images/video-icon-black.png");
        background-size: 24pt 16pt;
        background-repeat: no-repeat;
        background-position: center;
        width: 24pt;
        height: 16pt; }
      .amg-card-calendar-fixture .amg-card-calendar-fixture-meta .btn .text-group,
      .amg-card-calendar-fixture .amg-card-calendar-fixture-meta .btn .placeholder-2,
      .amg-card-calendar-fixture .amg-card-calendar-fixture-meta .btn .placeholder-1 {
        display: inline-block; }
      .amg-card-calendar-fixture .amg-card-calendar-fixture-meta .btn .placeholder-1,
      .amg-card-calendar-fixture .amg-card-calendar-fixture-meta .btn .placeholder-2 {
        margin-right: 5px; }
      .amg-card-calendar-fixture .amg-card-calendar-fixture-meta .btn .text-group,
      .amg-card-calendar-fixture .amg-card-calendar-fixture-meta .btn .placeholder-1 {
        vertical-align: middle; }
      .amg-card-calendar-fixture .amg-card-calendar-fixture-meta .btn .placeholder-2 {
        vertical-align: super; }
      .amg-card-calendar-fixture .amg-card-calendar-fixture-meta .btn .live,
      .amg-card-calendar-fixture .amg-card-calendar-fixture-meta .btn .audio {
        display: block;
        height: 15px;
        line-height: 15px;
        text-align: left; }

.amg-calendar .amg-carousel-calendar-tabs .amg-carousel .amg-card-text {
  border: #F4F4F4 1px solid;
  border-top-left-radius: 7px;
  border-top-right-radius: 7px;
  background-color: #FAFAFA; }
  .amg-calendar .amg-carousel-calendar-tabs .amg-carousel .amg-card-text .card-body {
    display: flex;
    flex-direction: column;
    text-align: center; }
    .amg-calendar .amg-carousel-calendar-tabs .amg-carousel .amg-card-text .card-body .month {
      font-weight: bold;
      color: #2B5697;
      font-size: 0.9em;
      order: 2; }
    .amg-calendar .amg-carousel-calendar-tabs .amg-carousel .amg-card-text .card-body .year {
      color: #588BBC;
      font-size: 0.9em;
      order: 1; }

.amg-calendar .amg-carousel-calendar-tabs .amg-carousel .active .amg-card-text {
  background-color: #F4F4F4; }
  .amg-calendar .amg-carousel-calendar-tabs .amg-carousel .active .amg-card-text .card-body .month {
    color: #BD9B60 !important; }

@media (min-width: 768px) {
  .amg-card-calendar-fixture .amg-card-calendar-fixture-teams .amg-card-calendar-fixture-team {
    text-align: center;
    min-width: 100px;
    flex-grow: 1; }
  .amg-card-calendar-fixture .amg-card-calendar-fixture-meta .btn {
    width: 123pt;
    height: 45pt;
    padding: 0.3em 0.75em;
    font-size: 18px; }
    .amg-card-calendar-fixture .amg-card-calendar-fixture-meta .btn .placeholder-2:before {
      margin-top: 1pt; }
    .amg-card-calendar-fixture .amg-card-calendar-fixture-meta .btn.amg-audio-button .placeholder-1 {
      background-size: 27pt 27pt;
      width: 27pt;
      height: 27pt; }
    .amg-card-calendar-fixture .amg-card-calendar-fixture-meta .btn.amg-video-button .placeholder-1 {
      background-size: 29pt 21pt;
      width: 29pt;
      height: 21pt; }
    .amg-card-calendar-fixture .amg-card-calendar-fixture-meta .btn .placeholder-2,
    .amg-card-calendar-fixture .amg-card-calendar-fixture-meta .btn .placeholder-1 {
      margin-right: 10pt; }
    .amg-card-calendar-fixture .amg-card-calendar-fixture-meta .btn .live,
    .amg-card-calendar-fixture .amg-card-calendar-fixture-meta .btn .audio {
      height: 20px;
      line-height: 20px; } }

@media (min-width: 992px) {
  .amg-card-calendar-fixture {
    flex-direction: row; }
    .amg-card-calendar-fixture .amg-card-calendar-fixture-info {
      align-items: center;
      align-content: flex-start;
      flex-direction: column;
      display: flex;
      flex-wrap: wrap;
      padding: 20px 10px;
      max-width: 250px;
      height: 160px; }
      .amg-card-calendar-fixture .amg-card-calendar-fixture-info .amg-card-calendar-fixture-sponsor {
        flex: 1 1 100%;
        align-self: flex-end;
        margin-right: 0.5em; }
        .amg-card-calendar-fixture .amg-card-calendar-fixture-info .amg-card-calendar-fixture-sponsor .amg-card-calendar-fixture-sponsor-img {
          width: 55px;
          height: 55px;
          margin-bottom: 8px; }
      .amg-card-calendar-fixture .amg-card-calendar-fixture-info .amg-date,
      .amg-card-calendar-fixture .amg-card-calendar-fixture-info .amg-card-calendar-fixture-stadium {
        align-self: flex-start;
        text-align: left;
        display: flex;
        flex: 1 1 50%; }
      .amg-card-calendar-fixture .amg-card-calendar-fixture-info .amg-date {
        align-items: flex-end; }
      .amg-card-calendar-fixture .amg-card-calendar-fixture-info .amg-card-calendar-fixture-stadium {
        align-items: flex-start;
        max-width: 160px;
        word-break: break-word;
        width: 100%; }
    .amg-card-calendar-fixture .amg-card-calendar-fixture-teams {
      margin: 0; }
    .amg-card-calendar-fixture .amg-card-calendar-fixture-meta {
      margin-top: 0.5em;
      flex-direction: column; }
      .amg-card-calendar-fixture .amg-card-calendar-fixture-meta .btn {
        margin: 0 0.8em 0.8em 0.8em; } }

.amg-fixtures,
.amg-fixture {
  width: 100%; }

.amg-card-fixture .amg-card-fixture-sponsor-img {
  max-width: 100%; }

.leeds-playlist-carousel .amg-card-image,
.leeds-playlist-carousel .amg-card-video,
.leeds-grid-playlist .amg-card-image,
.leeds-grid-playlist .amg-card-video,
.grid-playlist .amg-card-image,
.grid-playlist .amg-card-video {
  border: 0;
  border-radius: 16px;
  cursor: pointer;
  overflow: hidden; }
  .leeds-playlist-carousel .amg-card-image .card-body,
  .leeds-playlist-carousel .amg-card-video .card-body,
  .leeds-grid-playlist .amg-card-image .card-body,
  .leeds-grid-playlist .amg-card-video .card-body,
  .grid-playlist .amg-card-image .card-body,
  .grid-playlist .amg-card-video .card-body {
    display: block; }
    .leeds-playlist-carousel .amg-card-image .card-body > *,
    .leeds-playlist-carousel .amg-card-video .card-body > *,
    .leeds-grid-playlist .amg-card-image .card-body > *,
    .leeds-grid-playlist .amg-card-video .card-body > *,
    .grid-playlist .amg-card-image .card-body > *,
    .grid-playlist .amg-card-video .card-body > * {
      display: none; }
  .leeds-playlist-carousel .amg-card-image .card-img-top .title,
  .leeds-playlist-carousel .amg-card-image .card-body .title,
  .leeds-playlist-carousel .amg-card-video .card-img-top .title,
  .leeds-playlist-carousel .amg-card-video .card-body .title,
  .leeds-grid-playlist .amg-card-image .card-img-top .title,
  .leeds-grid-playlist .amg-card-image .card-body .title,
  .leeds-grid-playlist .amg-card-video .card-img-top .title,
  .leeds-grid-playlist .amg-card-video .card-body .title,
  .grid-playlist .amg-card-image .card-img-top .title,
  .grid-playlist .amg-card-image .card-body .title,
  .grid-playlist .amg-card-video .card-img-top .title,
  .grid-playlist .amg-card-video .card-body .title {
    display: block; }
  .leeds-playlist-carousel .amg-card-image .card-img-top > .title,
  .leeds-playlist-carousel .amg-card-image .card-body > .title,
  .leeds-playlist-carousel .amg-card-video .card-img-top > .title,
  .leeds-playlist-carousel .amg-card-video .card-body > .title,
  .leeds-grid-playlist .amg-card-image .card-img-top > .title,
  .leeds-grid-playlist .amg-card-image .card-body > .title,
  .leeds-grid-playlist .amg-card-video .card-img-top > .title,
  .leeds-grid-playlist .amg-card-video .card-body > .title,
  .grid-playlist .amg-card-image .card-img-top > .title,
  .grid-playlist .amg-card-image .card-body > .title,
  .grid-playlist .amg-card-video .card-img-top > .title,
  .grid-playlist .amg-card-video .card-body > .title {
    color: #FFFFFF;
    background: rgba(8, 26, 42, 0.76);
    text-align: center;
    font-size: 17px;
    padding: 10px;
    bottom: 10px; }
    @media (min-width: 768px) {
      .leeds-playlist-carousel .amg-card-image .card-img-top > .title,
      .leeds-playlist-carousel .amg-card-image .card-body > .title,
      .leeds-playlist-carousel .amg-card-video .card-img-top > .title,
      .leeds-playlist-carousel .amg-card-video .card-body > .title,
      .leeds-grid-playlist .amg-card-image .card-img-top > .title,
      .leeds-grid-playlist .amg-card-image .card-body > .title,
      .leeds-grid-playlist .amg-card-video .card-img-top > .title,
      .leeds-grid-playlist .amg-card-video .card-body > .title,
      .grid-playlist .amg-card-image .card-img-top > .title,
      .grid-playlist .amg-card-image .card-body > .title,
      .grid-playlist .amg-card-video .card-img-top > .title,
      .grid-playlist .amg-card-video .card-body > .title {
        font-size: 23px; } }

.leeds-playlist-carousel .amg-card-video .card-img-top:before,
.leeds-grid-playlist .amg-card-video .card-img-top:before,
.grid-playlist .amg-card-video .card-img-top:before {
  display: none; }

.leeds-playlist-carousel .amg-card-video .card-body > .title,
.leeds-grid-playlist .amg-card-video .card-body > .title,
.grid-playlist .amg-card-video .card-body > .title {
  position: absolute; }

.amg-card-profile {
  border: 0;
  cursor: pointer;
  color: #0E2A51; }
  .amg-card-profile,
  .amg-card-profile .card-img-top {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    background-color: transparent;
    border-radius: 16pt;
    overflow: hidden; }
  .amg-card-profile .card-img-top {
    box-shadow: inset 0pt 3pt 6pt 0pt rgba(0, 0, 0, 0.16);
    position: relative;
    padding-top: 100%; }
    .amg-card-profile .card-img-top:before {
      content: "";
      position: absolute;
      bottom: 10px;
      left: 10px;
      background: url("~src/assets/images/card-video-play.svg") center no-repeat transparent;
      width: 35px;
      height: 35px;
      background-size: contain; }
    .amg-card-profile .card-img-top img {
      display: none; }
  .amg-card-profile .card-body {
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap;
    padding-left: 0;
    padding-right: 0; }
    .amg-card-profile .card-body div {
      font-size: 17px; }
      .amg-card-profile .card-body div.number, .amg-card-profile .card-body div.name-first, .amg-card-profile .card-body div.name-last, .amg-card-profile .card-body div.position {
        text-transform: uppercase; }
      .amg-card-profile .card-body div.number {
        font-size: 32px;
        font-style: italic;
        color: #2B5697;
        order: 1;
        width: 100%;
        flex: none;
        text-align: center; }
        .amg-card-profile .card-body div.number:before {
          content: "";
          background: url("~src/assets/images/icon-shirt.svg") center no-repeat transparent;
          background-size: contain;
          padding: 0 25px 0 0; }
      .amg-card-profile .card-body div.name-first, .amg-card-profile .card-body div.name-last {
        width: auto; }
      .amg-card-profile .card-body div.name-first {
        font-weight: 500;
        font-size: 26px;
        order: 2;
        text-align: right;
        padding-right: 8px; }
      .amg-card-profile .card-body div.name-last {
        font-size: 26px;
        font-weight: bold;
        order: 3;
        text-align: left;
        padding-left: 8px; }
      .amg-card-profile .card-body div.position {
        display: none; }
      @media (min-width: 768px) {
        .amg-card-profile .card-body div {
          font-size: 17px; }
          .amg-card-profile .card-body div.number {
            font-size: 32px; }
          .amg-card-profile .card-body div.name-first {
            font-size: 26px; }
          .amg-card-profile .card-body div.name-last {
            font-size: 26px; } }

.card-subscription {
  border: 2pt solid #BD9B60;
  background: #FFFFFF; }
  .card-subscription > .amg-section-children {
    position: relative;
    /* not good!!

    >.amg-section-child {
      position: relative;

      &:nth-child(2) {
        position: absolute;
        top: 0;
        right: 0;
        pointer-events: none;
        * {
          pointer-events: auto;
        }
      }
    }*/ }
  .card-subscription .amg-icon.title-icon {
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    justify-content: flex-end;
    z-index: 0;
    max-width: 100%;
    color: #2B5697;
    /* doesn't work

    &:before {
      content: "";
      display: block;
      height: 0;
      border: 5pt solid $whiteLight;
      border-left-width: 0;
      border-right-width: 0;
      position: absolute;
      top: 50%;
      left: -($grid-gutter-width / 2);
      right: -($grid-gutter-width / 2);
      z-index: -1;
    }*/ }
    .card-subscription .amg-icon.title-icon > * {
      margin-bottom: 0; }
    .card-subscription .amg-icon.title-icon .amg-icon-image {
      height: 46pt;
      background: #FFFFFF; }
    .card-subscription .amg-icon.title-icon .amg-icon-label {
      margin: 0 0 0 16px;
      max-width: 100%;
      font-size: 14pt; }
      @media (min-width: 768px) {
        .card-subscription .amg-icon.title-icon .amg-icon-label {
          font-size: 23pt; } }
  .card-subscription .amg-icon.top-icon {
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    margin: 0 0 16px; }
    .card-subscription .amg-icon.top-icon > * {
      margin-bottom: 0; }
    .card-subscription .amg-icon.top-icon .amg-icon-image {
      height: 46pt;
      margin: 0 16px 0 0; }
  .card-subscription .amg-icon.faq-icon {
    position: absolute;
    top: 8px;
    right: 8px; }
    .card-subscription .amg-icon.faq-icon .amg-icon-image {
      height: 26pt; }
  .card-subscription .amg-text-group .amg-text-field {
    display: block;
    margin-bottom: 16px; }
    .card-subscription .amg-text-group .amg-text-field:nth-child(1) {
      font-weight: bold;
      font-size: 20pt;
      text-align: center;
      color: #2B5697; }
    .card-subscription .amg-text-group .amg-text-field:nth-child(2) {
      font-size: 16pt;
      text-align: center;
      color: #0E2A51; }
    .card-subscription .amg-text-group .amg-text-field:nth-child(3), .card-subscription .amg-text-group .amg-text-field:nth-child(4) {
      display: inline-block;
      width: 50%;
      font-weight: bold;
      font-size: 20pt;
      text-align: center; }
    .card-subscription .amg-text-group .amg-text-field:nth-child(3) {
      color: #2B5697; }
    .card-subscription .amg-text-group .amg-text-field:nth-child(4) {
      color: #0E2A51; }
  .card-subscription .amg-action-link .amg-button-container .amg-button {
    border-color: #BD9B60;
    border-radius: 50pt;
    background: #BD9B60;
    margin: 0 auto; }
    .card-subscription .amg-action-link .amg-button-container .amg-button:hover {
      border-color: #9C835E;
      background: #9C835E; }
  .card-subscription .amg-section-children {
    display: flex;
    flex-direction: column;
    padding: 16px; }
  .card-subscription.card-subscription-free .amg-section-children:before {
    background-size: 50px 50px;
    content: " ";
    background: #FFFFFF url("~src/assets/images/icon-video-beige.svg") no-repeat center;
    color: black;
    height: 55px;
    width: 90px;
    position: absolute;
    top: 0;
    left: 50%;
    margin-top: -27px;
    margin-left: -45px;
    border: 5px solid #FFFFFF; }
  .card-subscription.card-subscription-free .amg-section-children .amg-section-child .amg-action-link .amg-button-container .amg-button {
    border-color: #2B5697;
    background: #2B5697; }
    .card-subscription.card-subscription-free .amg-section-children .amg-section-child .amg-action-link .amg-button-container .amg-button:hover {
      border-color: #0E2A51;
      background: #0E2A51; }

.invert.card-subscription .amg-icon.top-icon,
.invert.card-subscription .amg-icon.title-icon,
.invert.card-subscription .amg-text-group .amg-text-field {
  color: #FFFFFF; }

.amg-card-video {
  border: 0;
  cursor: pointer;
  text-transform: uppercase; }
  .amg-card-video:hover.card-video .card-img-top {
    background-size: 120% auto; }
  .amg-card-video.card-video .card-img-top {
    position: relative;
    background-size: 100% auto;
    transition: background-size 0.333s ease-in-out; }
    .amg-card-video.card-video .card-img-top:before {
      pointer-events: none;
      content: "";
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      background: url("~src/assets/images/card-video-play.svg") center no-repeat transparent;
      background-position: 10px calc(100% - 10px);
      background-size: 37px; }
  .amg-card-video .card-body {
    padding: 0;
    color: #2F2F2F;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center; }
    .amg-card-video .card-body .categories,
    .amg-card-video .card-body .entitlement,
    .amg-card-video .card-body .runtime {
      line-height: 24px; }
    .amg-card-video .card-body .categories {
      font-weight: 800;
      font-size: 14px;
      color: #2B5697;
      flex-grow: 1; }
      .amg-card-video .card-body .categories .category + .category:before {
        content: ", "; }
    .amg-card-video .card-body .entitlement {
      margin-top: 2px;
      margin-right: 5px; }
      .amg-card-video .card-body .entitlement > * {
        display: block;
        color: transparent;
        background: center no-repeat transparent;
        background-size: contain;
        width: 32px;
        height: 16px;
        background-image: url("~src/assets/images/card-video-paid.svg"); }
      .amg-card-video .card-body .entitlement .entitlement-Registered,
      .amg-card-video .card-body .entitlement .entitlement-registered,
      .amg-card-video .card-body .entitlement [class="entitlement entitlement-*"] {
        background-image: url("~src/assets/images/card-video-registered.svg"); }
      .amg-card-video .card-body .entitlement .entitlement-Free,
      .amg-card-video .card-body .entitlement .entitlement-free,
      .amg-card-video .card-body .entitlement .entitlement- {
        background-image: url("~src/assets/images/card-video-free.svg");
        background-size: auto 42px; }
    .amg-card-video .card-body div.runtime {
      font-size: 12px;
      color: #2B5697;
      position: relative;
      padding: 0 0 0 14px; }
      .amg-card-video .card-body div.runtime:before {
        position: absolute;
        content: "";
        top: 50%;
        transform: translate(0, -50%);
        left: 0;
        width: 0;
        height: 0;
        vertical-align: top;
        border-style: solid;
        border-width: 6px 0 6px 11px;
        border-color: transparent transparent transparent #2B5697;
        margin: 2px 3px 0 0; }
      .amg-card-video .card-body div.runtime .runtime .hours[data-hours="00"]:before,
      .amg-card-video .card-body div.runtime .runtime .minutes[data-minutes="00"]:before,
      .amg-card-video .card-body div.runtime .runtime .seconds[data-seconds="00"]:before {
        content: "0"; }
      .amg-card-video .card-body div.runtime .runtime .hours[data-hours="01"]:before,
      .amg-card-video .card-body div.runtime .runtime .minutes[data-minutes="01"]:before,
      .amg-card-video .card-body div.runtime .runtime .seconds[data-seconds="01"]:before {
        content: "0"; }
      .amg-card-video .card-body div.runtime .runtime .hours[data-hours="02"]:before,
      .amg-card-video .card-body div.runtime .runtime .minutes[data-minutes="02"]:before,
      .amg-card-video .card-body div.runtime .runtime .seconds[data-seconds="02"]:before {
        content: "0"; }
      .amg-card-video .card-body div.runtime .runtime .hours[data-hours="03"]:before,
      .amg-card-video .card-body div.runtime .runtime .minutes[data-minutes="03"]:before,
      .amg-card-video .card-body div.runtime .runtime .seconds[data-seconds="03"]:before {
        content: "0"; }
      .amg-card-video .card-body div.runtime .runtime .hours[data-hours="04"]:before,
      .amg-card-video .card-body div.runtime .runtime .minutes[data-minutes="04"]:before,
      .amg-card-video .card-body div.runtime .runtime .seconds[data-seconds="04"]:before {
        content: "0"; }
      .amg-card-video .card-body div.runtime .runtime .hours[data-hours="05"]:before,
      .amg-card-video .card-body div.runtime .runtime .minutes[data-minutes="05"]:before,
      .amg-card-video .card-body div.runtime .runtime .seconds[data-seconds="05"]:before {
        content: "0"; }
      .amg-card-video .card-body div.runtime .runtime .hours[data-hours="06"]:before,
      .amg-card-video .card-body div.runtime .runtime .minutes[data-minutes="06"]:before,
      .amg-card-video .card-body div.runtime .runtime .seconds[data-seconds="06"]:before {
        content: "0"; }
      .amg-card-video .card-body div.runtime .runtime .hours[data-hours="07"]:before,
      .amg-card-video .card-body div.runtime .runtime .minutes[data-minutes="07"]:before,
      .amg-card-video .card-body div.runtime .runtime .seconds[data-seconds="07"]:before {
        content: "0"; }
      .amg-card-video .card-body div.runtime .runtime .hours[data-hours="08"]:before,
      .amg-card-video .card-body div.runtime .runtime .minutes[data-minutes="08"]:before,
      .amg-card-video .card-body div.runtime .runtime .seconds[data-seconds="08"]:before {
        content: "0"; }
      .amg-card-video .card-body div.runtime .runtime .hours[data-hours="09"]:before,
      .amg-card-video .card-body div.runtime .runtime .minutes[data-minutes="09"]:before,
      .amg-card-video .card-body div.runtime .runtime .seconds[data-seconds="09"]:before {
        content: "0"; }
      .amg-card-video .card-body div.runtime .runtime .hours:after,
      .amg-card-video .card-body div.runtime .runtime .minutes:after {
        content: ":"; }
      .amg-card-video .card-body div.runtime .runtime .hours[data-hours="00"] {
        display: none; }
    .amg-card-video .card-body .title {
      width: 100%;
      font-weight: bold;
      text-transform: uppercase;
      font-size: 18px;
      padding-top: 5px; }
    .amg-card-video .card-body .released {
      width: 100%;
      padding-top: 5px;
      font-size: 12px;
      color: #588BBC;
      display: flex;
      flex-direction: row; }
      .amg-card-video .card-body .released > *:after {
        content: "";
        margin-right: 4px; }
      .amg-card-video .card-body .released .days {
        order: 1; }
      .amg-card-video .card-body .released .months {
        order: 2;
        color: transparent; }
        .amg-card-video .card-body .released .months[data-months]:before {
          content: attr(data-month-name);
          color: #588BBC;
          text-transform: capitalize; }
        .amg-card-video .card-body .released .months:after {
          margin-right: 0; }
      .amg-card-video .card-body .released .years {
        order: 3;
        margin-left: -12px;
        position: relative; }
        .amg-card-video .card-body .released .years:after {
          margin-right: 8px; }
        .amg-card-video .card-body .released .years:before {
          content: '';
          position: absolute;
          background-color: #588BBC;
          width: 1px;
          height: 70%;
          right: 3px;
          top: 50%;
          transform: translate(0, -50%); }
      .amg-card-video .card-body .released .hours[data-hours="00"]:before,
      .amg-card-video .card-body .released .minutes[data-minutes="00"]:before,
      .amg-card-video .card-body .released .seconds[data-seconds="00"]:before {
        content: "0";
        margin-right: -2px; }
      .amg-card-video .card-body .released .hours[data-hours="01"]:before,
      .amg-card-video .card-body .released .minutes[data-minutes="01"]:before,
      .amg-card-video .card-body .released .seconds[data-seconds="01"]:before {
        content: "0";
        margin-right: -2px; }
      .amg-card-video .card-body .released .hours[data-hours="02"]:before,
      .amg-card-video .card-body .released .minutes[data-minutes="02"]:before,
      .amg-card-video .card-body .released .seconds[data-seconds="02"]:before {
        content: "0";
        margin-right: -2px; }
      .amg-card-video .card-body .released .hours[data-hours="03"]:before,
      .amg-card-video .card-body .released .minutes[data-minutes="03"]:before,
      .amg-card-video .card-body .released .seconds[data-seconds="03"]:before {
        content: "0";
        margin-right: -2px; }
      .amg-card-video .card-body .released .hours[data-hours="04"]:before,
      .amg-card-video .card-body .released .minutes[data-minutes="04"]:before,
      .amg-card-video .card-body .released .seconds[data-seconds="04"]:before {
        content: "0";
        margin-right: -2px; }
      .amg-card-video .card-body .released .hours[data-hours="05"]:before,
      .amg-card-video .card-body .released .minutes[data-minutes="05"]:before,
      .amg-card-video .card-body .released .seconds[data-seconds="05"]:before {
        content: "0";
        margin-right: -2px; }
      .amg-card-video .card-body .released .hours[data-hours="06"]:before,
      .amg-card-video .card-body .released .minutes[data-minutes="06"]:before,
      .amg-card-video .card-body .released .seconds[data-seconds="06"]:before {
        content: "0";
        margin-right: -2px; }
      .amg-card-video .card-body .released .hours[data-hours="07"]:before,
      .amg-card-video .card-body .released .minutes[data-minutes="07"]:before,
      .amg-card-video .card-body .released .seconds[data-seconds="07"]:before {
        content: "0";
        margin-right: -2px; }
      .amg-card-video .card-body .released .hours[data-hours="08"]:before,
      .amg-card-video .card-body .released .minutes[data-minutes="08"]:before,
      .amg-card-video .card-body .released .seconds[data-seconds="08"]:before {
        content: "0";
        margin-right: -2px; }
      .amg-card-video .card-body .released .hours[data-hours="09"]:before,
      .amg-card-video .card-body .released .minutes[data-minutes="09"]:before,
      .amg-card-video .card-body .released .seconds[data-seconds="09"]:before {
        content: "0";
        margin-right: -2px; }
      .amg-card-video .card-body .released .hours,
      .amg-card-video .card-body .released .minutes,
      .amg-card-video .card-body .released .seconds {
        text-align: right; }
      .amg-card-video .card-body .released .hours {
        order: 4; }
        .amg-card-video .card-body .released .hours:after {
          content: ":";
          margin-right: 0;
          margin-left: -3px; }
      .amg-card-video .card-body .released .minutes {
        order: 5; }
      .amg-card-video .card-body .released .seconds {
        display: none; }

.amg-card-video .card-body .released .years {
  margin-left: -6px; }

.invert .amg-card-video {
  background: transparent; }
  .invert .amg-card-video .card-body .released .years:after {
    border-right-color: #FFFFFF; }
  .invert .amg-card-video .card-body,
  .invert .amg-card-video .card-body .categories,
  .invert .amg-card-video .card-body .runtime,
  .invert .amg-card-video .card-body .released,
  .invert .amg-card-video .card-body .released .months:before {
    color: #FFFFFF; }
  .invert .amg-card-video .card-body .entitlement.entitlement-null, .invert .amg-card-video .card-body .entitlement.entitlement-null {
    background-color: #FFFFFF; }
  .invert .amg-card-video .card-body div.runtime:before {
    border-left-color: #FFFFFF; }

.leeds-carousel {
  position: relative;
  display: block;
  padding-top: 50px;
  padding-bottom: 50px;
  width: 100%; }
  .leeds-carousel .amg-section-children {
    display: block; }
    .leeds-carousel .amg-section-children > .amg-section-child {
      height: 0;
      display: block; }
      .leeds-carousel .amg-section-children > .amg-section-child:last-child {
        height: auto; }
  .leeds-carousel amg-wp-carousel {
    width: 100%; }
  .leeds-carousel .amg-section-title {
    font-weight: bold;
    margin-bottom: 50px;
    font-size: 18px;
    text-align: left;
    text-transform: uppercase; }
  .leeds-carousel .amg-action-link {
    position: absolute;
    top: 43px;
    right: 32px; }
  .leeds-carousel .amg-card-video {
    background: transparent; }
    .leeds-carousel .amg-card-video .card-body {
      padding-bottom: 0; }
  .leeds-carousel .amg-carousel-nav-container {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    pointer-events: none; }
    .leeds-carousel .amg-carousel-nav-container .amg-carousel-nav {
      width: 100%;
      height: 100%; }
      .leeds-carousel .amg-carousel-nav-container .amg-carousel-nav button {
        pointer-events: auto;
        border: 0;
        color: transparent;
        background: url("~src/assets/images/chevron-right.png") center no-repeat rgba(14, 42, 81, 0.75);
        background-size: 16pt auto; }
        .leeds-carousel .amg-carousel-nav-container .amg-carousel-nav button:hover {
          background-color: rgba(14, 42, 81, 0.75); }
        .leeds-carousel .amg-carousel-nav-container .amg-carousel-nav button[hidden] {
          display: block !important;
          pointer-events: none;
          opacity: 0; }
      .leeds-carousel .amg-carousel-nav-container .amg-carousel-nav .prev {
        transform: rotate(180deg); }
  .leeds-carousel .amg-side-details > div {
    display: flex;
    flex-direction: column;
    justify-content: center; }
  @media (min-width: 768px) {
    .leeds-carousel .amg-section-title {
      font-size: 22px; }
    .leeds-carousel .amg-action-link {
      top: 45px; } }
  @media (min-width: 3840px) {
    .leeds-carousel {
      padding-top: 100px;
      padding-bottom: 100px; }
      .leeds-carousel .amg-section-title {
        margin-bottom: 100px;
        font-size: 42px; }
      .leeds-carousel .amg-action-link {
        top: 90px; }
        .leeds-carousel .amg-action-link .btn {
          font-size: 30px;
          padding: 15px 30px; } }
  .leeds-carousel.invert {
    background: #0E2A51; }

.leeds-carousel {
  width: calc(100% + 16px);
  width: calc(100% + 16px);
  width: calc(100% + 16px);
  width: calc(100% + 16px);
  width: calc(100% + 16px);
  width: calc(100% + 16px);
  width: calc(100% + 16px);
  width: calc(100% + 16px);
  width: calc(100% + 16px);
  width: calc(100% + 16px);
  width: calc(100% + 16px);
  width: calc(100% + 16px);
  width: calc(100% + 16px);
  width: calc(100% + 16px);
  width: calc(100% + 16px); }
  .leeds-carousel .amg-carousel-nav-container {
    z-index: 1; }
    .leeds-carousel .amg-carousel-nav-container .amg-carousel-nav button {
      position: absolute;
      top: 0;
      bottom: 0; }
    .leeds-carousel .amg-carousel-nav-container .amg-carousel-nav .prev {
      left: 0; }
    .leeds-carousel .amg-carousel-nav-container .amg-carousel-nav .next {
      right: 0; }
  .leeds-carousel .amg-carousel-container[data-slide-visible="1.25"] + .amg-carousel-nav-container .amg-carousel-nav button {
    width: calc(((100% + 16px) / 1.25 * 0.25) - 16px); }
  .leeds-carousel .amg-carousel-container[data-slide-visible="1.5"] + .amg-carousel-nav-container .amg-carousel-nav button {
    width: calc(((100% + 16px) / 1.5 * 0.5) - 16px); }
  .leeds-carousel .amg-carousel-container[data-slide-visible="1.75"] + .amg-carousel-nav-container .amg-carousel-nav button {
    width: calc(((100% + 16px) / 1.75 * 0.75) - 16px); }
  .leeds-carousel .amg-carousel-container[data-slide-visible="2.25"] + .amg-carousel-nav-container .amg-carousel-nav button {
    width: calc(((100% + 16px) / 2.25 * 0.25) - 16px); }
  .leeds-carousel .amg-carousel-container[data-slide-visible="2.5"] + .amg-carousel-nav-container .amg-carousel-nav button {
    width: calc(((100% + 16px) / 2.5 * 0.5) - 16px); }
  .leeds-carousel .amg-carousel-container[data-slide-visible="2.75"] + .amg-carousel-nav-container .amg-carousel-nav button {
    width: calc(((100% + 16px) / 2.75 * 0.75) - 16px); }
  .leeds-carousel .amg-carousel-container[data-slide-visible="3.25"] + .amg-carousel-nav-container .amg-carousel-nav button {
    width: calc(((100% + 16px) / 3.25 * 0.25) - 16px); }
  .leeds-carousel .amg-carousel-container[data-slide-visible="3.5"] + .amg-carousel-nav-container .amg-carousel-nav button {
    width: calc(((100% + 16px) / 3.5 * 0.5) - 16px); }
  .leeds-carousel .amg-carousel-container[data-slide-visible="3.75"] + .amg-carousel-nav-container .amg-carousel-nav button {
    width: calc(((100% + 16px) / 3.75 * 0.75) - 16px); }
  .leeds-carousel .amg-carousel-container[data-slide-visible="4.25"] + .amg-carousel-nav-container .amg-carousel-nav button {
    width: calc(((100% + 16px) / 4.25 * 0.25) - 16px); }
  .leeds-carousel .amg-carousel-container[data-slide-visible="4.5"] + .amg-carousel-nav-container .amg-carousel-nav button {
    width: calc(((100% + 16px) / 4.5 * 0.5) - 16px); }
  .leeds-carousel .amg-carousel-container[data-slide-visible="4.75"] + .amg-carousel-nav-container .amg-carousel-nav button {
    width: calc(((100% + 16px) / 4.75 * 0.75) - 16px); }
  .leeds-carousel .amg-carousel-container[data-slide-visible="5.25"] + .amg-carousel-nav-container .amg-carousel-nav button {
    width: calc(((100% + 16px) / 5.25 * 0.25) - 16px); }
  .leeds-carousel .amg-carousel-container[data-slide-visible="5.5"] + .amg-carousel-nav-container .amg-carousel-nav button {
    width: calc(((100% + 16px) / 5.5 * 0.5) - 16px); }
  .leeds-carousel .amg-carousel-container[data-slide-visible="5.75"] + .amg-carousel-nav-container .amg-carousel-nav button {
    width: calc(((100% + 16px) / 5.75 * 0.75) - 16px); }
  .leeds-carousel.leeds-sidebar-carousel .amg-carousel-container[data-slide-visible="1.25"] + .amg-carousel-nav-container .amg-carousel-nav {
    padding-top: calc(((((100% + 16px) / 0.25) - 16px))); }
  .leeds-carousel.leeds-sidebar-carousel .amg-carousel-container[data-slide-visible="1.5"] + .amg-carousel-nav-container .amg-carousel-nav {
    padding-top: calc(((((100% + 16px) / 0.5) - 16px))); }
  .leeds-carousel.leeds-sidebar-carousel .amg-carousel-container[data-slide-visible="1.75"] + .amg-carousel-nav-container .amg-carousel-nav {
    padding-top: calc(((((100% + 16px) / 0.75) - 16px))); }
  .leeds-carousel.leeds-sidebar-carousel .amg-carousel-container[data-slide-visible="2.25"] + .amg-carousel-nav-container .amg-carousel-nav {
    padding-top: calc(((((100% + 16px) / 1.25) - 16px))); }
  .leeds-carousel.leeds-sidebar-carousel .amg-carousel-container[data-slide-visible="2.5"] + .amg-carousel-nav-container .amg-carousel-nav {
    padding-top: calc(((((100% + 16px) / 1.5) - 16px))); }
  .leeds-carousel.leeds-sidebar-carousel .amg-carousel-container[data-slide-visible="2.75"] + .amg-carousel-nav-container .amg-carousel-nav {
    padding-top: calc(((((100% + 16px) / 1.75) - 16px))); }
  .leeds-carousel .amg-carousel-container[data-slide-visible="1.25"] + .amg-carousel-nav-container .amg-carousel-nav {
    padding-top: calc((((100% / 1.25) - 16px))); }
  .leeds-carousel.leeds-video-carousel .amg-carousel-container[data-slide-visible="1.25"] + .amg-carousel-nav-container .amg-carousel-nav, .leeds-carousel.leeds-welcome-banner-carousel .amg-carousel-container[data-slide-visible="1.25"] + .amg-carousel-nav-container .amg-carousel-nav {
    padding-top: calc(((100% / 1.25) - 16px) * 9 / 16); }
  .leeds-carousel .amg-carousel-container[data-slide-visible="1.5"] + .amg-carousel-nav-container .amg-carousel-nav {
    padding-top: calc((((100% / 1.5) - 16px))); }
  .leeds-carousel.leeds-video-carousel .amg-carousel-container[data-slide-visible="1.5"] + .amg-carousel-nav-container .amg-carousel-nav, .leeds-carousel.leeds-welcome-banner-carousel .amg-carousel-container[data-slide-visible="1.5"] + .amg-carousel-nav-container .amg-carousel-nav {
    padding-top: calc(((100% / 1.5) - 16px) * 9 / 16); }
  .leeds-carousel .amg-carousel-container[data-slide-visible="1.75"] + .amg-carousel-nav-container .amg-carousel-nav {
    padding-top: calc((((100% / 1.75) - 16px))); }
  .leeds-carousel.leeds-video-carousel .amg-carousel-container[data-slide-visible="1.75"] + .amg-carousel-nav-container .amg-carousel-nav, .leeds-carousel.leeds-welcome-banner-carousel .amg-carousel-container[data-slide-visible="1.75"] + .amg-carousel-nav-container .amg-carousel-nav {
    padding-top: calc(((100% / 1.75) - 16px) * 9 / 16); }
  .leeds-carousel .amg-carousel-container[data-slide-visible="2.25"] + .amg-carousel-nav-container .amg-carousel-nav {
    padding-top: calc((((100% / 2.25) - 16px))); }
  .leeds-carousel.leeds-video-carousel .amg-carousel-container[data-slide-visible="2.25"] + .amg-carousel-nav-container .amg-carousel-nav, .leeds-carousel.leeds-welcome-banner-carousel .amg-carousel-container[data-slide-visible="2.25"] + .amg-carousel-nav-container .amg-carousel-nav {
    padding-top: calc(((100% / 2.25) - 16px) * 9 / 16); }
  .leeds-carousel .amg-carousel-container[data-slide-visible="2.5"] + .amg-carousel-nav-container .amg-carousel-nav {
    padding-top: calc((((100% / 2.5) - 16px))); }
  .leeds-carousel.leeds-video-carousel .amg-carousel-container[data-slide-visible="2.5"] + .amg-carousel-nav-container .amg-carousel-nav, .leeds-carousel.leeds-welcome-banner-carousel .amg-carousel-container[data-slide-visible="2.5"] + .amg-carousel-nav-container .amg-carousel-nav {
    padding-top: calc(((100% / 2.5) - 16px) * 9 / 16); }
  .leeds-carousel .amg-carousel-container[data-slide-visible="2.75"] + .amg-carousel-nav-container .amg-carousel-nav {
    padding-top: calc((((100% / 2.75) - 16px))); }
  .leeds-carousel.leeds-video-carousel .amg-carousel-container[data-slide-visible="2.75"] + .amg-carousel-nav-container .amg-carousel-nav, .leeds-carousel.leeds-welcome-banner-carousel .amg-carousel-container[data-slide-visible="2.75"] + .amg-carousel-nav-container .amg-carousel-nav {
    padding-top: calc(((100% / 2.75) - 16px) * 9 / 16); }
  .leeds-carousel .amg-carousel-container[data-slide-visible="3.25"] + .amg-carousel-nav-container .amg-carousel-nav {
    padding-top: calc((((100% / 3.25) - 16px))); }
  .leeds-carousel.leeds-video-carousel .amg-carousel-container[data-slide-visible="3.25"] + .amg-carousel-nav-container .amg-carousel-nav, .leeds-carousel.leeds-welcome-banner-carousel .amg-carousel-container[data-slide-visible="3.25"] + .amg-carousel-nav-container .amg-carousel-nav {
    padding-top: calc(((100% / 3.25) - 16px) * 9 / 16); }
  .leeds-carousel .amg-carousel-container[data-slide-visible="3.5"] + .amg-carousel-nav-container .amg-carousel-nav {
    padding-top: calc((((100% / 3.5) - 16px))); }
  .leeds-carousel.leeds-video-carousel .amg-carousel-container[data-slide-visible="3.5"] + .amg-carousel-nav-container .amg-carousel-nav, .leeds-carousel.leeds-welcome-banner-carousel .amg-carousel-container[data-slide-visible="3.5"] + .amg-carousel-nav-container .amg-carousel-nav {
    padding-top: calc(((100% / 3.5) - 16px) * 9 / 16); }
  .leeds-carousel .amg-carousel-container[data-slide-visible="3.75"] + .amg-carousel-nav-container .amg-carousel-nav {
    padding-top: calc((((100% / 3.75) - 16px))); }
  .leeds-carousel.leeds-video-carousel .amg-carousel-container[data-slide-visible="3.75"] + .amg-carousel-nav-container .amg-carousel-nav, .leeds-carousel.leeds-welcome-banner-carousel .amg-carousel-container[data-slide-visible="3.75"] + .amg-carousel-nav-container .amg-carousel-nav {
    padding-top: calc(((100% / 3.75) - 16px) * 9 / 16); }
  .leeds-carousel .amg-carousel-container[data-slide-visible="4.25"] + .amg-carousel-nav-container .amg-carousel-nav {
    padding-top: calc((((100% / 4.25) - 16px))); }
  .leeds-carousel.leeds-video-carousel .amg-carousel-container[data-slide-visible="4.25"] + .amg-carousel-nav-container .amg-carousel-nav, .leeds-carousel.leeds-welcome-banner-carousel .amg-carousel-container[data-slide-visible="4.25"] + .amg-carousel-nav-container .amg-carousel-nav {
    padding-top: calc(((100% / 4.25) - 16px) * 9 / 16); }
  .leeds-carousel .amg-carousel-container[data-slide-visible="4.5"] + .amg-carousel-nav-container .amg-carousel-nav {
    padding-top: calc((((100% / 4.5) - 16px))); }
  .leeds-carousel.leeds-video-carousel .amg-carousel-container[data-slide-visible="4.5"] + .amg-carousel-nav-container .amg-carousel-nav, .leeds-carousel.leeds-welcome-banner-carousel .amg-carousel-container[data-slide-visible="4.5"] + .amg-carousel-nav-container .amg-carousel-nav {
    padding-top: calc(((100% / 4.5) - 16px) * 9 / 16); }
  .leeds-carousel .amg-carousel-container[data-slide-visible="4.75"] + .amg-carousel-nav-container .amg-carousel-nav {
    padding-top: calc((((100% / 4.75) - 16px))); }
  .leeds-carousel.leeds-video-carousel .amg-carousel-container[data-slide-visible="4.75"] + .amg-carousel-nav-container .amg-carousel-nav, .leeds-carousel.leeds-welcome-banner-carousel .amg-carousel-container[data-slide-visible="4.75"] + .amg-carousel-nav-container .amg-carousel-nav {
    padding-top: calc(((100% / 4.75) - 16px) * 9 / 16); }
  .leeds-carousel .amg-carousel-container[data-slide-visible="5.25"] + .amg-carousel-nav-container .amg-carousel-nav {
    padding-top: calc((((100% / 5.25) - 16px))); }
  .leeds-carousel.leeds-video-carousel .amg-carousel-container[data-slide-visible="5.25"] + .amg-carousel-nav-container .amg-carousel-nav, .leeds-carousel.leeds-welcome-banner-carousel .amg-carousel-container[data-slide-visible="5.25"] + .amg-carousel-nav-container .amg-carousel-nav {
    padding-top: calc(((100% / 5.25) - 16px) * 9 / 16); }
  .leeds-carousel .amg-carousel-container[data-slide-visible="5.5"] + .amg-carousel-nav-container .amg-carousel-nav {
    padding-top: calc((((100% / 5.5) - 16px))); }
  .leeds-carousel.leeds-video-carousel .amg-carousel-container[data-slide-visible="5.5"] + .amg-carousel-nav-container .amg-carousel-nav, .leeds-carousel.leeds-welcome-banner-carousel .amg-carousel-container[data-slide-visible="5.5"] + .amg-carousel-nav-container .amg-carousel-nav {
    padding-top: calc(((100% / 5.5) - 16px) * 9 / 16); }
  .leeds-carousel .amg-carousel-container[data-slide-visible="5.75"] + .amg-carousel-nav-container .amg-carousel-nav {
    padding-top: calc((((100% / 5.75) - 16px))); }
  .leeds-carousel.leeds-video-carousel .amg-carousel-container[data-slide-visible="5.75"] + .amg-carousel-nav-container .amg-carousel-nav, .leeds-carousel.leeds-welcome-banner-carousel .amg-carousel-container[data-slide-visible="5.75"] + .amg-carousel-nav-container .amg-carousel-nav {
    padding-top: calc(((100% / 5.75) - 16px) * 9 / 16); }
  .leeds-carousel.leeds-video-carousel .amg-carousel-nav-container .amg-carousel-nav .next, .leeds-carousel.leeds-welcome-banner-carousel .amg-carousel-nav-container .amg-carousel-nav .next {
    right: 16px; }

.leeds-profile-carousel .amg-section-child {
  justify-content: stretch; }

.leeds-profile-carousel .amg-side-details,
.leeds-profile-carousel .amg-card-image {
  margin-right: 16px;
  border: 0; }

.leeds-profile-carousel.invert .amg-side-details {
  color: #FFFFFF; }

.leeds-profile-carousel .amg-side-details div.shirt_number, .leeds-profile-carousel .amg-side-details div.first_name, .leeds-profile-carousel .amg-side-details div.last_name, .leeds-profile-carousel .amg-side-details div.position {
  text-transform: uppercase; }

.leeds-profile-carousel .amg-side-details div.shirt_number {
  font-size: 25pt;
  font-style: italic; }
  .leeds-profile-carousel .amg-side-details div.shirt_number:before {
    content: url("~src/assets/images/icon-shirt-white.svg");
    margin-right: 8px; }

.leeds-profile-carousel .amg-side-details div.first_name {
  font-weight: 500;
  font-size: 20pt; }

.leeds-profile-carousel .amg-side-details div.last_name {
  font-size: 23pt;
  font-weight: bold; }

@media (min-width: 768px) {
  .leeds-profile-carousel .amg-side-details div.shirt_number {
    font-size: 27pt; }
  .leeds-profile-carousel .amg-side-details div.first_name {
    font-size: 30pt; }
  .leeds-profile-carousel .amg-side-details div.last_name {
    font-size: 30pt; }
  .leeds-profile-carousel .amg-side-details div.position {
    font-size: 18pt; } }

.leeds-profile-carousel .amg-card-image {
  box-shadow: 0pt 3pt 6pt 0pt rgba(0, 0, 0, 0.16);
  position: relative;
  cursor: pointer;
  color: #FFFFFF; }
  .leeds-profile-carousel .amg-card-image:before, .leeds-profile-carousel .amg-card-image:after {
    position: absolute;
    right: 0;
    left: 16px;
    z-index: 1; }
  .leeds-profile-carousel .amg-card-image:before {
    display: block;
    content: "";
    background: url("~src/assets/images/card-video-play.svg") center no-repeat transparent;
    background-size: cover;
    bottom: 16px;
    width: 37px;
    height: 37px; }
  .leeds-profile-carousel .amg-card-image:after {
    content: "Watch Now";
    bottom: 18pt;
    padding-left: 32pt;
    font-size: 15pt; }
    @media (min-width: 768px) {
      .leeds-profile-carousel .amg-card-image:after {
        font-size: 14pt; } }
    @media (min-width: 992px) {
      .leeds-profile-carousel .amg-card-image:after {
        font-size: 17pt; } }
  .leeds-profile-carousel .amg-card-image,
  .leeds-profile-carousel .amg-card-image .card-img-top {
    background-color: transparent;
    border-radius: 16px;
    overflow: hidden; }
  .leeds-profile-carousel .amg-card-image .card-img-top {
    position: relative; }
    .leeds-profile-carousel .amg-card-image .card-img-top:after {
      display: block;
      content: "";
      position: absolute;
      top: 50%;
      bottom: 0;
      left: 0;
      right: 0;
      background: linear-gradient(to bottom, transparent, #1e3c6a 45%, #000000 100%);
      opacity: 0; }

.leeds-profile-carousel .amg-carousel-container + .amg-carousel-nav-container {
  padding-left: 12px;
  top: 12px; }

@-moz-document url-prefix() {
  .leeds-profile-carousel .amg-carousel-container + .amg-carousel-nav-container {
    bottom: 12px; } }
  @supports (-webkit-overflow-scrolling: touch) {
    .leeds-profile-carousel .amg-carousel-container + .amg-carousel-nav-container {
      bottom: 12px; } }
  @supports (-ms-ime-align: auto) {
    .leeds-profile-carousel .amg-carousel-container + .amg-carousel-nav-container {
      bottom: 12px; } }

.leeds-profile-carousel .amg-side-details > .d-block {
  display: flex !important;
  flex-direction: column;
  justify-content: center; }

.leeds-profile-carousel .col.active .amg-card-image {
  transition: margin 0.333s ease-in-out 0s, max-width 0.333s ease-in-out 0s; }
  .leeds-profile-carousel .col.active .amg-card-image:after {
    transition: opacity 0.333s ease-in-out 0s; }
  .leeds-profile-carousel .col.active .amg-card-image .card-img-top:after {
    transition: opacity 0.333s ease-in-out 0s; }

.leeds-profile-carousel .amg-carousel-container .col:not(.active):not(.amg-side-details) {
  pointer-events: none; }

.leeds-profile-carousel .amg-carousel-container:hover .col.amg-side-details:hover ~ .col.active + .col .amg-card-image,
.leeds-profile-carousel .amg-carousel-container:hover .col.amg-side-details:hover ~ .col.active + .col.active .amg-card-image,
.leeds-profile-carousel .amg-carousel-container:hover .col.active:not(:hover) .amg-card-image,
.leeds-profile-carousel .amg-carousel-container .col.active + .col .amg-card-image,
.leeds-profile-carousel .amg-carousel-container .col.active + .col.active .amg-card-image {
  margin-top: 24px;
  margin-bottom: 24px;
  max-width: calc(100% - 48px); }
  .leeds-profile-carousel .amg-carousel-container:hover .col.amg-side-details:hover ~ .col.active + .col .amg-card-image:after,
  .leeds-profile-carousel .amg-carousel-container:hover .col.amg-side-details:hover ~ .col.active + .col.active .amg-card-image:after,
  .leeds-profile-carousel .amg-carousel-container:hover .col.active:not(:hover) .amg-card-image:after,
  .leeds-profile-carousel .amg-carousel-container .col.active + .col .amg-card-image:after,
  .leeds-profile-carousel .amg-carousel-container .col.active + .col.active .amg-card-image:after {
    opacity: 0; }
  .leeds-profile-carousel .amg-carousel-container:hover .col.amg-side-details:hover ~ .col.active + .col .amg-card-image .card-img-top:after,
  .leeds-profile-carousel .amg-carousel-container:hover .col.amg-side-details:hover ~ .col.active + .col.active .amg-card-image .card-img-top:after,
  .leeds-profile-carousel .amg-carousel-container:hover .col.active:not(:hover) .amg-card-image .card-img-top:after,
  .leeds-profile-carousel .amg-carousel-container .col.active + .col .amg-card-image .card-img-top:after,
  .leeds-profile-carousel .amg-carousel-container .col.active + .col.active .amg-card-image .card-img-top:after {
    opacity: 0; }

.leeds-profile-carousel .amg-carousel-container .col.active .amg-card-image,
.leeds-profile-carousel .amg-carousel-container:hover .col.active:hover .amg-card-image,
.leeds-profile-carousel .amg-carousel-container:hover .col.amg-side-details:hover ~ .col.active .amg-card-image {
  margin: 0 auto;
  margin-top: 12px;
  margin-bottom: 12px;
  max-width: calc(100% - 24px); }
  .leeds-profile-carousel .amg-carousel-container .col.active .amg-card-image:after,
  .leeds-profile-carousel .amg-carousel-container:hover .col.active:hover .amg-card-image:after,
  .leeds-profile-carousel .amg-carousel-container:hover .col.amg-side-details:hover ~ .col.active .amg-card-image:after {
    opacity: 1; }
  .leeds-profile-carousel .amg-carousel-container .col.active .amg-card-image .card-img-top:after,
  .leeds-profile-carousel .amg-carousel-container:hover .col.active:hover .amg-card-image .card-img-top:after,
  .leeds-profile-carousel .amg-carousel-container:hover .col.amg-side-details:hover ~ .col.active .amg-card-image .card-img-top:after {
    opacity: 0.76; }

.leeds-profile-carousel .amg-carousel-container:hover[data-slide-hovered="0"]:not([data-slide-current="0"]) + .amg-carousel-nav-container .prev {
  opacity: 1; }

.leeds-profile-carousel .amg-carousel-nav-container button {
  transition: margin 0.333s ease-in-out 0s, opacity 0.333s ease-in-out 0s; }

.leeds-profile-carousel .amg-carousel-nav-container .prev {
  border-radius: 0 16px 16px 0;
  opacity: 0;
  margin-left: 12px; }
  .leeds-profile-carousel .amg-carousel-nav-container .prev:hover {
    opacity: 1; }

.leeds-profile-carousel .amg-carousel-nav-container .next {
  border-radius: 16px 0 0 16px;
  margin-top: 12px;
  margin-bottom: 24px; }

.leeds-profile-carousel.leeds-carousel.leeds-sidebar-carousel {
  width: 100%;
  width: 100%;
  width: 100%;
  width: 100%;
  width: 100%;
  width: 100%;
  width: 100%;
  width: 100%;
  width: 100%; }
  .leeds-profile-carousel.leeds-carousel.leeds-sidebar-carousel .amg-carousel-container[data-slide-visible="1.25"] + .amg-carousel-nav-container .amg-carousel-nav button {
    width: calc(100% / 1.25 * 0.25); }
  .leeds-profile-carousel.leeds-carousel.leeds-sidebar-carousel .amg-carousel-container[data-slide-visible="1.5"] + .amg-carousel-nav-container .amg-carousel-nav button {
    width: calc(100% / 1.5 * 0.5); }
  .leeds-profile-carousel.leeds-carousel.leeds-sidebar-carousel .amg-carousel-container[data-slide-visible="1.75"] + .amg-carousel-nav-container .amg-carousel-nav button {
    width: calc(100% / 1.75 * 0.75); }
  .leeds-profile-carousel.leeds-carousel.leeds-sidebar-carousel .amg-carousel-container[data-slide-visible="2.25"] + .amg-carousel-nav-container .amg-carousel-nav button {
    width: calc(100% / 2.25 * 0.25); }
  .leeds-profile-carousel.leeds-carousel.leeds-sidebar-carousel .amg-carousel-container[data-slide-visible="2.5"] + .amg-carousel-nav-container .amg-carousel-nav button {
    width: calc(100% / 2.5 * 0.5); }
  .leeds-profile-carousel.leeds-carousel.leeds-sidebar-carousel .amg-carousel-container[data-slide-visible="2.75"] + .amg-carousel-nav-container .amg-carousel-nav button {
    width: calc(100% / 2.75 * 0.75); }
  .leeds-profile-carousel.leeds-carousel.leeds-sidebar-carousel .amg-carousel-container[data-slide-visible="3.25"] + .amg-carousel-nav-container .amg-carousel-nav {
    padding-top: calc(((((100%) / 3.25) - 12px))); }
  .leeds-profile-carousel.leeds-carousel.leeds-sidebar-carousel .amg-carousel-container[data-slide-visible="3.25"] + .amg-carousel-nav-container .amg-carousel-nav button {
    width: calc(100% / 3.25 * 0.25); }
  .leeds-profile-carousel.leeds-carousel.leeds-sidebar-carousel .amg-carousel-container[data-slide-visible="3.5"] + .amg-carousel-nav-container .amg-carousel-nav {
    padding-top: calc(((((100%) / 3.5) - 12px))); }
  .leeds-profile-carousel.leeds-carousel.leeds-sidebar-carousel .amg-carousel-container[data-slide-visible="3.5"] + .amg-carousel-nav-container .amg-carousel-nav button {
    width: calc(100% / 3.5 * 0.5); }
  .leeds-profile-carousel.leeds-carousel.leeds-sidebar-carousel .amg-carousel-container[data-slide-visible="3.75"] + .amg-carousel-nav-container .amg-carousel-nav {
    padding-top: calc(((((100%) / 3.75) - 12px))); }
  .leeds-profile-carousel.leeds-carousel.leeds-sidebar-carousel .amg-carousel-container[data-slide-visible="3.75"] + .amg-carousel-nav-container .amg-carousel-nav button {
    width: calc(100% / 3.75 * 0.75); }
  .leeds-profile-carousel.leeds-carousel.leeds-sidebar-carousel .amg-carousel-container[data-slide-visible="4.25"] + .amg-carousel-nav-container .amg-carousel-nav {
    padding-top: calc(((((100%) / 4.25) - 12px))); }
  .leeds-profile-carousel.leeds-carousel.leeds-sidebar-carousel .amg-carousel-container[data-slide-visible="4.25"] + .amg-carousel-nav-container .amg-carousel-nav button {
    width: calc(100% / 4.25 * 0.25); }
  .leeds-profile-carousel.leeds-carousel.leeds-sidebar-carousel .amg-carousel-container[data-slide-visible="4.5"] + .amg-carousel-nav-container .amg-carousel-nav {
    padding-top: calc(((((100%) / 4.5) - 12px))); }
  .leeds-profile-carousel.leeds-carousel.leeds-sidebar-carousel .amg-carousel-container[data-slide-visible="4.5"] + .amg-carousel-nav-container .amg-carousel-nav button {
    width: calc(100% / 4.5 * 0.5); }
  .leeds-profile-carousel.leeds-carousel.leeds-sidebar-carousel .amg-carousel-container[data-slide-visible="4.75"] + .amg-carousel-nav-container .amg-carousel-nav {
    padding-top: calc(((((100%) / 4.75) - 12px))); }
  .leeds-profile-carousel.leeds-carousel.leeds-sidebar-carousel .amg-carousel-container[data-slide-visible="4.75"] + .amg-carousel-nav-container .amg-carousel-nav button {
    width: calc(100% / 4.75 * 0.75); }
  .leeds-profile-carousel.leeds-carousel.leeds-sidebar-carousel .amg-carousel-container[data-slide-visible="5.25"] + .amg-carousel-nav-container .amg-carousel-nav {
    padding-top: calc(((((100%) / 5.25) - 12px))); }
  .leeds-profile-carousel.leeds-carousel.leeds-sidebar-carousel .amg-carousel-container[data-slide-visible="5.25"] + .amg-carousel-nav-container .amg-carousel-nav button {
    width: calc(100% / 5.25 * 0.25); }
  .leeds-profile-carousel.leeds-carousel.leeds-sidebar-carousel .amg-carousel-container[data-slide-visible="5.5"] + .amg-carousel-nav-container .amg-carousel-nav {
    padding-top: calc(((((100%) / 5.5) - 12px))); }
  .leeds-profile-carousel.leeds-carousel.leeds-sidebar-carousel .amg-carousel-container[data-slide-visible="5.5"] + .amg-carousel-nav-container .amg-carousel-nav button {
    width: calc(100% / 5.5 * 0.5); }
  .leeds-profile-carousel.leeds-carousel.leeds-sidebar-carousel .amg-carousel-container[data-slide-visible="5.75"] + .amg-carousel-nav-container .amg-carousel-nav {
    padding-top: calc(((((100%) / 5.75) - 12px))); }
  .leeds-profile-carousel.leeds-carousel.leeds-sidebar-carousel .amg-carousel-container[data-slide-visible="5.75"] + .amg-carousel-nav-container .amg-carousel-nav button {
    width: calc(100% / 5.75 * 0.75); }

.leeds-boxset-carousel .amg-section-child {
  justify-content: stretch; }

.leeds-boxset-carousel .amg-side-details,
.leeds-boxset-carousel .amg-card-image,
.leeds-boxset-carousel .amg-card-video {
  margin-right: 16px; }

.leeds-boxset-carousel .amg-side-details div.title {
  color: #0E2A51;
  font-size: 23pt;
  font-weight: bold;
  text-transform: uppercase; }

.leeds-boxset-carousel .amg-side-details div.description {
  font-size: 17pt;
  color: #BD9B60; }

@media (min-width: 768px) {
  .leeds-boxset-carousel .amg-side-details div.title {
    font-size: 30pt; }
  .leeds-boxset-carousel .amg-side-details div.description {
    font-size: 18pt; } }

.leeds-boxset-carousel .amg-carousel-container .amg-card-image,
.leeds-boxset-carousel .amg-carousel-container .amg-card-video {
  max-width: calc(100% - 60px); }

.leeds-boxset-carousel .amg-carousel-container .col:not(.active):not(.amg-side-details) {
  pointer-events: none; }

.leeds-boxset-carousel .amg-carousel-container:hover .col.amg-side-details:hover ~ .col.active + .col .amg-card-image, .leeds-boxset-carousel .amg-carousel-container:hover .col.amg-side-details:hover ~ .col.active + .col .amg-card-image:after, .leeds-boxset-carousel .amg-carousel-container:hover .col.amg-side-details:hover ~ .col.active + .col .amg-card-image:before,
.leeds-boxset-carousel .amg-carousel-container:hover .col.amg-side-details:hover ~ .col.active + .col .amg-card-image .card-img-top:after,
.leeds-boxset-carousel .amg-carousel-container:hover .col.amg-side-details:hover ~ .col.active + .col .amg-card-video,
.leeds-boxset-carousel .amg-carousel-container:hover .col.amg-side-details:hover ~ .col.active + .col .amg-card-video:after,
.leeds-boxset-carousel .amg-carousel-container:hover .col.amg-side-details:hover ~ .col.active + .col .amg-card-video:before,
.leeds-boxset-carousel .amg-carousel-container:hover .col.amg-side-details:hover ~ .col.active + .col .amg-card-video .card-img-top:after,
.leeds-boxset-carousel .amg-carousel-container:hover .col.amg-side-details:hover ~ .col.active + .col.active .amg-card-image,
.leeds-boxset-carousel .amg-carousel-container:hover .col.amg-side-details:hover ~ .col.active + .col.active .amg-card-image:after,
.leeds-boxset-carousel .amg-carousel-container:hover .col.amg-side-details:hover ~ .col.active + .col.active .amg-card-image:before,
.leeds-boxset-carousel .amg-carousel-container:hover .col.amg-side-details:hover ~ .col.active + .col.active .amg-card-image .card-img-top:after,
.leeds-boxset-carousel .amg-carousel-container:hover .col.amg-side-details:hover ~ .col.active + .col.active .amg-card-video,
.leeds-boxset-carousel .amg-carousel-container:hover .col.amg-side-details:hover ~ .col.active + .col.active .amg-card-video:after,
.leeds-boxset-carousel .amg-carousel-container:hover .col.amg-side-details:hover ~ .col.active + .col.active .amg-card-video:before,
.leeds-boxset-carousel .amg-carousel-container:hover .col.amg-side-details:hover ~ .col.active + .col.active .amg-card-video .card-img-top:after,
.leeds-boxset-carousel .amg-carousel-container:hover .col.active:not(:hover) .amg-card-image,
.leeds-boxset-carousel .amg-carousel-container:hover .col.active:not(:hover) .amg-card-image:after,
.leeds-boxset-carousel .amg-carousel-container:hover .col.active:not(:hover) .amg-card-image:before,
.leeds-boxset-carousel .amg-carousel-container:hover .col.active:not(:hover) .amg-card-image .card-img-top:after,
.leeds-boxset-carousel .amg-carousel-container:hover .col.active:not(:hover) .amg-card-video,
.leeds-boxset-carousel .amg-carousel-container:hover .col.active:not(:hover) .amg-card-video:after,
.leeds-boxset-carousel .amg-carousel-container:hover .col.active:not(:hover) .amg-card-video:before,
.leeds-boxset-carousel .amg-carousel-container:hover .col.active:not(:hover) .amg-card-video .card-img-top:after,
.leeds-boxset-carousel .amg-carousel-container .col.active + .col .amg-card-image,
.leeds-boxset-carousel .amg-carousel-container .col.active + .col .amg-card-image:after,
.leeds-boxset-carousel .amg-carousel-container .col.active + .col .amg-card-image:before,
.leeds-boxset-carousel .amg-carousel-container .col.active + .col .amg-card-image .card-img-top:after,
.leeds-boxset-carousel .amg-carousel-container .col.active + .col .amg-card-video,
.leeds-boxset-carousel .amg-carousel-container .col.active + .col .amg-card-video:after,
.leeds-boxset-carousel .amg-carousel-container .col.active + .col .amg-card-video:before,
.leeds-boxset-carousel .amg-carousel-container .col.active + .col .amg-card-video .card-img-top:after,
.leeds-boxset-carousel .amg-carousel-container .col.active + .col.active .amg-card-image,
.leeds-boxset-carousel .amg-carousel-container .col.active + .col.active .amg-card-image:after,
.leeds-boxset-carousel .amg-carousel-container .col.active + .col.active .amg-card-image:before,
.leeds-boxset-carousel .amg-carousel-container .col.active + .col.active .amg-card-image .card-img-top:after,
.leeds-boxset-carousel .amg-carousel-container .col.active + .col.active .amg-card-video,
.leeds-boxset-carousel .amg-carousel-container .col.active + .col.active .amg-card-video:after,
.leeds-boxset-carousel .amg-carousel-container .col.active + .col.active .amg-card-video:before,
.leeds-boxset-carousel .amg-carousel-container .col.active + .col.active .amg-card-video .card-img-top:after {
  transform: rotate(0); }

.leeds-boxset-carousel .amg-carousel-container .col.active .amg-card-image,
.leeds-boxset-carousel .amg-carousel-container .col.active .amg-card-video,
.leeds-boxset-carousel .amg-carousel-container:hover .col.active:hover .amg-card-image,
.leeds-boxset-carousel .amg-carousel-container:hover .col.active:hover .amg-card-video,
.leeds-boxset-carousel .amg-carousel-container:hover .col.amg-side-details:hover ~ .col.active .amg-card-image,
.leeds-boxset-carousel .amg-carousel-container:hover .col.amg-side-details:hover ~ .col.active .amg-card-video {
  margin: 40px auto; }

.leeds-boxset-carousel .amg-carousel-container .col.active .amg-card-image,
.leeds-boxset-carousel .amg-carousel-container .col.active .amg-card-video,
.leeds-boxset-carousel .amg-carousel-container:hover .col.active:hover .amg-card-image:hover,
.leeds-boxset-carousel .amg-carousel-container:hover .col.active:hover .amg-card-video:hover,
.leeds-boxset-carousel .amg-carousel-container:hover .col.amg-side-details:hover ~ .col.active .amg-card-image:hover,
.leeds-boxset-carousel .amg-carousel-container:hover .col.amg-side-details:hover ~ .col.active .amg-card-video:hover {
  transform: rotate(-4deg); }
  .leeds-boxset-carousel .amg-carousel-container .col.active .amg-card-image:after,
  .leeds-boxset-carousel .amg-carousel-container .col.active .amg-card-video:after,
  .leeds-boxset-carousel .amg-carousel-container:hover .col.active:hover .amg-card-image:hover:after,
  .leeds-boxset-carousel .amg-carousel-container:hover .col.active:hover .amg-card-video:hover:after,
  .leeds-boxset-carousel .amg-carousel-container:hover .col.amg-side-details:hover ~ .col.active .amg-card-image:hover:after,
  .leeds-boxset-carousel .amg-carousel-container:hover .col.amg-side-details:hover ~ .col.active .amg-card-video:hover:after {
    transform: rotate(9deg); }
  .leeds-boxset-carousel .amg-carousel-container .col.active .amg-card-image:before,
  .leeds-boxset-carousel .amg-carousel-container .col.active .amg-card-video:before,
  .leeds-boxset-carousel .amg-carousel-container:hover .col.active:hover .amg-card-image:hover:before,
  .leeds-boxset-carousel .amg-carousel-container:hover .col.active:hover .amg-card-video:hover:before,
  .leeds-boxset-carousel .amg-carousel-container:hover .col.amg-side-details:hover ~ .col.active .amg-card-image:hover:before,
  .leeds-boxset-carousel .amg-carousel-container:hover .col.amg-side-details:hover ~ .col.active .amg-card-video:hover:before {
    transform: rotate(0deg); }
  .leeds-boxset-carousel .amg-carousel-container .col.active .amg-card-image .card-img-top:after,
  .leeds-boxset-carousel .amg-carousel-container .col.active .amg-card-video .card-img-top:after,
  .leeds-boxset-carousel .amg-carousel-container:hover .col.active:hover .amg-card-image:hover .card-img-top:after,
  .leeds-boxset-carousel .amg-carousel-container:hover .col.active:hover .amg-card-video:hover .card-img-top:after,
  .leeds-boxset-carousel .amg-carousel-container:hover .col.amg-side-details:hover ~ .col.active .amg-card-image:hover .card-img-top:after,
  .leeds-boxset-carousel .amg-carousel-container:hover .col.amg-side-details:hover ~ .col.active .amg-card-video:hover .card-img-top:after {
    transform: rotate(4deg); }

.leeds-boxset-carousel .amg-carousel-container:hover[data-slide-hovered="0"]:not([data-slide-current="0"]) + .amg-carousel-nav-container .prev {
  opacity: 1; }

.leeds-boxset-carousel .amg-carousel-nav-container .amg-carousel-nav button {
  margin-top: 28px;
  margin-bottom: 28px;
  transition: margin 0.333s ease-in-out 0s, opacity 0.333s ease-in-out 0s; }

.leeds-boxset-carousel .amg-carousel-nav-container .amg-carousel-nav .prev {
  border-radius: 0 0 0 0;
  opacity: 0; }
  .leeds-boxset-carousel .amg-carousel-nav-container .amg-carousel-nav .prev:hover {
    opacity: 1; }

.leeds-boxset-carousel .amg-carousel-nav-container .amg-carousel-nav .next {
  border-radius: 0 0 0 0; }

.leeds-boxset-carousel.leeds-sidebar-carousel .amg-carousel-container[data-slide-visible="1"] .col .amg-card-image,
.leeds-boxset-carousel.leeds-sidebar-carousel .amg-carousel-container[data-slide-visible="1"] .col .amg-card-video,
.leeds-boxset-carousel.leeds-sidebar-carousel .amg-carousel-container[data-slide-visible="1.25"] .col .amg-card-image,
.leeds-boxset-carousel.leeds-sidebar-carousel .amg-carousel-container[data-slide-visible="1.25"] .col .amg-card-video,
.leeds-boxset-carousel.leeds-sidebar-carousel .amg-carousel-container[data-slide-visible="1.5"] .col .amg-card-image,
.leeds-boxset-carousel.leeds-sidebar-carousel .amg-carousel-container[data-slide-visible="1.5"] .col .amg-card-video,
.leeds-boxset-carousel.leeds-sidebar-carousel .amg-carousel-container[data-slide-visible="1.75"] .col .amg-card-image,
.leeds-boxset-carousel.leeds-sidebar-carousel .amg-carousel-container[data-slide-visible="1.75"] .col .amg-card-video,
.leeds-boxset-carousel.leeds-sidebar-carousel .amg-carousel-container[data-slide-visible="2"] .col .amg-card-image,
.leeds-boxset-carousel.leeds-sidebar-carousel .amg-carousel-container[data-slide-visible="2"] .col .amg-card-video,
.leeds-boxset-carousel.leeds-sidebar-carousel .amg-carousel-container[data-slide-visible="2.25"] .col .amg-card-image,
.leeds-boxset-carousel.leeds-sidebar-carousel .amg-carousel-container[data-slide-visible="2.25"] .col .amg-card-video,
.leeds-boxset-carousel.leeds-sidebar-carousel .amg-carousel-container[data-slide-visible="2.5"] .col .amg-card-image,
.leeds-boxset-carousel.leeds-sidebar-carousel .amg-carousel-container[data-slide-visible="2.5"] .col .amg-card-video,
.leeds-boxset-carousel.leeds-sidebar-carousel .amg-carousel-container[data-slide-visible="2.75"] .col .amg-card-image,
.leeds-boxset-carousel.leeds-sidebar-carousel .amg-carousel-container[data-slide-visible="2.75"] .col .amg-card-video,
.leeds-boxset-carousel.leeds-sidebar-carousel .amg-carousel-container[data-slide-visible="3"] .col .amg-card-image,
.leeds-boxset-carousel.leeds-sidebar-carousel .amg-carousel-container[data-slide-visible="3"] .col .amg-card-video,
.leeds-boxset-carousel.leeds-sidebar-carousel .amg-carousel-container[data-slide-visible="3.25"] .col .amg-card-image,
.leeds-boxset-carousel.leeds-sidebar-carousel .amg-carousel-container[data-slide-visible="3.25"] .col .amg-card-video,
.leeds-boxset-carousel.leeds-sidebar-carousel .amg-carousel-container[data-slide-visible="3.5"] .col .amg-card-image,
.leeds-boxset-carousel.leeds-sidebar-carousel .amg-carousel-container[data-slide-visible="3.5"] .col .amg-card-video,
.leeds-boxset-carousel.leeds-sidebar-carousel .amg-carousel-container[data-slide-visible="3.75"] .col .amg-card-image,
.leeds-boxset-carousel.leeds-sidebar-carousel .amg-carousel-container[data-slide-visible="3.75"] .col .amg-card-video {
  margin-top: 20%;
  margin-bottom: 20%; }
  .leeds-boxset-carousel.leeds-sidebar-carousel .amg-carousel-container[data-slide-visible="1"] .col .amg-card-image .card-img-top,
  .leeds-boxset-carousel.leeds-sidebar-carousel .amg-carousel-container[data-slide-visible="1"] .col .amg-card-video .card-img-top,
  .leeds-boxset-carousel.leeds-sidebar-carousel .amg-carousel-container[data-slide-visible="1.25"] .col .amg-card-image .card-img-top,
  .leeds-boxset-carousel.leeds-sidebar-carousel .amg-carousel-container[data-slide-visible="1.25"] .col .amg-card-video .card-img-top,
  .leeds-boxset-carousel.leeds-sidebar-carousel .amg-carousel-container[data-slide-visible="1.5"] .col .amg-card-image .card-img-top,
  .leeds-boxset-carousel.leeds-sidebar-carousel .amg-carousel-container[data-slide-visible="1.5"] .col .amg-card-video .card-img-top,
  .leeds-boxset-carousel.leeds-sidebar-carousel .amg-carousel-container[data-slide-visible="1.75"] .col .amg-card-image .card-img-top,
  .leeds-boxset-carousel.leeds-sidebar-carousel .amg-carousel-container[data-slide-visible="1.75"] .col .amg-card-video .card-img-top,
  .leeds-boxset-carousel.leeds-sidebar-carousel .amg-carousel-container[data-slide-visible="2"] .col .amg-card-image .card-img-top,
  .leeds-boxset-carousel.leeds-sidebar-carousel .amg-carousel-container[data-slide-visible="2"] .col .amg-card-video .card-img-top,
  .leeds-boxset-carousel.leeds-sidebar-carousel .amg-carousel-container[data-slide-visible="2.25"] .col .amg-card-image .card-img-top,
  .leeds-boxset-carousel.leeds-sidebar-carousel .amg-carousel-container[data-slide-visible="2.25"] .col .amg-card-video .card-img-top,
  .leeds-boxset-carousel.leeds-sidebar-carousel .amg-carousel-container[data-slide-visible="2.5"] .col .amg-card-image .card-img-top,
  .leeds-boxset-carousel.leeds-sidebar-carousel .amg-carousel-container[data-slide-visible="2.5"] .col .amg-card-video .card-img-top,
  .leeds-boxset-carousel.leeds-sidebar-carousel .amg-carousel-container[data-slide-visible="2.75"] .col .amg-card-image .card-img-top,
  .leeds-boxset-carousel.leeds-sidebar-carousel .amg-carousel-container[data-slide-visible="2.75"] .col .amg-card-video .card-img-top,
  .leeds-boxset-carousel.leeds-sidebar-carousel .amg-carousel-container[data-slide-visible="3"] .col .amg-card-image .card-img-top,
  .leeds-boxset-carousel.leeds-sidebar-carousel .amg-carousel-container[data-slide-visible="3"] .col .amg-card-video .card-img-top,
  .leeds-boxset-carousel.leeds-sidebar-carousel .amg-carousel-container[data-slide-visible="3.25"] .col .amg-card-image .card-img-top,
  .leeds-boxset-carousel.leeds-sidebar-carousel .amg-carousel-container[data-slide-visible="3.25"] .col .amg-card-video .card-img-top,
  .leeds-boxset-carousel.leeds-sidebar-carousel .amg-carousel-container[data-slide-visible="3.5"] .col .amg-card-image .card-img-top,
  .leeds-boxset-carousel.leeds-sidebar-carousel .amg-carousel-container[data-slide-visible="3.5"] .col .amg-card-video .card-img-top,
  .leeds-boxset-carousel.leeds-sidebar-carousel .amg-carousel-container[data-slide-visible="3.75"] .col .amg-card-image .card-img-top,
  .leeds-boxset-carousel.leeds-sidebar-carousel .amg-carousel-container[data-slide-visible="3.75"] .col .amg-card-video .card-img-top {
    padding-top: 80%; }

.leeds-boxset-carousel.leeds-sidebar-carousel .amg-carousel-container[data-slide-visible="1"] .col.active .amg-card-image,
.leeds-boxset-carousel.leeds-sidebar-carousel .amg-carousel-container[data-slide-visible="1"] .col.active .amg-card-video,
.leeds-boxset-carousel.leeds-sidebar-carousel .amg-carousel-container[data-slide-visible="1.25"] .col.active .amg-card-image,
.leeds-boxset-carousel.leeds-sidebar-carousel .amg-carousel-container[data-slide-visible="1.25"] .col.active .amg-card-video,
.leeds-boxset-carousel.leeds-sidebar-carousel .amg-carousel-container[data-slide-visible="1.5"] .col.active .amg-card-image,
.leeds-boxset-carousel.leeds-sidebar-carousel .amg-carousel-container[data-slide-visible="1.5"] .col.active .amg-card-video,
.leeds-boxset-carousel.leeds-sidebar-carousel .amg-carousel-container[data-slide-visible="1.75"] .col.active .amg-card-image,
.leeds-boxset-carousel.leeds-sidebar-carousel .amg-carousel-container[data-slide-visible="1.75"] .col.active .amg-card-video,
.leeds-boxset-carousel.leeds-sidebar-carousel .amg-carousel-container[data-slide-visible="2"] .col.active .amg-card-image,
.leeds-boxset-carousel.leeds-sidebar-carousel .amg-carousel-container[data-slide-visible="2"] .col.active .amg-card-video,
.leeds-boxset-carousel.leeds-sidebar-carousel .amg-carousel-container[data-slide-visible="2.25"] .col.active .amg-card-image,
.leeds-boxset-carousel.leeds-sidebar-carousel .amg-carousel-container[data-slide-visible="2.25"] .col.active .amg-card-video,
.leeds-boxset-carousel.leeds-sidebar-carousel .amg-carousel-container[data-slide-visible="2.5"] .col.active .amg-card-image,
.leeds-boxset-carousel.leeds-sidebar-carousel .amg-carousel-container[data-slide-visible="2.5"] .col.active .amg-card-video,
.leeds-boxset-carousel.leeds-sidebar-carousel .amg-carousel-container[data-slide-visible="2.75"] .col.active .amg-card-image,
.leeds-boxset-carousel.leeds-sidebar-carousel .amg-carousel-container[data-slide-visible="2.75"] .col.active .amg-card-video,
.leeds-boxset-carousel.leeds-sidebar-carousel .amg-carousel-container[data-slide-visible="3"] .col.active .amg-card-image,
.leeds-boxset-carousel.leeds-sidebar-carousel .amg-carousel-container[data-slide-visible="3"] .col.active .amg-card-video,
.leeds-boxset-carousel.leeds-sidebar-carousel .amg-carousel-container[data-slide-visible="3.25"] .col.active .amg-card-image,
.leeds-boxset-carousel.leeds-sidebar-carousel .amg-carousel-container[data-slide-visible="3.25"] .col.active .amg-card-video,
.leeds-boxset-carousel.leeds-sidebar-carousel .amg-carousel-container[data-slide-visible="3.5"] .col.active .amg-card-image,
.leeds-boxset-carousel.leeds-sidebar-carousel .amg-carousel-container[data-slide-visible="3.5"] .col.active .amg-card-video,
.leeds-boxset-carousel.leeds-sidebar-carousel .amg-carousel-container[data-slide-visible="3.75"] .col.active .amg-card-image,
.leeds-boxset-carousel.leeds-sidebar-carousel .amg-carousel-container[data-slide-visible="3.75"] .col.active .amg-card-video {
  margin-top: 10%;
  margin-bottom: 10%;
  margin-left: 0; }
  .leeds-boxset-carousel.leeds-sidebar-carousel .amg-carousel-container[data-slide-visible="1"] .col.active .amg-card-image .card-img-top,
  .leeds-boxset-carousel.leeds-sidebar-carousel .amg-carousel-container[data-slide-visible="1"] .col.active .amg-card-video .card-img-top,
  .leeds-boxset-carousel.leeds-sidebar-carousel .amg-carousel-container[data-slide-visible="1.25"] .col.active .amg-card-image .card-img-top,
  .leeds-boxset-carousel.leeds-sidebar-carousel .amg-carousel-container[data-slide-visible="1.25"] .col.active .amg-card-video .card-img-top,
  .leeds-boxset-carousel.leeds-sidebar-carousel .amg-carousel-container[data-slide-visible="1.5"] .col.active .amg-card-image .card-img-top,
  .leeds-boxset-carousel.leeds-sidebar-carousel .amg-carousel-container[data-slide-visible="1.5"] .col.active .amg-card-video .card-img-top,
  .leeds-boxset-carousel.leeds-sidebar-carousel .amg-carousel-container[data-slide-visible="1.75"] .col.active .amg-card-image .card-img-top,
  .leeds-boxset-carousel.leeds-sidebar-carousel .amg-carousel-container[data-slide-visible="1.75"] .col.active .amg-card-video .card-img-top,
  .leeds-boxset-carousel.leeds-sidebar-carousel .amg-carousel-container[data-slide-visible="2"] .col.active .amg-card-image .card-img-top,
  .leeds-boxset-carousel.leeds-sidebar-carousel .amg-carousel-container[data-slide-visible="2"] .col.active .amg-card-video .card-img-top,
  .leeds-boxset-carousel.leeds-sidebar-carousel .amg-carousel-container[data-slide-visible="2.25"] .col.active .amg-card-image .card-img-top,
  .leeds-boxset-carousel.leeds-sidebar-carousel .amg-carousel-container[data-slide-visible="2.25"] .col.active .amg-card-video .card-img-top,
  .leeds-boxset-carousel.leeds-sidebar-carousel .amg-carousel-container[data-slide-visible="2.5"] .col.active .amg-card-image .card-img-top,
  .leeds-boxset-carousel.leeds-sidebar-carousel .amg-carousel-container[data-slide-visible="2.5"] .col.active .amg-card-video .card-img-top,
  .leeds-boxset-carousel.leeds-sidebar-carousel .amg-carousel-container[data-slide-visible="2.75"] .col.active .amg-card-image .card-img-top,
  .leeds-boxset-carousel.leeds-sidebar-carousel .amg-carousel-container[data-slide-visible="2.75"] .col.active .amg-card-video .card-img-top,
  .leeds-boxset-carousel.leeds-sidebar-carousel .amg-carousel-container[data-slide-visible="3"] .col.active .amg-card-image .card-img-top,
  .leeds-boxset-carousel.leeds-sidebar-carousel .amg-carousel-container[data-slide-visible="3"] .col.active .amg-card-video .card-img-top,
  .leeds-boxset-carousel.leeds-sidebar-carousel .amg-carousel-container[data-slide-visible="3.25"] .col.active .amg-card-image .card-img-top,
  .leeds-boxset-carousel.leeds-sidebar-carousel .amg-carousel-container[data-slide-visible="3.25"] .col.active .amg-card-video .card-img-top,
  .leeds-boxset-carousel.leeds-sidebar-carousel .amg-carousel-container[data-slide-visible="3.5"] .col.active .amg-card-image .card-img-top,
  .leeds-boxset-carousel.leeds-sidebar-carousel .amg-carousel-container[data-slide-visible="3.5"] .col.active .amg-card-video .card-img-top,
  .leeds-boxset-carousel.leeds-sidebar-carousel .amg-carousel-container[data-slide-visible="3.75"] .col.active .amg-card-image .card-img-top,
  .leeds-boxset-carousel.leeds-sidebar-carousel .amg-carousel-container[data-slide-visible="3.75"] .col.active .amg-card-video .card-img-top {
    padding-top: 100%; }

.leeds-boxset-carousel.leeds-sidebar-carousel .amg-carousel-container[data-slide-visible="1"] .amg-side-details,
.leeds-boxset-carousel.leeds-sidebar-carousel .amg-carousel-container[data-slide-visible="1.25"] .amg-side-details,
.leeds-boxset-carousel.leeds-sidebar-carousel .amg-carousel-container[data-slide-visible="1.5"] .amg-side-details,
.leeds-boxset-carousel.leeds-sidebar-carousel .amg-carousel-container[data-slide-visible="1.75"] .amg-side-details,
.leeds-boxset-carousel.leeds-sidebar-carousel .amg-carousel-container[data-slide-visible="2"] .amg-side-details,
.leeds-boxset-carousel.leeds-sidebar-carousel .amg-carousel-container[data-slide-visible="2.25"] .amg-side-details,
.leeds-boxset-carousel.leeds-sidebar-carousel .amg-carousel-container[data-slide-visible="2.5"] .amg-side-details,
.leeds-boxset-carousel.leeds-sidebar-carousel .amg-carousel-container[data-slide-visible="2.75"] .amg-side-details,
.leeds-boxset-carousel.leeds-sidebar-carousel .amg-carousel-container[data-slide-visible="3"] .amg-side-details,
.leeds-boxset-carousel.leeds-sidebar-carousel .amg-carousel-container[data-slide-visible="3.25"] .amg-side-details,
.leeds-boxset-carousel.leeds-sidebar-carousel .amg-carousel-container[data-slide-visible="3.5"] .amg-side-details,
.leeds-boxset-carousel.leeds-sidebar-carousel .amg-carousel-container[data-slide-visible="3.75"] .amg-side-details {
  pointer-events: none;
  margin: 0 auto 0 !important; }
  .leeds-boxset-carousel.leeds-sidebar-carousel .amg-carousel-container[data-slide-visible="1"] .amg-side-details > div,
  .leeds-boxset-carousel.leeds-sidebar-carousel .amg-carousel-container[data-slide-visible="1.25"] .amg-side-details > div,
  .leeds-boxset-carousel.leeds-sidebar-carousel .amg-carousel-container[data-slide-visible="1.5"] .amg-side-details > div,
  .leeds-boxset-carousel.leeds-sidebar-carousel .amg-carousel-container[data-slide-visible="1.75"] .amg-side-details > div,
  .leeds-boxset-carousel.leeds-sidebar-carousel .amg-carousel-container[data-slide-visible="2"] .amg-side-details > div,
  .leeds-boxset-carousel.leeds-sidebar-carousel .amg-carousel-container[data-slide-visible="2.25"] .amg-side-details > div,
  .leeds-boxset-carousel.leeds-sidebar-carousel .amg-carousel-container[data-slide-visible="2.5"] .amg-side-details > div,
  .leeds-boxset-carousel.leeds-sidebar-carousel .amg-carousel-container[data-slide-visible="2.75"] .amg-side-details > div,
  .leeds-boxset-carousel.leeds-sidebar-carousel .amg-carousel-container[data-slide-visible="3"] .amg-side-details > div,
  .leeds-boxset-carousel.leeds-sidebar-carousel .amg-carousel-container[data-slide-visible="3.25"] .amg-side-details > div,
  .leeds-boxset-carousel.leeds-sidebar-carousel .amg-carousel-container[data-slide-visible="3.5"] .amg-side-details > div,
  .leeds-boxset-carousel.leeds-sidebar-carousel .amg-carousel-container[data-slide-visible="3.75"] .amg-side-details > div {
    padding-top: 100%; }

.leeds-boxset-carousel.leeds-sidebar-carousel .amg-carousel-container[data-slide-visible="1.25"] + .amg-carousel-nav-container {
  top: calc(100% / 1.25 * 0.25);
  bottom: calc(100% / 1.25 * 0.25); }
  .leeds-boxset-carousel.leeds-sidebar-carousel .amg-carousel-container[data-slide-visible="1.25"] + .amg-carousel-nav-container .amg-carousel-nav button {
    width: calc(100% / 1.25 * 0.25); }

.leeds-boxset-carousel.leeds-sidebar-carousel .amg-carousel-container[data-slide-visible="1.5"] + .amg-carousel-nav-container {
  top: calc(100% / 1.5 * 0.5);
  bottom: calc(100% / 1.5 * 0.5); }
  .leeds-boxset-carousel.leeds-sidebar-carousel .amg-carousel-container[data-slide-visible="1.5"] + .amg-carousel-nav-container .amg-carousel-nav button {
    width: calc(100% / 1.5 * 0.5); }

.leeds-boxset-carousel.leeds-sidebar-carousel .amg-carousel-container[data-slide-visible="1.75"] + .amg-carousel-nav-container {
  top: calc(100% / 1.75 * 0.75);
  bottom: calc(100% / 1.75 * 0.75); }
  .leeds-boxset-carousel.leeds-sidebar-carousel .amg-carousel-container[data-slide-visible="1.75"] + .amg-carousel-nav-container .amg-carousel-nav button {
    width: calc(100% / 1.75 * 0.75); }

.leeds-boxset-carousel.leeds-sidebar-carousel .amg-carousel-container[data-slide-visible="2.25"] + .amg-carousel-nav-container {
  top: calc(100% / 2.25 * 0.25);
  bottom: calc(100% / 2.25 * 0.25); }
  .leeds-boxset-carousel.leeds-sidebar-carousel .amg-carousel-container[data-slide-visible="2.25"] + .amg-carousel-nav-container .amg-carousel-nav button {
    width: calc(100% / 2.25 * 0.25); }

.leeds-boxset-carousel.leeds-sidebar-carousel .amg-carousel-container[data-slide-visible="2.5"] + .amg-carousel-nav-container {
  top: calc(100% / 2.5 * 0.5);
  bottom: calc(100% / 2.5 * 0.5); }
  .leeds-boxset-carousel.leeds-sidebar-carousel .amg-carousel-container[data-slide-visible="2.5"] + .amg-carousel-nav-container .amg-carousel-nav button {
    width: calc(100% / 2.5 * 0.5); }

.leeds-boxset-carousel.leeds-sidebar-carousel .amg-carousel-container[data-slide-visible="2.75"] + .amg-carousel-nav-container {
  top: calc(100% / 2.75 * 0.75);
  bottom: calc(100% / 2.75 * 0.75); }
  .leeds-boxset-carousel.leeds-sidebar-carousel .amg-carousel-container[data-slide-visible="2.75"] + .amg-carousel-nav-container .amg-carousel-nav button {
    width: calc(100% / 2.75 * 0.75); }

.leeds-boxset-carousel.leeds-sidebar-carousel .amg-carousel-container + .amg-carousel-nav-container {
  top: 40px;
  bottom: 40px; }
  .leeds-boxset-carousel.leeds-sidebar-carousel .amg-carousel-container + .amg-carousel-nav-container button {
    margin: 0;
    height: 100%; }

.leeds-boxset-carousel.leeds-sidebar-carousel {
  width: 100%; }
  .leeds-boxset-carousel.leeds-sidebar-carousel .amg-carousel-container[data-slide-visible="3.25"] + .amg-carousel-nav-container .amg-carousel-nav {
    padding-top: calc(((((100%) / 3.25) - 40px))); }
  .leeds-boxset-carousel.leeds-sidebar-carousel .amg-carousel-container[data-slide-visible="3.25"] + .amg-carousel-nav-container .amg-carousel-nav button {
    width: calc((100% / 3.25 * 0.25) - 30px); }

.leeds-boxset-carousel.leeds-sidebar-carousel {
  width: 100%; }
  .leeds-boxset-carousel.leeds-sidebar-carousel .amg-carousel-container[data-slide-visible="3.5"] + .amg-carousel-nav-container .amg-carousel-nav {
    padding-top: calc(((((100%) / 3.5) - 40px))); }
  .leeds-boxset-carousel.leeds-sidebar-carousel .amg-carousel-container[data-slide-visible="3.5"] + .amg-carousel-nav-container .amg-carousel-nav button {
    width: calc((100% / 3.5 * 0.5) - 30px); }

.leeds-boxset-carousel.leeds-sidebar-carousel {
  width: 100%; }
  .leeds-boxset-carousel.leeds-sidebar-carousel .amg-carousel-container[data-slide-visible="3.75"] + .amg-carousel-nav-container .amg-carousel-nav {
    padding-top: calc(((((100%) / 3.75) - 40px))); }
  .leeds-boxset-carousel.leeds-sidebar-carousel .amg-carousel-container[data-slide-visible="3.75"] + .amg-carousel-nav-container .amg-carousel-nav button {
    width: calc((100% / 3.75 * 0.75) - 30px); }

.leeds-boxset-carousel.leeds-sidebar-carousel {
  width: 100%; }
  .leeds-boxset-carousel.leeds-sidebar-carousel .amg-carousel-container[data-slide-visible="4.25"] + .amg-carousel-nav-container .amg-carousel-nav {
    padding-top: calc(((((100%) / 4.25) - 40px))); }
  .leeds-boxset-carousel.leeds-sidebar-carousel .amg-carousel-container[data-slide-visible="4.25"] + .amg-carousel-nav-container .amg-carousel-nav button {
    width: calc((100% / 4.25 * 0.25) - 30px); }

.leeds-boxset-carousel.leeds-sidebar-carousel {
  width: 100%; }
  .leeds-boxset-carousel.leeds-sidebar-carousel .amg-carousel-container[data-slide-visible="4.5"] + .amg-carousel-nav-container .amg-carousel-nav {
    padding-top: calc(((((100%) / 4.5) - 40px))); }
  .leeds-boxset-carousel.leeds-sidebar-carousel .amg-carousel-container[data-slide-visible="4.5"] + .amg-carousel-nav-container .amg-carousel-nav button {
    width: calc((100% / 4.5 * 0.5) - 30px); }

.leeds-boxset-carousel.leeds-sidebar-carousel {
  width: 100%; }
  .leeds-boxset-carousel.leeds-sidebar-carousel .amg-carousel-container[data-slide-visible="4.75"] + .amg-carousel-nav-container .amg-carousel-nav {
    padding-top: calc(((((100%) / 4.75) - 40px))); }
  .leeds-boxset-carousel.leeds-sidebar-carousel .amg-carousel-container[data-slide-visible="4.75"] + .amg-carousel-nav-container .amg-carousel-nav button {
    width: calc((100% / 4.75 * 0.75) - 30px); }

.leeds-boxset-carousel.leeds-sidebar-carousel {
  width: 100%; }
  .leeds-boxset-carousel.leeds-sidebar-carousel .amg-carousel-container[data-slide-visible="5.25"] + .amg-carousel-nav-container .amg-carousel-nav {
    padding-top: calc(((((100%) / 5.25) - 40px))); }
  .leeds-boxset-carousel.leeds-sidebar-carousel .amg-carousel-container[data-slide-visible="5.25"] + .amg-carousel-nav-container .amg-carousel-nav button {
    width: calc((100% / 5.25 * 0.25) - 30px); }

.leeds-boxset-carousel.leeds-sidebar-carousel {
  width: 100%; }
  .leeds-boxset-carousel.leeds-sidebar-carousel .amg-carousel-container[data-slide-visible="5.5"] + .amg-carousel-nav-container .amg-carousel-nav {
    padding-top: calc(((((100%) / 5.5) - 40px))); }
  .leeds-boxset-carousel.leeds-sidebar-carousel .amg-carousel-container[data-slide-visible="5.5"] + .amg-carousel-nav-container .amg-carousel-nav button {
    width: calc((100% / 5.5 * 0.5) - 30px); }

.leeds-boxset-carousel.leeds-sidebar-carousel {
  width: 100%; }
  .leeds-boxset-carousel.leeds-sidebar-carousel .amg-carousel-container[data-slide-visible="5.75"] + .amg-carousel-nav-container .amg-carousel-nav {
    padding-top: calc(((((100%) / 5.75) - 40px))); }
  .leeds-boxset-carousel.leeds-sidebar-carousel .amg-carousel-container[data-slide-visible="5.75"] + .amg-carousel-nav-container .amg-carousel-nav button {
    width: calc((100% / 5.75 * 0.75) - 30px); }

.leeds-playlist-carousel {
  width: 100%;
  width: 100%;
  width: 100%;
  width: 100%;
  width: 100%;
  width: 100%;
  width: 100%;
  width: 100%;
  width: 100%;
  width: 100%;
  width: 100%;
  width: 100%;
  width: 100%;
  width: 100%;
  width: 100%; }
  .leeds-playlist-carousel .amg-section-child {
    justify-content: stretch; }
  .leeds-playlist-carousel .amg-carousel-nav-container .amg-carousel-nav button {
    transition: border-radius 0.333s ease-in-out, margin 0.333s ease-in-out; }
  .leeds-playlist-carousel .amg-carousel-nav-container .amg-carousel-nav .prev {
    border-radius: 0 16px 16px 0; }
  .leeds-playlist-carousel .amg-carousel-nav-container .amg-carousel-nav .next {
    border-radius: 16px 0 0 16px; }
  .leeds-playlist-carousel .amg-carousel:hover [data-slide-visible="5.5"][data-slide-visible-hovered="5"] .amg-carousel-nav-container .amg-carousel-nav .next:not(:hover) {
    margin-right: 16px;
    border-radius: 0 16px 16px 0; }
  .leeds-playlist-carousel .col {
    transition: all 0.333s ease-in-out 0s !important; }
    .leeds-playlist-carousel .col .amg-card-image,
    .leeds-playlist-carousel .col .amg-card-video {
      margin-right: 16px; }
    .leeds-playlist-carousel .col .amg-card-image,
    .leeds-playlist-carousel .col .amg-card-video {
      height: 100%; }
      .leeds-playlist-carousel .col .amg-card-image .card-img-top,
      .leeds-playlist-carousel .col .amg-card-video .card-img-top {
        padding-top: 100%;
        height: 100%;
        transition: padding 0.333s ease-in-out 0.333s, height 0.333s ease-in-out 0s; }
    .leeds-playlist-carousel .col:hover .amg-card-image .card-img-top,
    .leeds-playlist-carousel .col:hover .amg-card-video .card-img-top {
      padding-top: 0;
      transition-delay: 0s, 0.333s;
      max-height: 100%; }
  .leeds-playlist-carousel .amg-carousel-container[data-slide-visible="1.25"] + .amg-carousel-nav-container .amg-carousel-nav button {
    width: calc(100% / 1.25 * 0.25); }
  .leeds-playlist-carousel .amg-carousel-container[data-slide-visible="1.5"] + .amg-carousel-nav-container .amg-carousel-nav button {
    width: calc(100% / 1.5 * 0.5); }
  .leeds-playlist-carousel .amg-carousel-container[data-slide-visible="1.75"] + .amg-carousel-nav-container .amg-carousel-nav button {
    width: calc(100% / 1.75 * 0.75); }
  .leeds-playlist-carousel .amg-carousel-container[data-slide-visible="2.25"] + .amg-carousel-nav-container .amg-carousel-nav button {
    width: calc(100% / 2.25 * 0.25); }
  .leeds-playlist-carousel .amg-carousel-container[data-slide-visible="2.5"] + .amg-carousel-nav-container .amg-carousel-nav button {
    width: calc(100% / 2.5 * 0.5); }
  .leeds-playlist-carousel .amg-carousel-container[data-slide-visible="2.75"] + .amg-carousel-nav-container .amg-carousel-nav button {
    width: calc(100% / 2.75 * 0.75); }
  .leeds-playlist-carousel .amg-carousel-container[data-slide-visible="3.25"] + .amg-carousel-nav-container .amg-carousel-nav button {
    width: calc(100% / 3.25 * 0.25); }
  .leeds-playlist-carousel .amg-carousel-container[data-slide-visible="3.5"] + .amg-carousel-nav-container .amg-carousel-nav button {
    width: calc(100% / 3.5 * 0.5); }
  .leeds-playlist-carousel .amg-carousel-container[data-slide-visible="3.75"] + .amg-carousel-nav-container .amg-carousel-nav button {
    width: calc(100% / 3.75 * 0.75); }
  .leeds-playlist-carousel .amg-carousel-container[data-slide-visible="4.25"] + .amg-carousel-nav-container .amg-carousel-nav button {
    width: calc(100% / 4.25 * 0.25); }
  .leeds-playlist-carousel .amg-carousel-container[data-slide-visible="4.5"] + .amg-carousel-nav-container .amg-carousel-nav button {
    width: calc(100% / 4.5 * 0.5); }
  .leeds-playlist-carousel .amg-carousel-container[data-slide-visible="4.75"] + .amg-carousel-nav-container .amg-carousel-nav button {
    width: calc(100% / 4.75 * 0.75); }
  .leeds-playlist-carousel .amg-carousel-container[data-slide-visible="5.25"] + .amg-carousel-nav-container .amg-carousel-nav button {
    width: calc(100% / 5.25 * 0.25); }
  .leeds-playlist-carousel .amg-carousel-container[data-slide-visible="5.5"] + .amg-carousel-nav-container .amg-carousel-nav button {
    width: calc(100% / 5.5 * 0.5); }
  .leeds-playlist-carousel .amg-carousel-container[data-slide-visible="5.75"] + .amg-carousel-nav-container .amg-carousel-nav button {
    width: calc(100% / 5.75 * 0.75); }

.leeds-video-carousel .amg-carousel-nav-container {
  bottom: initial; }

.leeds-video-carousel .amg-card-video {
  display: block;
  padding-right: 16px; }
  .leeds-video-carousel .amg-card-video,
  .leeds-video-carousel .amg-card-video > * {
    border-radius: 0; }
  .leeds-video-carousel .amg-card-video .card-body {
    transition: opacity 0.333s ease-in-out; }

.modal-backdrop {
  background-color: #0E2A51; }
  .modal-backdrop.show {
    opacity: .85; }

.modal .amg-template amg-form-builder form .amg-form-field {
  border-radius: 0;
  border-color: transparent;
  border-bottom-color: #588BBC;
  margin-bottom: 16px;
  padding-left: 0;
  padding-right: 0; }
  .modal .amg-template amg-form-builder form .amg-form-field:focus {
    outline: 0; }

.modal .amg-template amg-form-builder form button {
  border-radius: 50pt;
  border-style: solid;
  border-color: #2B5697;
  background-color: #2B5697;
  margin-bottom: 16px; }

.modal .amg-cta-description {
  font-size: 12pt; }

amg-template-builder .amg-template {
  padding: 1em; }
  amg-template-builder .amg-template .amg-logo-wrapper {
    text-align: center; }
  amg-template-builder .amg-template amg-form-builder form {
    margin-top: 2em; }
    amg-template-builder .amg-template amg-form-builder form .amg-form-field {
      margin-top: 0.5em;
      width: 100%;
      padding: 0.375rem 0.75rem;
      font-size: 1rem;
      line-height: 1.5;
      background-color: #FFFFFF;
      background-clip: padding-box;
      border: 1px solid #495057;
      border-radius: 0.25rem; }
    amg-template-builder .amg-template amg-form-builder form button {
      margin-top: 0.5em;
      display: inline-block;
      font-weight: 400;
      text-align: center;
      vertical-align: middle;
      user-select: none;
      padding: 0.375rem 0.75rem;
      font-size: 1rem;
      line-height: 1.5;
      border-radius: 0.25rem;
      transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  amg-template-builder .amg-template .amg-cta-btn-wrapper {
    margin-top: 0.5em; }

amg-template-builder {
  position: relative; }
  amg-template-builder .amg-form-error {
    position: absolute;
    left: 0;
    right: 0;
    bottom: -48px;
    text-align: center;
    background-color: #ffadb0;
    border-radius: 0 0 0.3rem 0.3rem;
    padding: 16px;
    font-size: 16px;
    color: #3e3e3e; }
  amg-template-builder .close {
    position: absolute;
    right: 16px;
    top: 16px; }
  amg-template-builder .amg-cta-btn {
    text-transform: uppercase; }
  amg-template-builder amg-call-to-action {
    display: block;
    margin-top: 0;
    margin-bottom: 32px;
    font-size: 14px; }
    amg-template-builder amg-call-to-action:last-child {
      margin-top: 32px;
      margin-bottom: 16px; }
      amg-template-builder amg-call-to-action:last-child .amg-cta-description {
        margin-top: 0;
        margin-bottom: 32px;
        font-size: 14px; }
      amg-template-builder amg-call-to-action:last-child .amg-cta-btn {
        border-radius: 50pt;
        color: #FFFFFF;
        border-style: solid;
        border-color: #BD9B60;
        background-color: #BD9B60;
        padding: 0.375rem 0.75rem; }
        amg-template-builder amg-call-to-action:last-child .amg-cta-btn:hover {
          text-decoration: none; }

.navigation-container {
  background: #0E2A51;
  background-size: cover;
  border-top: #2B5697 solid 3px;
  border-bottom: #2B5697 solid 3px;
  min-height: 220px;
  justify-content: center;
  padding: 32px 16px; }
  .navigation-container .amg-section-title {
    margin-bottom: 10px; }

.navigation-container-tabs {
  justify-content: space-between;
  padding: 0;
  /*.amg-section-title:before,
  .amg-section-children {
    min-height: 50px;
  }*/ }
  .navigation-container-tabs .amg-section-title {
    margin: auto;
    justify-self: center; }
    .navigation-container-tabs .amg-section-title:before {
      content: "";
      display: block; }
  .navigation-container-tabs .nav-link {
    font-weight: bold;
    font-size: 17px; }
  .navigation-container-tabs .amg-section-children {
    overflow-x: auto;
    scrollbar-color: #FFFFFF #2B5697;
    scrollbar-width: thin;
    width: auto;
    max-width: 100%;
    height: auto;
    margin: 0 auto;
    justify-content: initial; }
    .navigation-container-tabs .amg-section-children .amg-section-child {
      width: auto;
      height: auto;
      justify-content: flex-end; }
      .navigation-container-tabs .amg-section-children .amg-section-child .amg-action-link {
        min-width: 160px; }
        .navigation-container-tabs .amg-section-children .amg-section-child .amg-action-link .amg-link-container {
          width: 100%; }
          .navigation-container-tabs .amg-section-children .amg-section-child .amg-action-link .amg-link-container .nav-link {
            width: 100%;
            border-top-left-radius: 10px;
            border-top-right-radius: 10px;
            border-bottom-width: 0;
            padding-left: 20px;
            padding-right: 20px; }
            .navigation-container-tabs .amg-section-children .amg-section-child .amg-action-link .amg-link-container .nav-link.active {
              color: #FFFFFF;
              background-color: #2B5697;
              border-color: #2B5697; }

.amg-calendar {
  width: 100%; }
  .amg-calendar .amg-carousel-calendar .amg-carousel .amg-carousel-container {
    overflow: hidden; }
    .amg-calendar .amg-carousel-calendar .amg-carousel .amg-carousel-container .col .year {
      order: 2;
      font-size: 15px; }
    .amg-calendar .amg-carousel-calendar .amg-carousel .amg-carousel-container .col .monthText {
      font-family: futura-pt-condensed, serif;
      font-size: 28px;
      text-transform: uppercase;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      order: 3; }
    .amg-calendar .amg-carousel-calendar .amg-carousel .amg-carousel-container .col:hover {
      cursor: pointer; }
    .amg-calendar .amg-carousel-calendar .amg-carousel .amg-carousel-container .col.selected .amg-card-text {
      background-color: #F4F4F4; }
    .amg-calendar .amg-carousel-calendar .amg-carousel .amg-carousel-container .col.selected .monthText {
      color: #BD9B60; }
    .amg-calendar .amg-carousel-calendar .amg-carousel .amg-carousel-container .col:not(.active) {
      display: none; }
    .amg-calendar .amg-carousel-calendar .amg-carousel .amg-carousel-container .amg-card-text {
      background-color: #FAFAFA; }
  .amg-calendar .amg-carousel-calendar .amg-carousel .amg-carousel-nav-container {
    bottom: initial; }
    .amg-calendar .amg-carousel-calendar .amg-carousel .amg-carousel-nav-container .amg-carousel-nav button {
      position: absolute;
      top: 0;
      bottom: 0;
      pointer-events: auto;
      border: 0;
      color: transparent;
      background: url("~src/assets/images/chevron-right-blue.png") center no-repeat;
      background-size: 16pt auto; }
      .amg-calendar .amg-carousel-calendar .amg-carousel .amg-carousel-nav-container .amg-carousel-nav button[hidden] {
        display: block !important;
        pointer-events: none;
        opacity: 0; }
    .amg-calendar .amg-carousel-calendar .amg-carousel .amg-carousel-nav-container .amg-carousel-nav .prev {
      height: 80px;
      left: 0;
      padding-left: 10pt;
      margin-top: -85px;
      transform: rotate(180deg); }
    .amg-calendar .amg-carousel-calendar .amg-carousel .amg-carousel-nav-container .amg-carousel-nav .next {
      height: 80px;
      padding-right: 10pt;
      margin-top: -85px;
      right: 0; }

.dropdown .btn {
  background-color: #FFFFFF;
  border-color: #2B5697;
  color: #2B5697;
  outline: none; }
  .dropdown .btn.show {
    background-color: #2B5697;
    color: #FFFFFF; }

.dropdown .dropdown-menu {
  padding: 0;
  overflow: hidden; }

.amg-grid {
  width: 100%; }
  .amg-grid h2 {
    text-transform: capitalize;
    margin-bottom: 38px; }
  .amg-grid.grid-playlist .amg-card-image,
  .amg-grid.grid-playlist .amg-card-video {
    border: 0;
    border-radius: 16px;
    overflow: hidden;
    cursor: pointer; }
    .amg-grid.grid-playlist .amg-card-image .card-img-top > .title,
    .amg-grid.grid-playlist .amg-card-image .card-body > .title,
    .amg-grid.grid-playlist .amg-card-video .card-img-top > .title,
    .amg-grid.grid-playlist .amg-card-video .card-body > .title {
      color: #FFFFFF;
      background: rgba(8, 26, 42, 0.76);
      text-align: center;
      font-size: 17px;
      padding: 10px;
      bottom: 10px; }
      @media (min-width: 768px) {
        .amg-grid.grid-playlist .amg-card-image .card-img-top > .title,
        .amg-grid.grid-playlist .amg-card-image .card-body > .title,
        .amg-grid.grid-playlist .amg-card-video .card-img-top > .title,
        .amg-grid.grid-playlist .amg-card-video .card-body > .title {
          font-size: 23px; } }
  .amg-grid.grid-boxset .amg-card-video {
    cursor: pointer;
    color: #FFFFFF;
    position: relative;
    z-index: 0;
    margin: 32px; }
    .amg-grid.grid-boxset .amg-card-video, .amg-grid.grid-boxset .amg-card-video:after, .amg-grid.grid-boxset .amg-card-video:before,
    .amg-grid.grid-boxset .amg-card-video .card-img-top:after {
      transition: transform 0.333s ease-in-out 0s; }
    .amg-grid.grid-boxset .amg-card-video, .amg-grid.grid-boxset .amg-card-video:before, .amg-grid.grid-boxset .amg-card-video:after {
      box-shadow: 0pt 3pt 6pt 0pt rgba(0, 0, 0, 0.16); }
    .amg-grid.grid-boxset .amg-card-video:before, .amg-grid.grid-boxset .amg-card-video:after {
      display: block;
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      pointer-events: none; }
    .amg-grid.grid-boxset .amg-card-video:after {
      background: #2B5697;
      top: -10px;
      left: 10px;
      right: -10px;
      bottom: 10px;
      z-index: -1; }
    .amg-grid.grid-boxset .amg-card-video:before {
      background: #0E2A51;
      top: -20px;
      left: 20px;
      right: -20px;
      bottom: 20px;
      z-index: -2; }
    .amg-grid.grid-boxset .amg-card-video,
    .amg-grid.grid-boxset .amg-card-video .card-img-top {
      background-color: transparent;
      border-radius: 0; }
    .amg-grid.grid-boxset .amg-card-video .card-img-top {
      position: relative;
      background-color: #588BBC;
      padding-top: 100%; }
      .amg-grid.grid-boxset .amg-card-video .card-img-top:before {
        display: block;
        content: "";
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background: url("~src/assets/images/card-video-play.svg") center no-repeat transparent; }
    .amg-grid.grid-boxset .amg-card-video .card-body {
      display: none; }
  .amg-grid.grid-boxset .amg-card-image .card-body,
  .amg-grid.grid-boxset .amg-card-video .card-body {
    display: block; }
    .amg-grid.grid-boxset .amg-card-image .card-body > *,
    .amg-grid.grid-boxset .amg-card-video .card-body > * {
      display: none; }
  .amg-grid.grid-boxset .amg-card-image .card-img-top .title,
  .amg-grid.grid-boxset .amg-card-image .card-body .title,
  .amg-grid.grid-boxset .amg-card-video .card-img-top .title,
  .amg-grid.grid-boxset .amg-card-video .card-body .title {
    display: block; }
  .amg-grid.grid-boxset .amg-card-image .card-img-top > .title,
  .amg-grid.grid-boxset .amg-card-image .card-body > .title,
  .amg-grid.grid-boxset .amg-card-video .card-img-top > .title,
  .amg-grid.grid-boxset .amg-card-video .card-body > .title {
    color: #FFFFFF;
    background: rgba(8, 26, 42, 0.76);
    text-align: center;
    font-size: 17px;
    padding: 10px;
    bottom: 10px; }
    @media (min-width: 768px) {
      .amg-grid.grid-boxset .amg-card-image .card-img-top > .title,
      .amg-grid.grid-boxset .amg-card-image .card-body > .title,
      .amg-grid.grid-boxset .amg-card-video .card-img-top > .title,
      .amg-grid.grid-boxset .amg-card-video .card-body > .title {
        font-size: 23px; } }
  .amg-grid.grid-boxset .amg-card-video .card-body > .title {
    position: absolute; }
  .amg-grid .amg-grid > .search-container {
    font-family: Oswald, sans-serif;
    margin-top: 25px;
    margin-bottom: 25px;
    display: block;
    flex-basis: auto; }
    .amg-grid .amg-grid > .search-container .amg-input-search-field-container {
      margin-top: 10px;
      margin-bottom: 10px; }
      .amg-grid .amg-grid > .search-container .amg-input-search-field-container .input-search-label {
        display: none; }
    .amg-grid .amg-grid > .search-container [formControlName="search"] {
      width: 100%;
      margin-bottom: 25px;
      font-size: 18px;
      height: 62px;
      color: #2B5697;
      background-color: #E6E7E8;
      background-image: url("~src/assets/images/search_icon_small.png");
      background-repeat: no-repeat;
      background-position-y: 50%;
      background-position-x: 12px;
      text-indent: 32px;
      border-radius: 0;
      border: none;
      padding-left: 12px; }
      .amg-grid .amg-grid > .search-container [formControlName="search"]::placeholder {
        color: #2B5697; }
      .amg-grid .amg-grid > .search-container [formControlName="search"] + .close,
      .amg-grid .amg-grid > .search-container [formControlName="search"] + input[type="submit"],
      .amg-grid .amg-grid > .search-container [formControlName="search"] + .close + input[type="submit"] {
        display: none; }
    .amg-grid .amg-grid > .search-container amg-filter-dropdown {
      display: inline-block;
      padding: 0 10px 10px 0;
      max-width: 100%; }
      .amg-grid .amg-grid > .search-container amg-filter-dropdown:nth-child(1) {
        width: 200px; }
      .amg-grid .amg-grid > .search-container amg-filter-dropdown:nth-child(2) {
        width: 150px; }
      .amg-grid .amg-grid > .search-container amg-filter-dropdown:nth-child(3) {
        width: 135px; }
    .amg-grid .amg-grid > .search-container .amg-filter-dropdown {
      text-align: left;
      font-size: 16px; }
      .amg-grid .amg-grid > .search-container .amg-filter-dropdown .amg-select-field .ng-clear-wrapper {
        display: none; }
      .amg-grid .amg-grid > .search-container .amg-filter-dropdown .amg-select-field .ng-arrow-wrapper .ng-arrow {
        color: #2b5697;
        margin-left: -25px; }
        .amg-grid .amg-grid > .search-container .amg-filter-dropdown .amg-select-field .ng-arrow-wrapper .ng-arrow::after {
          display: inline-block;
          margin-left: 0.255em;
          vertical-align: 0.255em;
          content: "";
          border-top: 0.3em solid;
          border-right: 0.3em solid transparent;
          border-bottom: 0;
          border-left: 0.3em solid transparent; }
        .amg-grid .amg-grid > .search-container .amg-filter-dropdown .amg-select-field .ng-arrow-wrapper .ng-arrow:empty::after {
          margin-left: 0; }
      .amg-grid .amg-grid > .search-container .amg-filter-dropdown .amg-select-field .ng-value {
        color: #2b5697; }
      .amg-grid .amg-grid > .search-container .amg-filter-dropdown .amg-select-field .ng-select-container {
        background-color: #fff;
        border: 1px solid #2B5697;
        outline: 0;
        cursor: pointer;
        border-radius: 3px;
        padding: 12px 6px; }
      .amg-grid .amg-grid > .search-container .amg-filter-dropdown .amg-select-field.ng-select-opened .ng-select-container {
        background-color: #2b5697; }
        .amg-grid .amg-grid > .search-container .amg-filter-dropdown .amg-select-field.ng-select-opened .ng-select-container .ng-value {
          color: #fff; }
        .amg-grid .amg-grid > .search-container .amg-filter-dropdown .amg-select-field.ng-select-opened .ng-select-container .ng-arrow {
          color: #fff; }
      .amg-grid .amg-grid > .search-container .amg-filter-dropdown .amg-select-field .ng-option {
        border-left: transparent solid thick;
        padding: .25rem 1.5rem;
        font-weight: 400;
        color: #212529;
        white-space: nowrap; }
        .amg-grid .amg-grid > .search-container .amg-filter-dropdown .amg-select-field .ng-option:hover {
          background-color: inherit;
          border-left-color: #2B5697; }
      .amg-grid .amg-grid > .search-container .amg-filter-dropdown .amg-grid-filter-title {
        font-size: 13px;
        margin-bottom: 8px;
        white-space: nowrap; }
  .amg-grid .amg-grid .results-container {
    overflow: hidden; }
    .amg-grid .amg-grid .results-container .col {
      flex-grow: 0;
      flex-basis: auto;
      margin-bottom: 20px; }
  .amg-grid .amg-grid .btn-load-more {
    color: #FFFFFF;
    background-color: #2B5697;
    border: none;
    border-radius: 25px;
    display: block;
    margin: 30px auto; }
  @media (min-width: 768px) {
    .amg-grid .amg-grid .results-container {
      margin: 0 -8px; }
      .amg-grid .amg-grid .results-container .col {
        width: 50%;
        padding: 0 8px; }
        .amg-grid .amg-grid .results-container .col .amg-card-video .card-body .categories {
          font-size: 13px; } }
  @media (min-width: 992px) {
    .amg-grid .amg-grid > .search-container {
      flex-basis: 0; }
      .amg-grid .amg-grid > .search-container.filters-container > .row {
        justify-content: flex-end; }
        .amg-grid .amg-grid > .search-container.filters-container > .row > .col {
          padding: 0 35px 20px 0; }
    .amg-grid .amg-grid .results-container .col {
      width: 33.33333333%; } }
  @media (min-width: 1280px) {
    .amg-grid .amg-grid > .search-container .amg-filter-dropdown-container,
    .amg-grid .amg-grid > .search-container .amg-input-search-field-container {
      display: inline-block;
      width: 500px;
      vertical-align: middle; }
    .amg-grid .amg-grid > .search-container .amg-input-search-field-container {
      float: left; }
    .amg-grid .amg-grid > .search-container .amg-filter-dropdown-container {
      float: right;
      text-align: right; }
    .amg-grid .amg-grid > .search-container::after {
      display: block;
      clear: both;
      content: ""; }
    .amg-grid .amg-grid > .search-container [formControlName="search"] {
      max-width: 360px; }
    .amg-grid .amg-grid .results-container .col {
      width: 25%; } }
  @media (min-width: 1920px) {
    .amg-grid .amg-grid .results-container .col {
      width: 20%; } }
  @media (min-width: 3840px) {
    .amg-grid .amg-grid .results-container .col {
      width: 16.66666667%; } }

.amg-fixtures .amg-message-container {
  color: #FFFFFF;
  margin-top: 20px;
  margin-bottom: 30px; }

.welcome-banner {
  padding: 16px 0;
  background: #0E2A51;
  min-height: calc(100vh - 200px); }
  .welcome-banner .amg-login-button-group {
    display: block; }
  .welcome-banner .amg-section .amg-section-child {
    width: auto; }
  .welcome-banner,
  .welcome-banner > .amg-section-children {
    display: flex;
    flex-direction: column;
    justify-content: flex-end; }
  .welcome-banner .welcome-banner-social-links,
  .welcome-banner .welcome-banner-menu {
    display: none; }
  .welcome-banner.invert .amg-card-video .card-img-top {
    border: 1px solid #FFFFFF; }
  .welcome-banner .btn-home {
    cursor: pointer; }
  .welcome-banner > .amg-section-title,
  .welcome-banner > .amg-section-children,
  .welcome-banner .welcome-banner-social-links .amg-section-children,
  .welcome-banner .welcome-banner-menu .amg-section-children {
    padding-right: 16px;
    padding-left: 16px; }
    @media (min-width: 1200px) {
      .welcome-banner > .amg-section-title,
      .welcome-banner > .amg-section-children,
      .welcome-banner .welcome-banner-social-links .amg-section-children,
      .welcome-banner .welcome-banner-menu .amg-section-children {
        padding-left: 16px;
        padding-right: 16px; } }
  .welcome-banner .amg-section-title, .welcome-banner .amg-section-description {
    text-align: left; }
  .welcome-banner .amg-section-title, .welcome-banner .amg-section-description,
  .welcome-banner .amg-action-link .amg-button-container .amg-button {
    margin-bottom: 16px; }
  .welcome-banner > .amg-section-children {
    display: flex;
    justify-content: space-between;
    height: 100%;
    flex: auto; }
    .welcome-banner > .amg-section-children:before {
      display: block;
      content: ".";
      color: transparent;
      font-size: 25px; }
  .welcome-banner .amg-action-link .amg-button-container .amg-button {
    border-radius: 50px;
    color: #FFFFFF;
    border-color: #2B5697;
    background: #2B5697; }
  .welcome-banner .amg-card-video {
    margin-right: 16px; }
  .welcome-banner .leeds-carousel {
    padding: 0; }
    .welcome-banner .leeds-carousel .amg-carousel-nav-container .amg-carousel-nav button {
      background-color: transparent; }
      .welcome-banner .leeds-carousel .amg-carousel-nav-container .amg-carousel-nav button:focus {
        outline: none; }
    .welcome-banner .leeds-carousel .amg-carousel-nav-container .amg-carousel-nav .next {
      background-position: 8px center;
      background-size: 14px; }
    .welcome-banner .leeds-carousel .amg-carousel-container[data-slide-visible="1.25"][data-slide-visible-hovered="1.25"] + .amg-carousel-nav-container {
      right: calc((((100% + 16px) / 1.25 * 0.25) - 16px) * -1); }
    .welcome-banner .leeds-carousel .amg-carousel-container[data-slide-visible="1.25"][data-slide-visible-hovered="1.5"] + .amg-carousel-nav-container {
      right: calc((((100% + 16px) / 1.25 * 0.5) - 16px) * -1); }
    .welcome-banner .leeds-carousel .amg-carousel-container[data-slide-visible="1.25"][data-slide-visible-hovered="1.75"] + .amg-carousel-nav-container {
      right: calc((((100% + 16px) / 1.25 * 0.75) - 16px) * -1); }
    .welcome-banner .leeds-carousel .amg-carousel-container[data-slide-visible="1.25"][data-slide-visible-hovered="2.25"] + .amg-carousel-nav-container {
      right: calc((((100% + 16px) / 1.25 * 0.25) - 16px) * -1); }
    .welcome-banner .leeds-carousel .amg-carousel-container[data-slide-visible="1.25"][data-slide-visible-hovered="2.5"] + .amg-carousel-nav-container {
      right: calc((((100% + 16px) / 1.25 * 0.5) - 16px) * -1); }
    .welcome-banner .leeds-carousel .amg-carousel-container[data-slide-visible="1.25"][data-slide-visible-hovered="2.75"] + .amg-carousel-nav-container {
      right: calc((((100% + 16px) / 1.25 * 0.75) - 16px) * -1); }
    .welcome-banner .leeds-carousel .amg-carousel-container[data-slide-visible="1.25"][data-slide-visible-hovered="3.25"] + .amg-carousel-nav-container {
      right: calc((((100% + 16px) / 1.25 * 0.25) - 16px) * -1); }
    .welcome-banner .leeds-carousel .amg-carousel-container[data-slide-visible="1.25"][data-slide-visible-hovered="3.5"] + .amg-carousel-nav-container {
      right: calc((((100% + 16px) / 1.25 * 0.5) - 16px) * -1); }
    .welcome-banner .leeds-carousel .amg-carousel-container[data-slide-visible="1.25"][data-slide-visible-hovered="3.75"] + .amg-carousel-nav-container {
      right: calc((((100% + 16px) / 1.25 * 0.75) - 16px) * -1); }
    .welcome-banner .leeds-carousel .amg-carousel-container[data-slide-visible="1.25"][data-slide-visible-hovered="4.25"] + .amg-carousel-nav-container {
      right: calc((((100% + 16px) / 1.25 * 0.25) - 16px) * -1); }
    .welcome-banner .leeds-carousel .amg-carousel-container[data-slide-visible="1.25"][data-slide-visible-hovered="4.5"] + .amg-carousel-nav-container {
      right: calc((((100% + 16px) / 1.25 * 0.5) - 16px) * -1); }
    .welcome-banner .leeds-carousel .amg-carousel-container[data-slide-visible="1.25"][data-slide-visible-hovered="4.75"] + .amg-carousel-nav-container {
      right: calc((((100% + 16px) / 1.25 * 0.75) - 16px) * -1); }
    .welcome-banner .leeds-carousel .amg-carousel-container[data-slide-visible="1.25"][data-slide-visible-hovered="5.25"] + .amg-carousel-nav-container {
      right: calc((((100% + 16px) / 1.25 * 0.25) - 16px) * -1); }
    .welcome-banner .leeds-carousel .amg-carousel-container[data-slide-visible="1.25"][data-slide-visible-hovered="5.5"] + .amg-carousel-nav-container {
      right: calc((((100% + 16px) / 1.25 * 0.5) - 16px) * -1); }
    .welcome-banner .leeds-carousel .amg-carousel-container[data-slide-visible="1.25"][data-slide-visible-hovered="5.75"] + .amg-carousel-nav-container {
      right: calc((((100% + 16px) / 1.25 * 0.75) - 16px) * -1); }
    .welcome-banner .leeds-carousel .amg-carousel-container[data-slide-visible="1.5"][data-slide-visible-hovered="1.25"] + .amg-carousel-nav-container {
      right: calc((((100% + 16px) / 1.5 * 0.25) - 16px) * -1); }
    .welcome-banner .leeds-carousel .amg-carousel-container[data-slide-visible="1.5"][data-slide-visible-hovered="1.5"] + .amg-carousel-nav-container {
      right: calc((((100% + 16px) / 1.5 * 0.5) - 16px) * -1); }
    .welcome-banner .leeds-carousel .amg-carousel-container[data-slide-visible="1.5"][data-slide-visible-hovered="1.75"] + .amg-carousel-nav-container {
      right: calc((((100% + 16px) / 1.5 * 0.75) - 16px) * -1); }
    .welcome-banner .leeds-carousel .amg-carousel-container[data-slide-visible="1.5"][data-slide-visible-hovered="2.25"] + .amg-carousel-nav-container {
      right: calc((((100% + 16px) / 1.5 * 0.25) - 16px) * -1); }
    .welcome-banner .leeds-carousel .amg-carousel-container[data-slide-visible="1.5"][data-slide-visible-hovered="2.5"] + .amg-carousel-nav-container {
      right: calc((((100% + 16px) / 1.5 * 0.5) - 16px) * -1); }
    .welcome-banner .leeds-carousel .amg-carousel-container[data-slide-visible="1.5"][data-slide-visible-hovered="2.75"] + .amg-carousel-nav-container {
      right: calc((((100% + 16px) / 1.5 * 0.75) - 16px) * -1); }
    .welcome-banner .leeds-carousel .amg-carousel-container[data-slide-visible="1.5"][data-slide-visible-hovered="3.25"] + .amg-carousel-nav-container {
      right: calc((((100% + 16px) / 1.5 * 0.25) - 16px) * -1); }
    .welcome-banner .leeds-carousel .amg-carousel-container[data-slide-visible="1.5"][data-slide-visible-hovered="3.5"] + .amg-carousel-nav-container {
      right: calc((((100% + 16px) / 1.5 * 0.5) - 16px) * -1); }
    .welcome-banner .leeds-carousel .amg-carousel-container[data-slide-visible="1.5"][data-slide-visible-hovered="3.75"] + .amg-carousel-nav-container {
      right: calc((((100% + 16px) / 1.5 * 0.75) - 16px) * -1); }
    .welcome-banner .leeds-carousel .amg-carousel-container[data-slide-visible="1.5"][data-slide-visible-hovered="4.25"] + .amg-carousel-nav-container {
      right: calc((((100% + 16px) / 1.5 * 0.25) - 16px) * -1); }
    .welcome-banner .leeds-carousel .amg-carousel-container[data-slide-visible="1.5"][data-slide-visible-hovered="4.5"] + .amg-carousel-nav-container {
      right: calc((((100% + 16px) / 1.5 * 0.5) - 16px) * -1); }
    .welcome-banner .leeds-carousel .amg-carousel-container[data-slide-visible="1.5"][data-slide-visible-hovered="4.75"] + .amg-carousel-nav-container {
      right: calc((((100% + 16px) / 1.5 * 0.75) - 16px) * -1); }
    .welcome-banner .leeds-carousel .amg-carousel-container[data-slide-visible="1.5"][data-slide-visible-hovered="5.25"] + .amg-carousel-nav-container {
      right: calc((((100% + 16px) / 1.5 * 0.25) - 16px) * -1); }
    .welcome-banner .leeds-carousel .amg-carousel-container[data-slide-visible="1.5"][data-slide-visible-hovered="5.5"] + .amg-carousel-nav-container {
      right: calc((((100% + 16px) / 1.5 * 0.5) - 16px) * -1); }
    .welcome-banner .leeds-carousel .amg-carousel-container[data-slide-visible="1.5"][data-slide-visible-hovered="5.75"] + .amg-carousel-nav-container {
      right: calc((((100% + 16px) / 1.5 * 0.75) - 16px) * -1); }
    .welcome-banner .leeds-carousel .amg-carousel-container[data-slide-visible="1.75"][data-slide-visible-hovered="1.25"] + .amg-carousel-nav-container {
      right: calc((((100% + 16px) / 1.75 * 0.25) - 16px) * -1); }
    .welcome-banner .leeds-carousel .amg-carousel-container[data-slide-visible="1.75"][data-slide-visible-hovered="1.5"] + .amg-carousel-nav-container {
      right: calc((((100% + 16px) / 1.75 * 0.5) - 16px) * -1); }
    .welcome-banner .leeds-carousel .amg-carousel-container[data-slide-visible="1.75"][data-slide-visible-hovered="1.75"] + .amg-carousel-nav-container {
      right: calc((((100% + 16px) / 1.75 * 0.75) - 16px) * -1); }
    .welcome-banner .leeds-carousel .amg-carousel-container[data-slide-visible="1.75"][data-slide-visible-hovered="2.25"] + .amg-carousel-nav-container {
      right: calc((((100% + 16px) / 1.75 * 0.25) - 16px) * -1); }
    .welcome-banner .leeds-carousel .amg-carousel-container[data-slide-visible="1.75"][data-slide-visible-hovered="2.5"] + .amg-carousel-nav-container {
      right: calc((((100% + 16px) / 1.75 * 0.5) - 16px) * -1); }
    .welcome-banner .leeds-carousel .amg-carousel-container[data-slide-visible="1.75"][data-slide-visible-hovered="2.75"] + .amg-carousel-nav-container {
      right: calc((((100% + 16px) / 1.75 * 0.75) - 16px) * -1); }
    .welcome-banner .leeds-carousel .amg-carousel-container[data-slide-visible="1.75"][data-slide-visible-hovered="3.25"] + .amg-carousel-nav-container {
      right: calc((((100% + 16px) / 1.75 * 0.25) - 16px) * -1); }
    .welcome-banner .leeds-carousel .amg-carousel-container[data-slide-visible="1.75"][data-slide-visible-hovered="3.5"] + .amg-carousel-nav-container {
      right: calc((((100% + 16px) / 1.75 * 0.5) - 16px) * -1); }
    .welcome-banner .leeds-carousel .amg-carousel-container[data-slide-visible="1.75"][data-slide-visible-hovered="3.75"] + .amg-carousel-nav-container {
      right: calc((((100% + 16px) / 1.75 * 0.75) - 16px) * -1); }
    .welcome-banner .leeds-carousel .amg-carousel-container[data-slide-visible="1.75"][data-slide-visible-hovered="4.25"] + .amg-carousel-nav-container {
      right: calc((((100% + 16px) / 1.75 * 0.25) - 16px) * -1); }
    .welcome-banner .leeds-carousel .amg-carousel-container[data-slide-visible="1.75"][data-slide-visible-hovered="4.5"] + .amg-carousel-nav-container {
      right: calc((((100% + 16px) / 1.75 * 0.5) - 16px) * -1); }
    .welcome-banner .leeds-carousel .amg-carousel-container[data-slide-visible="1.75"][data-slide-visible-hovered="4.75"] + .amg-carousel-nav-container {
      right: calc((((100% + 16px) / 1.75 * 0.75) - 16px) * -1); }
    .welcome-banner .leeds-carousel .amg-carousel-container[data-slide-visible="1.75"][data-slide-visible-hovered="5.25"] + .amg-carousel-nav-container {
      right: calc((((100% + 16px) / 1.75 * 0.25) - 16px) * -1); }
    .welcome-banner .leeds-carousel .amg-carousel-container[data-slide-visible="1.75"][data-slide-visible-hovered="5.5"] + .amg-carousel-nav-container {
      right: calc((((100% + 16px) / 1.75 * 0.5) - 16px) * -1); }
    .welcome-banner .leeds-carousel .amg-carousel-container[data-slide-visible="1.75"][data-slide-visible-hovered="5.75"] + .amg-carousel-nav-container {
      right: calc((((100% + 16px) / 1.75 * 0.75) - 16px) * -1); }
    .welcome-banner .leeds-carousel .amg-carousel-container[data-slide-visible="2.25"][data-slide-visible-hovered="1.25"] + .amg-carousel-nav-container {
      right: calc((((100% + 16px) / 2.25 * 0.25) - 16px) * -1); }
    .welcome-banner .leeds-carousel .amg-carousel-container[data-slide-visible="2.25"][data-slide-visible-hovered="1.5"] + .amg-carousel-nav-container {
      right: calc((((100% + 16px) / 2.25 * 0.5) - 16px) * -1); }
    .welcome-banner .leeds-carousel .amg-carousel-container[data-slide-visible="2.25"][data-slide-visible-hovered="1.75"] + .amg-carousel-nav-container {
      right: calc((((100% + 16px) / 2.25 * 0.75) - 16px) * -1); }
    .welcome-banner .leeds-carousel .amg-carousel-container[data-slide-visible="2.25"][data-slide-visible-hovered="2.25"] + .amg-carousel-nav-container {
      right: calc((((100% + 16px) / 2.25 * 0.25) - 16px) * -1); }
    .welcome-banner .leeds-carousel .amg-carousel-container[data-slide-visible="2.25"][data-slide-visible-hovered="2.5"] + .amg-carousel-nav-container {
      right: calc((((100% + 16px) / 2.25 * 0.5) - 16px) * -1); }
    .welcome-banner .leeds-carousel .amg-carousel-container[data-slide-visible="2.25"][data-slide-visible-hovered="2.75"] + .amg-carousel-nav-container {
      right: calc((((100% + 16px) / 2.25 * 0.75) - 16px) * -1); }
    .welcome-banner .leeds-carousel .amg-carousel-container[data-slide-visible="2.25"][data-slide-visible-hovered="3.25"] + .amg-carousel-nav-container {
      right: calc((((100% + 16px) / 2.25 * 0.25) - 16px) * -1); }
    .welcome-banner .leeds-carousel .amg-carousel-container[data-slide-visible="2.25"][data-slide-visible-hovered="3.5"] + .amg-carousel-nav-container {
      right: calc((((100% + 16px) / 2.25 * 0.5) - 16px) * -1); }
    .welcome-banner .leeds-carousel .amg-carousel-container[data-slide-visible="2.25"][data-slide-visible-hovered="3.75"] + .amg-carousel-nav-container {
      right: calc((((100% + 16px) / 2.25 * 0.75) - 16px) * -1); }
    .welcome-banner .leeds-carousel .amg-carousel-container[data-slide-visible="2.25"][data-slide-visible-hovered="4.25"] + .amg-carousel-nav-container {
      right: calc((((100% + 16px) / 2.25 * 0.25) - 16px) * -1); }
    .welcome-banner .leeds-carousel .amg-carousel-container[data-slide-visible="2.25"][data-slide-visible-hovered="4.5"] + .amg-carousel-nav-container {
      right: calc((((100% + 16px) / 2.25 * 0.5) - 16px) * -1); }
    .welcome-banner .leeds-carousel .amg-carousel-container[data-slide-visible="2.25"][data-slide-visible-hovered="4.75"] + .amg-carousel-nav-container {
      right: calc((((100% + 16px) / 2.25 * 0.75) - 16px) * -1); }
    .welcome-banner .leeds-carousel .amg-carousel-container[data-slide-visible="2.25"][data-slide-visible-hovered="5.25"] + .amg-carousel-nav-container {
      right: calc((((100% + 16px) / 2.25 * 0.25) - 16px) * -1); }
    .welcome-banner .leeds-carousel .amg-carousel-container[data-slide-visible="2.25"][data-slide-visible-hovered="5.5"] + .amg-carousel-nav-container {
      right: calc((((100% + 16px) / 2.25 * 0.5) - 16px) * -1); }
    .welcome-banner .leeds-carousel .amg-carousel-container[data-slide-visible="2.25"][data-slide-visible-hovered="5.75"] + .amg-carousel-nav-container {
      right: calc((((100% + 16px) / 2.25 * 0.75) - 16px) * -1); }
    .welcome-banner .leeds-carousel .amg-carousel-container[data-slide-visible="2.5"][data-slide-visible-hovered="1.25"] + .amg-carousel-nav-container {
      right: calc((((100% + 16px) / 2.5 * 0.25) - 16px) * -1); }
    .welcome-banner .leeds-carousel .amg-carousel-container[data-slide-visible="2.5"][data-slide-visible-hovered="1.5"] + .amg-carousel-nav-container {
      right: calc((((100% + 16px) / 2.5 * 0.5) - 16px) * -1); }
    .welcome-banner .leeds-carousel .amg-carousel-container[data-slide-visible="2.5"][data-slide-visible-hovered="1.75"] + .amg-carousel-nav-container {
      right: calc((((100% + 16px) / 2.5 * 0.75) - 16px) * -1); }
    .welcome-banner .leeds-carousel .amg-carousel-container[data-slide-visible="2.5"][data-slide-visible-hovered="2.25"] + .amg-carousel-nav-container {
      right: calc((((100% + 16px) / 2.5 * 0.25) - 16px) * -1); }
    .welcome-banner .leeds-carousel .amg-carousel-container[data-slide-visible="2.5"][data-slide-visible-hovered="2.5"] + .amg-carousel-nav-container {
      right: calc((((100% + 16px) / 2.5 * 0.5) - 16px) * -1); }
    .welcome-banner .leeds-carousel .amg-carousel-container[data-slide-visible="2.5"][data-slide-visible-hovered="2.75"] + .amg-carousel-nav-container {
      right: calc((((100% + 16px) / 2.5 * 0.75) - 16px) * -1); }
    .welcome-banner .leeds-carousel .amg-carousel-container[data-slide-visible="2.5"][data-slide-visible-hovered="3.25"] + .amg-carousel-nav-container {
      right: calc((((100% + 16px) / 2.5 * 0.25) - 16px) * -1); }
    .welcome-banner .leeds-carousel .amg-carousel-container[data-slide-visible="2.5"][data-slide-visible-hovered="3.5"] + .amg-carousel-nav-container {
      right: calc((((100% + 16px) / 2.5 * 0.5) - 16px) * -1); }
    .welcome-banner .leeds-carousel .amg-carousel-container[data-slide-visible="2.5"][data-slide-visible-hovered="3.75"] + .amg-carousel-nav-container {
      right: calc((((100% + 16px) / 2.5 * 0.75) - 16px) * -1); }
    .welcome-banner .leeds-carousel .amg-carousel-container[data-slide-visible="2.5"][data-slide-visible-hovered="4.25"] + .amg-carousel-nav-container {
      right: calc((((100% + 16px) / 2.5 * 0.25) - 16px) * -1); }
    .welcome-banner .leeds-carousel .amg-carousel-container[data-slide-visible="2.5"][data-slide-visible-hovered="4.5"] + .amg-carousel-nav-container {
      right: calc((((100% + 16px) / 2.5 * 0.5) - 16px) * -1); }
    .welcome-banner .leeds-carousel .amg-carousel-container[data-slide-visible="2.5"][data-slide-visible-hovered="4.75"] + .amg-carousel-nav-container {
      right: calc((((100% + 16px) / 2.5 * 0.75) - 16px) * -1); }
    .welcome-banner .leeds-carousel .amg-carousel-container[data-slide-visible="2.5"][data-slide-visible-hovered="5.25"] + .amg-carousel-nav-container {
      right: calc((((100% + 16px) / 2.5 * 0.25) - 16px) * -1); }
    .welcome-banner .leeds-carousel .amg-carousel-container[data-slide-visible="2.5"][data-slide-visible-hovered="5.5"] + .amg-carousel-nav-container {
      right: calc((((100% + 16px) / 2.5 * 0.5) - 16px) * -1); }
    .welcome-banner .leeds-carousel .amg-carousel-container[data-slide-visible="2.5"][data-slide-visible-hovered="5.75"] + .amg-carousel-nav-container {
      right: calc((((100% + 16px) / 2.5 * 0.75) - 16px) * -1); }
    .welcome-banner .leeds-carousel .amg-carousel-container[data-slide-visible="2.75"][data-slide-visible-hovered="1.25"] + .amg-carousel-nav-container {
      right: calc((((100% + 16px) / 2.75 * 0.25) - 16px) * -1); }
    .welcome-banner .leeds-carousel .amg-carousel-container[data-slide-visible="2.75"][data-slide-visible-hovered="1.5"] + .amg-carousel-nav-container {
      right: calc((((100% + 16px) / 2.75 * 0.5) - 16px) * -1); }
    .welcome-banner .leeds-carousel .amg-carousel-container[data-slide-visible="2.75"][data-slide-visible-hovered="1.75"] + .amg-carousel-nav-container {
      right: calc((((100% + 16px) / 2.75 * 0.75) - 16px) * -1); }
    .welcome-banner .leeds-carousel .amg-carousel-container[data-slide-visible="2.75"][data-slide-visible-hovered="2.25"] + .amg-carousel-nav-container {
      right: calc((((100% + 16px) / 2.75 * 0.25) - 16px) * -1); }
    .welcome-banner .leeds-carousel .amg-carousel-container[data-slide-visible="2.75"][data-slide-visible-hovered="2.5"] + .amg-carousel-nav-container {
      right: calc((((100% + 16px) / 2.75 * 0.5) - 16px) * -1); }
    .welcome-banner .leeds-carousel .amg-carousel-container[data-slide-visible="2.75"][data-slide-visible-hovered="2.75"] + .amg-carousel-nav-container {
      right: calc((((100% + 16px) / 2.75 * 0.75) - 16px) * -1); }
    .welcome-banner .leeds-carousel .amg-carousel-container[data-slide-visible="2.75"][data-slide-visible-hovered="3.25"] + .amg-carousel-nav-container {
      right: calc((((100% + 16px) / 2.75 * 0.25) - 16px) * -1); }
    .welcome-banner .leeds-carousel .amg-carousel-container[data-slide-visible="2.75"][data-slide-visible-hovered="3.5"] + .amg-carousel-nav-container {
      right: calc((((100% + 16px) / 2.75 * 0.5) - 16px) * -1); }
    .welcome-banner .leeds-carousel .amg-carousel-container[data-slide-visible="2.75"][data-slide-visible-hovered="3.75"] + .amg-carousel-nav-container {
      right: calc((((100% + 16px) / 2.75 * 0.75) - 16px) * -1); }
    .welcome-banner .leeds-carousel .amg-carousel-container[data-slide-visible="2.75"][data-slide-visible-hovered="4.25"] + .amg-carousel-nav-container {
      right: calc((((100% + 16px) / 2.75 * 0.25) - 16px) * -1); }
    .welcome-banner .leeds-carousel .amg-carousel-container[data-slide-visible="2.75"][data-slide-visible-hovered="4.5"] + .amg-carousel-nav-container {
      right: calc((((100% + 16px) / 2.75 * 0.5) - 16px) * -1); }
    .welcome-banner .leeds-carousel .amg-carousel-container[data-slide-visible="2.75"][data-slide-visible-hovered="4.75"] + .amg-carousel-nav-container {
      right: calc((((100% + 16px) / 2.75 * 0.75) - 16px) * -1); }
    .welcome-banner .leeds-carousel .amg-carousel-container[data-slide-visible="2.75"][data-slide-visible-hovered="5.25"] + .amg-carousel-nav-container {
      right: calc((((100% + 16px) / 2.75 * 0.25) - 16px) * -1); }
    .welcome-banner .leeds-carousel .amg-carousel-container[data-slide-visible="2.75"][data-slide-visible-hovered="5.5"] + .amg-carousel-nav-container {
      right: calc((((100% + 16px) / 2.75 * 0.5) - 16px) * -1); }
    .welcome-banner .leeds-carousel .amg-carousel-container[data-slide-visible="2.75"][data-slide-visible-hovered="5.75"] + .amg-carousel-nav-container {
      right: calc((((100% + 16px) / 2.75 * 0.75) - 16px) * -1); }
    .welcome-banner .leeds-carousel .amg-carousel-container[data-slide-visible="3.25"][data-slide-visible-hovered="1.25"] + .amg-carousel-nav-container {
      right: calc((((100% + 16px) / 3.25 * 0.25) - 16px) * -1); }
    .welcome-banner .leeds-carousel .amg-carousel-container[data-slide-visible="3.25"][data-slide-visible-hovered="1.5"] + .amg-carousel-nav-container {
      right: calc((((100% + 16px) / 3.25 * 0.5) - 16px) * -1); }
    .welcome-banner .leeds-carousel .amg-carousel-container[data-slide-visible="3.25"][data-slide-visible-hovered="1.75"] + .amg-carousel-nav-container {
      right: calc((((100% + 16px) / 3.25 * 0.75) - 16px) * -1); }
    .welcome-banner .leeds-carousel .amg-carousel-container[data-slide-visible="3.25"][data-slide-visible-hovered="2.25"] + .amg-carousel-nav-container {
      right: calc((((100% + 16px) / 3.25 * 0.25) - 16px) * -1); }
    .welcome-banner .leeds-carousel .amg-carousel-container[data-slide-visible="3.25"][data-slide-visible-hovered="2.5"] + .amg-carousel-nav-container {
      right: calc((((100% + 16px) / 3.25 * 0.5) - 16px) * -1); }
    .welcome-banner .leeds-carousel .amg-carousel-container[data-slide-visible="3.25"][data-slide-visible-hovered="2.75"] + .amg-carousel-nav-container {
      right: calc((((100% + 16px) / 3.25 * 0.75) - 16px) * -1); }
    .welcome-banner .leeds-carousel .amg-carousel-container[data-slide-visible="3.25"][data-slide-visible-hovered="3.25"] + .amg-carousel-nav-container {
      right: calc((((100% + 16px) / 3.25 * 0.25) - 16px) * -1); }
    .welcome-banner .leeds-carousel .amg-carousel-container[data-slide-visible="3.25"][data-slide-visible-hovered="3.5"] + .amg-carousel-nav-container {
      right: calc((((100% + 16px) / 3.25 * 0.5) - 16px) * -1); }
    .welcome-banner .leeds-carousel .amg-carousel-container[data-slide-visible="3.25"][data-slide-visible-hovered="3.75"] + .amg-carousel-nav-container {
      right: calc((((100% + 16px) / 3.25 * 0.75) - 16px) * -1); }
    .welcome-banner .leeds-carousel .amg-carousel-container[data-slide-visible="3.25"][data-slide-visible-hovered="4.25"] + .amg-carousel-nav-container {
      right: calc((((100% + 16px) / 3.25 * 0.25) - 16px) * -1); }
    .welcome-banner .leeds-carousel .amg-carousel-container[data-slide-visible="3.25"][data-slide-visible-hovered="4.5"] + .amg-carousel-nav-container {
      right: calc((((100% + 16px) / 3.25 * 0.5) - 16px) * -1); }
    .welcome-banner .leeds-carousel .amg-carousel-container[data-slide-visible="3.25"][data-slide-visible-hovered="4.75"] + .amg-carousel-nav-container {
      right: calc((((100% + 16px) / 3.25 * 0.75) - 16px) * -1); }
    .welcome-banner .leeds-carousel .amg-carousel-container[data-slide-visible="3.25"][data-slide-visible-hovered="5.25"] + .amg-carousel-nav-container {
      right: calc((((100% + 16px) / 3.25 * 0.25) - 16px) * -1); }
    .welcome-banner .leeds-carousel .amg-carousel-container[data-slide-visible="3.25"][data-slide-visible-hovered="5.5"] + .amg-carousel-nav-container {
      right: calc((((100% + 16px) / 3.25 * 0.5) - 16px) * -1); }
    .welcome-banner .leeds-carousel .amg-carousel-container[data-slide-visible="3.25"][data-slide-visible-hovered="5.75"] + .amg-carousel-nav-container {
      right: calc((((100% + 16px) / 3.25 * 0.75) - 16px) * -1); }
    .welcome-banner .leeds-carousel .amg-carousel-container[data-slide-visible="3.5"][data-slide-visible-hovered="1.25"] + .amg-carousel-nav-container {
      right: calc((((100% + 16px) / 3.5 * 0.25) - 16px) * -1); }
    .welcome-banner .leeds-carousel .amg-carousel-container[data-slide-visible="3.5"][data-slide-visible-hovered="1.5"] + .amg-carousel-nav-container {
      right: calc((((100% + 16px) / 3.5 * 0.5) - 16px) * -1); }
    .welcome-banner .leeds-carousel .amg-carousel-container[data-slide-visible="3.5"][data-slide-visible-hovered="1.75"] + .amg-carousel-nav-container {
      right: calc((((100% + 16px) / 3.5 * 0.75) - 16px) * -1); }
    .welcome-banner .leeds-carousel .amg-carousel-container[data-slide-visible="3.5"][data-slide-visible-hovered="2.25"] + .amg-carousel-nav-container {
      right: calc((((100% + 16px) / 3.5 * 0.25) - 16px) * -1); }
    .welcome-banner .leeds-carousel .amg-carousel-container[data-slide-visible="3.5"][data-slide-visible-hovered="2.5"] + .amg-carousel-nav-container {
      right: calc((((100% + 16px) / 3.5 * 0.5) - 16px) * -1); }
    .welcome-banner .leeds-carousel .amg-carousel-container[data-slide-visible="3.5"][data-slide-visible-hovered="2.75"] + .amg-carousel-nav-container {
      right: calc((((100% + 16px) / 3.5 * 0.75) - 16px) * -1); }
    .welcome-banner .leeds-carousel .amg-carousel-container[data-slide-visible="3.5"][data-slide-visible-hovered="3.25"] + .amg-carousel-nav-container {
      right: calc((((100% + 16px) / 3.5 * 0.25) - 16px) * -1); }
    .welcome-banner .leeds-carousel .amg-carousel-container[data-slide-visible="3.5"][data-slide-visible-hovered="3.5"] + .amg-carousel-nav-container {
      right: calc((((100% + 16px) / 3.5 * 0.5) - 16px) * -1); }
    .welcome-banner .leeds-carousel .amg-carousel-container[data-slide-visible="3.5"][data-slide-visible-hovered="3.75"] + .amg-carousel-nav-container {
      right: calc((((100% + 16px) / 3.5 * 0.75) - 16px) * -1); }
    .welcome-banner .leeds-carousel .amg-carousel-container[data-slide-visible="3.5"][data-slide-visible-hovered="4.25"] + .amg-carousel-nav-container {
      right: calc((((100% + 16px) / 3.5 * 0.25) - 16px) * -1); }
    .welcome-banner .leeds-carousel .amg-carousel-container[data-slide-visible="3.5"][data-slide-visible-hovered="4.5"] + .amg-carousel-nav-container {
      right: calc((((100% + 16px) / 3.5 * 0.5) - 16px) * -1); }
    .welcome-banner .leeds-carousel .amg-carousel-container[data-slide-visible="3.5"][data-slide-visible-hovered="4.75"] + .amg-carousel-nav-container {
      right: calc((((100% + 16px) / 3.5 * 0.75) - 16px) * -1); }
    .welcome-banner .leeds-carousel .amg-carousel-container[data-slide-visible="3.5"][data-slide-visible-hovered="5.25"] + .amg-carousel-nav-container {
      right: calc((((100% + 16px) / 3.5 * 0.25) - 16px) * -1); }
    .welcome-banner .leeds-carousel .amg-carousel-container[data-slide-visible="3.5"][data-slide-visible-hovered="5.5"] + .amg-carousel-nav-container {
      right: calc((((100% + 16px) / 3.5 * 0.5) - 16px) * -1); }
    .welcome-banner .leeds-carousel .amg-carousel-container[data-slide-visible="3.5"][data-slide-visible-hovered="5.75"] + .amg-carousel-nav-container {
      right: calc((((100% + 16px) / 3.5 * 0.75) - 16px) * -1); }
    .welcome-banner .leeds-carousel .amg-carousel-container[data-slide-visible="3.75"][data-slide-visible-hovered="1.25"] + .amg-carousel-nav-container {
      right: calc((((100% + 16px) / 3.75 * 0.25) - 16px) * -1); }
    .welcome-banner .leeds-carousel .amg-carousel-container[data-slide-visible="3.75"][data-slide-visible-hovered="1.5"] + .amg-carousel-nav-container {
      right: calc((((100% + 16px) / 3.75 * 0.5) - 16px) * -1); }
    .welcome-banner .leeds-carousel .amg-carousel-container[data-slide-visible="3.75"][data-slide-visible-hovered="1.75"] + .amg-carousel-nav-container {
      right: calc((((100% + 16px) / 3.75 * 0.75) - 16px) * -1); }
    .welcome-banner .leeds-carousel .amg-carousel-container[data-slide-visible="3.75"][data-slide-visible-hovered="2.25"] + .amg-carousel-nav-container {
      right: calc((((100% + 16px) / 3.75 * 0.25) - 16px) * -1); }
    .welcome-banner .leeds-carousel .amg-carousel-container[data-slide-visible="3.75"][data-slide-visible-hovered="2.5"] + .amg-carousel-nav-container {
      right: calc((((100% + 16px) / 3.75 * 0.5) - 16px) * -1); }
    .welcome-banner .leeds-carousel .amg-carousel-container[data-slide-visible="3.75"][data-slide-visible-hovered="2.75"] + .amg-carousel-nav-container {
      right: calc((((100% + 16px) / 3.75 * 0.75) - 16px) * -1); }
    .welcome-banner .leeds-carousel .amg-carousel-container[data-slide-visible="3.75"][data-slide-visible-hovered="3.25"] + .amg-carousel-nav-container {
      right: calc((((100% + 16px) / 3.75 * 0.25) - 16px) * -1); }
    .welcome-banner .leeds-carousel .amg-carousel-container[data-slide-visible="3.75"][data-slide-visible-hovered="3.5"] + .amg-carousel-nav-container {
      right: calc((((100% + 16px) / 3.75 * 0.5) - 16px) * -1); }
    .welcome-banner .leeds-carousel .amg-carousel-container[data-slide-visible="3.75"][data-slide-visible-hovered="3.75"] + .amg-carousel-nav-container {
      right: calc((((100% + 16px) / 3.75 * 0.75) - 16px) * -1); }
    .welcome-banner .leeds-carousel .amg-carousel-container[data-slide-visible="3.75"][data-slide-visible-hovered="4.25"] + .amg-carousel-nav-container {
      right: calc((((100% + 16px) / 3.75 * 0.25) - 16px) * -1); }
    .welcome-banner .leeds-carousel .amg-carousel-container[data-slide-visible="3.75"][data-slide-visible-hovered="4.5"] + .amg-carousel-nav-container {
      right: calc((((100% + 16px) / 3.75 * 0.5) - 16px) * -1); }
    .welcome-banner .leeds-carousel .amg-carousel-container[data-slide-visible="3.75"][data-slide-visible-hovered="4.75"] + .amg-carousel-nav-container {
      right: calc((((100% + 16px) / 3.75 * 0.75) - 16px) * -1); }
    .welcome-banner .leeds-carousel .amg-carousel-container[data-slide-visible="3.75"][data-slide-visible-hovered="5.25"] + .amg-carousel-nav-container {
      right: calc((((100% + 16px) / 3.75 * 0.25) - 16px) * -1); }
    .welcome-banner .leeds-carousel .amg-carousel-container[data-slide-visible="3.75"][data-slide-visible-hovered="5.5"] + .amg-carousel-nav-container {
      right: calc((((100% + 16px) / 3.75 * 0.5) - 16px) * -1); }
    .welcome-banner .leeds-carousel .amg-carousel-container[data-slide-visible="3.75"][data-slide-visible-hovered="5.75"] + .amg-carousel-nav-container {
      right: calc((((100% + 16px) / 3.75 * 0.75) - 16px) * -1); }
    .welcome-banner .leeds-carousel .amg-carousel-container[data-slide-visible="4.25"][data-slide-visible-hovered="1.25"] + .amg-carousel-nav-container {
      right: calc((((100% + 16px) / 4.25 * 0.25) - 16px) * -1); }
    .welcome-banner .leeds-carousel .amg-carousel-container[data-slide-visible="4.25"][data-slide-visible-hovered="1.5"] + .amg-carousel-nav-container {
      right: calc((((100% + 16px) / 4.25 * 0.5) - 16px) * -1); }
    .welcome-banner .leeds-carousel .amg-carousel-container[data-slide-visible="4.25"][data-slide-visible-hovered="1.75"] + .amg-carousel-nav-container {
      right: calc((((100% + 16px) / 4.25 * 0.75) - 16px) * -1); }
    .welcome-banner .leeds-carousel .amg-carousel-container[data-slide-visible="4.25"][data-slide-visible-hovered="2.25"] + .amg-carousel-nav-container {
      right: calc((((100% + 16px) / 4.25 * 0.25) - 16px) * -1); }
    .welcome-banner .leeds-carousel .amg-carousel-container[data-slide-visible="4.25"][data-slide-visible-hovered="2.5"] + .amg-carousel-nav-container {
      right: calc((((100% + 16px) / 4.25 * 0.5) - 16px) * -1); }
    .welcome-banner .leeds-carousel .amg-carousel-container[data-slide-visible="4.25"][data-slide-visible-hovered="2.75"] + .amg-carousel-nav-container {
      right: calc((((100% + 16px) / 4.25 * 0.75) - 16px) * -1); }
    .welcome-banner .leeds-carousel .amg-carousel-container[data-slide-visible="4.25"][data-slide-visible-hovered="3.25"] + .amg-carousel-nav-container {
      right: calc((((100% + 16px) / 4.25 * 0.25) - 16px) * -1); }
    .welcome-banner .leeds-carousel .amg-carousel-container[data-slide-visible="4.25"][data-slide-visible-hovered="3.5"] + .amg-carousel-nav-container {
      right: calc((((100% + 16px) / 4.25 * 0.5) - 16px) * -1); }
    .welcome-banner .leeds-carousel .amg-carousel-container[data-slide-visible="4.25"][data-slide-visible-hovered="3.75"] + .amg-carousel-nav-container {
      right: calc((((100% + 16px) / 4.25 * 0.75) - 16px) * -1); }
    .welcome-banner .leeds-carousel .amg-carousel-container[data-slide-visible="4.25"][data-slide-visible-hovered="4.25"] + .amg-carousel-nav-container {
      right: calc((((100% + 16px) / 4.25 * 0.25) - 16px) * -1); }
    .welcome-banner .leeds-carousel .amg-carousel-container[data-slide-visible="4.25"][data-slide-visible-hovered="4.5"] + .amg-carousel-nav-container {
      right: calc((((100% + 16px) / 4.25 * 0.5) - 16px) * -1); }
    .welcome-banner .leeds-carousel .amg-carousel-container[data-slide-visible="4.25"][data-slide-visible-hovered="4.75"] + .amg-carousel-nav-container {
      right: calc((((100% + 16px) / 4.25 * 0.75) - 16px) * -1); }
    .welcome-banner .leeds-carousel .amg-carousel-container[data-slide-visible="4.25"][data-slide-visible-hovered="5.25"] + .amg-carousel-nav-container {
      right: calc((((100% + 16px) / 4.25 * 0.25) - 16px) * -1); }
    .welcome-banner .leeds-carousel .amg-carousel-container[data-slide-visible="4.25"][data-slide-visible-hovered="5.5"] + .amg-carousel-nav-container {
      right: calc((((100% + 16px) / 4.25 * 0.5) - 16px) * -1); }
    .welcome-banner .leeds-carousel .amg-carousel-container[data-slide-visible="4.25"][data-slide-visible-hovered="5.75"] + .amg-carousel-nav-container {
      right: calc((((100% + 16px) / 4.25 * 0.75) - 16px) * -1); }
    .welcome-banner .leeds-carousel .amg-carousel-container[data-slide-visible="4.5"][data-slide-visible-hovered="1.25"] + .amg-carousel-nav-container {
      right: calc((((100% + 16px) / 4.5 * 0.25) - 16px) * -1); }
    .welcome-banner .leeds-carousel .amg-carousel-container[data-slide-visible="4.5"][data-slide-visible-hovered="1.5"] + .amg-carousel-nav-container {
      right: calc((((100% + 16px) / 4.5 * 0.5) - 16px) * -1); }
    .welcome-banner .leeds-carousel .amg-carousel-container[data-slide-visible="4.5"][data-slide-visible-hovered="1.75"] + .amg-carousel-nav-container {
      right: calc((((100% + 16px) / 4.5 * 0.75) - 16px) * -1); }
    .welcome-banner .leeds-carousel .amg-carousel-container[data-slide-visible="4.5"][data-slide-visible-hovered="2.25"] + .amg-carousel-nav-container {
      right: calc((((100% + 16px) / 4.5 * 0.25) - 16px) * -1); }
    .welcome-banner .leeds-carousel .amg-carousel-container[data-slide-visible="4.5"][data-slide-visible-hovered="2.5"] + .amg-carousel-nav-container {
      right: calc((((100% + 16px) / 4.5 * 0.5) - 16px) * -1); }
    .welcome-banner .leeds-carousel .amg-carousel-container[data-slide-visible="4.5"][data-slide-visible-hovered="2.75"] + .amg-carousel-nav-container {
      right: calc((((100% + 16px) / 4.5 * 0.75) - 16px) * -1); }
    .welcome-banner .leeds-carousel .amg-carousel-container[data-slide-visible="4.5"][data-slide-visible-hovered="3.25"] + .amg-carousel-nav-container {
      right: calc((((100% + 16px) / 4.5 * 0.25) - 16px) * -1); }
    .welcome-banner .leeds-carousel .amg-carousel-container[data-slide-visible="4.5"][data-slide-visible-hovered="3.5"] + .amg-carousel-nav-container {
      right: calc((((100% + 16px) / 4.5 * 0.5) - 16px) * -1); }
    .welcome-banner .leeds-carousel .amg-carousel-container[data-slide-visible="4.5"][data-slide-visible-hovered="3.75"] + .amg-carousel-nav-container {
      right: calc((((100% + 16px) / 4.5 * 0.75) - 16px) * -1); }
    .welcome-banner .leeds-carousel .amg-carousel-container[data-slide-visible="4.5"][data-slide-visible-hovered="4.25"] + .amg-carousel-nav-container {
      right: calc((((100% + 16px) / 4.5 * 0.25) - 16px) * -1); }
    .welcome-banner .leeds-carousel .amg-carousel-container[data-slide-visible="4.5"][data-slide-visible-hovered="4.5"] + .amg-carousel-nav-container {
      right: calc((((100% + 16px) / 4.5 * 0.5) - 16px) * -1); }
    .welcome-banner .leeds-carousel .amg-carousel-container[data-slide-visible="4.5"][data-slide-visible-hovered="4.75"] + .amg-carousel-nav-container {
      right: calc((((100% + 16px) / 4.5 * 0.75) - 16px) * -1); }
    .welcome-banner .leeds-carousel .amg-carousel-container[data-slide-visible="4.5"][data-slide-visible-hovered="5.25"] + .amg-carousel-nav-container {
      right: calc((((100% + 16px) / 4.5 * 0.25) - 16px) * -1); }
    .welcome-banner .leeds-carousel .amg-carousel-container[data-slide-visible="4.5"][data-slide-visible-hovered="5.5"] + .amg-carousel-nav-container {
      right: calc((((100% + 16px) / 4.5 * 0.5) - 16px) * -1); }
    .welcome-banner .leeds-carousel .amg-carousel-container[data-slide-visible="4.5"][data-slide-visible-hovered="5.75"] + .amg-carousel-nav-container {
      right: calc((((100% + 16px) / 4.5 * 0.75) - 16px) * -1); }
    .welcome-banner .leeds-carousel .amg-carousel-container[data-slide-visible="4.75"][data-slide-visible-hovered="1.25"] + .amg-carousel-nav-container {
      right: calc((((100% + 16px) / 4.75 * 0.25) - 16px) * -1); }
    .welcome-banner .leeds-carousel .amg-carousel-container[data-slide-visible="4.75"][data-slide-visible-hovered="1.5"] + .amg-carousel-nav-container {
      right: calc((((100% + 16px) / 4.75 * 0.5) - 16px) * -1); }
    .welcome-banner .leeds-carousel .amg-carousel-container[data-slide-visible="4.75"][data-slide-visible-hovered="1.75"] + .amg-carousel-nav-container {
      right: calc((((100% + 16px) / 4.75 * 0.75) - 16px) * -1); }
    .welcome-banner .leeds-carousel .amg-carousel-container[data-slide-visible="4.75"][data-slide-visible-hovered="2.25"] + .amg-carousel-nav-container {
      right: calc((((100% + 16px) / 4.75 * 0.25) - 16px) * -1); }
    .welcome-banner .leeds-carousel .amg-carousel-container[data-slide-visible="4.75"][data-slide-visible-hovered="2.5"] + .amg-carousel-nav-container {
      right: calc((((100% + 16px) / 4.75 * 0.5) - 16px) * -1); }
    .welcome-banner .leeds-carousel .amg-carousel-container[data-slide-visible="4.75"][data-slide-visible-hovered="2.75"] + .amg-carousel-nav-container {
      right: calc((((100% + 16px) / 4.75 * 0.75) - 16px) * -1); }
    .welcome-banner .leeds-carousel .amg-carousel-container[data-slide-visible="4.75"][data-slide-visible-hovered="3.25"] + .amg-carousel-nav-container {
      right: calc((((100% + 16px) / 4.75 * 0.25) - 16px) * -1); }
    .welcome-banner .leeds-carousel .amg-carousel-container[data-slide-visible="4.75"][data-slide-visible-hovered="3.5"] + .amg-carousel-nav-container {
      right: calc((((100% + 16px) / 4.75 * 0.5) - 16px) * -1); }
    .welcome-banner .leeds-carousel .amg-carousel-container[data-slide-visible="4.75"][data-slide-visible-hovered="3.75"] + .amg-carousel-nav-container {
      right: calc((((100% + 16px) / 4.75 * 0.75) - 16px) * -1); }
    .welcome-banner .leeds-carousel .amg-carousel-container[data-slide-visible="4.75"][data-slide-visible-hovered="4.25"] + .amg-carousel-nav-container {
      right: calc((((100% + 16px) / 4.75 * 0.25) - 16px) * -1); }
    .welcome-banner .leeds-carousel .amg-carousel-container[data-slide-visible="4.75"][data-slide-visible-hovered="4.5"] + .amg-carousel-nav-container {
      right: calc((((100% + 16px) / 4.75 * 0.5) - 16px) * -1); }
    .welcome-banner .leeds-carousel .amg-carousel-container[data-slide-visible="4.75"][data-slide-visible-hovered="4.75"] + .amg-carousel-nav-container {
      right: calc((((100% + 16px) / 4.75 * 0.75) - 16px) * -1); }
    .welcome-banner .leeds-carousel .amg-carousel-container[data-slide-visible="4.75"][data-slide-visible-hovered="5.25"] + .amg-carousel-nav-container {
      right: calc((((100% + 16px) / 4.75 * 0.25) - 16px) * -1); }
    .welcome-banner .leeds-carousel .amg-carousel-container[data-slide-visible="4.75"][data-slide-visible-hovered="5.5"] + .amg-carousel-nav-container {
      right: calc((((100% + 16px) / 4.75 * 0.5) - 16px) * -1); }
    .welcome-banner .leeds-carousel .amg-carousel-container[data-slide-visible="4.75"][data-slide-visible-hovered="5.75"] + .amg-carousel-nav-container {
      right: calc((((100% + 16px) / 4.75 * 0.75) - 16px) * -1); }
    .welcome-banner .leeds-carousel .amg-carousel-container[data-slide-visible="5.25"][data-slide-visible-hovered="1.25"] + .amg-carousel-nav-container {
      right: calc((((100% + 16px) / 5.25 * 0.25) - 16px) * -1); }
    .welcome-banner .leeds-carousel .amg-carousel-container[data-slide-visible="5.25"][data-slide-visible-hovered="1.5"] + .amg-carousel-nav-container {
      right: calc((((100% + 16px) / 5.25 * 0.5) - 16px) * -1); }
    .welcome-banner .leeds-carousel .amg-carousel-container[data-slide-visible="5.25"][data-slide-visible-hovered="1.75"] + .amg-carousel-nav-container {
      right: calc((((100% + 16px) / 5.25 * 0.75) - 16px) * -1); }
    .welcome-banner .leeds-carousel .amg-carousel-container[data-slide-visible="5.25"][data-slide-visible-hovered="2.25"] + .amg-carousel-nav-container {
      right: calc((((100% + 16px) / 5.25 * 0.25) - 16px) * -1); }
    .welcome-banner .leeds-carousel .amg-carousel-container[data-slide-visible="5.25"][data-slide-visible-hovered="2.5"] + .amg-carousel-nav-container {
      right: calc((((100% + 16px) / 5.25 * 0.5) - 16px) * -1); }
    .welcome-banner .leeds-carousel .amg-carousel-container[data-slide-visible="5.25"][data-slide-visible-hovered="2.75"] + .amg-carousel-nav-container {
      right: calc((((100% + 16px) / 5.25 * 0.75) - 16px) * -1); }
    .welcome-banner .leeds-carousel .amg-carousel-container[data-slide-visible="5.25"][data-slide-visible-hovered="3.25"] + .amg-carousel-nav-container {
      right: calc((((100% + 16px) / 5.25 * 0.25) - 16px) * -1); }
    .welcome-banner .leeds-carousel .amg-carousel-container[data-slide-visible="5.25"][data-slide-visible-hovered="3.5"] + .amg-carousel-nav-container {
      right: calc((((100% + 16px) / 5.25 * 0.5) - 16px) * -1); }
    .welcome-banner .leeds-carousel .amg-carousel-container[data-slide-visible="5.25"][data-slide-visible-hovered="3.75"] + .amg-carousel-nav-container {
      right: calc((((100% + 16px) / 5.25 * 0.75) - 16px) * -1); }
    .welcome-banner .leeds-carousel .amg-carousel-container[data-slide-visible="5.25"][data-slide-visible-hovered="4.25"] + .amg-carousel-nav-container {
      right: calc((((100% + 16px) / 5.25 * 0.25) - 16px) * -1); }
    .welcome-banner .leeds-carousel .amg-carousel-container[data-slide-visible="5.25"][data-slide-visible-hovered="4.5"] + .amg-carousel-nav-container {
      right: calc((((100% + 16px) / 5.25 * 0.5) - 16px) * -1); }
    .welcome-banner .leeds-carousel .amg-carousel-container[data-slide-visible="5.25"][data-slide-visible-hovered="4.75"] + .amg-carousel-nav-container {
      right: calc((((100% + 16px) / 5.25 * 0.75) - 16px) * -1); }
    .welcome-banner .leeds-carousel .amg-carousel-container[data-slide-visible="5.25"][data-slide-visible-hovered="5.25"] + .amg-carousel-nav-container {
      right: calc((((100% + 16px) / 5.25 * 0.25) - 16px) * -1); }
    .welcome-banner .leeds-carousel .amg-carousel-container[data-slide-visible="5.25"][data-slide-visible-hovered="5.5"] + .amg-carousel-nav-container {
      right: calc((((100% + 16px) / 5.25 * 0.5) - 16px) * -1); }
    .welcome-banner .leeds-carousel .amg-carousel-container[data-slide-visible="5.25"][data-slide-visible-hovered="5.75"] + .amg-carousel-nav-container {
      right: calc((((100% + 16px) / 5.25 * 0.75) - 16px) * -1); }
    .welcome-banner .leeds-carousel .amg-carousel-container[data-slide-visible="5.5"][data-slide-visible-hovered="1.25"] + .amg-carousel-nav-container {
      right: calc((((100% + 16px) / 5.5 * 0.25) - 16px) * -1); }
    .welcome-banner .leeds-carousel .amg-carousel-container[data-slide-visible="5.5"][data-slide-visible-hovered="1.5"] + .amg-carousel-nav-container {
      right: calc((((100% + 16px) / 5.5 * 0.5) - 16px) * -1); }
    .welcome-banner .leeds-carousel .amg-carousel-container[data-slide-visible="5.5"][data-slide-visible-hovered="1.75"] + .amg-carousel-nav-container {
      right: calc((((100% + 16px) / 5.5 * 0.75) - 16px) * -1); }
    .welcome-banner .leeds-carousel .amg-carousel-container[data-slide-visible="5.5"][data-slide-visible-hovered="2.25"] + .amg-carousel-nav-container {
      right: calc((((100% + 16px) / 5.5 * 0.25) - 16px) * -1); }
    .welcome-banner .leeds-carousel .amg-carousel-container[data-slide-visible="5.5"][data-slide-visible-hovered="2.5"] + .amg-carousel-nav-container {
      right: calc((((100% + 16px) / 5.5 * 0.5) - 16px) * -1); }
    .welcome-banner .leeds-carousel .amg-carousel-container[data-slide-visible="5.5"][data-slide-visible-hovered="2.75"] + .amg-carousel-nav-container {
      right: calc((((100% + 16px) / 5.5 * 0.75) - 16px) * -1); }
    .welcome-banner .leeds-carousel .amg-carousel-container[data-slide-visible="5.5"][data-slide-visible-hovered="3.25"] + .amg-carousel-nav-container {
      right: calc((((100% + 16px) / 5.5 * 0.25) - 16px) * -1); }
    .welcome-banner .leeds-carousel .amg-carousel-container[data-slide-visible="5.5"][data-slide-visible-hovered="3.5"] + .amg-carousel-nav-container {
      right: calc((((100% + 16px) / 5.5 * 0.5) - 16px) * -1); }
    .welcome-banner .leeds-carousel .amg-carousel-container[data-slide-visible="5.5"][data-slide-visible-hovered="3.75"] + .amg-carousel-nav-container {
      right: calc((((100% + 16px) / 5.5 * 0.75) - 16px) * -1); }
    .welcome-banner .leeds-carousel .amg-carousel-container[data-slide-visible="5.5"][data-slide-visible-hovered="4.25"] + .amg-carousel-nav-container {
      right: calc((((100% + 16px) / 5.5 * 0.25) - 16px) * -1); }
    .welcome-banner .leeds-carousel .amg-carousel-container[data-slide-visible="5.5"][data-slide-visible-hovered="4.5"] + .amg-carousel-nav-container {
      right: calc((((100% + 16px) / 5.5 * 0.5) - 16px) * -1); }
    .welcome-banner .leeds-carousel .amg-carousel-container[data-slide-visible="5.5"][data-slide-visible-hovered="4.75"] + .amg-carousel-nav-container {
      right: calc((((100% + 16px) / 5.5 * 0.75) - 16px) * -1); }
    .welcome-banner .leeds-carousel .amg-carousel-container[data-slide-visible="5.5"][data-slide-visible-hovered="5.25"] + .amg-carousel-nav-container {
      right: calc((((100% + 16px) / 5.5 * 0.25) - 16px) * -1); }
    .welcome-banner .leeds-carousel .amg-carousel-container[data-slide-visible="5.5"][data-slide-visible-hovered="5.5"] + .amg-carousel-nav-container {
      right: calc((((100% + 16px) / 5.5 * 0.5) - 16px) * -1); }
    .welcome-banner .leeds-carousel .amg-carousel-container[data-slide-visible="5.5"][data-slide-visible-hovered="5.75"] + .amg-carousel-nav-container {
      right: calc((((100% + 16px) / 5.5 * 0.75) - 16px) * -1); }
    .welcome-banner .leeds-carousel .amg-carousel-container[data-slide-visible="5.75"][data-slide-visible-hovered="1.25"] + .amg-carousel-nav-container {
      right: calc((((100% + 16px) / 5.75 * 0.25) - 16px) * -1); }
    .welcome-banner .leeds-carousel .amg-carousel-container[data-slide-visible="5.75"][data-slide-visible-hovered="1.5"] + .amg-carousel-nav-container {
      right: calc((((100% + 16px) / 5.75 * 0.5) - 16px) * -1); }
    .welcome-banner .leeds-carousel .amg-carousel-container[data-slide-visible="5.75"][data-slide-visible-hovered="1.75"] + .amg-carousel-nav-container {
      right: calc((((100% + 16px) / 5.75 * 0.75) - 16px) * -1); }
    .welcome-banner .leeds-carousel .amg-carousel-container[data-slide-visible="5.75"][data-slide-visible-hovered="2.25"] + .amg-carousel-nav-container {
      right: calc((((100% + 16px) / 5.75 * 0.25) - 16px) * -1); }
    .welcome-banner .leeds-carousel .amg-carousel-container[data-slide-visible="5.75"][data-slide-visible-hovered="2.5"] + .amg-carousel-nav-container {
      right: calc((((100% + 16px) / 5.75 * 0.5) - 16px) * -1); }
    .welcome-banner .leeds-carousel .amg-carousel-container[data-slide-visible="5.75"][data-slide-visible-hovered="2.75"] + .amg-carousel-nav-container {
      right: calc((((100% + 16px) / 5.75 * 0.75) - 16px) * -1); }
    .welcome-banner .leeds-carousel .amg-carousel-container[data-slide-visible="5.75"][data-slide-visible-hovered="3.25"] + .amg-carousel-nav-container {
      right: calc((((100% + 16px) / 5.75 * 0.25) - 16px) * -1); }
    .welcome-banner .leeds-carousel .amg-carousel-container[data-slide-visible="5.75"][data-slide-visible-hovered="3.5"] + .amg-carousel-nav-container {
      right: calc((((100% + 16px) / 5.75 * 0.5) - 16px) * -1); }
    .welcome-banner .leeds-carousel .amg-carousel-container[data-slide-visible="5.75"][data-slide-visible-hovered="3.75"] + .amg-carousel-nav-container {
      right: calc((((100% + 16px) / 5.75 * 0.75) - 16px) * -1); }
    .welcome-banner .leeds-carousel .amg-carousel-container[data-slide-visible="5.75"][data-slide-visible-hovered="4.25"] + .amg-carousel-nav-container {
      right: calc((((100% + 16px) / 5.75 * 0.25) - 16px) * -1); }
    .welcome-banner .leeds-carousel .amg-carousel-container[data-slide-visible="5.75"][data-slide-visible-hovered="4.5"] + .amg-carousel-nav-container {
      right: calc((((100% + 16px) / 5.75 * 0.5) - 16px) * -1); }
    .welcome-banner .leeds-carousel .amg-carousel-container[data-slide-visible="5.75"][data-slide-visible-hovered="4.75"] + .amg-carousel-nav-container {
      right: calc((((100% + 16px) / 5.75 * 0.75) - 16px) * -1); }
    .welcome-banner .leeds-carousel .amg-carousel-container[data-slide-visible="5.75"][data-slide-visible-hovered="5.25"] + .amg-carousel-nav-container {
      right: calc((((100% + 16px) / 5.75 * 0.25) - 16px) * -1); }
    .welcome-banner .leeds-carousel .amg-carousel-container[data-slide-visible="5.75"][data-slide-visible-hovered="5.5"] + .amg-carousel-nav-container {
      right: calc((((100% + 16px) / 5.75 * 0.5) - 16px) * -1); }
    .welcome-banner .leeds-carousel .amg-carousel-container[data-slide-visible="5.75"][data-slide-visible-hovered="5.75"] + .amg-carousel-nav-container {
      right: calc((((100% + 16px) / 5.75 * 0.75) - 16px) * -1); }
    .welcome-banner .leeds-carousel .amg-card-video {
      margin-right: 16px; }
  @media (min-width: 769px) {
    .welcome-banner {
      padding: 32px 0; }
      .welcome-banner > .amg-section-children .welcome-banner-social-links * {
        display: block;
        height: auto; }
      .welcome-banner > .amg-section-children .welcome-banner-social-links .amg-section-children {
        text-align: right; }
        .welcome-banner > .amg-section-children .welcome-banner-social-links .amg-section-children .amg-section-child {
          vertical-align: middle; }
        .welcome-banner > .amg-section-children .welcome-banner-social-links .amg-section-children .amg-section-child, .welcome-banner > .amg-section-children .welcome-banner-social-links .amg-section-children .amg-icon {
          display: inline-block; }
      .welcome-banner > .amg-section-children .welcome-banner-social-links,
      .welcome-banner > .amg-section-children .welcome-banner-menu {
        display: block; }
        .welcome-banner > .amg-section-children .welcome-banner-social-links .amg-section-child:nth-child(2),
        .welcome-banner > .amg-section-children .welcome-banner-menu .amg-section-child:nth-child(2) {
          flex-grow: 1; }
        .welcome-banner > .amg-section-children .welcome-banner-social-links .amg-icon-image,
        .welcome-banner > .amg-section-children .welcome-banner-menu .amg-icon-image {
          cursor: pointer; }
      .welcome-banner > .amg-section-children > .amg-section-child {
        align-items: baseline; }
        .welcome-banner > .amg-section-children > .amg-section-child,
        .welcome-banner > .amg-section-children > .amg-section-child .amg-section {
          display: block;
          height: auto;
          width: 100%; }
        .welcome-banner > .amg-section-children > .amg-section-child:nth-child(1), .welcome-banner > .amg-section-children > .amg-section-child:nth-child(2) {
          position: absolute;
          left: 0;
          right: 0;
          z-index: 10; }
          .welcome-banner > .amg-section-children > .amg-section-child:nth-child(1) .amg-sections,
          .welcome-banner > .amg-section-children > .amg-section-child:nth-child(1) .amg-sections .amg-section, .welcome-banner > .amg-section-children > .amg-section-child:nth-child(2) .amg-sections,
          .welcome-banner > .amg-section-children > .amg-section-child:nth-child(2) .amg-sections .amg-section {
            display: block;
            height: auto; }
            .welcome-banner > .amg-section-children > .amg-section-child:nth-child(1) .amg-sections .amg-section-children,
            .welcome-banner > .amg-section-children > .amg-section-child:nth-child(1) .amg-sections .amg-section .amg-section-children, .welcome-banner > .amg-section-children > .amg-section-child:nth-child(2) .amg-sections .amg-section-children,
            .welcome-banner > .amg-section-children > .amg-section-child:nth-child(2) .amg-sections .amg-section .amg-section-children {
              justify-content: normal; }
        .welcome-banner > .amg-section-children > .amg-section-child:nth-child(1) {
          top: 10px; }
          .welcome-banner > .amg-section-children > .amg-section-child:nth-child(1) .amg-sections .amg-section .amg-button {
            color: #FFFFFF;
            border-color: transparent;
            background-color: transparent; }
          .welcome-banner > .amg-section-children > .amg-section-child:nth-child(1) .amg-sections .amg-section .amg-nav-link {
            padding: 0;
            padding-right: 16px;
            border-right: 1px solid white;
            margin: 0 0 0 8px; }
          .welcome-banner > .amg-section-children > .amg-section-child:nth-child(1) .amg-sections .amg-section .amg-social-links {
            margin: 0; }
            .welcome-banner > .amg-section-children > .amg-section-child:nth-child(1) .amg-sections .amg-section .amg-social-links .amg-icon,
            .welcome-banner > .amg-section-children > .amg-section-child:nth-child(1) .amg-sections .amg-section .amg-social-links .amg-icon-image {
              margin: 0; }
            .welcome-banner > .amg-section-children > .amg-section-child:nth-child(1) .amg-sections .amg-section .amg-social-links .amg-icon {
              margin-left: 16px; }
            .welcome-banner > .amg-section-children > .amg-section-child:nth-child(1) .amg-sections .amg-section .amg-social-links .amg-icon-image {
              max-width: 18px;
              max-height: 18px; }
        .welcome-banner > .amg-section-children > .amg-section-child:nth-child(2) {
          top: 38px; }
          .welcome-banner > .amg-section-children > .amg-section-child:nth-child(2) .amg-sections .amg-section .nav-item {
            margin-left: 0;
            margin-right: 0; }
          .welcome-banner > .amg-section-children > .amg-section-child:nth-child(2) .amg-sections .amg-section .nav-link {
            font-family: "futura-pt-condensed";
            font-size: 18px;
            padding: 0;
            margin: 4px 15px 0;
            border-bottom: 4px solid transparent; }
            .welcome-banner > .amg-section-children > .amg-section-child:nth-child(2) .amg-sections .amg-section .nav-link.active {
              border-color: #2B5697; }
          .welcome-banner > .amg-section-children > .amg-section-child:nth-child(2) .amg-sections .amg-section .navbar-toggler,
          .welcome-banner > .amg-section-children > .amg-section-child:nth-child(2) .amg-sections .amg-section .amg-register-btn {
            display: none; }
          .welcome-banner > .amg-section-children > .amg-section-child:nth-child(2) .amg-sections .amg-section .amg-icon .amg-icon-image {
            max-width: 188px; }
          .welcome-banner > .amg-section-children > .amg-section-child:nth-child(2) .amg-sections .amg-section .amg-login-btn,
          .welcome-banner > .amg-section-children > .amg-section-child:nth-child(2) .amg-sections .amg-section .amg-logout-btn,
          .welcome-banner > .amg-section-children > .amg-section-child:nth-child(2) .amg-sections .amg-section .btn-my-account.amg-action-link .amg-button-container .amg-button {
            display: block;
            border-radius: 50px;
            width: 100px;
            border-color: #FFFFFF;
            background-color: transparent;
            margin-left: 8px;
            font-size: 10px; }
          .welcome-banner > .amg-section-children > .amg-section-child:nth-child(2) .amg-sections .amg-section .collapse.navbar-collapse {
            margin-right: auto; }
      .welcome-banner .amg-input-field-container {
        background: url("~src/assets/images/icon-search-white.svg") center right no-repeat transparent;
        background-size: auto 26px;
        margin-left: 16px;
        position: relative;
        width: 26px;
        height: 54px; }
      .welcome-banner .amg-input-field {
        padding-top: 15px;
        padding-bottom: 15px;
        border-radius: 0;
        max-width: initial;
        max-height: initial;
        position: absolute;
        right: 0;
        transition: all ease-in-out .24s;
        width: 258px; }
        .welcome-banner .amg-input-field:not(:focus) {
          width: 26px;
          background: transparent;
          border: transparent;
          color: transparent;
          cursor: pointer; }
          .welcome-banner .amg-input-field:not(:focus)::placeholder {
            color: transparent; } }
  @media (min-width: 992px) {
    .welcome-banner > .amg-section-children > .amg-section-child:nth-child(2) .amg-sections .amg-section .nav-link {
      font-size: 22px; } }
  @media (min-width: 1280px) {
    .welcome-banner > .amg-section-title,
    .welcome-banner > .amg-section-children {
      padding-left: 45px;
      padding-right: 45px; }
    .welcome-banner .welcome-banner-social-links .amg-section-children,
    .welcome-banner .welcome-banner-menu .amg-section-children {
      padding-left: 45px;
      padding-right: 45px; } }
  @media (min-width: 3840px) {
    .welcome-banner .amg-section-description {
      font-size: 60px; } }

.amg-subscription-packages {
  width: 100%;
  display: flex;
  flex-direction: column; }
  .amg-subscription-packages .amg-subscription-package {
    width: 100%;
    margin: 20px 0;
    border: 2pt solid #BD9B60;
    padding: 0.5px;
    background: #FFFFFF;
    color: #2F2F2F;
    text-align: center;
    position: relative; }
    .amg-subscription-packages .amg-subscription-package .amg-subscription-package-inner {
      background-color: #FFFFFF;
      padding: 80px 16px 16px; }
      .amg-subscription-packages .amg-subscription-package .amg-subscription-package-inner .amg-title {
        color: #2B5697;
        font-size: 20pt;
        margin-bottom: 18px;
        display: block;
        font-weight: bold; }
      .amg-subscription-packages .amg-subscription-package .amg-subscription-package-inner .amg-body {
        color: #0E2A51;
        font-size: 16pt;
        margin-top: 20px;
        margin-bottom: 20px;
        display: flex;
        vertical-align: middle;
        align-items: center;
        justify-content: center; }
      .amg-subscription-packages .amg-subscription-package .amg-subscription-package-inner .amg-amount {
        text-align: center;
        width: 100%;
        font-weight: 700;
        display: inline-block;
        margin-bottom: 10px;
        padding-right: 10px; }
        .amg-subscription-packages .amg-subscription-package .amg-subscription-package-inner .amg-amount:before {
          content: "Only";
          margin-right: 10px;
          font-weight: 400; }
      .amg-subscription-packages .amg-subscription-package .amg-subscription-package-inner .amg-interval-group {
        display: none; }
      .amg-subscription-packages .amg-subscription-package .amg-subscription-package-inner .amg-button {
        display: block;
        color: white;
        border-color: #BD9B60;
        border-radius: 50px;
        background: #BD9B60;
        margin: 0 auto 10px auto; }
        .amg-subscription-packages .amg-subscription-package .amg-subscription-package-inner .amg-button:hover {
          border-color: #9C835E;
          background: #9C835E; }

@media (min-width: 768px) {
  .amg-subscription-packages {
    flex-direction: row; }
    .amg-subscription-packages .amg-subscription-package {
      border: 2px solid #BD9B60;
      background: #FFFFFF;
      color: #2F2F2F;
      text-align: center; } }

.amg-header {
  background: #0E2A51; }
  @media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {
    .amg-header,
    .amg-header .amg-section,
    .amg-header .amg-section-children,
    .amg-header .amg-section-child,
    .amg-header .amg-action-link,
    .amg-header .amg-navigation-bar,
    .amg-header amg-wp-payment-fields-group {
      display: block;
      height: auto; }
    .amg-header .header-main .amg-navigation-bar div .navbar-nav {
      display: table; }
      .amg-header .header-main .amg-navigation-bar div .navbar-nav .nav-item {
        margin: 4px 15px 0; }
      .amg-header .header-main .amg-navigation-bar div .navbar-nav .nav-link {
        display: table-cell; } }
  .amg-header .nav-link {
    font-family: "futura-pt-condensed"; }
  .amg-header .header-main {
    padding-right: 16px;
    padding-left: 16px;
    padding-top: 16px;
    padding-bottom: 16px;
    width: 100%; }
    @media (min-width: 1200px) {
      .amg-header .header-main {
        padding-left: 16px;
        padding-right: 16px; } }
    .amg-header .header-main .nav-link.amg-nav-link {
      border-radius: 50px;
      width: 100px;
      border-color: #FFFFFF;
      background-color: transparent;
      margin: auto 8px;
      font-size: 18px;
      border-style: solid;
      border-width: 1px; }
      @media (min-width: 1920px) {
        .amg-header .header-main .nav-link.amg-nav-link {
          font-size: 16px;
          width: 140px; } }
      @media (min-width: 1920px) {
        .amg-header .header-main .nav-link.amg-nav-link {
          font-size: 18px; } }
  .amg-header .header-links {
    padding-right: 16px;
    padding-left: 16px; }
    @media (min-width: 1200px) {
      .amg-header .header-links {
        padding-left: 16px;
        padding-right: 16px; } }
    .amg-header .header-links .amg-social-links {
      margin: 0; }
  .amg-header .btn-home {
    cursor: pointer; }
  @media (max-width: 576px) {
    .amg-header .header-main .navbar-collapse.show {
      top: 84px; } }
  @media (max-width: 768px) {
    .amg-header .amg-icon-image {
      max-width: 35vw;
      max-height: initial; }
    .amg-header .navbar-toggler {
      background: linear-gradient(to bottom, #FFFFFF 0, #FFFFFF 20%, transparent 20%, transparent 40%, #FFFFFF 40%, #FFFFFF 60%, transparent 60%, transparent 80%, #FFFFFF 80%, #FFFFFF 100%);
      border: 0;
      border-radius: 0;
      width: 20px;
      height: 18px;
      padding: 0; }
      .amg-header .navbar-toggler * {
        display: none; }
    .amg-header .header-main .amg-section-children {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center; }
      .amg-header .header-main .amg-section-children .amg-section-child {
        display: block;
        width: auto; }
        .amg-header .header-main .amg-section-children .amg-section-child:nth-child(1) {
          order: 1; }
        .amg-header .header-main .amg-section-children .amg-section-child:nth-child(3) {
          order: 2;
          margin-left: auto; }
        .amg-header .header-main .amg-section-children .amg-section-child:nth-child(4) {
          order: 3; }
        .amg-header .header-main .amg-section-children .amg-section-child:nth-child(2) {
          order: 4; }
        .amg-header .header-main .amg-section-children .amg-section-child:nth-child(5), .amg-header .header-main .amg-section-children .amg-section-child:nth-child(6) {
          display: none; }
        .amg-header .header-main .amg-section-children .amg-section-child:not(:nth-child(2)) {
          margin-right: 16px; }
    .amg-header .header-links {
      display: none; }
    .amg-header .amg-payment-fields-group amg-register-btn {
      display: none; }
    .amg-header .btn {
      border-radius: 50px; }
    .amg-header .navbar-collapse.show {
      background: #0E2A51;
      position: absolute;
      top: 122px;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: 100;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center; }
      .amg-header .navbar-collapse.show .navbar-nav {
        height: 85%;
        display: flex;
        flex-direction: column;
        justify-content: space-around; }
      .amg-header .navbar-collapse.show .nav-item {
        margin-left: 0;
        margin-right: 0; }
      .amg-header .navbar-collapse.show .amg-navbar-extra {
        width: 100%;
        height: 15%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin-left: -16px;
        margin-right: -16px; }
        .amg-header .navbar-collapse.show .amg-navbar-extra .amg-input-field {
          font-size: 21px;
          height: 48px;
          color: #0E2A51;
          background: url("~src/assets/images/search_icon_small.png") 12px 50% no-repeat #FFFFFF;
          text-indent: 32px;
          border: none;
          border-radius: 0; }
        .amg-header .navbar-collapse.show .amg-navbar-extra .amg-navigation-bar-section {
          display: none; }
      .amg-header .navbar-collapse.show .nav-link {
        border: 2px solid transparent;
        border-top: 0;
        border-bottom: 0;
        font-size: 22px;
        padding: 4px 0; }
        .amg-header .navbar-collapse.show .nav-link.active {
          border-left-color: #2B5697; } }
  @media (min-width: 769px) {
    .amg-header .header-links {
      background: #FFFFFF;
      line-height: 30px; }
      .amg-header .header-links .amg-icon {
        margin: 0 0 0 10px; }
        .amg-header .header-links .amg-icon .amg-icon-image {
          max-height: 24px;
          max-width: 24px; }
      .amg-header .header-links .nav-link {
        color: #0E2A51;
        font-size: 14px;
        padding: 0 9px;
        border-left: solid 1px #E6E7E8;
        border-right: solid 1px #E6E7E8; }
      .amg-header .header-links .amg-social-links img {
        margin: 0; }
      .amg-header .header-links > .amg-section-children {
        justify-content: flex-end; }
        .amg-header .header-links > .amg-section-children > .amg-section-child {
          display: inline-block;
          width: auto;
          height: auto;
          vertical-align: middle; }
    .amg-header .header-main > .amg-section-children {
      display: flex; }
    .amg-header .header-main .amg-navigation-bar div .navbar-nav {
      display: block;
      text-align: center; }
      .amg-header .header-main .amg-navigation-bar div .navbar-nav .nav-link {
        display: inline-block; }
    .amg-header .header-main .amg-icon .amg-icon-image {
      max-width: 155px;
      max-height: 62px; }
    .amg-header .header-main > .amg-section-children > .amg-section-child {
      width: auto; }
      .amg-header .header-main > .amg-section-children > .amg-section-child:nth-child(2) {
        width: 100%; }
      .amg-header .header-main > .amg-section-children > .amg-section-child:nth-child(3), .amg-header .header-main > .amg-section-children > .amg-section-child:nth-child(4) {
        flex-direction: row;
        justify-content: flex-end; }
    .amg-header .header-main .collapse.navbar-collapse {
      display: block; }
      .amg-header .header-main .collapse.navbar-collapse .nav-item {
        margin: 0; }
      .amg-header .header-main .collapse.navbar-collapse .nav-link {
        font-size: 18px;
        padding: 0;
        margin: 4px 15px 0;
        border-bottom: 4px solid transparent;
        line-height: 18px; }
        .amg-header .header-main .collapse.navbar-collapse .nav-link.active {
          border-bottom-color: #2B5697; }
    .amg-header .amg-login-btn,
    .amg-header .amg-logout-btn,
    .amg-header .btn-my-account.amg-action-link .amg-button-container .amg-button {
      border-radius: 50px;
      min-width: 100px;
      border-color: #FFFFFF;
      background-color: transparent;
      white-space: nowrap;
      margin-left: 16px; }
    .amg-header .navbar-toggler,
    .amg-header .amg-register-btn {
      display: none; }
    .amg-header .amg-input-field-container {
      background: url("~src/assets/images/icon-search-white.svg") center right no-repeat transparent;
      background-size: auto 26px;
      margin-left: 16px;
      position: relative;
      width: 26px;
      height: 54px; }
    .amg-header .amg-input-field {
      padding-top: 15px;
      padding-bottom: 15px;
      border-radius: 0;
      max-width: initial;
      max-height: initial;
      position: absolute;
      right: 0;
      transition: all ease-in-out .24s;
      width: 262px; }
      .amg-header .amg-input-field:not(:focus) {
        width: 26px;
        background: transparent;
        border: transparent;
        color: transparent;
        cursor: pointer; }
        .amg-header .amg-input-field:not(:focus)::placeholder {
          color: transparent; } }
  @media (min-width: 992px) {
    .amg-header .header-main .collapse.navbar-collapse .nav-link {
      font-size: 22px; } }

footer {
  font-family: 'Oswald', serif;
  overflow: hidden;
  font-size: 14px; }
  footer > .amg-footer,
  footer > .amg-footer .amg-section,
  footer > .amg-footer .amg-section-children,
  footer > .amg-footer .amg-section-child,
  footer > .amg-footer .amg-action-link {
    display: block;
    height: auto; }
  footer > .amg-footer > .amg-section > * {
    padding-right: 16px;
    padding-left: 16px; }
    @media (min-width: 1200px) {
      footer > .amg-footer > .amg-section > * {
        padding-left: 16px;
        padding-right: 16px; } }
  footer .advertisement-container .amg-section-children {
    max-width: 1300px;
    margin: 0 auto; }
    footer .advertisement-container .amg-section-children .amg-section-child {
      text-align: center; }
    footer .advertisement-container .amg-section-children .amg-icon-image {
      padding: 16px 0;
      max-width: 100%;
      max-height: none; }
  footer .sponsor-container {
    background: #0E2A51; }
    footer .sponsor-container > .amg-section-children {
      flex-direction: column; }
      footer .sponsor-container > .amg-section-children > .amg-section-child {
        padding-top: 0;
        padding-bottom: 0; }
    footer .sponsor-container .amg-icon-group {
      flex-wrap: wrap; }
      footer .sponsor-container .amg-icon-group .amg-icon {
        padding: 0 16px;
        text-align: center; }
        footer .sponsor-container .amg-icon-group .amg-icon .amg-icon-image {
          max-width: 140px; }
  footer .navigation-footer-container > .amg-section-children > .amg-section-child > .amg-sections > .amg-section {
    padding: 16px 0; }
  footer .navigation-footer-container .logo-container .amg-section-child {
    text-align: center; }
  footer .navigation-footer-container .logo-container .amg-icon {
    position: relative; }
    footer .navigation-footer-container .logo-container .amg-icon .amg-icon-image {
      max-width: 200px; }
    footer .navigation-footer-container .logo-container .amg-icon .amg-icon-label {
      position: absolute;
      top: 100%;
      left: 0;
      margin: 10px 0 0;
      font-size: 14px; }
  footer .navigation-footer-container .social-links-container {
    display: flex; }
    footer .navigation-footer-container .social-links-container .amg-section-children {
      display: flex;
      margin: auto; }
  footer .navigation-footer-container .navigation-links-container {
    flex-grow: 1; }
    footer .navigation-footer-container .navigation-links-container > .amg-section-children {
      display: flex; }
    footer .navigation-footer-container .navigation-links-container .amg-section-child {
      width: auto;
      padding: 0 16px; }
    footer .navigation-footer-container .navigation-links-container .amg-text-links {
      flex-direction: column;
      white-space: nowrap; }
      footer .navigation-footer-container .navigation-links-container .amg-text-links .nav-link {
        font-family: 'Oswald', serif;
        text-align: left;
        color: #000000;
        font-size: 14px;
        padding: 5px 0; }
  footer .privacy-container .amg-section-children {
    padding-top: 16px; }
  footer .privacy-container .amg-section-child {
    align-items: flex-end; }
  footer .privacy-container .amg-action-link .amg-button-container {
    text-align: right; }
    footer .privacy-container .amg-action-link .amg-button-container .amg-button {
      border-radius: 5px 5px 0 0;
      padding: 5px 20px; }
  footer .copyrights-container {
    padding: 0 16px; }
    footer .copyrights-container .amg-section-title {
      font-weight: normal;
      color: #000000;
      font-size: 14px; }
  @media (max-width: 767.98px) {
    footer .navigation-footer-container > .amg-section-children > .amg-section-child > .amg-sections {
      flex-direction: column; }
      footer .navigation-footer-container > .amg-section-children > .amg-section-child > .amg-sections .social-links-container > .amg-section-children > .amg-section-child {
        width: auto;
        padding: 0 10px; } }
  @media (min-width: 576px) {
    footer {
      font-size: 16px; } }
  @media (min-width: 768px) {
    footer .sponsor-container .amg-icon-group .amg-icon .amg-icon-image {
      max-width: 170px; }
    footer .copyrights-container .amg-section-title {
      font-size: 16px;
      text-align: left; } }
  @media (min-width: 992px) {
    footer .navigation-footer-container .logo-container .amg-icon .amg-icon-image {
      max-width: 250px; }
    footer .navigation-footer-container .navigation-links-container .amg-text-links .nav-link {
      font-size: 16px; } }

#page-container {
  display: block;
  min-height: calc(100vh - 141px); }
  #page-container > *:not(app-home):not(app-video) > amg-wp-page > amg-wp-section:not(.navigation-container) {
    padding-right: 16px;
    padding-left: 16px; }
    @media (min-width: 1200px) {
      #page-container > *:not(app-home):not(app-video) > amg-wp-page > amg-wp-section:not(.navigation-container) {
        padding-left: 16px;
        padding-right: 16px; } }
  #page-container .amg-page > *.leeds-carousel {
    overflow: hidden; }
    #page-container .amg-page > *.leeds-carousel,
    #page-container .amg-page > *.leeds-carousel .amg-section-children {
      padding-right: 0; }
  @media (min-width: 768px) {
    #page-container {
      min-height: calc(100vh - 94px); }
      [data-page='home'] #page-container {
        min-height: 100vh; } }
  @media (min-width: 992px) {
    #page-container {
      min-height: calc(100vh - 124px); } }

.page-home {
  overflow: hidden; }
  .page-home > amg-wp-section:not(.welcome-banner):not(.fixture-section) {
    padding-right: 16px;
    padding-left: 16px; }
    @media (min-width: 1200px) {
      .page-home > amg-wp-section:not(.welcome-banner):not(.fixture-section) {
        padding-left: 16px;
        padding-right: 16px; } }
  .page-home .welcome-banner {
    position: relative;
    min-height: 700px; }
    .page-home .welcome-banner > * {
      z-index: 1; }
    .page-home .welcome-banner:after {
      display: block;
      content: "";
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      background: linear-gradient(to bottom, rgba(2, 21, 44, 0.11), rgba(2, 21, 44, 0.15) 75%, #02152C);
      z-index: 0; }
    .page-home .welcome-banner .welcome-banner-menu .amg-button-container .amg-button {
      border-radius: 50px;
      width: 100px;
      border-color: #FFFFFF;
      background-color: transparent;
      margin: auto 8px;
      font-size: 10px;
      border-style: solid;
      border-width: 1px; }
      @media (min-width: 1920px) {
        .page-home .welcome-banner .welcome-banner-menu .amg-button-container .amg-button {
          font-size: 16px;
          width: 140px; } }
  .page-home .fixture-section {
    display: block;
    background: #FFFFFF; }
    .page-home .fixture-section .amg-section-title {
      font-size: 16pt;
      text-align: left;
      padding-top: 16px;
      padding-bottom: 8px;
      padding: 16px 16px 8px; }
    .page-home .fixture-section > .amg-section-children {
      background: #0E2A51;
      flex-direction: column; }
      .page-home .fixture-section > .amg-section-children > .amg-section-child {
        display: block;
        height: auto; }
        .page-home .fixture-section > .amg-section-children > .amg-section-child:first-child {
          background: #FFFFFF; }
          .page-home .fixture-section > .amg-section-children > .amg-section-child:first-child .amg-action-link .amg-button-container .amg-button {
            color: #588BBC;
            border-color: transparent;
            background: transparent;
            text-decoration: underline;
            padding: 0 16px;
            margin: 0 0 16px 0; }
        .page-home .fixture-section > .amg-section-children > .amg-section-child:last-child .amg-fixtures .amg-fixture {
          padding: 0 16px; }
          .page-home .fixture-section > .amg-section-children > .amg-section-child:last-child .amg-fixtures .amg-fixture .amg-card-fixture-teams .amg-card-fixture-team {
            flex: 0 0 37.5%;
            width: 37.5%; }
            .page-home .fixture-section > .amg-section-children > .amg-section-child:last-child .amg-fixtures .amg-fixture .amg-card-fixture-teams .amg-card-fixture-team .amg-card-fixture-team-icon {
              margin-left: auto; }
            .page-home .fixture-section > .amg-section-children > .amg-section-child:last-child .amg-fixtures .amg-fixture .amg-card-fixture-teams .amg-card-fixture-team .amg-card-fixture-team-name {
              margin-right: auto;
              text-overflow: ellipsis;
              overflow: hidden; }
          .page-home .fixture-section > .amg-section-children > .amg-section-child:last-child .amg-fixtures .amg-fixture .amg-card-fixture-teams .t {
            flex: 0 0 25%;
            width: 25%; }
          .page-home .fixture-section > .amg-section-children > .amg-section-child:last-child .amg-fixtures .amg-fixture .amg-card-fixture-meta amg-live-buttons {
            width: 100%; }
  @media (max-width: 1199.98px) {
    .page-home .amg-fixtures .amg-fixture:not(:nth-child(1)):not(:nth-child(2)) {
      display: none; } }
  .page-home .amg-fixtures .amg-fixture {
    display: block; }
    .page-home .amg-fixtures .amg-fixture:not(:last-child) .amg-card-event,
    .page-home .amg-fixtures .amg-fixture:not(:last-child) .amg-card-fixture {
      border-bottom: 1pt solid #FFFFFF; }
  .page-home .amg-fixtures .amg-card-event,
  .page-home .amg-fixtures .amg-card-fixture {
    color: #FFFFFF; }
    .page-home .amg-fixtures .amg-card-event .amg-card-event-info,
    .page-home .amg-fixtures .amg-card-event .amg-card-fixture-info,
    .page-home .amg-fixtures .amg-card-event .amg-card-fixture-teams,
    .page-home .amg-fixtures .amg-card-event .amg-card-event-details,
    .page-home .amg-fixtures .amg-card-event .amg-card-fixture-meta,
    .page-home .amg-fixtures .amg-card-event .amg-card-fixture-meta amg-live-buttons,
    .page-home .amg-fixtures .amg-card-fixture .amg-card-event-info,
    .page-home .amg-fixtures .amg-card-fixture .amg-card-fixture-info,
    .page-home .amg-fixtures .amg-card-fixture .amg-card-fixture-teams,
    .page-home .amg-fixtures .amg-card-fixture .amg-card-event-details,
    .page-home .amg-fixtures .amg-card-fixture .amg-card-fixture-meta,
    .page-home .amg-fixtures .amg-card-fixture .amg-card-fixture-meta amg-live-buttons {
      padding: 0; }
    .page-home .amg-fixtures .amg-card-event .amg-card-event-info,
    .page-home .amg-fixtures .amg-card-event .amg-card-fixture-info,
    .page-home .amg-fixtures .amg-card-fixture .amg-card-event-info,
    .page-home .amg-fixtures .amg-card-fixture .amg-card-fixture-info {
      background: transparent;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      font-weight: 500;
      text-transform: uppercase; }
      .page-home .amg-fixtures .amg-card-event .amg-card-event-info .amg-date .time,
      .page-home .amg-fixtures .amg-card-event .amg-card-fixture-info .amg-date .time,
      .page-home .amg-fixtures .amg-card-fixture .amg-card-event-info .amg-date .time,
      .page-home .amg-fixtures .amg-card-fixture .amg-card-fixture-info .amg-date .time {
        font-weight: bold; }
    .page-home .amg-fixtures .amg-card-event .amg-card-event-details .amg-card-event-details-title,
    .page-home .amg-fixtures .amg-card-fixture .amg-card-event-details .amg-card-event-details-title {
      font-weight: bold; }
    .page-home .amg-fixtures .amg-card-event .amg-card-fixture-sponsor,
    .page-home .amg-fixtures .amg-card-fixture .amg-card-fixture-sponsor {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center; }
      .page-home .amg-fixtures .amg-card-event .amg-card-fixture-sponsor .amg-card-fixture-sponsor-img,
      .page-home .amg-fixtures .amg-card-fixture .amg-card-fixture-sponsor .amg-card-fixture-sponsor-img {
        max-width: 100%; }
    .page-home .amg-fixtures .amg-card-event .amg-card-fixture-teams,
    .page-home .amg-fixtures .amg-card-fixture .amg-card-fixture-teams {
      align-items: flex-end; }
      .page-home .amg-fixtures .amg-card-event .amg-card-fixture-teams .amg-card-fixture-team,
      .page-home .amg-fixtures .amg-card-fixture .amg-card-fixture-teams .amg-card-fixture-team {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        font-weight: bold;
        text-align: left;
        text-transform: uppercase; }
        .page-home .amg-fixtures .amg-card-event .amg-card-fixture-teams .amg-card-fixture-team .amg-card-fixture-team-icon,
        .page-home .amg-fixtures .amg-card-fixture .amg-card-fixture-teams .amg-card-fixture-team .amg-card-fixture-team-icon {
          max-width: initial; }
      .page-home .amg-fixtures .amg-card-event .amg-card-fixture-teams .amg-date,
      .page-home .amg-fixtures .amg-card-fixture .amg-card-fixture-teams .amg-date {
        font-weight: bold; }
    .page-home .amg-fixtures .amg-card-event amg-live-buttons,
    .page-home .amg-fixtures .amg-card-fixture amg-live-buttons {
      display: flex;
      flex-direction: row;
      justify-content: space-around; }
      .page-home .amg-fixtures .amg-card-event amg-live-buttons .btn,
      .page-home .amg-fixtures .amg-card-fixture amg-live-buttons .btn {
        color: #0E2A51;
        border-radius: 50pt;
        border: 2pt solid;
        text-transform: uppercase;
        transition: border-color 0.333s ease-in-out 0s, background-color 0.333s ease-in-out 0s; }
        .page-home .amg-fixtures .amg-card-event amg-live-buttons .btn .live:after,
        .page-home .amg-fixtures .amg-card-fixture amg-live-buttons .btn .live:after {
          content: " "; }
      .page-home .amg-fixtures .amg-card-event amg-live-buttons .btn,
      .page-home .amg-fixtures .amg-card-fixture amg-live-buttons .btn {
        background-color: #F4F4F4;
        background-image: radial-gradient(circle, #FC0000, #FC0000 5px, transparent 5px);
        border-color: #BD9B60; }
        .page-home .amg-fixtures .amg-card-event amg-live-buttons .btn[disabled],
        .page-home .amg-fixtures .amg-card-fixture amg-live-buttons .btn[disabled] {
          border-color: #9C835E;
          background-color: #E6E7E8;
          background-image: radial-gradient(circle, #0E2A51, #0E2A51 5px, transparent 5px); }
  .page-home .amg-fixtures .amg-fixture .amg-card-calendar-fixture-sponsor-img {
    display: none; }
  .page-home .amg-fixtures .amg-fixture + .amg-fixture .amg-card-event,
  .page-home .amg-fixtures .amg-fixture + .amg-fixture .amg-card-fixture {
    margin-top: -8pt; }
  .page-home .amg-fixtures .amg-card-event,
  .page-home .amg-fixtures .amg-card-fixture {
    padding: 8pt 0;
    margin-bottom: 8pt; }
    .page-home .amg-fixtures .amg-card-event .amg-card-event-info,
    .page-home .amg-fixtures .amg-card-event .amg-card-fixture-info,
    .page-home .amg-fixtures .amg-card-event .amg-card-fixture-teams,
    .page-home .amg-fixtures .amg-card-event .amg-card-event-details,
    .page-home .amg-fixtures .amg-card-fixture .amg-card-event-info,
    .page-home .amg-fixtures .amg-card-fixture .amg-card-fixture-info,
    .page-home .amg-fixtures .amg-card-fixture .amg-card-fixture-teams,
    .page-home .amg-fixtures .amg-card-fixture .amg-card-event-details {
      margin-bottom: 8pt; }
    .page-home .amg-fixtures .amg-card-event .amg-card-event-info,
    .page-home .amg-fixtures .amg-card-event .amg-card-fixture-info,
    .page-home .amg-fixtures .amg-card-fixture .amg-card-event-info,
    .page-home .amg-fixtures .amg-card-fixture .amg-card-fixture-info {
      margin-bottom: 8pt;
      font-size: 8pt; }
    .page-home .amg-fixtures .amg-card-event .amg-card-fixture-sponsor .amg-card-fixture-sponsor-img,
    .page-home .amg-fixtures .amg-card-fixture .amg-card-fixture-sponsor .amg-card-fixture-sponsor-img {
      max-height: 26px; }
    .page-home .amg-fixtures .amg-card-event .amg-card-fixture-teams,
    .page-home .amg-fixtures .amg-card-fixture .amg-card-fixture-teams {
      font-size: 8pt; }
      .page-home .amg-fixtures .amg-card-event .amg-card-fixture-teams .amg-card-fixture-team .amg-card-fixture-team-icon,
      .page-home .amg-fixtures .amg-card-fixture .amg-card-fixture-teams .amg-card-fixture-team .amg-card-fixture-team-icon {
        max-height: 32pt;
        margin-right: 4pt; }
      .page-home .amg-fixtures .amg-card-event .amg-card-fixture-teams .amg-date,
      .page-home .amg-fixtures .amg-card-fixture .amg-card-fixture-teams .amg-date {
        font-size: 12pt; }
    .page-home .amg-fixtures .amg-card-event amg-live-buttons .btn,
    .page-home .amg-fixtures .amg-card-fixture amg-live-buttons .btn {
      background-position: -22pt -2pt;
      font-size: 8pt;
      padding-left: 16pt;
      padding-right: 8pt; }
  @media (min-width: 768px) {
    .page-home .amg-fixtures {
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      overflow: hidden;
      max-height: calc(100vh - 90px);
      height: 100%; }
      .page-home .amg-fixtures .amg-card-event,
      .page-home .amg-fixtures .amg-card-fixture {
        padding: 10pt 0;
        margin-bottom: 10pt; }
        .page-home .amg-fixtures .amg-card-event .amg-card-event-info,
        .page-home .amg-fixtures .amg-card-event .amg-card-fixture-info,
        .page-home .amg-fixtures .amg-card-event .amg-card-fixture-teams,
        .page-home .amg-fixtures .amg-card-event .amg-card-event-details,
        .page-home .amg-fixtures .amg-card-fixture .amg-card-event-info,
        .page-home .amg-fixtures .amg-card-fixture .amg-card-fixture-info,
        .page-home .amg-fixtures .amg-card-fixture .amg-card-fixture-teams,
        .page-home .amg-fixtures .amg-card-fixture .amg-card-event-details {
          margin-bottom: 10pt; }
        .page-home .amg-fixtures .amg-card-event .amg-card-event-info,
        .page-home .amg-fixtures .amg-card-event .amg-card-fixture-info,
        .page-home .amg-fixtures .amg-card-fixture .amg-card-event-info,
        .page-home .amg-fixtures .amg-card-fixture .amg-card-fixture-info {
          margin-bottom: 10pt;
          font-size: 10pt; }
        .page-home .amg-fixtures .amg-card-event .amg-card-fixture-sponsor .amg-card-fixture-sponsor-img,
        .page-home .amg-fixtures .amg-card-fixture .amg-card-fixture-sponsor .amg-card-fixture-sponsor-img {
          max-height: 26px; }
        .page-home .amg-fixtures .amg-card-event .amg-card-fixture-teams,
        .page-home .amg-fixtures .amg-card-fixture .amg-card-fixture-teams {
          font-size: 10pt; }
          .page-home .amg-fixtures .amg-card-event .amg-card-fixture-teams .amg-card-fixture-team .amg-card-fixture-team-icon,
          .page-home .amg-fixtures .amg-card-fixture .amg-card-fixture-teams .amg-card-fixture-team .amg-card-fixture-team-icon {
            max-height: 40pt; }
          .page-home .amg-fixtures .amg-card-event .amg-card-fixture-teams .amg-date,
          .page-home .amg-fixtures .amg-card-fixture .amg-card-fixture-teams .amg-date {
            font-size: 21pt; }
        .page-home .amg-fixtures .amg-card-event amg-live-buttons .btn,
        .page-home .amg-fixtures .amg-card-fixture amg-live-buttons .btn {
          background-position: -28pt center;
          font-size: 10pt;
          padding-left: 20pt;
          padding-right: 10pt; } }
  @media (min-width: 1200px) and (max-width: 1919.98px) {
    .page-home .amg-fixtures .amg-card-fixture .amg-card-fixture-teams .amg-card-fixture-team .amg-card-fixture-team-icon {
      max-height: 25px; }
    .page-home .amg-fixtures .amg-card-fixture .amg-card-fixture-teams .amg-date {
      font-size: 20px; } }
  @media (min-width: 1280px) {
    .page-home .amg-fixtures .amg-fixture + .amg-fixture .amg-card-event,
    .page-home .amg-fixtures .amg-fixture + .amg-fixture .amg-card-fixture {
      margin-top: -10pt; }
    .page-home .amg-fixtures .amg-card-event,
    .page-home .amg-fixtures .amg-card-fixture {
      padding: 10pt 0;
      margin-bottom: 10pt; }
      .page-home .amg-fixtures .amg-card-event .amg-card-event-info,
      .page-home .amg-fixtures .amg-card-event .amg-card-fixture-info,
      .page-home .amg-fixtures .amg-card-event .amg-card-fixture-teams,
      .page-home .amg-fixtures .amg-card-event .amg-card-event-details,
      .page-home .amg-fixtures .amg-card-fixture .amg-card-event-info,
      .page-home .amg-fixtures .amg-card-fixture .amg-card-fixture-info,
      .page-home .amg-fixtures .amg-card-fixture .amg-card-fixture-teams,
      .page-home .amg-fixtures .amg-card-fixture .amg-card-event-details {
        margin-bottom: 10pt; }
      .page-home .amg-fixtures .amg-card-event .amg-card-event-info,
      .page-home .amg-fixtures .amg-card-event .amg-card-fixture-info,
      .page-home .amg-fixtures .amg-card-fixture .amg-card-event-info,
      .page-home .amg-fixtures .amg-card-fixture .amg-card-fixture-info {
        margin-bottom: 10pt;
        font-size: 10pt; }
      .page-home .amg-fixtures .amg-card-event .amg-card-fixture-sponsor .amg-card-fixture-sponsor-img,
      .page-home .amg-fixtures .amg-card-fixture .amg-card-fixture-sponsor .amg-card-fixture-sponsor-img {
        max-height: 26px; }
      .page-home .amg-fixtures .amg-card-event .amg-card-fixture-teams,
      .page-home .amg-fixtures .amg-card-fixture .amg-card-fixture-teams {
        font-size: 10pt; }
        .page-home .amg-fixtures .amg-card-event .amg-card-fixture-teams .amg-card-fixture-team .amg-card-fixture-team-icon,
        .page-home .amg-fixtures .amg-card-fixture .amg-card-fixture-teams .amg-card-fixture-team .amg-card-fixture-team-icon {
          max-height: 35px; }
        .page-home .amg-fixtures .amg-card-event .amg-card-fixture-teams .amg-date,
        .page-home .amg-fixtures .amg-card-fixture .amg-card-fixture-teams .amg-date {
          font-size: 14pt; }
      .page-home .amg-fixtures .amg-card-event amg-live-buttons .btn,
      .page-home .amg-fixtures .amg-card-fixture amg-live-buttons .btn {
        background-position: -32pt center;
        font-size: 10pt;
        padding-left: 20pt;
        padding-right: 10pt; } }
  @media (min-width: 3840px) {
    .page-home .amg-fixtures .amg-fixture + .amg-fixture .amg-card-event,
    .page-home .amg-fixtures .amg-fixture + .amg-fixture .amg-card-fixture {
      margin-top: -18pt; }
    .page-home .amg-fixtures .amg-card-event,
    .page-home .amg-fixtures .amg-card-fixture {
      padding: 18pt 0; }
      .page-home .amg-fixtures .amg-card-event .amg-card-event-info,
      .page-home .amg-fixtures .amg-card-event .amg-card-fixture-info,
      .page-home .amg-fixtures .amg-card-event .amg-card-fixture-teams,
      .page-home .amg-fixtures .amg-card-event .amg-card-event-details,
      .page-home .amg-fixtures .amg-card-fixture .amg-card-event-info,
      .page-home .amg-fixtures .amg-card-fixture .amg-card-fixture-info,
      .page-home .amg-fixtures .amg-card-fixture .amg-card-fixture-teams,
      .page-home .amg-fixtures .amg-card-fixture .amg-card-event-details {
        margin-bottom: 18pt; }
      .page-home .amg-fixtures .amg-card-event .amg-card-event-info,
      .page-home .amg-fixtures .amg-card-event .amg-card-fixture-info,
      .page-home .amg-fixtures .amg-card-fixture .amg-card-event-info,
      .page-home .amg-fixtures .amg-card-fixture .amg-card-fixture-info {
        margin-bottom: 18pt;
        font-size: 18pt; }
      .page-home .amg-fixtures .amg-card-event .amg-card-fixture-sponsor .amg-card-fixture-sponsor-img,
      .page-home .amg-fixtures .amg-card-fixture .amg-card-fixture-sponsor .amg-card-fixture-sponsor-img {
        max-height: 26px; }
      .page-home .amg-fixtures .amg-card-event .amg-card-fixture-teams,
      .page-home .amg-fixtures .amg-card-fixture .amg-card-fixture-teams {
        font-size: 18pt; }
        .page-home .amg-fixtures .amg-card-event .amg-card-fixture-teams .amg-card-fixture-team .amg-card-fixture-team-icon,
        .page-home .amg-fixtures .amg-card-fixture .amg-card-fixture-teams .amg-card-fixture-team .amg-card-fixture-team-icon {
          max-height: 72pt; }
        .page-home .amg-fixtures .amg-card-event .amg-card-fixture-teams .amg-date,
        .page-home .amg-fixtures .amg-card-fixture .amg-card-fixture-teams .amg-date {
          font-size: 34pt; }
      .page-home .amg-fixtures .amg-card-event amg-live-buttons .btn,
      .page-home .amg-fixtures .amg-card-fixture amg-live-buttons .btn {
        background-position: -52pt center;
        font-size: 18pt;
        padding-left: 36pt;
        padding-right: 18pt; } }
  @media (min-width: 992px) {
    .page-home .welcome-banner .amg-section-title {
      font-size: 36px; } }
  @media (min-width: 1920px) {
    .page-home .welcome-banner .amg-section-title {
      font-size: 52px;
      padding-right: 40%; } }
  @media (min-width: 3840px) {
    .page-home .welcome-banner .amg-section-title {
      font-size: 124px; } }
  @media (min-width: 992px) {
    .page-home .welcome-banner .amg-section-description {
      font-size: 22px; } }
  @media (min-width: 1920px) {
    .page-home .welcome-banner .amg-section-description {
      font-size: 40px; } }
  @media (min-width: 3840px) {
    .page-home .welcome-banner .amg-section-description {
      font-size: 60px; } }
  @media (min-width: 768px) {
    .page-home .welcome-banner {
      padding-top: 86pt; }
      .page-home .welcome-banner .amg-login-btn,
      .page-home .welcome-banner .amg-logout-btn,
      .page-home .welcome-banner .btn-my-account.amg-action-link .amg-button-container .amg-button {
        white-space: nowrap; }
      .page-home .welcome-banner > .amg-section-children > .amg-section-child:nth-child(1) .amg-sections .amg-section {
        top: 12pt;
        margin-bottom: 12pt; }
        .page-home .welcome-banner > .amg-section-children > .amg-section-child:nth-child(1) .amg-sections .amg-section .amg-nav-link {
          font-size: 12pt; }
      .page-home .welcome-banner > .amg-section-children > .amg-section-child:nth-child(2) .amg-sections .amg-section {
        top: 26pt; } }
  @media (min-width: 1200px) {
    .page-home .welcome-banner {
      padding-top: 100pt; }
      .page-home .welcome-banner,
      .page-home .welcome-banner + .fixture-section {
        height: 100vh;
        min-height: 700px; }
      .page-home .welcome-banner + .fixture-section {
        position: absolute;
        top: 0;
        right: 0;
        overflow: hidden;
        background: #0E2A51; }
        .page-home .welcome-banner + .fixture-section .amg-section-title {
          text-align: left;
          font-size: 16pt;
          background: #FFFFFF; }
        .page-home .welcome-banner + .fixture-section .amg-section-children {
          display: block; }
          .page-home .welcome-banner + .fixture-section .amg-section-children .amg-section-child {
            display: block;
            height: auto;
            height: initial; } }
  @media (min-width: 1920px) {
    .page-home .welcome-banner {
      padding-top: 120pt; }
      .page-home .welcome-banner > .amg-section-children > .amg-section-child:nth-child(1) .amg-sections .amg-section {
        top: 20pt;
        margin-bottom: 20pt; }
        .page-home .welcome-banner > .amg-section-children > .amg-section-child:nth-child(1) .amg-sections .amg-section .amg-nav-link {
          font-size: 14pt;
          margin: 0 0 0 16px; }
        .page-home .welcome-banner > .amg-section-children > .amg-section-child:nth-child(1) .amg-sections .amg-section .amg-social-links .amg-icon-image {
          max-width: 24pt;
          max-height: 24pt; }
      .page-home .welcome-banner > .amg-section-children > .amg-section-child:nth-child(2) .amg-sections .amg-section {
        top: 40pt; }
        .page-home .welcome-banner > .amg-section-children > .amg-section-child:nth-child(2) .amg-sections .amg-section .nav-link {
          font-size: 18pt;
          margin: 0 16px; }
        .page-home .welcome-banner > .amg-section-children > .amg-section-child:nth-child(2) .amg-sections .amg-section .amg-login-btn,
        .page-home .welcome-banner > .amg-section-children > .amg-section-child:nth-child(2) .amg-sections .amg-section .amg-logout-btn,
        .page-home .welcome-banner > .amg-section-children > .amg-section-child:nth-child(2) .amg-sections .amg-section .btn-my-account.amg-action-link .amg-button-container .amg-button {
          font-size: 12pt;
          margin: 0 0 0 16px; }
        .page-home .welcome-banner > .amg-section-children > .amg-section-child:nth-child(2) .amg-sections .amg-section .btn-my-account.amg-action-link .amg-button-container .amg-button {
          width: 140px; }
        .page-home .welcome-banner > .amg-section-children > .amg-section-child:nth-child(2) .amg-sections .amg-section .amg-icon .amg-icon-image {
          max-width: 250px; }
    .page-home .amg-input-field-container {
      margin: 0 0 0 16px; } }
  @media (min-width: 3840px) {
    .page-home .welcome-banner {
      padding-top: 256px; }
      .page-home .welcome-banner > .amg-section-children > .amg-section-child:nth-child(1) .amg-sections .amg-section {
        bottom: calc(100vh - 80pt); }
        .page-home .welcome-banner > .amg-section-children > .amg-section-child:nth-child(1) .amg-sections .amg-section .amg-nav-link {
          font-size: 24pt;
          margin: 0 0 0 32px; }
        .page-home .welcome-banner > .amg-section-children > .amg-section-child:nth-child(1) .amg-sections .amg-section .amg-social-links .amg-icon-image {
          max-width: 48pt;
          max-height: 48pt; }
      .page-home .welcome-banner > .amg-section-children > .amg-section-child:nth-child(2) .amg-sections .amg-section {
        bottom: calc(100vh - 200pt); }
        .page-home .welcome-banner > .amg-section-children > .amg-section-child:nth-child(2) .amg-sections .amg-section .nav-link {
          font-size: 35px;
          margin: 0 32px; }
        .page-home .welcome-banner > .amg-section-children > .amg-section-child:nth-child(2) .amg-sections .amg-section .amg-login-btn,
        .page-home .welcome-banner > .amg-section-children > .amg-section-child:nth-child(2) .amg-sections .amg-section .amg-logout-btn,
        .page-home .welcome-banner > .amg-section-children > .amg-section-child:nth-child(2) .amg-sections .amg-section .btn-my-account.amg-action-link .amg-button-container .amg-button {
          font-size: 24pt;
          margin: 0 0 0 32px; }
        .page-home .welcome-banner > .amg-section-children > .amg-section-child:nth-child(2) .amg-sections .amg-section .amg-login-btn,
        .page-home .welcome-banner > .amg-section-children > .amg-section-child:nth-child(2) .amg-sections .amg-section .amg-logout-btn {
          width: 160px; }
        .page-home .welcome-banner > .amg-section-children > .amg-section-child:nth-child(2) .amg-sections .amg-section .btn-my-account.amg-action-link .amg-button-container .amg-button {
          width: 250px; }
        .page-home .welcome-banner > .amg-section-children > .amg-section-child:nth-child(2) .amg-sections .amg-section .amg-icon .amg-icon-image {
          max-width: 500px; }
        .page-home .welcome-banner > .amg-section-children > .amg-section-child:nth-child(2) .amg-sections .amg-section .amg-input-field-container,
        .page-home .welcome-banner > .amg-section-children > .amg-section-child:nth-child(2) .amg-sections .amg-section .amg-input-field {
          height: 50px; }
        .page-home .welcome-banner > .amg-section-children > .amg-section-child:nth-child(2) .amg-sections .amg-section .amg-input-field-container {
          background-size: auto 50px;
          width: 50px;
          margin-left: 60px; }
          .page-home .welcome-banner > .amg-section-children > .amg-section-child:nth-child(2) .amg-sections .amg-section .amg-input-field-container .amg-input-field {
            padding-top: 30pt;
            padding-bottom: 30pt;
            height: 40pt;
            width: 360pt; }
            .page-home .welcome-banner > .amg-section-children > .amg-section-child:nth-child(2) .amg-sections .amg-section .amg-input-field-container .amg-input-field:not(:focus) {
              max-width: 50pt; }
      .page-home .welcome-banner .amg-section-title,
      .page-home .welcome-banner .amg-section-description {
        margin-bottom: 32px; }
      .page-home .welcome-banner .amg-input-field-container {
        margin: 0 0 0 32px; }
      .page-home .welcome-banner + .fixture-section .amg-action-link .amg-button-container .amg-button {
        font-size: 1rem; } }
  @media (min-width: 1200px) {
    .page-home .welcome-banner {
      width: calc(100% - 28%); }
      .page-home .welcome-banner + .fixture-section {
        width: 28%; }
      .page-home .welcome-banner > .amg-section-children > .amg-section-child:nth-child(1) .amg-sections .amg-section, .page-home .welcome-banner > .amg-section-children > .amg-section-child:nth-child(2) .amg-sections .amg-section {
        right: 0; } }
  @media (min-width: 1280px) {
    .page-home .welcome-banner {
      width: calc(100% - 27%); }
      .page-home .welcome-banner + .fixture-section {
        width: 27%; }
      .page-home .welcome-banner > .amg-section-children > .amg-section-child:nth-child(1) .amg-sections .amg-section, .page-home .welcome-banner > .amg-section-children > .amg-section-child:nth-child(2) .amg-sections .amg-section {
        right: 0; } }
  @media (min-width: 1920px) {
    .page-home .welcome-banner {
      width: calc(100% - 20%); }
      .page-home .welcome-banner + .fixture-section {
        width: 20%; }
      .page-home .welcome-banner > .amg-section-children > .amg-section-child:nth-child(1) .amg-sections .amg-section, .page-home .welcome-banner > .amg-section-children > .amg-section-child:nth-child(2) .amg-sections .amg-section {
        right: 0; } }
  @media (min-width: 3840px) {
    .page-home .welcome-banner {
      width: calc(100% - 18%); }
      .page-home .welcome-banner + .fixture-section {
        width: 18%; }
      .page-home .welcome-banner > .amg-section-children > .amg-section-child:nth-child(1) .amg-sections .amg-section, .page-home .welcome-banner > .amg-section-children > .amg-section-child:nth-child(2) .amg-sections .amg-section {
        right: 0; } }

.page-browse .amg-section.page-browse {
  width: 100%; }

.page-browse amg-wp-content-projection amg-wp-page > .amg-section > .amg-section-children {
  padding: 0; }

.page-faq {
  background: #2B5697; }
  .page-faq,
  .page-faq .amg-section,
  .page-faq .amg-section-children,
  .page-faq .amg-section-child,
  .page-faq .amg-input-field-container {
    display: block;
    height: auto; }
  .page-faq .amg-input-field-container .amg-input-field, .page-faq .amg-input-field-container .amg-text-area, .page-faq .amg-input-field-container .amg-select-field {
    border: none; }
  .page-faq .amg-input-field-container .amg-text-area {
    height: 150px; }
  .page-faq > *:not(:first-child) {
    padding-top: 20px;
    padding-bottom: 20px; }
    .page-faq > *:not(:first-child) .amg-section-title {
      color: #ffffff;
      font-size: 23px;
      margin-top: 20px;
      text-align: left; }
    .page-faq > *:not(:first-child) .amg-section-description {
      color: #ffffff;
      font-size: 18px;
      text-align: left; }
  .page-faq .faq-container > .amg-section-children {
    flex-direction: column;
    height: auto; }
    .page-faq .faq-container > .amg-section-children > .amg-section-child {
      height: auto; }
    .page-faq .faq-container > .amg-section-children .amg-collapsible {
      margin: 10px 0 0;
      width: 100%; }
      .page-faq .faq-container > .amg-section-children .amg-collapsible.opened .card-header {
        border-bottom: 1px solid rgba(0, 0, 0, 0.125); }
        .page-faq .faq-container > .amg-section-children .amg-collapsible.opened .card-header button:after {
          transform: rotate(90deg); }
      .page-faq .faq-container > .amg-section-children .amg-collapsible .card-header {
        border-bottom: none;
        padding: 30px 15px; }
        .page-faq .faq-container > .amg-section-children .amg-collapsible .card-header .amg-collapsible-titles {
          width: calc(100% - 42px); }
        .page-faq .faq-container > .amg-section-children .amg-collapsible .card-header .amg-collapsible-title {
          font-size: 18px; }
        .page-faq .faq-container > .amg-section-children .amg-collapsible .card-header button {
          text-indent: -9000px;
          position: relative;
          width: 32px;
          height: 32px;
          padding: 5px; }
          .page-faq .faq-container > .amg-section-children .amg-collapsible .card-header button:before, .page-faq .faq-container > .amg-section-children .amg-collapsible .card-header button:after {
            content: '';
            position: absolute;
            background-color: #BD9B60; }
          .page-faq .faq-container > .amg-section-children .amg-collapsible .card-header button:before {
            height: 2px;
            width: 20px;
            left: 5px;
            top: calc(50% - 1px); }
          .page-faq .faq-container > .amg-section-children .amg-collapsible .card-header button:after {
            height: 20px;
            width: 2px;
            transition: all .5s;
            transform: rotate(0deg);
            top: 5px;
            left: calc(50% - 1px); }
      .page-faq .faq-container > .amg-section-children .amg-collapsible > .card-body {
        padding: 10px;
        font-size: 13px; }
        .page-faq .faq-container > .amg-section-children .amg-collapsible > .card-body > div {
          margin: 0 0 10px; }
          .page-faq .faq-container > .amg-section-children .amg-collapsible > .card-body > div:last-child {
            margin: 0; }
        .page-faq .faq-container > .amg-section-children .amg-collapsible > .card-body .amg-card-text {
          border: none; }
          .page-faq .faq-container > .amg-section-children .amg-collapsible > .card-body .amg-card-text .card-body {
            padding: 0; }
  .page-faq .form-container {
    font-size: 18px;
    padding-bottom: 60px; }
    .page-faq .form-container .amg-section-description {
      margin-bottom: 20px; }
    .page-faq .form-container > .amg-section-children > .amg-section-child {
      justify-content: start; }
    .page-faq .form-container .amg-form-group form {
      background-color: #588BBC;
      border-radius: 5px;
      height: auto; }
    .page-faq .form-container .amg-form-group .amg-form-message,
    .page-faq .form-container .amg-form-group .amg-form-input-group {
      padding: 18px;
      margin: 0; }
      .page-faq .form-container .amg-form-group .amg-form-message .amg-input-field-container,
      .page-faq .form-container .amg-form-group .amg-form-input-group .amg-input-field-container {
        align-items: normal; }
        .page-faq .form-container .amg-form-group .amg-form-message .amg-input-field-container > *,
        .page-faq .form-container .amg-form-group .amg-form-input-group .amg-input-field-container > * {
          display: block;
          width: 100%; }
        .page-faq .form-container .amg-form-group .amg-form-message .amg-input-field-container .amg-input-field-label,
        .page-faq .form-container .amg-form-group .amg-form-input-group .amg-input-field-container .amg-input-field-label {
          color: #ffffff; }
    .page-faq .form-container .amg-form-group .amg-form-message .message-error,
    .page-faq .form-container .amg-form-group .amg-form-message .message-success {
      width: auto;
      color: #000000; }
    .page-faq .form-container .amg-form-group .amg-form-message .message-success:before {
      content: "Form has been submitted..."; }
    .page-faq .form-container .amg-form-group .amg-form-message .message-error:before {
      content: "Error while submitting form..."; }
    .page-faq .form-container .amg-form-group .amg-form-button-container {
      padding: 0 18px 18px;
      text-align: left; }
      .page-faq .form-container .amg-form-group .amg-form-button-container button {
        text-transform: uppercase;
        border: none;
        background-color: #BD9B60;
        font-size: 15px;
        border-radius: 20px; }
  @media (min-width: 768px) {
    .page-faq {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap; }
      .page-faq > .navigation-container {
        width: 100%; }
      .page-faq > *:not(:first-child) {
        padding-bottom: 60px;
        width: 50%; }
        .page-faq > *:not(:first-child) .amg-section-title {
          font-size: 28px; }
        .page-faq > *:not(:first-child) .amg-section-description {
          font-size: 20px; }
      .page-faq .faq-container {
        padding-right: 8px; }
        .page-faq .faq-container .amg-section-title {
          margin-bottom: 24px; }
      .page-faq .form-container {
        padding-left: 8px; } }
  @media (min-width: 1280px) {
    #page-container .page-faq .faq-container {
      padding-left: 140px;
      padding-right: 70px; }
      #page-container .page-faq .faq-container > .amg-section-children .amg-collapsible > .card-body {
        font-size: 15px; }
    #page-container .page-faq .form-container {
      padding-right: 140px;
      padding-left: 70px; } }
  @media (min-width: 1920px) {
    .page-faq {
      /* > *:not(:first-child) {

      padding-left: 140px;
      padding-right: 140px;
    }*/ }
      .page-faq .faq-container > .amg-section-children .amg-collapsible .card-header .amg-collapsible-title {
        font-size: 22px; }
      .page-faq .faq-container > .amg-section-children .amg-collapsible > .card-body {
        font-size: 18px; }
      .page-faq .amg-input-field-container .amg-text-area {
        height: 250px; } }

.page-live,
.page-live .amg-section,
.page-live .amg-section-children,
.page-live .amg-section-child,
.page-live .amg-input-field-container,
.page-live amg-wp-subscribe-packages {
  display: block;
  height: auto; }

.page-live .amg-fixtures .amg-message-container {
  color: #000000; }

.page-live .navigation-container .amg-section-description,
.page-live .navigation-container .amg-section-title {
  font-weight: bold;
  color: #FFFFFF;
  text-align: center; }

.page-live .whats-next-container,
.page-live .next-live-container {
  margin-top: 2em !important; }
  .page-live .whats-next-container .amg-section-children,
  .page-live .whats-next-container .amg-section-description,
  .page-live .whats-next-container .amg-section-title,
  .page-live .next-live-container .amg-section-children,
  .page-live .next-live-container .amg-section-description,
  .page-live .next-live-container .amg-section-title {
    padding-left: 0 !important;
    padding-right: 0 !important; }
  .page-live .whats-next-container .amg-section-description,
  .page-live .whats-next-container .amg-section-title,
  .page-live .next-live-container .amg-section-description,
  .page-live .next-live-container .amg-section-title {
    text-align: left;
    font-size: 1em !important; }

.page-live .next-live-container .amg-section-title {
  color: #2F2F2F !important;
  margin-bottom: 0.8em; }

.page-live .whats-next-container {
  position: relative; }
  .page-live .whats-next-container .amg-section-title {
    color: #BD9B60;
    font-size: 14pt;
    font-weight: bold; }
  .page-live .whats-next-container .amg-section-description {
    color: #2F2F2F !important;
    margin-bottom: 0.8em;
    font-weight: bold; }
  .page-live .whats-next-container .amg-section-children {
    flex-direction: column; }
    .page-live .whats-next-container .amg-section-children .amg-section-child:first-child {
      order: 2; }
      .page-live .whats-next-container .amg-section-children .amg-section-child:first-child .amg-button-container {
        margin: 0 auto; }
    .page-live .whats-next-container .amg-section-children .amg-section-child:last-child {
      margin-bottom: 0.8em;
      order: 1; }

.page-live .subscription-packages-container .amg-payment-fields-group {
  width: 100%; }
  .page-live .subscription-packages-container .amg-payment-fields-group .amg-subscription-packages {
    margin-bottom: 0; }
    .page-live .subscription-packages-container .amg-payment-fields-group .amg-subscription-packages .amg-subscription-package {
      background: url("~src/assets/images/backgournd-video-player-300x300.png") no-repeat;
      background-size: 100% 100%; }
      .page-live .subscription-packages-container .amg-payment-fields-group .amg-subscription-packages .amg-subscription-package .amg-icon-placeholder {
        background-size: 50px 50px;
        background: url("~src/assets/images/icon-video-white.svg") no-repeat center;
        color: white;
        height: 45px;
        width: 80px;
        position: absolute;
        top: 5%;
        left: 50%;
        margin-left: -40px; }
      .page-live .subscription-packages-container .amg-payment-fields-group .amg-subscription-packages .amg-subscription-package .amg-subscription-package-inner {
        background-color: transparent; }
      .page-live .subscription-packages-container .amg-payment-fields-group .amg-subscription-packages .amg-subscription-package .amg-title,
      .page-live .subscription-packages-container .amg-payment-fields-group .amg-subscription-packages .amg-subscription-package .amg-amount,
      .page-live .subscription-packages-container .amg-payment-fields-group .amg-subscription-packages .amg-subscription-package .amg-interval-group,
      .page-live .subscription-packages-container .amg-payment-fields-group .amg-subscription-packages .amg-subscription-package .amg-body,
      .page-live .subscription-packages-container .amg-payment-fields-group .amg-subscription-packages .amg-subscription-package .amg-button {
        color: #FFFFFF !important; }

.page-live > *:not(.navigation-container) {
  margin-bottom: 16px; }

.page-live .card-subscription {
  margin-bottom: 16px; }
  .page-live .card-subscription.card-watch .amg-icon-image {
    width: 42pt;
    height: 31pt; }
  .page-live .card-subscription.card-listen .amg-icon-image {
    width: 40pt;
    height: 40pt; }
  .page-live .card-subscription .amg-icon-image {
    font-weight: bold; }
  .page-live .card-subscription .amg-section-children {
    padding-top: 16px; }
    .page-live .card-subscription .amg-section-children > .amg-section-child:nth-child(1) {
      height: auto; }
    .page-live .card-subscription .amg-section-children .amg-button {
      color: #FFFFFF; }

@media (min-width: 768px) {
  .page-live {
    /* display: flex;
     flex-direction: row;
     flex-wrap: wrap;*/
    /*

    > * {
      flex: 0 0 100%;
      width: 100%;
    }
    */ }
    .page-live .card-subscription {
      flex: 0 0 calc(50% - 32px);
      width: 50%; }
      .page-live .card-subscription:nth-child(3) {
        margin-right: 8px; }
      .page-live .card-subscription:nth-child(4) {
        margin-left: 8px; } }

@media (min-width: 1920px) {
  .page-live .amg-subscription-packages .amg-subscription-package:nth-child(odd) {
    margin-right: 70px; }
  .page-live .amg-subscription-packages .amg-subscription-package:nth-child(even) {
    margin-left: 70px; } }

@media (max-width: 767.98px) {
  .page-live .whats-next-container .amg-section-children {
    display: flex; } }

@media (min-width: 768px) {
  .page-live .whats-next-container .amg-section-children {
    flex-direction: column; }
    .page-live .whats-next-container .amg-section-children .amg-section-child:first-child {
      position: absolute;
      width: auto;
      height: auto;
      top: 0;
      right: 16px;
      order: 1; }
    .page-live .whats-next-container .amg-section-children .amg-section-child:last-child {
      order: 2; } }

@media (min-width: 1280px) {
  .page-live .whats-next-container .amg-section-children .amg-section-child:first-child {
    right: 140px; } }

.amg-toggler {
  position: relative;
  display: inline-block;
  width: 37.5pt;
  height: 18pt;
  margin: auto 0 auto 8px;
  /* Rounded sliders */ }
  .amg-toggler input {
    opacity: 0;
    width: 0;
    height: 0; }
  .amg-toggler .slider {
    cursor: pointer;
    background-color: #ccc;
    -webkit-transition: .4s;
    transition: .4s; }
    .amg-toggler .slider:before {
      position: absolute;
      content: "";
      height: 13pt;
      width: 13pt;
      left: 4pt;
      bottom: 2.5pt;
      background-color: white;
      -webkit-transition: .4s;
      transition: .4s; }
  .amg-toggler input:checked + .slider {
    background-color: #2196F3; }
  .amg-toggler input:focus + .slider {
    box-shadow: 0 0 1px #2196F3; }
  .amg-toggler input:checked + .slider:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px); }
  .amg-toggler .slider.round {
    border-radius: 34px; }
  .amg-toggler .slider.round:before {
    border-radius: 50%; }

.amg-category-wrapper .amg-video-category:not(:first-child):before {
  content: ", "; }

.amg-play-btn.video-icon {
  background: url("~src/assets/images/card-video-play.svg") center no-repeat;
  background-size: contain; }

.amg-play-btn.audio-icon {
  background: "~src/assets/images/card-video-play.svg"; }

.amg-video-meta-data {
  display: flex;
  align-items: center;
  width: 100%; }
  .amg-video-meta-data > * {
    margin: auto 8px; }
    .amg-video-meta-data > *:first-child {
      margin-left: 0; }
  .amg-video-meta-data .amg-membership-icon {
    width: 32pt;
    height: 32pt;
    display: inline-block; }
    .amg-video-meta-data .amg-membership-icon.free {
      background: url("~src/assets/images/membership-icon-free.svg") no-repeat center; }
    .amg-video-meta-data .amg-membership-icon.paid {
      background: url("~src/assets/images/membership-icon-paid.svg") no-repeat center; }
    .amg-video-meta-data .amg-membership-icon.registered {
      background: url("~src/assets/images/membership-icon-registered.svg") no-repeat center; }

.amg-live-icons {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 108px;
  height: 36px; }
  .amg-live-icons .amg-live-icon {
    width: 100%;
    height: 100%;
    display: inline-block; }
    .amg-live-icons .amg-live-icon.video {
      background: url("~src/assets/images/live-icon-video.svg") no-repeat right; }
    .amg-live-icons .amg-live-icon.audio {
      background: url("~src/assets/images/live-icon-audio.svg") no-repeat right; }

.amg-thumbnail-overlay-text {
  font-weight: 600;
  font-size: 10pt;
  display: none;
  justify-content: flex-start;
  align-items: flex-end;
  padding: 6pt;
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.65) 100%); }

.active .amg-thumbnail-overlay-text {
  display: flex; }

app-video > amg-wp-page > amg-wp-section:not(:first-child) {
  padding-right: 16px;
  padding-left: 16px; }
  @media (min-width: 1200px) {
    app-video > amg-wp-page > amg-wp-section:not(:first-child) {
      padding-left: 16px;
      padding-right: 16px; } }

app-video .amg-page .amg-section .amg-section-children {
  padding: 0px;
  margin: 0px;
  max-width: none; }

app-video .amg-payment-fields-group,
app-video .amg-video-player {
  width: 100%; }

amg-playlist-card.active .amg-thumbnail-overlay .amg-play-btn {
  display: none; }

amg-playlist-card .amg-playlist-thumbnail-wrapper {
  position: relative; }
  amg-playlist-card .amg-playlist-thumbnail-wrapper .amg-video-duration-overlay {
    display: none; }
  amg-playlist-card .amg-playlist-thumbnail-wrapper .amg-fixture-thumbnail {
    background-image: none;
    background-position: center center;
    background-repeat: no-repeat;
    background-color: transparent;
    background-size: 100% auto;
    padding-top: 56.25%;
    position: relative;
    pointer-events: none;
    transition: background-size 0.333s ease-in-out; }
  amg-playlist-card .amg-playlist-thumbnail-wrapper .amg-thumbnail-overlay .amg-play-btn {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 37pt;
    height: 37pt;
    margin: 6pt; }

.amg-video-player-component {
  background: #0E2A51; }
  .amg-video-player-component.amg-mobile-view {
    position: relative;
    height: inherit !important; }
    .amg-video-player-component.amg-mobile-view .amg-player-content-wrapper .amg-video-details {
      padding: 16px 16px; }
      .amg-video-player-component.amg-mobile-view .amg-player-content-wrapper .amg-video-details .amg-title {
        font-size: 15.75pt; }
      .amg-video-player-component.amg-mobile-view .amg-player-content-wrapper .amg-video-details .amg-description {
        font-size: 12pt; }
    .amg-video-player-component.amg-mobile-view .amg-playlist-items .amg-playlist-item .amg-left {
      max-width: 33%; }
    .amg-video-player-component.amg-mobile-view .amg-playlist-items .amg-playlist-item .amg-playlist-row {
      padding: 16px; }
      .amg-video-player-component.amg-mobile-view .amg-playlist-items .amg-playlist-item .amg-playlist-row > * {
        flex: 1; }

amg-playlist {
  position: relative; }

.amg-sidebar-wrapper {
  overflow: hidden;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  flex-direction: column; }
  @media (min-width: 768px) {
    .amg-sidebar-wrapper {
      position: absolute; } }

.amg-sidebar {
  color: #ffffff;
  height: 100%;
  display: flex;
  flex-direction: column;
  background-color: #121922;
  overflow-x: hidden;
  overflow-y: scroll;
  margin-right: -17px; }
  .amg-sidebar .amg-playlist-wrapper {
    background-color: #050E1A;
    padding-right: 17px; }
  .amg-sidebar .amg-title {
    font-size: 21pt; }
  .amg-sidebar .amg-subtitle {
    font-weight: 600;
    font-size: 15pt; }
  .amg-sidebar .amg-playlist-items {
    color: #ffffff; }
    .amg-sidebar .amg-playlist-items .amg-playlist-item .amg-left {
      min-width: 50%; }
    .amg-sidebar .amg-playlist-items .amg-playlist-item .amg-title {
      color: #BD9B60; }
    .amg-sidebar .amg-playlist-items .more-items-loader {
      display: none; }
      .amg-sidebar .amg-playlist-items .more-items-loader.collapse {
        display: none; }

amg-wp-video-player {
  overflow: hidden;
  min-height: 540px; }

.amg-video-player-component {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  position: relative; }
  .amg-video-player-component .amg-title {
    font-size: 14pt; }
  .amg-video-player-component .amg-title,
  .amg-video-player-component .amg-video-category,
  .amg-video-player-component .amg-description {
    text-transform: uppercase; }
  .amg-video-player-component .amg-category-wrapper {
    font-size: 10pt;
    font-weight: 800; }
  .amg-video-player-component .amg-title {
    font-weight: 800;
    font-size: 13pt; }
  .amg-video-player-component .amg-description {
    color: #BD9B60;
    font-weight: bold;
    font-size: 15pt; }
  .amg-video-player-component .amg-date {
    font-size: 10pt;
    vertical-align: middle; }
    .amg-video-player-component .amg-date > * {
      margin: 1pt; }
    .amg-video-player-component .amg-date .amg-inline-separator {
      display: inline-block;
      width: 1pt;
      height: 9pt;
      margin: auto 4pt;
      background-color: #ffffff; }
  .amg-video-player-component .amg-player-content-wrapper {
    margin-right: 16px;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly; }
    .amg-video-player-component .amg-player-content-wrapper .amg-duration {
      display: none; }
    .amg-video-player-component .amg-player-content-wrapper .amg-video-details {
      color: #ffffff;
      padding: 16px 0; }
      .amg-video-player-component .amg-player-content-wrapper .amg-video-details > * {
        margin: 4px auto 4px 0; }
      .amg-video-player-component .amg-player-content-wrapper .amg-video-details .amg-title {
        font-weight: bold;
        font-size: 21pt;
        letter-spacing: 1pt; }
      .amg-video-player-component .amg-player-content-wrapper .amg-video-details .amg-description {
        color: #ffffff;
        font-size: 18pt;
        text-transform: none; }
  .amg-video-player-component .amg-video-player {
    justify-content: flex-end;
    background-color: #000;
    position: relative;
    padding-bottom: 56.25%;
    display: block;
    width: 100%; }
    .amg-video-player-component .amg-video-player > * {
      display: flex;
      align-items: center; }
    .amg-video-player-component .amg-video-player img {
      display: inline-block;
      width: auto;
      height: 100%;
      margin: auto; }
    .amg-video-player-component .amg-video-player .amg-play-btn-wrapper {
      top: auto;
      right: auto;
      padding: 16px; }
      .amg-video-player-component .amg-video-player .amg-play-btn-wrapper .amg-play-btn {
        height: 50pt;
        width: 50pt; }
  .amg-video-player-component .amg-video-thumbnail-wrapper.amg-video-icon:before, .amg-video-player-component .amg-video-thumbnail-wrapper.amg-audio-icon:before {
    pointer-events: none;
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-size: 48px; }
  .amg-video-player-component .amg-video-thumbnail-wrapper.amg-video-icon:before {
    background: url("~src/assets/images/card-video-play.svg") 10px calc(100% - 10px) no-repeat transparent; }
  .amg-video-player-component .amg-video-thumbnail-wrapper.amg-audio-icon:before {
    background: url("~src/assets/images/card-video-play.svg") 10px calc(100% - 10px) no-repeat transparent; }
  .amg-video-player-component .amg-video-login-overlay {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 16px;
    z-index: 0; }
    .amg-video-player-component .amg-video-login-overlay > * {
      margin-bottom: 10.66666667px; }
      .amg-video-player-component .amg-video-login-overlay > *:last-child {
        margin-top: 10.66666667px; }
      @media (min-width: 376px) {
        .amg-video-player-component .amg-video-login-overlay > * {
          margin-bottom: 16px; }
          .amg-video-player-component .amg-video-login-overlay > *:last-child {
            margin-top: 16px; } }
    .amg-video-player-component .amg-video-login-overlay .amg-title {
      font-weight: bold;
      font-size: 16.66666667pt; }
      @media (min-width: 376px) {
        .amg-video-player-component .amg-video-login-overlay .amg-title {
          font-size: 18.75pt; } }
    .amg-video-player-component .amg-video-login-overlay .amg-sub-title {
      font-size: 12.66666667pt; }
      @media (min-width: 376px) {
        .amg-video-player-component .amg-video-login-overlay .amg-sub-title {
          font-size: 14.75pt; } }
    .amg-video-player-component .amg-video-login-overlay .amg-btn-wrapper {
      display: flex; }
      .amg-video-player-component .amg-video-login-overlay .amg-btn-wrapper .btn {
        color: #ffffff;
        border-radius: 20pt;
        height: inherit;
        font-size: 14px;
        padding-left: 7pt;
        padding-right: 7pt;
        border: solid 1px transparent;
        transition: all ease-in-out .25s;
        min-height: 36px; }
        .amg-video-player-component .amg-video-login-overlay .amg-btn-wrapper .btn.amg-subscribe-btn {
          background-color: #BD9B60; }
          .amg-video-player-component .amg-video-login-overlay .amg-btn-wrapper .btn.amg-subscribe-btn:hover {
            background-color: #fff;
            border: solid 1px #BD9B60; }
        .amg-video-player-component .amg-video-login-overlay .amg-btn-wrapper .btn.amg-log-in-btn {
          background-color: #588BBC; }
          .amg-video-player-component .amg-video-login-overlay .amg-btn-wrapper .btn.amg-log-in-btn:hover {
            background-color: #fff;
            border: solid 1px #588BBC; }
    @media (min-width: 769px) {
      .amg-video-player-component .amg-video-login-overlay .amg-btn-wrapper .btn {
        height: 40pt;
        font-size: 16px;
        padding-left: 17pt;
        padding-right: 17pt; }
      .amg-video-player-component .amg-video-login-overlay .amg-title {
        font-size: 25pt; }
      .amg-video-player-component .amg-video-login-overlay .amg-sub-title {
        font-size: 21pt; } }
  .amg-video-player-component .amg-playlist-row {
    display: flex;
    padding: 16px;
    border-top: solid 1px #BD9B60;
    max-width: 100%;
    background-color: transparent;
    transition: background-color 0.333s ease-in-out; }
    .amg-video-player-component .amg-playlist-row:hover {
      background-color: black; }
      .amg-video-player-component .amg-playlist-row:hover .amg-playlist-thumbnail-wrapper .amg-fixture-thumbnail {
        background-size: 120% auto; }
    .amg-video-player-component .amg-playlist-row > * {
      flex: 1;
      cursor: pointer; }
    .amg-video-player-component .amg-playlist-row:first-child {
      margin-top: 0; }
    .amg-video-player-component .amg-playlist-row .amg-middle {
      margin-left: 16px; }
      .amg-video-player-component .amg-playlist-row .amg-middle > * {
        margin: 4pt 0; }
        .amg-video-player-component .amg-playlist-row .amg-middle > *:first-child {
          margin-top: 0; }
        .amg-video-player-component .amg-playlist-row .amg-middle > *:last-child {
          margin-bottom: 0; }
    .amg-video-player-component .amg-playlist-row img {
      max-width: 100%; }
  .amg-video-player-component.amg-live-player .amg-playlist-row .amg-left {
    display: none; }
  .amg-video-player-component.amg-live-player .amg-playlist-row .amg-middle {
    margin-left: 0; }
  .amg-video-player-component.amg-live-player .amg-playlist-row .amg-right {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin: auto 4pt;
    flex: 1; }
  .amg-video-player-component.amg-live-player .amg-playlist-item {
    cursor: pointer; }
    .amg-video-player-component.amg-live-player .amg-playlist-item amg-thumbnail {
      display: none; }
    .amg-video-player-component.amg-live-player .amg-playlist-item amg-live-icons {
      flex: none; }
    .amg-video-player-component.amg-live-player .amg-playlist-item .amg-title {
      color: #ffffff; }
  @media (min-width: 769px) {
    .amg-video-player-component {
      flex-direction: row;
      padding-left: 16px; }
      .amg-video-player-component .amg-video-player {
        margin-top: 16px; }
      .amg-video-player-component .amg-player-content-wrapper {
        flex: 1;
        flex-grow: 3;
        justify-content: flex-start; }
      .amg-video-player-component amg-playlist {
        flex: 1;
        flex-shrink: 1; } }
  @media (min-width: 769px) and (max-width: 1279.98px) {
    .amg-video-player-component .amg-sidebar .amg-playlist-row {
      flex-direction: column; }
      .amg-video-player-component .amg-sidebar .amg-playlist-row .amg-left {
        margin-bottom: 16px;
        width: auto; }
      .amg-video-player-component .amg-sidebar .amg-playlist-row .amg-middle {
        margin: 0; } }

.amg-sidebar-header {
  color: #ffffff;
  background-color: #121922;
  padding: 16px 32px 16px 16px;
  display: flex;
  text-transform: uppercase;
  justify-content: space-between; }
  .amg-sidebar-header .amg-title {
    min-width: 94px; }
  .amg-sidebar-header .amg-title,
  .amg-sidebar-header .amg-subtitle {
    font-size: 11.25pt; }
  .amg-sidebar-header .amg-autoplay-wrapper .amg-autoplay {
    font-size: 10.5pt; }
  .amg-sidebar-header .amg-autoplay-wrapper {
    align-self: flex-end;
    display: flex;
    align-content: center;
    width: 136pt;
    margin-left: auto;
    align-items: center;
    font-weight: 600;
    margin-right: 16px; }
    @media (min-width: 768px) {
      .amg-sidebar-header .amg-autoplay-wrapper {
        margin-right: 0px; } }
  .amg-sidebar-header .amg-collapse-toggler {
    display: flex;
    font-size: 46pt;
    line-height: 23pt;
    color: #BD9B60;
    justify-content: center;
    align-items: center; }
    @media (min-width: 769px) {
      .amg-sidebar-header .amg-collapse-toggler {
        display: none; } }
  .amg-sidebar-header .amg-title {
    color: #BD9B60; }
  .amg-sidebar-header .amg-autoplay {
    font-size: 14pt;
    margin-left: auto; }
  @media (min-width: 769px) and (max-width: 1279.98px) {
    .amg-sidebar-header .amg-autoplay-wrapper {
      margin: 8px 0 0 auto; }
      .amg-sidebar-header .amg-autoplay-wrapper .amg-autoplay {
        margin: 0 auto 0 0; } }

.fixtures-page .navigation-container .amg-section-children {
  margin-left: 10%; }

.fixtures-page .fixtures-projection {
  padding-right: 16px;
  padding-left: 16px; }
  @media (min-width: 1200px) {
    .fixtures-page .fixtures-projection {
      padding-left: 16px;
      padding-right: 16px; } }
  .fixtures-page .fixtures-projection .amg-section-children {
    align-self: center;
    padding: 0;
    margin: 0;
    max-width: 100%; }

.fixtures-page .first-team-page .next-match-container,
.fixtures-page .first-team-page .calendar-container,
.fixtures-page .first-team-page .login-container {
  margin-top: 2em; }

.fixtures-page .first-team-page .next-match-container .amg-fixtures .amg-message-container {
  color: #000000; }

.fixtures-page .first-team-page .calendar-container .amg-section-child {
  background-color: #F4F4F4; }

.fixtures-page .first-team-page .calendar-container .amg-carousel-calendar .amg-carousel-calendar-tabs .amg-carousel-nav-container {
  position: relative; }

.fixtures-page .first-team-page .amg-section-title {
  text-align: left;
  font-size: 18pt !important;
  color: #2F2F2F !important;
  margin-bottom: 0.4em; }

.fixtures-page .first-team-page .login-container[is-logged-in="true"] {
  display: none; }

.fixtures-page .first-team-page .login-container .amg-section-title {
  font-weight: bold;
  font-size: 18pt !important; }

.fixtures-page .first-team-page .login-container .amg-text-field {
  color: #2B5697; }

.fixtures-page .first-team-page .login-container .amg-section-children {
  justify-content: left; }
  .fixtures-page .first-team-page .login-container .amg-section-children > * {
    display: inline;
    width: auto; }
  .fixtures-page .first-team-page .login-container .amg-section-children .amg-text-field {
    margin: 0 0.4em; }
  .fixtures-page .first-team-page .login-container .amg-section-children .btn {
    border: #588BBC 1pt solid;
    background-color: #588BBC;
    border-radius: 20pt;
    font-size: 0.5em; }
    .fixtures-page .first-team-page .login-container .amg-section-children .btn:hover {
      background-color: #FFFFFF;
      color: #588BBC; }
    .fixtures-page .first-team-page .login-container .amg-section-children .btn.amg-login-btn:hover {
      background: url("~src/assets/images/icon-user.svg") 12px center no-repeat transparent; }

.fixtures-page .first-team-page .amg-calendar .amg-carousel-calendar {
  padding-bottom: 5em; }
  .fixtures-page .first-team-page .amg-calendar .amg-carousel-calendar .amg-carousel-calendar-tabs {
    background-color: #FAFAFA; }

@media (min-width: 576px) {
  .fixtures-page .first-team-page .login-container .amg-section-children .btn {
    font-size: 0.8em; }
  .fixtures-page .first-team-page .login-container .amg-section-children .amg-text-field {
    margin: 0 0.5em; } }

@media (min-width: 768px) {
  .fixtures-page .navigation-container .amg-section-children {
    margin-left: 20%; } }

@media (min-width: 1200px) {
  .fixtures-page .navigation-container .amg-section-children {
    margin-left: 25%; } }

#page-container app-subscribe > amg-wp-page, #page-container app-subscribe > amg-wp-page *:not(span) {
  display: block;
  height: auto; }

#page-container app-subscribe > amg-wp-page .section-subscriptions {
  background-position: center;
  background-size: cover;
  padding-top: 32px;
  padding-bottom: 32px; }
  #page-container app-subscribe > amg-wp-page .section-subscriptions .amg-section-title {
    color: #2B5697; }
  #page-container app-subscribe > amg-wp-page .section-subscriptions .amg-section-description {
    color: #0E2A51;
    margin-bottom: 32px; }
  #page-container app-subscribe > amg-wp-page .section-subscriptions > .amg-section-children {
    width: 100%;
    margin: 0 auto; }
    @media (min-width: 992px) {
      #page-container app-subscribe > amg-wp-page .section-subscriptions > .amg-section-children {
        max-width: calc(50% - 16px); } }
    #page-container app-subscribe > amg-wp-page .section-subscriptions > .amg-section-children > .amg-section-child > .amg-sections {
      flex-wrap: wrap; }
  #page-container app-subscribe > amg-wp-page .section-subscriptions .amg-subscription-packages,
  #page-container app-subscribe > amg-wp-page .section-subscriptions .amg-payment-fields-group,
  #page-container app-subscribe > amg-wp-page .section-subscriptions .subscription-packages-container {
    width: 100%; }
  #page-container app-subscribe > amg-wp-page .section-subscriptions .amg-subscription-packages {
    display: flex; }
  #page-container app-subscribe > amg-wp-page .section-subscriptions .subscription-packages-container .amg-icon-placeholder {
    background-size: 50px 50px;
    background: #FFFFFF url("~src/assets/images/icon-video-beige.svg") no-repeat center;
    color: black;
    height: 55px;
    width: 90px;
    position: absolute;
    top: 0;
    left: 50%;
    margin-top: -27px;
    margin-left: -45px;
    border: 5px solid #FFFFFF; }
  #page-container app-subscribe > amg-wp-page .section-subscriptions .card-subscription {
    width: 100%;
    margin-bottom: 20px; }
    #page-container app-subscribe > amg-wp-page .section-subscriptions .card-subscription .amg-icon.title-icon {
      display: flex;
      justify-content: center; }
    #page-container app-subscribe > amg-wp-page .section-subscriptions .card-subscription .amg-icon-image {
      margin-top: 0;
      height: 46pt; }
    @media (min-width: 992px) {
      #page-container app-subscribe > amg-wp-page .section-subscriptions .card-subscription {
        width: calc(50% - 8px);
        flex: auto 50%;
        margin-top: 26px;
        margin-bottom: 16px; } }
    #page-container app-subscribe > amg-wp-page .section-subscriptions .card-subscription.card-subscription-free {
      width: 100%;
      flex: 0 1 100%; }
      #page-container app-subscribe > amg-wp-page .section-subscriptions .card-subscription.card-subscription-free .amg-section-children {
        padding: 64px 16px 16px; }

app-privacy-notice,
app-cookie-policy,
app-terms-and-conditions {
  max-width: 1280px;
  margin: 0 auto;
  padding-top: 32px;
  padding-bottom: 32px; }
  app-privacy-notice,
  app-privacy-notice .amg-section,
  app-privacy-notice .amg-section-children,
  app-privacy-notice .amg-section-child,
  app-cookie-policy,
  app-cookie-policy .amg-section,
  app-cookie-policy .amg-section-children,
  app-cookie-policy .amg-section-child,
  app-terms-and-conditions,
  app-terms-and-conditions .amg-section,
  app-terms-and-conditions .amg-section-children,
  app-terms-and-conditions .amg-section-child {
    display: block;
    height: auto; }
  app-privacy-notice .amg-section-title,
  app-cookie-policy .amg-section-title,
  app-terms-and-conditions .amg-section-title {
    color: #2B5697;
    font-size: 1.5em;
    margin: 0 0 30px; }
  app-privacy-notice .amg-section-description,
  app-cookie-policy .amg-section-description,
  app-terms-and-conditions .amg-section-description {
    text-align: left; }
    app-privacy-notice .amg-section-description > *,
    app-cookie-policy .amg-section-description > *,
    app-terms-and-conditions .amg-section-description > * {
      margin: 16px 0;
      text-align: left;
      font-size: .9em; }
    app-privacy-notice .amg-section-description h1,
    app-privacy-notice .amg-section-description h2,
    app-privacy-notice .amg-section-description h3,
    app-privacy-notice .amg-section-description h4,
    app-privacy-notice .amg-section-description h5,
    app-cookie-policy .amg-section-description h1,
    app-cookie-policy .amg-section-description h2,
    app-cookie-policy .amg-section-description h3,
    app-cookie-policy .amg-section-description h4,
    app-cookie-policy .amg-section-description h5,
    app-terms-and-conditions .amg-section-description h1,
    app-terms-and-conditions .amg-section-description h2,
    app-terms-and-conditions .amg-section-description h3,
    app-terms-and-conditions .amg-section-description h4,
    app-terms-and-conditions .amg-section-description h5 {
      margin-top: 30px;
      font-size: 1.2em; }
    app-privacy-notice .amg-section-description ul,
    app-cookie-policy .amg-section-description ul,
    app-terms-and-conditions .amg-section-description ul {
      margin-left: 32px;
      padding: 0;
      /*    li {
            padding: 0 0 $grid-padding-width;
          }*/ }
    app-privacy-notice .amg-section-description td,
    app-cookie-policy .amg-section-description td,
    app-terms-and-conditions .amg-section-description td {
      padding: 5px; }

.form-group, form {
  margin: 0 0 20px; }
  .form-group label, .form-group select,
  .form-group input:not([type='submit']), form label, form select,
  form input:not([type='submit']) {
    width: 100%;
    display: block; }
  .form-group label, form label {
    text-transform: uppercase;
    text-align: left;
    margin-bottom: 5px;
    font-size: 15px;
    font-weight: bold; }
  .form-group input, .form-group select, form input, form select {
    height: 40px;
    background-color: #F9F9F8;
    border: 1px solid #E6E7E8;
    border-radius: 5px;
    color: #495057; }
  .form-group input, form input {
    padding-left: 7px;
    padding-right: 7px; }
  .form-group select, form select {
    padding: 8px 14px 5px 7px; }
  .form-group .row > div, form .row > div {
    margin: 0 auto; }
  .form-group .form-control-static, form .form-control-static {
    margin: 5px 0 0;
    border-bottom: 1px solid #495057; }
  .form-group .validation-summary-errors,
  .form-group .field-validation-error,
  .form-group .field-error, form .validation-summary-errors,
  form .field-validation-error,
  form .field-error {
    margin-top: 3px;
    color: #d71011; }
  .form-group .validation-summary-errors ul, form .validation-summary-errors ul {
    list-style-type: none;
    padding-inline-start: 0; }

#menu-container {
  background-color: #161616;
  color: #FFFFFF;
  text-transform: uppercase;
  border-top: 1px solid #bd9b60; }
  #menu-container .close-menu {
    display: none; }
  #menu-container h1 {
    padding-right: 16px;
    padding-left: 16px;
    margin: 0;
    font-size: 32px;
    font-weight: bold;
    padding: 25px 30px;
    border-bottom: 1px solid #bd9b60; }
    @media (min-width: 1200px) {
      #menu-container h1 {
        padding-left: 16px;
        padding-right: 16px; } }
  #menu-container ul {
    list-style-type: none;
    padding-inline-start: 0; }
    #menu-container ul li {
      padding: 10px 20px;
      border-bottom: 1px solid #bd9b60; }
      #menu-container ul li.selected {
        background-color: #2b5697; }
      #menu-container ul li a {
        display: block;
        color: #FFFFFF;
        position: relative;
        padding: 15px 10px 15px 45px; }
        #menu-container ul li a:hover {
          text-decoration: none; }
        #menu-container ul li a:before {
          content: "";
          position: absolute;
          left: 0;
          width: 42px;
          height: 42px;
          top: 50%;
          transform: translateY(-50%);
          background-position: center;
          background-repeat: no-repeat;
          background-size: contain; }
      #menu-container ul li.your-profile a:before {
        background-image: url("/src/assets/images/payments/myaccount-my-profile.svg"); }
      #menu-container ul li.billing-details a:before {
        background-image: url("/src/assets/images/payments/myaccount-billing-details.svg"); }
      #menu-container ul li.active-subscriptions a:before {
        background-image: url("/src/assets/images/payments/myaccount-active-subscriptions.svg"); }
      #menu-container ul li.recent-transactions a:before {
        background-image: url("/src/assets/images/payments/myaccount-recent-transactions.svg"); }

@media (max-width: 991.98px) {
  html.menu-opened {
    overflow: hidden;
    height: 100%;
    width: 100%; }
  #menu-container {
    transition: all .7s;
    position: absolute;
    top: 200px;
    left: 0;
    right: 0;
    height: 0;
    overflow: hidden;
    z-index: 300; }
    #menu-container.opened {
      overflow: auto; }
    .menu-opened #menu-container {
      height: calc(100% - 200px);
      border-bottom: none; }
    #menu-container .close-menu {
      transform: rotate(-90deg);
      cursor: pointer;
      display: block;
      position: absolute;
      right: 20px;
      top: 30px;
      background-image: url("/src/assets/images/payments/myaccount-arrow-menu.svg");
      background-repeat: no-repeat;
      background-position: center;
      background-size: 12px 20px;
      width: 40px;
      height: 40px; } }

/*

@include media-breakpoint-between(lg, xl) {
  #menu-container {
    h1 {
      font-size: 32px;
    }
  }
}
*/
@media (min-width: 992px) {
  #menu-container {
    width: 30%; }
    #menu-container h1, #menu-container ul li {
      padding-right: 40px; } }

@media (min-width: 1200px) {
  #menu-container ul li {
    padding-left: 90px; } }

#ProgressSteps ol {
  margin: 0 -30px 20px !important;
  display: flex; }
  #ProgressSteps ol[data-progress-steps="3"] li {
    width: 32% !important; }

#ProgressSteps li {
  padding-top: 40px;
  background: center 10px no-repeat transparent;
  background-size: auto 16px;
  background-repeat: no-repeat;
  list-style: none; }
  @media (min-width: 768px) {
    #ProgressSteps li {
      background-position: center 14px; } }
  #ProgressSteps li:before, #ProgressSteps li:after {
    background-color: #E6E7E8 !important;
    border-color: #2B5697 !important; }
  #ProgressSteps li:nth-child(1) {
    background-image: url("~src/assets/images/icon-video-paid.svg"); }
    #ProgressSteps li:nth-child(1).done {
      color: transparent !important;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap; }
  #ProgressSteps li:nth-child(2) {
    background-image: url("~src/assets/images/icon-user.svg"); }
  #ProgressSteps li:nth-child(3) {
    background-image: url("~src/assets/images/icon-euro.svg"); }
  #ProgressSteps li.done {
    color: #161616 !important; }
    #ProgressSteps li.done:before, #ProgressSteps li.done:after {
      background-color: #2B5697 !important;
      border-color: #2B5697 !important; }

.blog-heading {
  text-align: center; }
  .blog-heading h1 {
    font-size: 4rem;
    font-weight: bold;
    color: #bd9b60; }

.account.checkout .payments div.col-lg-8 .container #main-content .well p:last-child {
  border-top: 1px solid #E6E7E8;
  margin-top: 20px; }

.account.checkout .payments div.col-lg-8 .container #main-content > .row {
  margin-bottom: 40px;
  padding-bottom: 20px;
  border-bottom: 1px solid #E6E7E8; }
  .account.checkout .payments div.col-lg-8 .container #main-content > .row:before {
    display: block;
    content: "1. You have selected";
    font-size: 18px;
    font-weight: bold;
    color: #d71011;
    margin-bottom: 20px; }
  .account.checkout .payments div.col-lg-8 .container #main-content > .row:last-child {
    border-bottom: none;
    margin-bottom: 0;
    padding-bottom: 0; }
    .account.checkout .payments div.col-lg-8 .container #main-content > .row:last-child:before {
      content: "2. Select a method of payment"; }

body.account:not(.no-menu) .blog-heading {
  padding-right: 16px;
  padding-left: 16px; }
  @media (min-width: 1200px) {
    body.account:not(.no-menu) .blog-heading {
      padding-left: 16px;
      padding-right: 16px; } }

body.account:not(.no-menu) #main-container > .container {
  padding-right: 16px;
  padding-left: 16px; }
  @media (min-width: 1200px) {
    body.account:not(.no-menu) #main-container > .container {
      padding-left: 16px;
      padding-right: 16px; } }

body.account:not(.no-menu) #main-content {
  position: relative; }
  body.account:not(.no-menu) #main-content .page-section {
    display: none; }
    body.account:not(.no-menu) #main-content .page-section.selected {
      display: block; }
    body.account:not(.no-menu) #main-content .page-section > h2 {
      display: none; }
    body.account:not(.no-menu) #main-content .page-section > .row {
      margin: 0 0 30px; }
      body.account:not(.no-menu) #main-content .page-section > .row:last-child {
        margin: 0; }
  body.account:not(.no-menu) #main-content > .row > div {
    margin: 0 0 20px; }
    body.account:not(.no-menu) #main-content > .row > div:last-child {
      margin: 0; }

@media (max-width: 991.98px) {
  body.account:not(.no-menu) .blog-heading {
    padding: 20px 40px 20px 50px;
    background-color: #161616;
    color: #FFFFFF;
    position: relative;
    cursor: pointer; }
    body.account:not(.no-menu) .blog-heading:before, body.account:not(.no-menu) .blog-heading:after {
      content: "";
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      background-repeat: no-repeat;
      background-position: center;
      background-size: contain; }
    body.account:not(.no-menu) .blog-heading:after {
      background-image: url("/src/assets/images/payments/myaccount-arrow-menu.svg");
      right: 20px;
      width: 12px;
      height: 20px; }
    body.account:not(.no-menu) .blog-heading:before {
      left: 10px;
      height: 42px;
      width: 42px; }
    body.account:not(.no-menu) .blog-heading[data-section='your-profile']:before {
      background-image: url("/src/assets/images/payments/myaccount-my-profile.svg"); }
    body.account:not(.no-menu) .blog-heading[data-section='billing-details']:before {
      background-image: url("/src/assets/images/payments/myaccount-billing-details.svg"); }
    body.account:not(.no-menu) .blog-heading[data-section='active-subscriptions']:before {
      background-image: url("/src/assets/images/payments/myaccount-active-subscriptions.svg"); }
    body.account:not(.no-menu) .blog-heading[data-section='recent-transactions']:before {
      background-image: url("/src/assets/images/payments/myaccount-recent-transactions.svg"); }
    body.account:not(.no-menu) .blog-heading h1 {
      font-size: 16px;
      font-weight: normal;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis; } }

@media (min-width: 992px) {
  body.account:not(.no-menu) #content {
    display: flex;
    min-height: calc(100vh - 400px); }
    body.account:not(.no-menu) #content > div {
      padding-bottom: 40px; }
  body.account:not(.no-menu) #main-container {
    width: 70%; }
  body.account:not(.no-menu) .blog-heading {
    margin-top: 35px; } }

@media (min-width: 1200px) {
  body.account:not(.no-menu) #content > div {
    padding-top: 40px; } }

.account.register.step1 .payments .col-md-10 .col-md-4 {
  flex: none;
  max-width: initial; }
  .account.register.step1 .payments .col-md-10 .col-md-4 .subscription-plan-card {
    border: none; }

.account.register.step1 .payments div.col-lg-8 #main-content form:nth-child(2) {
  padding-bottom: 20px;
  border-bottom: 1px solid #E6E7E8; }
  .account.register.step1 .payments div.col-lg-8 #main-content form:nth-child(2) .row .row > div {
    border: 1px solid #E6E7E8;
    margin-bottom: 20px; }
    .account.register.step1 .payments div.col-lg-8 #main-content form:nth-child(2) .row .row > div:last-child {
      margin-bottom: 0; }
  .account.register.step1 .payments div.col-lg-8 #main-content form:nth-child(2) .subscription-plan-card {
    width: 100%;
    text-align: center;
    padding: 5px; }
    .account.register.step1 .payments div.col-lg-8 #main-content form:nth-child(2) .subscription-plan-card h2,
    .account.register.step1 .payments div.col-lg-8 #main-content form:nth-child(2) .subscription-plan-card button {
      margin: 20px auto;
      display: block; }

.account.register.step1 .payments div.col-lg-8 #main-content form#voucher-form {
  margin-top: 20px; }
  .account.register.step1 .payments div.col-lg-8 #main-content form#voucher-form .form-group {
    margin-bottom: 0; }
  .account.register.step1 .payments div.col-lg-8 #main-content form#voucher-form h2 {
    font-size: 18px;
    margin-bottom: 0; }
  .account.register.step1 .payments div.col-lg-8 #main-content form#voucher-form .input-group {
    margin-top: 10px;
    display: flex; }
    .account.register.step1 .payments div.col-lg-8 #main-content form#voucher-form .input-group input {
      flex-grow: 1; }
    .account.register.step1 .payments div.col-lg-8 #main-content form#voucher-form .input-group span {
      padding-left: 20px; }
      .account.register.step1 .payments div.col-lg-8 #main-content form#voucher-form .input-group span button {
        padding-top: 0;
        padding-bottom: 0;
        line-height: 40px; }

@media (min-width: 768px) {
  .account.register.step1 .payments div.col-lg-8 #main-content form:nth-child(2) .row .row {
    display: flex;
    height: 100%; }
    .account.register.step1 .payments div.col-lg-8 #main-content form:nth-child(2) .row .row > div {
      width: 50%;
      margin-bottom: 0; }
      .account.register.step1 .payments div.col-lg-8 #main-content form:nth-child(2) .row .row > div:first-child {
        margin-right: 10px; }
      .account.register.step1 .payments div.col-lg-8 #main-content form:nth-child(2) .row .row > div:last-child {
        margin-left: 10px; } }

.account.freeregistration .payments,
.account.register .payments {
  position: relative; }
  .account.freeregistration .payments:before,
  .account.register .payments:before {
    position: absolute;
    display: block;
    content: "";
    top: 0;
    bottom: 0;
    width: calc(100vw - 17px);
    left: 50%;
    margin-left: -50vw;
    background: url("~src/assets/images/background-payments.png") bottom center no-repeat white;
    background-size: contain;
    z-index: -1; }

body.no-menu #content {
  padding-right: 16px;
  padding-left: 16px;
  padding-top: 16px;
  padding-bottom: 16px;
  min-height: calc(100vh - 400px);
  max-width: 1200px;
  margin: 0 auto; }
  @media (min-width: 1200px) {
    body.no-menu #content {
      padding-left: 16px;
      padding-right: 16px;
      padding-top: 16px;
      padding-bottom: 16px; } }
