$sectionTitleSize: 22.5pt;
$sectionTitleSize-md: 30pt;

body {
  font-family: "futura-pt", sans-serif;
  font-size: 18pt;
  line-height: 1.2;
}

.nav-link {
  font-weight: normal;
  text-align: center;
  color: $whiteLight;
  font-size: ($grid-gutter-width / 2);

  &:hover {
    color: $whiteLight;
  }
}

.amg-section-title {
  font-weight: bold;
  text-align: center;
  color: $greenDark;
  font-size: $sectionTitleSize;

  @include media-breakpoint-up(md) {
    font-size: 40pt;
  }
}

.amg-section-description {
  text-align: center;
  color: $greenDark;
  font-size: 18px;
  line-height: initial;


  @include media-breakpoint-up(md) {
    font-size: 22px;
  }
}

.amg-action-link {
  &:not(.btn-my-account) {
    .amg-button-container {
      .amg-button {
        border-radius: 50pt;
        color: $whiteLight;
        border-color: $blueMed;
        background-color: $blueMed;

        @include media-breakpoint-up(FourK) {
          font-size: 26pt;
          padding: 14pt 24pt;
        }
      }
    }
  }
}

.invert,
.navigation-container {

  .amg-section-title,
  .amg-section-description {
    color: $whiteLight;
  }

  .amg-action-link .amg-button-container .amg-button {
    color: $blueMed;
    border-color: $whiteLight;
    background-color: $whiteLight;
  }
}

.amg-logo {
  > img.amg-icon-image {
    max-width: $logo-width;
    max-height: $logo-height;
  }
}

.amg-login-btn {
  background: url("~src/assets/images/icon-user-white.svg") 12px center no-repeat transparent;
  padding-left: 34px;
  margin-right: 5px
}

.show-logged-in,
.show-logged-out {
  display: none !important;
}

[is-logged-in="false"] .show-logged-out,
[is-logged-in="true"] .show-logged-in {
  display: block !important;
}

%overlay {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}

.mobile-only {
  display: block;
}

.amg-section-bg-image {
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
}

@include media-breakpoint-up(md, false) {
  .mobile-only {
    display: none;
  }
}

@include media-breakpoint-down(sm) {
  /* .desktop-only {
     display: none;
   }
   .mobile-only {
     display: block;
   }*/

  .home-highlight {
    .amg-sections {
      flex-direction: column;
    }
  }
}
