.page-browse {

  .amg-section.page-browse {
    width: 100%;
  }

  amg-wp-content-projection {
    amg-wp-page {
      > .amg-section {
        > .amg-section-children {
          padding: 0;
        }
      }
    }
  }
}
