$itemMargin: 16px;
$borderRadius: 0;
$transitionSpeed: 0.333s;

.leeds-boxset-carousel {

  .amg-card-image,
  .amg-card-video {
    cursor: pointer;
    color: $whiteLight;
    position: relative;
    z-index: 0;

    &,
    &:after,
    &:before,
    .card-img-top:after {
      transition:
        transform $transitionSpeed ease-in-out 0s;
    }

    &,
    &:before,
    &:after {
      box-shadow: 0pt 3pt 6pt 0pt transparentize($color: #000000, $amount: .84);
    }

    &:before,
    &:after {
      display: block;
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      pointer-events: none;
    }

    &:after {
      background: $blueMed;
      top: -10px;
      left: 10px;
      right: -10px;
      bottom: 10px;
      z-index: -1;
    }

    &:before {
      background: $blueDark;
      top: -20px;
      left: 20px;
      right: -20px;
      bottom: 20px;
      z-index: -2;
    }


    &,
    .card-img-top {
      background-color: transparent;
      border-radius: $borderRadius;
    }

    .card-img-top {
      position: relative;
      background-color: $blueLight;
      padding-top: 100%;

      &:before {
        display: block;
        content: "";
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background: url('~src/assets/images/card-video-play.svg') center no-repeat transparent;
      }
    }

    .title {
      display: none;
    }

    .card-body {
        display: none;
    }
  }

  &:not(.leeds-sidebar-carousel) {

    .amg-card-image,
    .amg-card-video {

      .card-body {
        display: block;

        >* {
          display: none;
        }
      }

      .card-img-top,
      .card-body {

        .title {
          display: block;
        }

        >.title {
          color: $whiteLight;
          background: transparentize($color: #081A2A, $amount: .24);
          text-align: center;
          font-size: 17px;
          padding: 10px;
          bottom: 10px;

          @include media-breakpoint-up(md) {
            font-size: 23px;
          }
        }
      }
    }

    .amg-card-video {
      .card-body {
        >.title {
          position: absolute;
        }
      }
    }
  }
}
