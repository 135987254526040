$itemMargin: 16px;
$borderRadius: 16px;
$transitionSpeed: 0.333s;

.leeds-playlist-carousel {
  .amg-section-child {
    justify-content: stretch;
  }

  .amg-carousel-nav-container {
    .amg-carousel-nav {

      button {
        transition:
          border-radius $transitionSpeed ease-in-out,
          margin $transitionSpeed ease-in-out;
      }
      .prev {
        border-radius: 0 $borderRadius $borderRadius 0;
      }
      .next {
        border-radius: $borderRadius 0 0 $borderRadius;
      }
    }
  }

  .amg-carousel {
    &:hover {
      [data-slide-visible="5.5"][data-slide-visible-hovered="5"]
      .amg-carousel-nav-container {
        .amg-carousel-nav {
          .next {
            &:not(:hover) {
              margin-right: $itemMargin;
              border-radius: 0 $borderRadius $borderRadius 0;
            }
          }
        }
      }
    }
  }

  .col {
    transition: all $transitionSpeed ease-in-out 0s !important;

    .amg-card-image,
    .amg-card-video {
      margin-right: $itemMargin;
    }

    .amg-card-image,
    .amg-card-video {
      height: 100%;

      .card-img-top {
        padding-top: 100%;
        height: 100%;
        transition: padding $transitionSpeed ease-in-out $transitionSpeed, height $transitionSpeed ease-in-out 0s;
      }
    }

    &:hover {

      .amg-card-image,
      .amg-card-video {
        .card-img-top {
          padding-top: 0;
          transition-delay: 0s, $transitionSpeed;
          max-height: 100%;
        }
      }
    }
  }

  @each $i in $carouselSize {
    @each $i2 in $carouselIntervals {
      $items: $i + $i2;

      @include prev-next($items);
    }
  }
}
