$bannerTitleSize: 50px;
$bannerNavLinkSize: 18px;
$navLinkSpacing: 15px;

.welcome-banner {
  padding: #{$grid-gutter-width / 2} 0;
  background: $blueDark;
  min-height: calc(100vh - 200px);


  .amg-login-button-group {
    display: block;
  }

  .amg-section .amg-section-child {
    width: auto;
  }

  &,
  > .amg-section-children {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
  }

  .welcome-banner-social-links,
  .welcome-banner-menu {
    display: none;
  }

  &.invert {
    .amg-card-video {
      .card-img-top {
        border: 1px solid $whiteLight;
      }
    }
  }

  .btn-home {
    cursor: pointer;
  }

  > .amg-section-title,
  > .amg-section-children,
  .welcome-banner-social-links .amg-section-children,
  .welcome-banner-menu .amg-section-children {
    @include make-container-amg();
  }

  .amg-section-title, .amg-section-description {
    text-align: left;
  }

  .amg-section-title, .amg-section-description,
  .amg-action-link .amg-button-container .amg-button {
    margin-bottom: $grid-gutter-width / 2;
  }

  > .amg-section-children {
    display: flex;
    justify-content: space-between;
    height: 100%;
    flex: auto;

    &:before {
      display: block;
      content: ".";
      color: transparent;
      font-size: 25px;
    }
  }

  .amg-action-link {
    .amg-button-container .amg-button {
      border-radius: 50px;
      color: $whiteLight;
      border-color: $blueMed;
      background: $blueMed;
    }
  }

  .amg-card-video {
    margin-right: $grid-gutter-width / 2;
  }

  .leeds-carousel {
    padding: 0;

    .amg-carousel-nav-container {
      .amg-carousel-nav {

        button {
          background-color: transparent;

          &:focus {
            outline: none;
          }
        }

        .next {
          background-position: 8px center;
          background-size: 14px;
        }
      }
    }

    @each $i in $carouselSize {
      @each $i2 in $carouselIntervals {
        $items: $i + $i2;

        @each $i in $carouselSize {
          @each $i2 in $carouselIntervals {
            $hoverItems: $i + $i2;

            .amg-carousel-container[data-slide-visible="#{$items}"][data-slide-visible-hovered="#{$hoverItems}"] {
              + .amg-carousel-nav-container {
                right: calc((((100% + #{$itemMargin}) / #{$items} * #{max($hoverItems - floor($hoverItems), .25)}) - #{$itemMargin}) * -1);
              }
            }
          }
        }
      }
    }

    .amg-card-video {
      margin-right: $grid-gutter-width / 2;
    }
  }

  @include media-breakpoint-up(md, false) {
    padding: #{$grid-gutter-width} 0;

    > .amg-section-children {

      .welcome-banner-social-links {
        * {

          display: block;
          height: auto;
        }

        .amg-section-children {
          text-align: right;

          .amg-section-child {
            vertical-align: middle;
          }

          .amg-section-child, .amg-icon {

            display: inline-block;
          }
        }

      }

      .welcome-banner-social-links,
      .welcome-banner-menu {
        display: block;

        .amg-section-child:nth-child(2) {
          flex-grow: 1;
        }

        .amg-icon-image {
          cursor: pointer;
        }
      }

      > .amg-section-child {
        align-items: baseline;

        &,
        .amg-section {
          display: block;
          height: auto;
          width: 100%;
        }

        &:nth-child(1),
        &:nth-child(2) {
          position: absolute;

          left: 0;
          right: 0;
          z-index: 10;

          .amg-sections {


            &,
            .amg-section {
              display: block;
              height: auto;

              .amg-section-children {
                justify-content: normal;
              }
            }
          }
        }

        &:nth-child(1) {
          // social links
          top: 10px;

          .amg-sections {
            .amg-section {

              .amg-button {
                color: $whiteLight;
                border-color: transparent;
                background-color: transparent;
              }

              .amg-nav-link {
                padding: 0;
                padding-right: $grid-gutter-width / 2;
                border-right: 1px solid white;
                margin: 0 0 0 #{$grid-gutter-width / 4};
              }

              .amg-social-links {
                margin: 0;

                .amg-icon,
                .amg-icon-image {
                  margin: 0;
                }

                .amg-icon {
                  margin-left: $grid-gutter-width / 2;
                }

                .amg-icon-image {
                  max-width: 18px;
                  max-height: 18px;
                }
              }
            }
          }
        }

        &:nth-child(2) {
          top: 38px;
          // site menu
          .amg-sections {
            .amg-section {

              .nav-item {
                margin-left: 0;
                margin-right: 0;
              }

              .nav-link {
                font-family: "futura-pt-condensed";
                font-size: 18px;
                padding: 0;
                margin: 4px 15px 0;
                border-bottom: 4px solid transparent;

                &.active {
                  border-color: $blueMed;
                }
              }

              .navbar-toggler,
              .amg-register-btn {
                display: none;
              }

              .amg-icon .amg-icon-image {
                max-width: 188px;
              }

              .amg-login-btn,
              .amg-logout-btn,
              .btn-my-account.amg-action-link .amg-button-container .amg-button {
                display: block;
                border-radius: 50px;
                width: 100px;
                border-color: $whiteLight;
                background-color: transparent;
                margin-left: #{$grid-gutter-width / 4};
                font-size: 10px;
              }

              .collapse.navbar-collapse {
                margin-right: auto;
              }
            }
          }
        }
      }
    }

    .amg-input-field-container {
      background: url("~src/assets/images/icon-search-white.svg") center right no-repeat transparent;
      background-size: auto 26px;
      margin-left: $grid-gutter-width / 2;
      position: relative;
      width: 26px;
      height: 54px;
    }

    .amg-input-field {
      padding-top: 15px;
      padding-bottom: 15px;
      border-radius: 0;
      max-width: initial;
      max-height: initial;
      position: absolute;
      right: 0;
      transition: all ease-in-out .24s;
      width: 258px;

      &:not(:focus) {
        width: 26px;
        background: transparent;
        border: transparent;
        color: transparent;
        cursor: pointer;

        &::placeholder {
          color: transparent;
        }
      }
    }
  }

  @include media-breakpoint-up(lg) {
    > .amg-section-children > .amg-section-child:nth-child(2) .amg-sections .amg-section .nav-link {
      font-size: 22px;
    }
  }


  @include media-breakpoint-up(HD) {
    > .amg-section-title,
    > .amg-section-children {
      padding-left: 45px;
      padding-right: 45px;
    }

    .welcome-banner-social-links,
    .welcome-banner-menu {

      .amg-section-children {
        padding-left: 45px;
        padding-right: 45px;
      }
    }

  }

  @include media-breakpoint-up(FourK) {
    .amg-section-description {

      font-size: 60px;
    }
  }
}
