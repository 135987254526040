.banner {
  background: $beigeLight;

  &,
  .amg-sections,
  .amg-section,
  .amg-section-children,
  .amg-section-child,
  .amg-icon,
  .amg-action-link{
    display: block;
    height: auto;
  }

  &,
  .amg-section-title,
  .amg-section-description,
  .amg-icon {
    color: $whiteLight;
    text-align: left;
  }

  .amg-action-link {
    display: block;
    text-align: right;

    .amg-button-container .amg-button {
      border-radius: 50pt;
      color: $greenDark;
      border-color: $whiteLight;
      background: $whiteLight;
    }
  }

  > .amg-section-children {
    padding-top: $grid-gutter-width / 2;
    padding-bottom: $grid-gutter-width / 2;

    .amg-section-children {
      height: auto;
      flex-wrap: wrap;

      > .amg-section-child {
        flex: auto;

        &:not(:last-child) {
          margin: #{$grid-gutter-width / 2} 0;
        }

        &:nth-child(1),
        &:nth-child(2) {

          .amg-icon {
            display: flex;
            flex-direction: row-reverse;
            align-items: center;
            color: $greenDark;

            .amg-icon-image {
              max-height: 42pt;
              margin-right: $grid-gutter-width / 4;
            }

            > * {
              margin-bottom: 0;

            }
          }
        }

        &:nth-child(3) {
          align-items: flex-start;
        }
      }

    }
  }

  @include media-breakpoint-up(md) {
    > .amg-section-children {
      .amg-section {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-template-rows: 1fr 1fr;
        grid-template-areas: "a c""b c";
        width: 100%;

        .amg-section-title {
          grid-area: a;
        }

        .amg-section-description {
          grid-area: b;
        }

        .amg-section-children {
          grid-area: c;
          flex-wrap: nowrap;

          > .amg-section-child {
            flex: auto;
            width: auto !important;
            margin-left: $grid-gutter-width / 4 !important;
          }
        }
      }
    }
  }
}

.banner {
  $banner-font-size: 18pt;

  .amg-section-title {
    font-size: 21pt;
  }

  .amg-section-description {
    font-size: 14pt;
  }

  .amg-icon {
    font-size: 16pt;
  }

  .btn {
    white-space: nowrap;
  }

  @include media-breakpoint-up(md) {
    .amg-section-title {
      font-size: 24pt;
    }

    .amg-section-description {
      font-size: 23pt;
    }

    .amg-icon {
      font-size: 20pt;
    }
  }
}

@import 'banner-free-access';
@import 'banner-match-pass';
