#page-container {
  app-subscribe {

    > amg-wp-page {

      &, *:not(span) {
        display: block;
        height: auto;
      }

      .section-subscriptions {
        background-position: center;
        background-size: cover;

        padding-top: $grid-gutter-width;
        padding-bottom: $grid-gutter-width;

        .amg-section-title {
          color: $blueMed;
        }

        .amg-section-description {
          color: $blueDark;
          margin-bottom: $grid-gutter-width;
        }

        > .amg-section-children {
          width: 100%;
          margin: 0 auto;

          @include media-breakpoint-up(lg) {
            max-width: calc(50% - #{$grid-gutter-width / 2});
          }


          > .amg-section-child {
            > .amg-sections {
              flex-wrap: wrap;
            }
          }
        }

        .amg-subscription-packages,
        .amg-payment-fields-group,
        .subscription-packages-container {
          width: 100%;
        }

        .amg-subscription-packages {
          display: flex;
        }

        .subscription-packages-container {

          .amg-icon-placeholder {
            background-size: 50px 50px;
            background: $whiteLight url("~src/assets/images/icon-video-beige.svg") no-repeat center;
            color: black;
            height: 55px;
            width: 90px;
            position: absolute;
            top: 0;
            left: 50%;
            margin-top: -27px;
            margin-left: -45px;
            border: 5px solid $whiteLight;
          }
        }

        .card-subscription {
          width: 100%;
          margin-bottom: 20px;

          .amg-icon.title-icon {
            display: flex;
            justify-content: center;
          }

          .amg-icon-image {
            margin-top: 0;
            height: 46pt;
          }

          @include media-breakpoint-up(lg) {
            width: calc(50% - #{($grid-gutter-width / 4)});
            flex: auto 50%;
            margin-top: 26px;
            margin-bottom: ($grid-gutter-width / 2);

          }

          &.card-subscription-free {
            width: 100%;
            flex: 0 1 100%;

            .amg-section-children {
              padding: ($grid-gutter-width * 2) ($grid-gutter-width / 2) ($grid-gutter-width / 2);
            }
          }
        }
      }
    }
  }
}
