$itemMargin: 16px;
$borderRadius: 0;
$transitionSpeed: 0.333s;

.leeds-boxset-carousel {
  .amg-section-child {
    justify-content: stretch;
  }

  .amg-side-details,
  .amg-card-image,
  .amg-card-video {
    margin-right: $itemMargin;
  }

  .amg-side-details {
    div {

      &.title {
        color: $blueDark;
        font-size: 23pt;
        font-weight: bold;
        text-transform: uppercase;
      }

      &.description {
        font-size: 17pt;
        color: $beigeLight;
      }

      @include media-breakpoint-up(md) {
        &.title {
          font-size: 30pt;
        }

        &.description {
          font-size: 18pt;
        }
      }
    }
  }

  $specificHoverMargin: $itemMargin * 3;

  .amg-carousel-container {

    .amg-card-image,
    .amg-card-video {
      max-width: calc(100% - 60px);
    }

    .col:not(.active):not(.amg-side-details) {
      pointer-events: none;
    }

    &:hover .col.amg-side-details:hover~.col.active+.col,
    &:hover .col.amg-side-details:hover~.col.active+.col.active,
    &:hover .col.active:not(:hover),
    .col.active+.col,
    .col.active+.col.active {

      .amg-card-image,
      .amg-card-video {

        &,
        &:after,
        &:before,
        .card-img-top:after {
          transform: rotate(0);
        }
      }
    }


    .col.active,
    &:hover .col.active:hover,
    &:hover .col.amg-side-details:hover~.col.active {

      .amg-card-image,
      .amg-card-video {
        margin: 40px auto;
      }
    }


    .col.active .amg-card-image,
    .col.active .amg-card-video,
    &:hover .col.active:hover .amg-card-image:hover,
    &:hover .col.active:hover .amg-card-video:hover,
    &:hover .col.amg-side-details:hover~.col.active .amg-card-image:hover,
    &:hover .col.amg-side-details:hover~.col.active .amg-card-video:hover {
      transform: rotate(-4deg);

      &:after {
        transform: rotate(9deg);
      }

      &:before {
        transform: rotate(0deg);
      }

      .card-img-top {

        &:after {
          transform: rotate(4deg);
        }
      }
    }


    &:hover {
      &[data-slide-hovered="0"]:not([data-slide-current="0"]) {
        +.amg-carousel-nav-container {
          .prev {
            opacity: 1;
          }
        }
      }
    }
  }

  .amg-carousel-nav-container {

    .amg-carousel-nav {

      button {
        margin-top: 28px;
        margin-bottom: 28px;
        transition:
          margin $transitionSpeed ease-in-out 0s,
          opacity $transitionSpeed ease-in-out 0s;
      }

      .prev {
        border-radius: 0 $borderRadius $borderRadius 0;
        opacity: 0;

        &:hover {
          opacity: 1;
        }
      }

      .next {
        border-radius: $borderRadius 0 0 $borderRadius;
      }
    }
  }

  &.leeds-sidebar-carousel {

    .amg-carousel-container[data-slide-visible="1"],
    .amg-carousel-container[data-slide-visible="1.25"],
    .amg-carousel-container[data-slide-visible="1.5"],
    .amg-carousel-container[data-slide-visible="1.75"],
    .amg-carousel-container[data-slide-visible="2"],
    .amg-carousel-container[data-slide-visible="2.25"],
    .amg-carousel-container[data-slide-visible="2.5"],
    .amg-carousel-container[data-slide-visible="2.75"],
    .amg-carousel-container[data-slide-visible="3"],
    .amg-carousel-container[data-slide-visible="3.25"],
    .amg-carousel-container[data-slide-visible="3.5"],
    .amg-carousel-container[data-slide-visible="3.75"] {
      .col {

        .amg-card-image,
        .amg-card-video {
          margin-top: 20%;
          margin-bottom: 20%;

          .card-img-top {
            padding-top: 80%;
          }
        }

        &.active {

          .amg-card-image,
          .amg-card-video {
            margin-top: 10%;
            margin-bottom: 10%;
            margin-left: 0;

            .card-img-top {
              padding-top: 100%;
            }
          }
        }
      }

      .amg-side-details {
        pointer-events: none;
        margin: 0 auto 0 !important;

        >div {
          padding-top: 100%;
        }
      }

    }
    @each $i in $carouselSidebarSmall {
      @each $i2 in $carouselIntervals {
        $items: $i + $i2;

        .amg-carousel-container[data-slide-visible="#{$items}"] {
          + .amg-carousel-nav-container {
            @if ($i2 == 0) {
              top: calc(100% / #{$items});
              bottom: calc(100% / #{$items});
              .amg-carousel-nav {
                button {
                  width: calc(100% / #{$items});
                }
              }
            }
            @else {
              top: calc(100% / #{$items} * #{$i2});
              bottom: calc(100% / #{$items} * #{$i2});
              .amg-carousel-nav {
                button {
                  width: calc(100% / #{$items} * #{$i2});
                }
              }
            }
          }
        }
      }
    }

    .amg-carousel-container {

      +.amg-carousel-nav-container {
        top: 40px;
        bottom: 40px;

        button {
          margin: 0;
          height: 100%;
        }
      }
    }
  }

  @each $i in $carouselSidebarNormal {
    @each $i2 in $carouselIntervals {
      $items: $i + $i2;

      &.leeds-sidebar-carousel {
        .amg-carousel-container[data-slide-visible="#{$items}"] {
          + .amg-carousel-nav-container {
            .amg-carousel-nav {
              padding-top: calc(((((100%) / #{$items}) - 40px)));
            }
          }
        }

        @include prev-next($items, 0px, 30px, $forSidebar: true);
      }
    }
  }
}
