.form-group, form {
  margin: 0 0 20px;

  label, select,
  input:not([type='submit']) {
    width: 100%;
    display: block;
  }

  label {
    text-transform: uppercase;
    text-align: left;
    margin-bottom: 5px;
    font-size: 15px;
    font-weight: bold;
  }

  input, select {
    height: 40px;
    background-color: #F9F9F8;
    border: 1px solid #E6E7E8;
    border-radius: 5px;
    color: #495057;
  }

  input {
    padding-left: 7px;
    padding-right: 7px;
  }

  select {
    // line-height: 1.5;
    // outline: 0;
    padding: 8px 14px 5px 7px;
  }

  .row {
    > div {
      margin: 0 auto;
    }
  }

  .form-control-static {
    margin: 5px 0 0;
    border-bottom: 1px solid #495057;
  }

  .validation-summary-errors,
  .field-validation-error,
  .field-error {
    margin-top: 3px;
    color: #d71011;
  }

  .validation-summary-errors {
    ul {
      list-style-type: none;
      padding-inline-start: 0;
    }
  }
}
