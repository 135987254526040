/* set the overriding variables */
$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  HD: 1280px,
  FullHD: 1920px,
  FourK: 3840px,
);

$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1140px,
  HD: 1200px,
  FullHD: 1900px,
  FourK: 3800px,
);

$carouselSize: (
  1,
  2,
  3,
  4,
  5,
);

$carouselSidebarSmall: (
  1,
  2,
);

$carouselSidebarNormal: (
  3,
  4,
  5,
);

$carouselIntervals: (
  0.25,
  0.5,
  0.75,
);

$grid-gutter-width: 32px;
$grid-padding-width: 16px;
$grid-padding-width-hd: 140px;

$input-btn-padding-y: 10rem;
$input-btn-padding-x: 30rem;

$beigeLight: #BD9B60;
$beigeDark: #9C835E;
$blueDark: #0E2A51;
$blueMed: #2B5697;
$blueLight: #588BBC;
$redLight: #FC0000;
$redDark: #961A1A;
$redLighter: #FF0000;
$greenDark: #2F2F2F;
$greenMed: #4A4848;
$greenLight: #050E1A;
$grayPinkSwan: #B5B5B5;
$grayLight: #D5D5D5;

$whiteLight: #FFFFFF;
$whiteMed: #FAFAFA;
$whiteDark: #F7F7F7;
$greyLight: #F4F4F4;
$greyDark: #E6E7E8;

$primary: #0e2a51;
$primary-text-color: #ffffff;

$secondary: #ffffff;
$secondary-text-color: #000000;

$secondary-white: #F5F5F5;

$logo-width: 155px;
$logo-height: 62px;

$image-path: "/src/assets/images/";
$header-height: 200px;
$footer-height-large: 200px;

$grid-padding-width-large: $grid-padding-width;
