@mixin prev-next($items: 1, $itemMargin: 0px, $itemMarginMod: 0px, $forSidebar: false) {
  $modifier: $items - floor($items);

  $itemDivider: $items;

  @if ($modifier==0) {
    $modifier: 0.25;

    @if ($itemMargin !=0px) {
      width: calc(100% + #{$itemMargin});
    }

    @else {
      width: 100%;
    }

    .amg-carousel-nav-container[data-slide-visible="#{$items}"] {
      .amg-carousel-nav {
        button {
          @if ($itemMargin !=0px) {
            width: calc(((100% + #{$itemMargin}) / #{$itemDivider} * #{$modifier}) - #{$itemMargin});
          }

          @else {
            width: calc(100% / #{$itemDivider} * #{$modifier});
          }
        }
      }
    }

  }

  @else {
    @if ($itemMargin !=0px) {
      width: calc(100% + #{$itemMargin});
    }

    @else {
      width: 100%;
    }

    @if ($itemDivider !=0) {

      .amg-carousel-container[data-slide-visible="#{$items}"] {
        +.amg-carousel-nav-container {
          .amg-carousel-nav {

            button {
              @if ($itemMargin !=0px) {
                @if ($itemMarginMod !=0px) {
                  width: calc(((100% + #{$itemMargin}) / #{$itemDivider} * #{$modifier}) - #{$itemMarginMod});
                }

                @else {
                  width: calc(((100% + #{$itemMargin}) / #{$itemDivider} * #{$modifier}) - #{$itemMargin});
                }
              }

              @else {
                @if ($itemMarginMod !=0px) {
                  width: calc((100% / #{$itemDivider} * #{$modifier}) - #{$itemMarginMod});
                }

                @else {
                  width: calc(100% / #{$itemDivider} * #{$modifier});
                }
              }
            }
          }
        }
      }
    }
  }
}
