@import "form";
@import "my-account-navigation";
@import "progress-steps";

.blog-heading {
    text-align: center;
    h1 {
        font-size: 4rem;
        font-weight: bold;
        color:#bd9b60;
    }
}
