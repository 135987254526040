$subCardTitleSize: 20pt;

.card-subscription {
  border: 2pt solid $beigeLight;
  background: $whiteLight;

  >.amg-section-children {
    position: relative;

    /* not good!!

    >.amg-section-child {
      position: relative;

      &:nth-child(2) {
        position: absolute;
        top: 0;
        right: 0;
        pointer-events: none;
        * {
          pointer-events: auto;
        }
      }
    }*/
  }

  .amg-icon.title-icon {
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    justify-content: flex-end;
    z-index: 0;
    max-width: 100%;
    color: $blueMed;

    /* doesn't work

    &:before {
      content: "";
      display: block;
      height: 0;
      border: 5pt solid $whiteLight;
      border-left-width: 0;
      border-right-width: 0;
      position: absolute;
      top: 50%;
      left: -($grid-gutter-width / 2);
      right: -($grid-gutter-width / 2);
      z-index: -1;
    }*/

    > * {
      margin-bottom: 0;
    }

    .amg-icon-image {
      height: 46pt;
      background: $whiteLight;
    }

    .amg-icon-label {
      margin: 0 0 0 ($grid-gutter-width / 2);
      max-width: 100%;
      font-size: 14pt;

      @include media-breakpoint-up(md) {
        font-size: 23pt;
      }
    }
  }

  .amg-icon.top-icon {
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    margin: 0 0 #{$grid-gutter-width / 2};

    > * {
      margin-bottom: 0;
    }

    .amg-icon-image {
      height: 46pt;
      margin: 0 #{$grid-gutter-width / 2} 0 0;
    }
  }

  .amg-icon.faq-icon {
    position: absolute;
    top: $grid-gutter-width / 4;
    right: $grid-gutter-width / 4;

    .amg-icon-image {
      height: 26pt;
    }
  }

  .amg-text-group {
    .amg-text-field {
      display: block;
      margin-bottom: $grid-gutter-width / 2;

      &:nth-child(1) {
        font-weight: bold;
        font-size: 20pt;
        text-align: center;
        color: $blueMed;
      }

      &:nth-child(2) {
        font-size: 16pt;
        text-align: center;
        color: $blueDark;
      }

      &:nth-child(3),
      &:nth-child(4) {
        display: inline-block;
        width: 50%;
        font-weight: bold;
        font-size: 20pt;
        text-align: center;
      }

      &:nth-child(3) {
        color: $blueMed;
      }

      &:nth-child(4) {
        color: $blueDark;
      }
    }
  }

  .amg-action-link .amg-button-container .amg-button {
    border-color: $beigeLight;
    border-radius: 50pt;
    background: $beigeLight;
    margin: 0 auto;

    &:hover {
      border-color: $beigeDark;
      background: $beigeDark;
    }
  }

  .amg-section-children {
    display: flex;
    flex-direction: column;
    padding: ($grid-gutter-width / 2);
  }

  &.card-subscription-free {
    .amg-section-children {
      &:before {
        background-size: 50px 50px;
        content: " ";
        background: $whiteLight url("~src/assets/images/icon-video-beige.svg") no-repeat center;
        color: black;
        height: 55px;
        width: 90px;
        position: absolute;
        top: 0;
        left: 50%;
        margin-top: -27px;
        margin-left: -45px;
        border: 5px solid $whiteLight;
      }

      .amg-section-child {
        .amg-action-link .amg-button-container .amg-button {
          border-color: $blueMed;
          background: $blueMed;

          &:hover {
            border-color: $blueDark;
            background: $blueDark;
          }
        }
      }
    }
  }
}

.invert {
  &.card-subscription {

    .amg-icon.top-icon,
    .amg-icon.title-icon,
    .amg-text-group .amg-text-field {
      color: $whiteLight
    }
  }
}
