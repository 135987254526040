.fixtures-page {
  .navigation-container {
    .amg-section-children {
      margin-left: 10%;
    }
  }

  .fixtures-projection {
    @include make-container-amg();
    //max-width: 1920px;
    //margin: 0 auto;

    .amg-section-children {
      align-self: center;
      padding: 0;
      margin: 0;
      max-width: 100%;
    }
  }

  .first-team-page {
    .next-match-container,
    .calendar-container,
    .login-container {
      margin-top: 2em;
    }

    .next-match-container {
      .amg-fixtures {
        .amg-message-container {
          color: $secondary-text-color;
        }
      }
    }

    .calendar-container {


      .amg-section-child {
        background-color: $greyLight;
      }

      .amg-carousel-calendar {
        .amg-carousel-calendar-tabs {

          .amg-carousel-nav-container {
            position: relative;
          }
        }
      }
    }

    .amg-section-title {
      text-align: left;
      font-size: 18pt !important;
      color: $greenDark !important;
      margin-bottom: 0.4em;
    }

    .login-container {
      &[is-logged-in="true"] {
        display: none;
      }

      .amg-section-title {
        font-weight: bold;
        font-size: 18pt !important;
      }

      .amg-text-field {
        color: $blueMed;
      }

      .amg-section-children {
        justify-content: left;

        & > * {
          display: inline;
          width: auto;
        }

        .amg-text-field {
          margin: 0 0.4em;
        }

        .btn {
          border: $blueLight 1pt solid;
          background-color: $blueLight;
          border-radius: 20pt;
          font-size: 0.5em;

          &:hover {
            background-color: $whiteLight;
            color: $blueLight;
          }

          &.amg-login-btn {
            &:hover {
              background: url("~src/assets/images/icon-user.svg") 12px center no-repeat transparent;
            }
          }
        }
      }
    }

    .amg-calendar {
      .amg-carousel-calendar {
        padding-bottom: 5em;

        .amg-carousel-calendar-tabs {
          background-color: $whiteMed;

        }
      }
    }
  }

  @include media-breakpoint-up(sm) {
    .first-team-page {
      .login-container {
        .amg-section-children {
          .btn {
            font-size: 0.8em;
          }

          .amg-text-field {
            margin: 0 0.5em;
          }
        }
      }
    }
  }

  // Medium devices (tablets, 768px and up)
  @include media-breakpoint-up(md) {
    .navigation-container .amg-section-children {
      margin-left: 20%;
    }
  }

  // Extra large devices (large desktops, 1200px and up)
  @include media-breakpoint-up(xl) {
    .navigation-container .amg-section-children {
      margin-left: 25%;
    }
  }
}





