
#ProgressSteps {
  ol {
    margin: 0 -30px 20px !important;
    display: flex;

    &[data-progress-steps="3"] {
      li {
        width: 32% !important;
      }
    }
  }

  li {
    padding-top: 40px;
    background: center 10px no-repeat transparent;
    background-size: auto 16px;
    background-repeat: no-repeat;
    list-style: none;

    @media (min-width: 768px) {
      background-position: center 14px;
    }

    &:before,
    &:after {
      
      background-color: $greyDark !important;
      border-color: $blueMed !important;
    }

    &:nth-child(1) {
      background-image: url("~src/assets/images/icon-video-paid.svg");

      &.done {
        color: transparent !important;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }

    &:nth-child(2) {
      background-image: url("~src/assets/images/icon-user.svg");
    }

    &:nth-child(3) {
      background-image: url("~src/assets/images/icon-euro.svg");
    }

    &.done {
      color: #161616 !important;

      &:before,
      &:after {
        background-color: $blueMed !important;
        border-color: $blueMed !important;
      }
    }
  }
}
