$subCardTitleSize: 20pt;
$textMargin: 10px;

.amg-subscription-packages {
  width: 100%;
  display: flex;
  flex-direction: column;

  .amg-subscription-package {
    width: 100%;
    margin: 20px 0;
    border: 2pt solid $beigeLight;
    padding: 0.5px;
    background: $whiteLight;
    color: $greenDark;
    text-align: center;
    position: relative;

    .amg-subscription-package-inner {
      background-color: $whiteLight;
      padding: 80px ($grid-gutter-width / 2) ($grid-gutter-width / 2);

      .amg-title {
        color: $blueMed;
        font-size: $subCardTitleSize;
        margin-bottom: 18px;
        display: block;
        font-weight: bold;
      }

      .amg-body {
        color: $blueDark;
        font-size: 16pt;
        margin-top: $textMargin*2;
        margin-bottom: $textMargin*2;
        display: flex;
        vertical-align: middle;
        align-items: center;
        justify-content: center;
      }

      .amg-amount {
        text-align: center;
        width: 100%;
        font-weight: 700;
        display: inline-block;
        margin-bottom: $textMargin;
        padding-right: $textMargin;

        &:before {
          content: "Only";
          margin-right: $textMargin;
          font-weight: 400;
        }
      }

      .amg-interval-group {
        display: none;
      }

      .amg-button {
        display: block;
        color: white;
        border-color: $beigeLight;
        border-radius: 50px;
        background: $beigeLight;
        margin: 0 auto $textMargin auto;

        &:hover {
          border-color: $beigeDark;
          background: $beigeDark;
        }
      }
    }
  }
}

@include media-breakpoint-up(md) {
  .amg-subscription-packages {
    flex-direction: row;

    .amg-subscription-package {
      border: 2px solid $beigeLight;
      background: $whiteLight;
      color: $greenDark;
      text-align: center;
    }
  }
}
