.banner-match-pass {
  > .amg-section-children {

    .amg-section-children {

      display: flex;


      > .amg-section-child {

        display: flex;
        width: auto;
        align-items: flex-start;

        &:not(:last-child) {
         // padding-right: $grid-padding-width;
        }

        &:nth-child(1),
        &:nth-child(2) {
         // flex: 0 0 50%;
          width: 50%;
        }

        &:nth-child(3) {
         // flex: 0 0 100%;
          width: 100%;
          align-items: flex-start;
        }
      }

    }
  }

  /*@include media-breakpoint-up(md) {
    > .amg-section-children {
      .amg-section {
        .amg-section-children {
          > .amg-section-child {
            flex: auto;
            width: auto !important;
            margin-left: $grid-gutter-width / 4 !important;
          }
        }
      }
    }
  }*/
}
