.page-home {
  overflow: hidden;

  > amg-wp-section {
    &:not(.welcome-banner):not(.fixture-section) {

      @include make-container-amg();
    }
  }


  .welcome-banner {
    position: relative;
    min-height: 700px;

    > * {
      z-index: 1;
    }

    &:after {
      display: block;
      content: "";
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      background: linear-gradient(to bottom, transparentize(#02152C, 0.89), transparentize(#02152C, 0.85) 75%, #02152C);
      z-index: 0;
    }

    .welcome-banner-menu {
      .amg-button-container {
        .amg-button {
          @include amgButton($fontSize: 10px);
        }
      }
    }
  }

  .fixture-section {
    display: block;
    background: $whiteLight;


    .amg-section-title {
      font-size: 16pt;
      text-align: left;
      padding-top: $grid-gutter-width / 2;
      padding-bottom: $grid-gutter-width / 4;
      padding: #{$grid-gutter-width / 2} #{$grid-gutter-width / 2} #{$grid-gutter-width / 4};
    }

    > .amg-section-children {
      background: $blueDark;
      flex-direction: column;

      > .amg-section-child {
        display: block;
        height: auto;

        &:first-child {
          background: $whiteLight;

          .amg-action-link .amg-button-container .amg-button {
            color: $blueLight;
            border-color: transparent;
            background: transparent;
            text-decoration: underline;
            padding: 0 #{$grid-gutter-width / 2};
            margin: 0 0 #{$grid-gutter-width / 2} 0;
          }
        }

        &:last-child {
          .amg-fixtures .amg-fixture {
            padding: 0 #{$grid-gutter-width / 2};

            .amg-card-fixture-teams {
              .amg-card-fixture-team {
                flex: 0 0 37.5%;
                width: 37.5%;

                .amg-card-fixture-team-icon {
                  margin-left: auto;
                }
                .amg-card-fixture-team-name {
                  margin-right: auto;
                  text-overflow: ellipsis;
                  overflow: hidden;
                }
              }
              .t {
                flex: 0 0 25%;
                width: 25%;
              }

            }

            .amg-card-fixture-meta {
              amg-live-buttons {
                width: 100%;
              }
            }
          }
        }
      }
    }
  }

  .amg-fixtures {
    @include media-breakpoint-down(lg) {
      .amg-fixture {
        &:not(:nth-child(1)):not(:nth-child(2)) {
          display: none;
        }
      }
    }

    .amg-fixture {
      display: block;

      &:not(:last-child) {

        .amg-card-event,
        .amg-card-fixture {
          border-bottom: 1pt solid $whiteLight;
        }
      }
    }


    .amg-card-event,
    .amg-card-fixture {
      color: $whiteLight;

      .amg-card-event-info,
      .amg-card-fixture-info,
      .amg-card-fixture-teams,
      .amg-card-event-details,
      .amg-card-fixture-meta,
      .amg-card-fixture-meta amg-live-buttons {
        padding: 0;
      }


      .amg-card-event-info,
      .amg-card-fixture-info {
        background: transparent;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        font-weight: 500;
        text-transform: uppercase;

        .amg-date {
          .time {
            font-weight: bold;
          }
        }
      }

      .amg-card-event-details {
        .amg-card-event-details-title {
          font-weight: bold;
        }

      }

      .amg-card-fixture-sponsor {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        .amg-card-fixture-sponsor-img {
          max-width: 100%;
        }
      }

      .amg-card-fixture-teams {
        align-items: flex-end;

        .amg-card-fixture-team {
          display: flex;
          flex-direction: row;
          justify-content: flex-start;
          align-items: center;
          font-weight: bold;
          text-align: left;
          text-transform: uppercase;

          .amg-card-fixture-team-icon {
            max-width: initial;
          }
        }

        .amg-date {
          font-weight: bold;
        }
      }

      amg-live-buttons {
        display: flex;
        flex-direction: row;
        justify-content: space-around;

        .btn {
          color: $blueDark;
          border-radius: 50pt;
          border: 2pt solid;
          text-transform: uppercase;
          transition: border-color $transitionSpeed ease-in-out 0s,
          background-color $transitionSpeed ease-in-out 0s;

          .live {
            &:after {
              content: " ";
            }
          }
        }

        .btn {
          background-color: $greyLight;
          background-image: radial-gradient(circle, $redLight, $redLight 5px, transparent 5px);
          border-color: $beigeLight;

          &[disabled] {
            border-color: $beigeDark;
            background-color: $greyDark;
            background-image: radial-gradient(circle, $blueDark, $blueDark 5px, transparent 5px);
          }
        }
      }
    }
  }

  .amg-fixtures {
    $fixture-font-size: 8pt;

    .amg-fixture {
      .amg-card-calendar-fixture-sponsor-img {
        display: none;
      }

      + .amg-fixture {

        .amg-card-event,
        .amg-card-fixture {
          margin-top: -$fixture-font-size;
        }
      }
    }

    .amg-card-event,
    .amg-card-fixture {
      padding: $fixture-font-size 0;
      margin-bottom: $fixture-font-size;


      .amg-card-event-info,
      .amg-card-fixture-info,
      .amg-card-fixture-teams,
      .amg-card-event-details {
        margin-bottom: $fixture-font-size;
      }


      .amg-card-event-info,
      .amg-card-fixture-info {
        margin-bottom: $fixture-font-size;
        font-size: $fixture-font-size;

      }

      .amg-card-fixture-sponsor {

        .amg-card-fixture-sponsor-img {
          max-height: 26px;
        }
      }

      .amg-card-fixture-teams {
        font-size: $fixture-font-size;

        .amg-card-fixture-team {

          .amg-card-fixture-team-icon {
            max-height: #{$fixture-font-size * 4};
            margin-right: #{$fixture-font-size / 2};
          }
        }

        .amg-date {
          font-size: 12pt;
        }
      }

      amg-live-buttons {
        .btn {
          background-position: -22pt -2pt;
          font-size: $fixture-font-size;
          padding-left: $fixture-font-size * 2;
          padding-right: $fixture-font-size;
        }
      }
    }

    @include media-breakpoint-up(md) {
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      overflow: hidden;
      max-height: calc(100vh - 90px); // 90px is the size of the upcoming events header
      height: 100%;

      $fixture-font-size: 10pt;

      .amg-card-event,
      .amg-card-fixture {
        padding: $fixture-font-size 0;
        margin-bottom: $fixture-font-size;


        .amg-card-event-info,
        .amg-card-fixture-info,
        .amg-card-fixture-teams,
        .amg-card-event-details {
          margin-bottom: $fixture-font-size;
        }


        .amg-card-event-info,
        .amg-card-fixture-info {
          margin-bottom: $fixture-font-size;
          font-size: $fixture-font-size;

        }

        .amg-card-fixture-sponsor {

          .amg-card-fixture-sponsor-img {
            max-height: 26px;
          }
        }

        .amg-card-fixture-teams {
          font-size: $fixture-font-size;

          .amg-card-fixture-team {

            .amg-card-fixture-team-icon {
              max-height: #{$fixture-font-size * 4};
            }
          }

          .amg-date {
            font-size: 21pt;
          }
        }

        amg-live-buttons {
          .btn {
            background-position: -28pt center;
            font-size: $fixture-font-size;
            padding-left: $fixture-font-size * 2;
            padding-right: $fixture-font-size;
          }
        }
      }
    }

    @include media-breakpoint-between(xl, HD) {
     .amg-card-fixture .amg-card-fixture-teams  {
       .amg-card-fixture-team .amg-card-fixture-team-icon {
          max-height: 25px;
        }

        .amg-date {
          font-size: 20px;
        }
      }
    }

    @include media-breakpoint-up(HD) {
      $fixture-font-size: 10pt;

      .amg-fixture {
        + .amg-fixture {

          .amg-card-event,
          .amg-card-fixture {
            margin-top: -$fixture-font-size;
          }
        }
      }

      .amg-card-event,
      .amg-card-fixture {
        padding: $fixture-font-size 0;
        margin-bottom: $fixture-font-size;


        .amg-card-event-info,
        .amg-card-fixture-info,
        .amg-card-fixture-teams,
        .amg-card-event-details {
          margin-bottom: $fixture-font-size;
        }


        .amg-card-event-info,
        .amg-card-fixture-info {
          margin-bottom: $fixture-font-size;
          font-size: $fixture-font-size;

        }

        .amg-card-fixture-sponsor {

          .amg-card-fixture-sponsor-img {
            max-height: 26px;
          }
        }

        .amg-card-fixture-teams {
          font-size: $fixture-font-size;

          .amg-card-fixture-team {

            .amg-card-fixture-team-icon {
              max-height: 35px;
            }
          }

          .amg-date {
            font-size: 14pt;
          }
        }

        amg-live-buttons {
          .btn {
            background-position: -32pt center;
            font-size: $fixture-font-size;
            padding-left: $fixture-font-size * 2;
            padding-right: $fixture-font-size;
          }
        }
      }
    }

    @include media-breakpoint-up(FourK) {
      $fixture-font-size: 18pt;

      .amg-fixture {
        + .amg-fixture {

          .amg-card-event,
          .amg-card-fixture {
            margin-top: -$fixture-font-size;
          }
        }
      }

      .amg-card-event,
      .amg-card-fixture {
        padding: $fixture-font-size 0;


        .amg-card-event-info,
        .amg-card-fixture-info,
        .amg-card-fixture-teams,
        .amg-card-event-details {
          margin-bottom: $fixture-font-size;
        }


        .amg-card-event-info,
        .amg-card-fixture-info {
          margin-bottom: $fixture-font-size;
          font-size: $fixture-font-size;

        }

        .amg-card-fixture-sponsor {

          .amg-card-fixture-sponsor-img {
            max-height: 26px;
          }
        }

        .amg-card-fixture-teams {
          font-size: $fixture-font-size;

          .amg-card-fixture-team {

            .amg-card-fixture-team-icon {
              max-height: #{$fixture-font-size * 4};
            }
          }

          .amg-date {
            font-size: 34pt;
          }
        }

        amg-live-buttons {
          .btn {
            background-position: -52pt center;
            font-size: $fixture-font-size;
            padding-left: $fixture-font-size * 2;
            padding-right: $fixture-font-size;
          }
        }
      }
    }
  }


  .welcome-banner {
    .amg-section-title {
      @include media-breakpoint-up(lg) {
        font-size: 36px;
      }

      @include media-breakpoint-up(FullHD) {
        font-size: 52px;
        padding-right: 40%;
      }

      @include media-breakpoint-up(FourK) {
        font-size: 124px;
        //padding-right: 50%;
      }
    }

    .amg-section-description {
      @include media-breakpoint-up(lg) {
        font-size: 22px;
      }

      @include media-breakpoint-up(FullHD) {
        font-size: 40px;
      }

      @include media-breakpoint-up(FourK) {
        font-size: 60px;
      }
    }
  }

  @include media-breakpoint-up(md) {
    .welcome-banner {
      padding-top: 86pt;

      .amg-login-btn,
      .amg-logout-btn,
      .btn-my-account.amg-action-link .amg-button-container .amg-button {
        white-space: nowrap;
      }

      > .amg-section-children {


        > .amg-section-child {

          &:nth-child(1) {

            .amg-sections {
              .amg-section {
                top: 12pt;
                margin-bottom: 12pt;

                .amg-nav-link {
                  font-size: 12pt;
                }
              }
            }
          }

          &:nth-child(2) {
            .amg-sections {
              .amg-section {
                top: 26pt;
              }
            }
          }
        }
      }
    }
  }

  @include media-breakpoint-up(xl) {
    .welcome-banner {
      padding-top: 100pt;

      &,
      + .fixture-section {
        height: 100vh;
        min-height: 700px;
      }

      + .fixture-section {
        position: absolute;
        top: 0;
        right: 0;
        overflow: hidden;
        background: $blueDark;

        .amg-section-title {
          text-align: left;
          font-size: 16pt;
          background: $whiteLight;
        }

        .amg-section-children {
          display: block;

          .amg-section-child {
            display: block;
            height: auto;
            height: initial;
          }
        }
      }
    }
  }


  @include media-breakpoint-up(FullHD) {
    .welcome-banner {
      padding-top: 120pt;

      > .amg-section-children {
        > .amg-section-child {
          &:nth-child(1) {
            .amg-sections {
              .amg-section {
                top: 20pt;
                margin-bottom: 20pt;

                .amg-nav-link {
                  font-size: 14pt;
                  margin: 0 0 0 #{$grid-gutter-width / 2};
                }

                .amg-social-links {
                  .amg-icon-image {
                    max-width: 24pt;
                    max-height: 24pt;
                  }
                }
              }
            }
          }

          &:nth-child(2) {
            .amg-sections {
              .amg-section {
                top: 40pt;

                .nav-link {
                  font-size: 18pt;
                  margin: 0 #{$grid-gutter-width / 2};
                }

                .amg-login-btn,
                .amg-logout-btn,
                .btn-my-account.amg-action-link .amg-button-container .amg-button {
                  font-size: 12pt;
                  margin: 0 0 0 #{$grid-gutter-width / 2};
                }

                .btn-my-account.amg-action-link .amg-button-container .amg-button {
                  width: 140px;
                }

                .amg-icon .amg-icon-image {
                  max-width: 250px;
                }
              }
            }
          }
        }
      }
    }

    .amg-input-field-container {
      margin: 0 0 0 #{$grid-gutter-width / 2};
    }
  }

  @include media-breakpoint-up(FourK) {
    .welcome-banner {
      padding-top: #{$grid-gutter-width * 8};

      > .amg-section-children {
        > .amg-section-child {
          &:nth-child(1) {

            // social links
            .amg-sections {
              .amg-section {
                bottom: calc(100vh - 80pt);

                .amg-nav-link {
                  font-size: 24pt;
                  margin: 0 0 0 #{$grid-gutter-width};
                }

                .amg-social-links {
                  .amg-icon-image {
                    max-width: 48pt;
                    max-height: 48pt;
                  }
                }
              }
            }
          }

          &:nth-child(2) {

            // site menu
            .amg-sections {
              .amg-section {
                bottom: calc(100vh - 200pt);

                .nav-link {
                  font-size: 35px;
                  margin: 0 #{$grid-gutter-width};
                }

                .amg-login-btn,
                .amg-logout-btn,
                .btn-my-account.amg-action-link .amg-button-container .amg-button {
                  font-size: 24pt;
                  margin: 0 0 0 #{$grid-gutter-width};
                }

                .amg-login-btn,
                .amg-logout-btn {
                  width: 160px;
                }
                .btn-my-account.amg-action-link .amg-button-container .amg-button {
                  width: 250px;
                }

                .amg-icon .amg-icon-image {
                  max-width: 500px;
                }

                .amg-input-field-container,
                .amg-input-field {
                  height: 50px;
                }

                .amg-input-field-container {
                  background-size: auto 50px;
                  width: 50px;
                  margin-left: 60px;

                  .amg-input-field {
                    padding-top: 30pt;
                    padding-bottom: 30pt;
                    height: 40pt;
                    width: 360pt;

                    &:not(:focus) {
                      max-width: 50pt;
                    }
                  }
                }
              }
            }
          }
        }
      }

      .amg-section-title,
      .amg-section-description {
        margin-bottom: #{$grid-gutter-width};
      }

      .amg-input-field-container {
        margin: 0 0 0 #{$grid-gutter-width};
      }

      + .fixture-section {
        .amg-action-link .amg-button-container .amg-button {
          font-size: 1rem;
        }
      }
    }
  }

  $width-array: (xl, 28%) (HD, 27%) (FullHD, 20%) (FourK, 18%);

  @each $pair in $width-array {
    $viewport: nth($pair, 1);
    $fixture-width: nth($pair, 2);
    $breakpoint: #{map-get($grid-breakpoints, $viewport)};

    @media (min-width: $breakpoint) {
      .welcome-banner {
        width: calc(100% - #{$fixture-width});

        + .fixture-section {
          width: #{$fixture-width};
        }

        > .amg-section-children {

          > .amg-section-child {

            &:nth-child(1),
            &:nth-child(2) {
              .amg-sections {
                .amg-section {
                  right: 0;
                }
              }
            }
          }
        }
      }
    }
  }
}
