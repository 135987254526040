body.account:not(.no-menu) {
  .blog-heading {
    @include make-container-amg();
  }

  #main-container {

    > .container {
      @include make-container-amg();
    }
  }

  #main-content {
    position: relative;

    .page-section {
      display: none;

      &.selected {
        display: block;

      }

      > h2 {
        display: none;
      }

      > .row {
        margin: 0 0 30px;

        &:last-child {
          margin: 0;
        }
      }
    }

    > .row {
      > div {
        margin: 0 0 20px;

        &:last-child {
          margin: 0;
        }
      }
    }
  }


  @include media-breakpoint-down(md) {
    .blog-heading {
      padding: 20px 40px 20px 50px;
      background-color: #161616;
      color: #FFFFFF;
      position: relative;
      cursor: pointer;

      &:before, &:after {
        content: "";
        position: absolute;

        top: 50%;
        transform: translateY(-50%);
        background-repeat: no-repeat;
        background-position: center;
        background-size: contain;
      }

      &:after {
        background-image: url($image-path + "payments/myaccount-arrow-menu.svg");
        right: 20px;
        width: 12px;
        height: 20px;
      }

      &:before {
        left: 10px;
        height: 42px;
        width: 42px;
      }

      // Navigation Icons
      &[data-section='your-profile']:before {
        background-image: url($image-path + "payments/myaccount-my-profile.svg");
      }

      &[data-section='billing-details']:before {
        background-image: url($image-path + "payments/myaccount-billing-details.svg");
      }

      &[data-section='active-subscriptions']:before {
        background-image: url($image-path + "payments/myaccount-active-subscriptions.svg");
      }

      &[data-section='recent-transactions']:before {
        background-image: url($image-path + "payments/myaccount-recent-transactions.svg");
      }

      h1 {
        font-size: 16px;
        font-weight: normal;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }

  @include media-breakpoint-up(lg) {

    #content {
      display: flex;
      min-height: calc(100vh - #{$header-height + $footer-height-large});

      > div {

        padding-bottom: 40px;
      }
    }

    #main-container {
      width: 70%;
    }

    .blog-heading {
      margin-top: 35px;
    }
  }

  @include media-breakpoint-up(xl) {
    #content {

      > div {
        padding-top: 40px;
      }
    }
  }
}
