$itemMargin: 16px;

.leeds-carousel {
  .amg-carousel-nav-container {
    z-index: 1;
    .amg-carousel-nav {
      button {
        position: absolute;
        top: 0;
        bottom: 0;
      }

      .prev {
        left: 0;
      }

      .next {
        right: 0;
      }
    }
  }

  @each $i in $carouselSize {
    @each $i2 in $carouselIntervals {
      $items: $i + $i2;

      @include prev-next($items, $itemMargin);
    }
  }

  @each $i in $carouselSidebarSmall {
    @each $i2 in $carouselIntervals {
      $items: $i + $i2;

      &.leeds-sidebar-carousel {
        .amg-carousel-container[data-slide-visible="#{$items}"] {
          + .amg-carousel-nav-container {
            .amg-carousel-nav {
              padding-top: calc(((((100% + #{$itemMargin}) / #{$items - 1}) - #{$itemMargin})));
            }
          }
        }
      }
    }
  }

  @each $i in $carouselSize {
    @each $i2 in $carouselIntervals {
      $items: $i + $i2;

      .amg-carousel-container[data-slide-visible="#{$items}"] {
        + .amg-carousel-nav-container {
          .amg-carousel-nav {
            padding-top: calc((((100% / #{$items}) - #{$itemMargin})));
          }
        }
      }

      &.leeds-video-carousel,
      &.leeds-welcome-banner-carousel {
        .amg-carousel-container[data-slide-visible="#{$items}"] {
          + .amg-carousel-nav-container {
            .amg-carousel-nav {
              padding-top: calc(((100% / #{$items}) - #{$itemMargin}) * 9 / 16);
            }
          }
        }
      }
    }
  }

  &.leeds-video-carousel,
  &.leeds-welcome-banner-carousel {
    .amg-carousel-nav-container {
      .amg-carousel-nav {
        .next {
          right: $itemMargin;
        }
      }
    }
  }
}
