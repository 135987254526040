$navContainerHeight: 220px;
.navigation-container {
  background: $blueDark;
  background-size: cover;
  border-top: $blueMed solid 3px;
  border-bottom: $blueMed solid 3px;
  min-height: $navContainerHeight;
  justify-content: center;
  //overflow: hidden;
  padding: $grid-gutter-width ($grid-gutter-width / 2);
  .amg-section-title {
    margin-bottom: 10px;
  }
}
