.amg-fixtures,
.amg-fixture {
  width: 100%;
}

.amg-card-fixture {
    .amg-card-fixture-sponsor-img {
        max-width: 100%;
    }
}
