.modal-backdrop {
  background-color: $blueDark;

  &.show {
    opacity: .85;
  }
}

.modal {
  .amg-template amg-form-builder form .amg-form-field {
    border-radius: 0;
    border-color: transparent;
    border-bottom-color: $blueLight;
    margin-bottom: $grid-gutter-width / 2;
    padding-left: 0;
    padding-right: 0;

    &:focus {
      outline: 0;
    }
  }

  .amg-template amg-form-builder form button {
    border-radius: 50pt;
    border-style: solid;
    border-color: $blueMed;
    background-color: $blueMed;
    margin-bottom: $grid-gutter-width / 2;
  }

  .amg-cta-description {
    font-size: 12pt;
  }
}

@import 'modal-login';
