@mixin make-container-amg($apply-vertical: false) {
  padding-right: $grid-padding-width;
  padding-left: $grid-padding-width;

  @if $apply-vertical {
    padding-top: $grid-padding-width;
    padding-bottom: $grid-padding-width;
  }

  @include media-breakpoint-up(xl) {
    padding-left: $grid-padding-width-large;
    padding-right: $grid-padding-width-large;

    @if $apply-vertical {
      padding-top: $grid-padding-width-large;
      padding-bottom: $grid-padding-width-large;
    }
  }
}

@mixin amgButton(
  $borderRadius: 50px,
  $width: 100px,
  $borderColor: #FFFFFF,
  $bgColor: transparent,
  $margin:auto 8px,
  $fontSize: 18px,
  $borderWidth: 1px) {
  border-radius: $borderRadius;
  width: $width;
  border-color: $borderColor;
  background-color: $bgColor;
  margin: $margin;
  font-size: $fontSize;
  border-style: solid;
  border-width: $borderWidth;
  @include media-breakpoint-up(FullHD) {
    font-size: 16px;
    width: 140px;
  }
}


// Media of at least the minimum breakpoint width. No query for the smallest breakpoint.
// Makes the @content apply to the given breakpoint and wider.
@mixin media-breakpoint-up($name, $include-itself: true, $breakpoints: $grid-breakpoints) {
  $min: breakpoint-min($name, $breakpoints);

  @if not($include-itself) {
    $min: $min + 1px;
  }

  @if $min {
    @media (min-width: $min) {
      @content;
    }
  } @else {
    @content;
  }
}

// Media of at most the maximum breakpoint width. No query for the largest breakpoint.
// Makes the @content apply to the given breakpoint and narrower.
@mixin media-breakpoint-down($name, $include-itself: false, $breakpoints: $grid-breakpoints) {
  $max: breakpoint-max($name, $breakpoints);
  @if $include-itself {
    $max: breakpoint-min($name, $breakpoints);
  }
  @if $max {
    @media (max-width: $max) {
      @content;
    }
  } @else {
    @content;
  }
}

// Media that spans multiple breakpoint widths.
// Makes the @content apply between the min and max breakpoints
@mixin media-breakpoint-between($lower, $upper, $include-itself: true, $breakpoints: $grid-breakpoints) {
  $min: breakpoint-min($lower, $breakpoints);
  $max: breakpoint-max($upper, $breakpoints);

  @if not($include-itself) {
    $min: $min + 1px;
  }
  @if $min != null and $max != null {
    @media (min-width: $min) and (max-width: $max) {
      @content;
    }
  } @else if $max == null {
    @include media-breakpoint-up($lower, $breakpoints) {
      @content;
    }
  } @else if $min == null {
    @include media-breakpoint-down($upper, $breakpoints) {
      @content;
    }
  }
}
