$itemMargin: 16px;
$borderRadius: 16px;
$transitionSpeed: 0.333s;

$specificHoverMargin: $itemMargin * 3;

.leeds-profile-carousel {
  .amg-section-child {
    justify-content: stretch;
  }

  .amg-side-details,
  .amg-card-image {
    margin-right: $itemMargin;
    border: 0;
  }

  &.invert {
    .amg-side-details {
      color: $whiteLight;
    }
  }

  .amg-side-details {
    div {
      &.shirt_number,
      &.first_name,
      &.last_name,
      &.position {
        text-transform: uppercase;
      }

      &.shirt_number {
        font-size: 25pt;
        font-style: italic;

        &:before {
          content: url("~src/assets/images/icon-shirt-white.svg");
          margin-right: #{$grid-gutter-width / 4};
        }
      }

      &.first_name {
        font-weight: 500;
        font-size: 20pt;
      }

      &.last_name {
        font-size: 23pt;
        font-weight: bold;
      }

      @include media-breakpoint-up(md) {
        &.shirt_number {
          font-size: 27pt;
        }

        &.first_name {
          font-size: 30pt;
        }

        &.last_name {
          font-size: 30pt;
        }

        &.position {
          font-size: 18pt;
        }
      }
    }
  }

  .amg-card-image {
    box-shadow: 0pt 3pt 6pt 0pt transparentize($color: #000000, $amount: 0.84);
    position: relative;
    cursor: pointer;
    color: $whiteLight;

    &:before,
    &:after {
      position: absolute;
      right: 0;
      left: 16px;
      z-index: 1;
    }

    &:before {
      display: block;
      content: "";
      background: url("~src/assets/images/card-video-play.svg") center no-repeat transparent;
      background-size: cover;
      bottom: 16px;
      width: 37px;
      height: 37px;
    }

    &:after {
      content: "Watch Now";
      bottom: 18pt;
      padding-left: 32pt;
      font-size: 15pt;

      @include media-breakpoint-up(md) {
        font-size: 14pt;
      }

      @include media-breakpoint-up(lg) {
        font-size: 17pt;
      }
    }

    &,
    .card-img-top {
      background-color: transparent;
      border-radius: $borderRadius;
      overflow: hidden;
    }

    .card-img-top {
      position: relative;

      &:after {
        display: block;
        content: "";
        position: absolute;
        top: 50%;
        bottom: 0;
        left: 0;
        right: 0;
        background: linear-gradient(to bottom, transparent, #1e3c6a 45%, #000000 100%);
        opacity: 0;
      }
    }
  }

  .amg-carousel-container {
    + .amg-carousel-nav-container {
      padding-left: $specificHoverMargin / 4;
      top: #{$specificHoverMargin / 4};
      @-moz-document url-prefix() {
        bottom: #{$specificHoverMargin / 4};
      }
      @supports (-webkit-overflow-scrolling: touch) {
        bottom: #{$specificHoverMargin / 4};
      }
      @supports (-ms-ime-align: auto) {
        bottom: #{$specificHoverMargin / 4};
      }
    }
  }

  .amg-side-details {
    > .d-block {
      display: flex !important;
      flex-direction: column;
      justify-content: center;
    }
  }

  .col.active {
    .amg-card-image {
      transition: margin $transitionSpeed ease-in-out 0s, max-width $transitionSpeed ease-in-out 0s;

      &:after {
        transition: opacity $transitionSpeed ease-in-out 0s;
      }

      .card-img-top {
        &:after {
          transition: opacity $transitionSpeed ease-in-out 0s;
        }
      }
    }
  }

  .amg-carousel-container {
    .col:not(.active):not(.amg-side-details) {
      pointer-events: none;
    }

    &:hover .col.amg-side-details:hover ~ .col.active + .col,
    &:hover .col.amg-side-details:hover ~ .col.active + .col.active,
    &:hover .col.active:not(:hover),
    .col.active + .col,
    .col.active + .col.active {
      .amg-card-image {
        margin-top: #{$specificHoverMargin / 2};
        margin-bottom: #{$specificHoverMargin / 2};
        max-width: calc(100% - #{$specificHoverMargin});

        &:after {
          opacity: 0;
        }

        .card-img-top {
          &:after {
            opacity: 0;
          }
        }
      }
    }

    .col.active,
    &:hover .col.active:hover,
    &:hover .col.amg-side-details:hover ~ .col.active {
      .amg-card-image {
        margin: 0 auto;
        margin-top: #{$specificHoverMargin / 4};
        margin-bottom: #{$specificHoverMargin / 4};
        max-width: calc(100% - #{$specificHoverMargin / 2});

        &:after {
          opacity: 1;
        }

        .card-img-top {
          &:after {
            opacity: 0.76;
          }
        }
      }
    }

    &:hover {
      &[data-slide-hovered="0"]:not([data-slide-current="0"]) {
        + .amg-carousel-nav-container {
          .prev {
            opacity: 1;
          }
        }
      }
    }
  }

  .amg-carousel-nav-container {
    button {
      transition: margin $transitionSpeed ease-in-out 0s, opacity $transitionSpeed ease-in-out 0s;
    }

    .prev {
      border-radius: 0 $borderRadius $borderRadius 0;
      opacity: 0;
      margin-left: $specificHoverMargin / 4;

      &:hover {
        opacity: 1;
      }
    }

    .next {
      border-radius: $borderRadius 0 0 $borderRadius;
      margin-top: #{$specificHoverMargin / 4};
      margin-bottom: #{$specificHoverMargin / 2};
    }
  }

  &.leeds-carousel {
    &.leeds-sidebar-carousel {
      @each $i in $carouselSidebarSmall {
        @each $i2 in $carouselIntervals {
          $items: $i + $i2;

          .amg-carousel-container[data-slide-visible="#{$items}"] {
            + .amg-carousel-nav-container {
              .amg-carousel-nav {
                button {
                  @if ($i2 == 0) {
                    width: calc(100% / #{$items});
                  }
                  @else {
                    width: calc(100% / #{$items} * #{$i2});
                  }
                }
              }
            }
          }
        }
      }

      @each $i in $carouselSidebarNormal {
        @each $i2 in $carouselIntervals {
          $items: $i + $i2;

          .amg-carousel-container[data-slide-visible="#{$items}"] {
            + .amg-carousel-nav-container {
              .amg-carousel-nav {
                padding-top: calc(((((100%) / #{$items}) - #{$specificHoverMargin / 4})));
              }
            }
          }

          @include prev-next($items, 0px, 0px, $forSidebar: true);
        }
      }
    }
  }
}
