$paddingMobile: 30px;
$paddingDesktop: 40px;
$navLinkFontSize: 18px;
$navLinkLineHeight: $navLinkFontSize;
$navLinkSpacing: 15px;
$iconSize: 24px;
$separatorColor: #E6E7E8;

.amg-header {
  background: $blueDark;

  @media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {
    &,
    .amg-section,
    .amg-section-children,
    .amg-section-child,
    .amg-action-link,
    .amg-navigation-bar,
    amg-wp-payment-fields-group {
      display: block;
      height: auto;
    }
    .header-main .amg-navigation-bar div .navbar-nav {
      display: table;

      .nav-item {
        margin: 4px 15px 0;
      }

      .nav-link {
        display: table-cell;
      }
    }
  }

  .nav-link {
    font-family: "futura-pt-condensed";
  }

  .header-main {
    @include make-container-amg();

    padding-top: $grid-padding-width;
    padding-bottom: $grid-padding-width;

    .nav-link.amg-nav-link {
      @include amgButton;
      @include media-breakpoint-up(FullHD) {
        font-size: 18px;
      }
    }

    width: 100%;
  }

  .header-links {
    @include make-container-amg();

    .amg-social-links {
      margin: 0;
    }
  }

  .btn-home {
    cursor: pointer;
  }

  @include media-breakpoint-down(sm, true) {
    .header-main {
      .navbar-collapse {
        &.show {
          top: 84px;
        }
      }
    }
  }
  @include media-breakpoint-down(md, true) {
    .amg-icon-image {
      max-width: 35vw;
      max-height: initial;
    }

    .navbar-toggler {
      background: linear-gradient(to bottom,
        $whiteLight 0, $whiteLight 20%,
        transparent 20%, transparent 40%,
        $whiteLight 40%, $whiteLight 60%,
        transparent 60%, transparent 80%,
        $whiteLight 80%, $whiteLight 100%);
      border: 0;
      border-radius: 0;
      width: 20px;
      height: 18px;
      padding: 0;

      * {
        display: none;
      }
    }

    .header-main {
      .amg-section-children {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;

        .amg-section-child {
          display: block;
          width: auto;

          &:nth-child(1) {
            order: 1;
          }

          &:nth-child(3) {
            order: 2;
            margin-left: auto;
          }

          &:nth-child(4) {
            order: 3;
          }

          &:nth-child(2) {
            order: 4;
          }

          &:nth-child(5), &:nth-child(6) {
            display: none;
          }

          &:not(:nth-child(2)) {
            margin-right: $grid-gutter-width / 2;
          }
        }
      }
    }

    .header-links {
      display: none;
    }

    .amg-payment-fields-group {
      amg-register-btn {
        display: none;
      }
    }

    .btn {
      border-radius: 50px;
    }

    .navbar-collapse {
      &.show {
        background: $blueDark;
        position: absolute;
        top: 122px;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 100;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;

        .navbar-nav {
          height: 85%;
          display: flex;
          flex-direction: column;
          justify-content: space-around;
        }

        .nav-item {
          margin-left: 0;
          margin-right: 0;
        }

        .amg-navbar-extra {
          width: 100%;
          height: 15%;
          display: flex;
          flex-direction: column;
          justify-content: center;
          margin-left: #{$grid-gutter-width / -2};
          margin-right: #{$grid-gutter-width / -2};

          .amg-input-field {
            font-size: 21px;
            height: 48px;
            color: $blueDark;
            background: url("~src/assets/images/search_icon_small.png") 12px 50% no-repeat $whiteLight;
            text-indent: 32px;
            border: none;
            border-radius: 0;
          }

          .amg-navigation-bar-section {
            display: none;
          }
        }

        .nav-link {
          border: #{$grid-gutter-width / 16} solid transparent;
          border-top: 0;
          border-bottom: 0;
          font-size: 22px;
          padding: #{$grid-gutter-width / 8} 0;

          &.active {
            border-left-color: $blueMed;
          }
        }
      }
    }
  }

  @include media-breakpoint-up(md, false) {
    .header-links {
      background: $whiteLight;
      line-height: 30px;

      .amg-icon {
        margin: 0 0 0 10px;

        .amg-icon-image {
          max-height: $iconSize;
          max-width: $iconSize;
        }
      }

      .nav-link {
        color: $blueDark;
        font-size: 14px;
        padding: 0 9px;
        border-left: solid 1px $separatorColor;
        border-right: solid 1px $separatorColor;
      }

      .amg-social-links img {
        margin: 0;
      }

      > .amg-section-children {
        justify-content: flex-end;

        > .amg-section-child {
          display: inline-block;
          width: auto;
          height: auto;
          vertical-align: middle;
        }
      }
    }

    .header-main {

      > .amg-section-children {
        display: flex;
      }

      .amg-navigation-bar div .navbar-nav {
        display: block;
        text-align: center;

        .nav-link {
          display: inline-block;
        }
      }

      .amg-icon .amg-icon-image {
        max-width: $logo-width;
        max-height: $logo-height;
      }

      > .amg-section-children {
        > .amg-section-child {
          width: auto;

          &:nth-child(2) {
            width: 100%;
          }

          &:nth-child(3),
          &:nth-child(4) {
            flex-direction: row;
            justify-content: flex-end;
          }
        }
      }

      .collapse.navbar-collapse {
        display: block;

        .nav-item {
          margin: 0;
        }

        .nav-link {
          font-size: $navLinkFontSize;
          padding: 0;
          margin: 4px $navLinkSpacing 0;
          border-bottom: 4px solid transparent;
          line-height: $navLinkLineHeight;

          &.active {
            border-bottom-color: $blueMed;
          }
        }
      }
    }

    .amg-login-btn,
    .amg-logout-btn,
    .btn-my-account.amg-action-link .amg-button-container .amg-button {
      border-radius: 50px;
      min-width: 100px;
      border-color: $whiteLight;
      background-color: transparent;
      white-space: nowrap;
      margin-left: 16px;
    }

    .navbar-toggler,
    .amg-register-btn {
      display: none;
    }

    .amg-input-field-container {
      background: url("~src/assets/images/icon-search-white.svg") center right no-repeat transparent;
      background-size: auto 26px;
      margin-left: $grid-gutter-width / 2;
      position: relative;
      width: 26px;
      height: 54px;
    }

    .amg-input-field {
      padding-top: 15px;
      padding-bottom: 15px;
      border-radius: 0;
      max-width: initial;
      max-height: initial;
      position: absolute;
      right: 0;
      transition: all ease-in-out .24s;
      width: 262px;

      &:not(:focus) {
        width: 26px;
        background: transparent;
        border: transparent;
        color: transparent;
        cursor: pointer;

        &::placeholder {
          color: transparent;
        }
      }
    }
  }

  @include media-breakpoint-up(lg) {
    .header-main .collapse.navbar-collapse .nav-link {
      font-size: 22px;
    }
  }
}
