.amg-grid {
  width: 100%;

  h2 {
    text-transform: capitalize;
    margin-bottom: 38px;
  }

  &.grid-playlist {
    .amg-card-image,
    .amg-card-video {
      border: 0;
      border-radius: 16px;
      overflow: hidden;
      cursor: pointer;

      .card-img-top,
      .card-body {

        > .title {
          color: $whiteLight;
          background: transparentize($color: #081A2A, $amount: .24);
          text-align: center;
          font-size: 17px;
          padding: 10px;
          bottom: 10px;

          @include media-breakpoint-up(md) {
            font-size: 23px;
          }
        }
      }
    }
  }

  &.grid-boxset {
    .amg-card-video {
      cursor: pointer;
      color: $whiteLight;
      position: relative;
      z-index: 0;
      margin: 32px;

      &,
      &:after,
      &:before,
      .card-img-top:after {
        transition: transform $transitionSpeed ease-in-out 0s;
      }

      &,
      &:before,
      &:after {
        box-shadow: 0pt 3pt 6pt 0pt transparentize($color: #000000, $amount: .84);
      }

      &:before,
      &:after {
        display: block;
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        pointer-events: none;
      }

      &:after {
        background: $blueMed;
        top: -10px;
        left: 10px;
        right: -10px;
        bottom: 10px;
        z-index: -1;
      }

      &:before {
        background: $blueDark;
        top: -20px;
        left: 20px;
        right: -20px;
        bottom: 20px;
        z-index: -2;
      }


      &,
      .card-img-top {
        background-color: transparent;
        border-radius: $borderRadius;
      }

      .card-img-top {
        position: relative;
        background-color: $blueLight;
        padding-top: 100%;

        &:before {
          display: block;
          content: "";
          position: absolute;
          top: 0;
          bottom: 0;
          left: 0;
          right: 0;
          background: url('~src/assets/images/card-video-play.svg') center no-repeat transparent;
        }
      }

      .card-body {
        display: none;
      }
    }

    .amg-card-image,
    .amg-card-video {

      .card-body {
        display: block;

        > * {
          display: none;
        }
      }

      .card-img-top,
      .card-body {

        .title {
          display: block;
        }

        > .title {
          color: $whiteLight;
          background: transparentize($color: #081A2A, $amount: .24);
          text-align: center;
          font-size: 17px;
          padding: 10px;
          bottom: 10px;

          @include media-breakpoint-up(md) {
            font-size: 23px;
          }
        }
      }
    }

    .amg-card-video {
      .card-body {
        > .title {
          position: absolute;
        }
      }
    }
  }

  .amg-grid {

    > .search-container { //form
      font-family: Oswald, sans-serif;;
      margin-top: 25px;
      margin-bottom: 25px;
      display: block;
      flex-basis: auto;

      .amg-input-search-field-container {
        margin-top: 10px;
        margin-bottom: 10px;
        .input-search-label {
          display: none;
        }
      }

      [formControlName="search"] {
        width: 100%;
        margin-bottom: 25px;
        font-size: 18px;
        height: 62px;
        color: $blueMed;
        background-color: $greyDark;
        background-image: url("~src/assets/images/search_icon_small.png");
        background-repeat: no-repeat;
        background-position-y: 50%;
        background-position-x: 12px;
        text-indent: 32px;
        border-radius: 0;
        border: none;
        padding-left: 12px;

        &::placeholder {
          color: $blueMed;
        }

        + .close,
        + input[type="submit"],
        + .close + input[type="submit"] {
          display: none;
        }
      }

      amg-filter-dropdown {
        display: inline-block;
        padding: 0 10px 10px 0;
        max-width: 100%;

        &:nth-child(1) {
          width: 200px;
        }
        &:nth-child(2) {
          width: 150px;
        }
        &:nth-child(3) {
          width: 135px;
        }
      }

      .amg-filter-dropdown {
        text-align: left;
        font-size: 16px;

        .amg-select-field {

          .ng-clear-wrapper {
            display: none;
          }

          .ng-arrow-wrapper {
            .ng-arrow {
              @include caret(down);
              color: #2b5697;
              margin-left: -25px;
            }
          }

          .ng-value {
            color: #2b5697;
          }

          .ng-select-container {
            background-color: #fff;
            border: 1px solid #2B5697;
            outline: 0;
            cursor: pointer;
            border-radius: 3px;
            padding: 12px 6px;
          }

          &.ng-select-opened {
            .ng-select-container {
              background-color: #2b5697;
              .ng-value {
                color: #fff;
              }

              .ng-arrow {
                color: #fff;
              }
            }
          }

          .ng-option {
            border-left: transparent solid thick;
            padding: .25rem 1.5rem;
            font-weight: 400;
            color: #212529;
            white-space: nowrap;

            &:hover {
              background-color: inherit;
              border-left-color: $blueMed;
            }
          }
        }

        .amg-grid-filter-title {
          font-size: 13px;
          margin-bottom: 8px;
          white-space: nowrap;
        }
      }
    }

    .results-container {
      overflow: hidden;

      .col {
        flex-grow: 0;
        flex-basis: auto;
        margin-bottom: 20px;
      }
    }

    .btn-load-more {
      color: $whiteLight;
      background-color: $blueMed;
      border: none;
      border-radius: 25px;
      display: block;
      margin: 30px auto;
    }


    @include media-breakpoint-up(md) {

      .results-container {
        margin: 0 (-($grid-gutter-width / 4));



        .col {
          width: 50%;
          padding: 0 $grid-gutter-width / 4;

          .amg-card-video .card-body .categories {
            font-size: 13px;
          }
        }
      }
    }

    @include media-breakpoint-up(lg) {

      > .search-container { //form
          flex-basis: 0;

        &.filters-container {
          > .row {
            justify-content: flex-end;

            > .col {
              padding: 0 35px 20px 0;
            }
          }
        }

      }

      .results-container {
        .col {
          width: (100% / 3);
        }
      }
    }

    @include media-breakpoint-up(HD) {
      > .search-container {
        .amg-filter-dropdown-container,
        .amg-input-search-field-container {
          display: inline-block;
          width: 500px;
          vertical-align: middle;
        }

        .amg-input-search-field-container {
          float: left;
        }

        .amg-filter-dropdown-container {
          float: right;
          text-align: right;
        }

        @include clearfix();

        [formControlName="search"] {
          max-width: 360px;
        }
      }

      .results-container {
        .col {
          width: 25%;
        }
      }
    }

    @include media-breakpoint-up(FullHD) {

      .results-container {
        .col {
          width: 20%;
        }
      }
    }

    @include media-breakpoint-up(FourK) {

      .results-container {
        .col {
          width: (100% / 6);
        }
      }
    }
  }
}
