.amg-fixture {
  &.calendar-card {
    width: 100%;
  }
}

.amg-card-calendar-fixture {
  width: 100%;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: $whiteDark;
  flex-direction: column;
  border-bottom: $blueLight solid 1px;

  .amg-card-calendar-fixture-info {
    background-color: $greyDark;
    display: flex;
    flex-direction: row;
    width: 100%;
    padding: 5px 5%;
    position: relative;

    .amg-card-calendar-fixture-sponsor {
      flex: 1 1 10%;
      display: flex;
      align-items: center;

      .amg-card-calendar-fixture-sponsor-img {
        margin-right: 0.5em;
        width: 35px;
        height: 35px;
      }
    }
    // Sponsor Date
    .amg-date {
      display: inline-block;
      font-size: 1.1em;
      flex: 1 1 30%;
      align-self: center;

      .time {
        display: none;
      }

      .date {
        font-family: futura-pt-condensed, serif;
        font-size: 25px;
        color: $blueMed;
        .year:before {
          content: '.';
        }

        .month:before {
          content: '.';
        }
      }
    }
    .amg-card-calendar-fixture-stadium {
      color: $blueLight;
      display: block;
      flex: 1 1 50%;
      font-size: 0.8em;
      align-self: center;
      text-align: right;
      max-width: 100%;
    }
  }

  .amg-card-calendar-fixture-teams {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin: 8% auto;
    position: relative;
    font-size: 0.7em;

    .amg-card-calendar-fixture-team {
      text-align: center;
      min-width: 100px;
      padding: $grid-padding-width;
      width: 50%;
    }

    .amg-card-calendar-fixture-team-icon {
      width: 10vw;
      max-width: 65px;
      height: auto;
      margin-bottom: 3px;
    }

    .amg-card-calendar-fixture-team-home {
      padding-right: 40px;
      //order: 1;
    }

    .amg-card-calendar-fixture-team-away {
      padding-left: 40px;
      //order: 3;
    }
    // Fixture Date
    .amg-date {
      font-size: 1.5em;
      //order: 2;
      left: 0;
      position: absolute;
      width: 100%;
      text-align: center;
      font-weight: bold;


      .date {
        display: none;
      }
      .time {
        .minute:before {
          content: ':';
        }
      }
    }

  }

  .amg-card-calendar-fixture-meta {
    display: flex;
    flex-direction: row;
    align-items: center;

    .btn {
      width: 100pt;
      height: 33pt;
      padding: 0 1px;
      margin: 0 0.8em 2em 0.8em;
      flex-direction: column;
      align-content: center;
      position: relative;
      text-align: center;
      align-items: center;
      font-size: 14px;
      border-radius: 50px;

      .placeholder-2:before {
        content: "";
        border-radius: 100%;
        width: 5pt;
        height: 5pt;
        display: inline-block;
        vertical-align: text-top;
        margin-top: 2pt;
      }

      &[disabled] {
        border: $grayPinkSwan 2pt solid;
        background-color: $grayLight;
        .placeholder-2:before {
          background-color: $greenMed;
        }
      }
      &:not([disabled]) {
        border: $beigeLight 2pt solid;
        background-color: $whiteDark;
        .placeholder-2:before {
          background-color: $redLighter;
        }
      }

      &.amg-audio-button {
        .placeholder-1 {
          background-image: url("~src/assets/images/audio-icon-black.png");
          background-size: 21pt 21pt;
          background-repeat: no-repeat;
          background-position: center;
          width: 21pt;
          height: 21pt;
        }
      }
      &.amg-video-button {
        .placeholder-1 {
          background-image: url("~src/assets/images/video-icon-black.png");
          background-size: 24pt 16pt;
          background-repeat: no-repeat;
          background-position: center;
          width: 24pt;
          height: 16pt;
        }
      }

      .text-group,
      .placeholder-2,
      .placeholder-1 {
        display: inline-block;
      }
      .placeholder-1,
      .placeholder-2 {
        margin-right: 5px;
      }
      .text-group,
      .placeholder-1 {
        vertical-align: middle;
      }
      .placeholder-2 {
        vertical-align: super;
      }
      .live,
      .audio {
        display: block;
        height: 15px;
        line-height: 15px;
        text-align: left;
      }
    }
  }
}

.amg-calendar {
  .amg-carousel-calendar-tabs {
    .amg-carousel {
      .amg-card-text {
        border: $greyLight 1px solid;
        border-top-left-radius: 7px;
        border-top-right-radius: 7px;
        background-color: $whiteMed;

        .card-body {
          display: flex;
          flex-direction: column;
          text-align: center;

          .month {
            font-weight: bold;
            color: $blueMed;
            font-size: 0.9em;
            order: 2;
          }

          .year {
            color: $blueLight;
            font-size: 0.9em;
            order: 1;
          }
        }
      }

      .active {
        .amg-card-text {
          background-color: $greyLight;

          .card-body {
            .month {
              color: $beigeLight !important;
            }
          }
        }
      }
    }
  }
}

// Medium devices (tablets, 768px and up)
@media (min-width: 768px) {
  .amg-card-calendar-fixture {
    .amg-card-calendar-fixture-teams {
      .amg-card-calendar-fixture-team {
        text-align: center;
        min-width: 100px;
        flex-grow: 1;
      }
    }
    .amg-card-calendar-fixture-meta {
      .btn {
        width: 123pt;
        height: 45pt;
        padding: 0.3em 0.75em;
        font-size: 18px;

        .placeholder-2:before {
          margin-top: 1pt;
        }

        &.amg-audio-button {
          .placeholder-1 {
            background-size: 27pt 27pt;
            width: 27pt;
            height: 27pt;
          }
        }
        &.amg-video-button {
          .placeholder-1 {
            background-size: 29pt 21pt;
            width: 29pt;
            height: 21pt;
          }
        }

        .placeholder-2,
        .placeholder-1 {
          margin-right: 10pt;
        }
        .live,
        .audio {
          height: 20px;
          line-height: 20px;
        }
      }
    }
  }
}

// Large devices (desktops, 992px and up)
@media (min-width: 992px) {
  .amg-card-calendar-fixture {
    flex-direction: row;

    .amg-card-calendar-fixture-info {
      align-items: center;
      align-content: flex-start;
      flex-direction: column;
      display: flex;
      flex-wrap: wrap;
      padding: 20px 10px;
      max-width: 250px;
      height: 160px;

      .amg-card-calendar-fixture-sponsor {
        flex: 1 1 100%;
        align-self: flex-end;
        margin-right: 0.5em;

        .amg-card-calendar-fixture-sponsor-img {
          width: 55px;
          height: 55px;
          margin-bottom: 8px;
        }
      }
      .amg-date,
      .amg-card-calendar-fixture-stadium {
        align-self: flex-start;
        text-align: left;
        display: flex;
        flex: 1 1 50%;
      }

      .amg-date {
        align-items: flex-end;
      }
      .amg-card-calendar-fixture-stadium {
        align-items: flex-start;
        max-width: 160px;
        word-break: break-word;
        width: 100%;
      }
    }
    .amg-card-calendar-fixture-teams {
      margin: 0;
    }

    .amg-card-calendar-fixture-meta {
      margin-top: 0.5em;
      flex-direction: column;

      .btn {
        margin: 0 0.8em 0.8em 0.8em;
      }
    }
  }
}
