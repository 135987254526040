$textColor: $primary-text-color;
$titleColor: $textColor;
$subtitleColor: $beigeLight;

$highlightColor: $blueMed;
$highlightBtnColor: $blueLight;
$playlistBgColor: #050E1A;
$playlistHeaderBgColor: #121922;

$metaFontSize: 10pt;
$titleFontSize: 13pt;
$labelFontSize: 14pt;
$metaFontSize-md: 14pt;
$subtitleFontSize: 15pt;
$descriptionFontSize-md: 15pt;
$subtitleFontSize-md: 18pt;
$titleFontSize-md: 21pt;
$overlayTitleFontSize: 25pt;

$playButtonSize: 50pt;
$toggleSize: 46pt;
$sliderHeight: 18pt;
$sliderWidth: 37.5pt;

$videoAspectRatio: 56.25%;

// Icons & Images - BEGIN
$playIcon-video: '~src/assets/images/card-video-play.svg';
$playIcon-audio: '~src/assets/images/card-video-play.svg';
$playIconSize: 37pt;

$membershipIconSize: 32pt;
$membershipIcon-free: '~src/assets/images/membership-icon-free.svg';
$membershipIcon-paid: '~src/assets/images/membership-icon-paid.svg';
$membershipIcon-registered: '~src/assets/images/membership-icon-registered.svg';

$liveIcon-audio: '~src/assets/images/live-icon-audio.svg';
$liveIcon-video: '~src/assets/images/live-icon-video.svg';
$liveIconSize: 36px;

// Icons & Images - END

// Single elements - BEGIN

.amg-toggler {
  position: relative;
  display: inline-block;
  width: $sliderWidth;
  height: $sliderHeight;
  margin: auto 0 auto 8px;

  input {
    opacity: 0;
    width: 0;
    height: 0;
  }

  .slider {
    @extend %overlay;
    cursor: pointer;
    background-color: #ccc;
    -webkit-transition: .4s;
    transition: .4s;

    &:before {
      position: absolute;
      content: "";
      height: $sliderHeight - 5pt;
      width: $sliderHeight - 5pt;
      left: 4pt;
      bottom: 2.5pt;
      background-color: white;
      -webkit-transition: .4s;
      transition: .4s;
    }
  }

  input:checked + .slider {
    background-color: #2196F3;
  }

  input:focus + .slider {
    box-shadow: 0 0 1px #2196F3;
  }

  input:checked + .slider:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
  }

  /* Rounded sliders */
  .slider.round {
    border-radius: 34px;
  }

  .slider.round:before {
    border-radius: 50%;
  }
}

.amg-category-wrapper {
  .amg-video-category:not(:first-child) {
    &:before {
      content: ", ";
    }
  }
}

.amg-play-btn {
  &.video-icon {
    background: url($playIcon-video) center no-repeat;
    background-size: contain;
  }

  &.audio-icon {
    background: $playIcon-audio;
  }
}

.amg-video-meta-data {
  display: flex;
  align-items: center;
  width: 100%;

  > * {
    margin: auto $grid-gutter-width/4;

    &:first-child {
      margin-left: 0;
    }
  }

  .amg-membership-icon {
    width: $membershipIconSize;
    height: $membershipIconSize;
    display: inline-block;

    &.free {
      background: url($membershipIcon-free) no-repeat center;
    }

    &.paid {
      background: url($membershipIcon-paid) no-repeat center;
    }

    &.registered {
      background: url($membershipIcon-registered) no-repeat center;
    }
  }
}

.amg-live-icons {
  display: flex;
  justify-content: center;
  align-items: center;
  width: $liveIconSize * 3;
  height: $liveIconSize;

  .amg-live-icon {
    width: 100%;
    height: 100%;
    display: inline-block;

    &.video {
      background: url($liveIcon-video) no-repeat right;
    }

    &.audio {
      background: url($liveIcon-audio) no-repeat right;
    }
  }
}

.amg-thumbnail-overlay-text {
  @extend %overlay;
  //font-family: $futuraBold; // todo
  font-weight: 600;
  font-size: $metaFontSize;
  display: none;
  justify-content: flex-start;
  align-items: flex-end;
  padding: 6pt;
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.65) 100%);
}

.active {
  .amg-thumbnail-overlay-text {
    display: flex;
  }
}

// Single elements - END

app-video {

  > amg-wp-page > amg-wp-section {
    &:not(:first-child) {
      @include make-container-amg();
    }
  }

  .amg-page {
    .amg-section {
      .amg-section-children {
        padding: 0px;
        margin: 0px;
        max-width: none;
      }
    }
  }

  .amg-payment-fields-group,
  .amg-video-player {
    width: 100%;
  }
}

amg-playlist-card {
  &.active {
    .amg-thumbnail-overlay {
      .amg-play-btn {
        display: none;
      }
    }
  }

  .amg-playlist-thumbnail-wrapper {
    position: relative;

    .amg-video-duration-overlay {
      display: none;
    }

    .amg-fixture-thumbnail {
      background-image: none;
      background-position: center center;
      background-repeat: no-repeat;
      background-color: transparent;
      background-size: 100% auto;
      padding-top: $videoAspectRatio;
      position: relative;
      pointer-events: none;
      transition: background-size 0.333s ease-in-out;
    }

    .amg-thumbnail-overlay {
      @extend %overlay;

      .amg-play-btn {
        position: absolute;
        left: 0;
        bottom: 0;
        width: $playIconSize;
        height: $playIconSize;
        margin: 6pt;
      }
    }
  }
}

.amg-video-player-component {
  background: $blueDark;

  &.amg-mobile-view {
    position: relative;
    height: inherit !important;

    .amg-player-content-wrapper {
      .amg-video-details {
        padding: $grid-gutter-width/2 $grid-gutter-width/2;

        .amg-title {
          font-size: $titleFontSize-md - $titleFontSize-md/4;
        }

        .amg-description {
          font-size: $subtitleFontSize-md - $subtitleFontSize-md/3;
        }
      }
    }

    .amg-playlist-items {
      .amg-playlist-item {
        .amg-left {
          max-width: 33%;
        }

        .amg-playlist-row {
          padding: $grid-gutter-width/2;

          > * {
            flex: 1;
          }
        }
      }
    }
  }
}

amg-playlist {
  position: relative;
}

.amg-sidebar-wrapper {
  overflow: hidden;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  @include media-breakpoint-up(md) {
    position: absolute;
  }
}

.amg-sidebar {
  color: $textColor;
  height: 100%;
  display: flex;
  flex-direction: column;
  background-color: #121922;
  overflow-x: hidden;
  overflow-y: scroll;
  margin-right: -17px;

  @include media-breakpoint-up(md, false) {
    //max-height: calc(#{(100vw / 16 * 9)});
  }

  .amg-playlist-wrapper {
    background-color: $playlistBgColor;
    padding-right: 17px;
  }

  .amg-title {
    //font-family: $futuraMedium; // todo
    font-size: $titleFontSize-md;
  }

  .amg-subtitle {
    //font-family: $futuraBold; // todo
    font-weight: 600;
    font-size: $descriptionFontSize-md;
  }

  .amg-playlist-items {

    color: $textColor;

    .amg-playlist-item {
      .amg-left {
        min-width: 50%;
      }

      .amg-title {
        color: $beigeLight;
      }
    }

    .more-items-loader {
      display: none;
      // height: (100px + (16px*2));
      // background: url('~src/assets/images/football.svg') center no-repeat transparent;
      // background-size: 100px;
      // animation: spin 2s linear infinite;
      // @keyframes spin {
      //   100% {
      //     transform: rotate(360deg);
      //   }
      // }
      &.collapse {
        display: none;
      }
    }
  }
}

amg-wp-video-player {
  overflow: hidden;
  min-height: 540px;

  @include media-breakpoint-up(md) {
    //height: calc(#{(80vw / 16 * 9)} + 190px);
  }
}

.amg-video-player-component {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  position: relative;

  .amg-title {
    font-size: $labelFontSize;
  }

  .amg-title,
  .amg-video-category,
  .amg-description {
    text-transform: uppercase;
  }

  .amg-category-wrapper {
    font-size: 10pt;
    font-weight: 800;
  }

  .amg-title {
    font-weight: 800;
    font-size: $titleFontSize;
  }

  .amg-description {
    color: $beigeLight;
    font-weight: bold;
    font-size: $subtitleFontSize;
  }

  .amg-date {
    font-size: $metaFontSize;
    vertical-align: middle;

    > * {
      margin: 1pt;
    }

    .amg-inline-separator {
      display: inline-block;
      width: 1pt;
      height: 9pt;
      margin: auto 4pt;
      background-color: $textColor;
    }
  }

  .amg-player-content-wrapper {
    margin-right: $grid-gutter-width/2;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;

    .amg-duration {
      display: none;
    }

    .amg-video-details {
      color: $textColor;
      padding: $grid-gutter-width/2 0;

      > * {
        margin: $grid-gutter-width/8 auto $grid-gutter-width/8 0;
      }

      .amg-title {
        font-weight: bold;
        font-size: $titleFontSize-md;
        letter-spacing: 1pt;
      }

      .amg-description {
        color: $textColor;
        font-size: $subtitleFontSize-md;
        text-transform: none;
      }
    }
  }

  .amg-video-player {
    justify-content: flex-end;
    background-color: #000;
    position: relative;
    padding-bottom: $videoAspectRatio;
    display: block;
    width: 100%;

    > * {
      @extend %overlay;
      display: flex;
      align-items: center;
    }

    img {
      display: inline-block;
      width: auto;
      height: 100%;
      margin: auto;
    }

    .amg-play-btn-wrapper {
      top: auto;
      right: auto;
      padding: $grid-gutter-width/2;

      .amg-play-btn {
        height: $playButtonSize;
        width: $playButtonSize;
      }
    }
  }

  .amg-video-thumbnail-wrapper {
    &.amg-video-icon,
    &.amg-audio-icon {
      &:before {
        pointer-events: none;
        content: "";
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background-size: 48px;
      }
    }

    &.amg-video-icon {
      &:before {
        background: url($playIcon-video) 10px calc(100% - 10px) no-repeat transparent;
      }
    }

    &.amg-audio-icon {
      &:before {
        background: url($playIcon-audio) 10px calc(100% - 10px) no-repeat transparent;
      }
    }
  }

  .amg-video-login-overlay {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: $grid-gutter-width/2;
    z-index: 0;

    > * {
      margin-bottom: $grid-gutter-width/3;

      &:last-child {
        margin-top: $grid-gutter-width/3;
      }
      @media (min-width: 376px) {
        margin-bottom: $grid-gutter-width/2;

        &:last-child {
          margin-top: $grid-gutter-width/2;
        }
      }
    }

    .amg-title {
      font-weight: bold;
      font-size: $overlayTitleFontSize - $overlayTitleFontSize/3;
      @media (min-width: 376px) {
        font-size: $overlayTitleFontSize - $overlayTitleFontSize/4;
      }
    }

    .amg-sub-title {
      font-size: $titleFontSize-md - $overlayTitleFontSize/3;
      @media (min-width: 376px) {
        font-size: $titleFontSize-md - $overlayTitleFontSize/4;
      }
    }

    .amg-btn-wrapper {
      display: flex;

      .btn {
        color: $textColor;
        border-radius: 20pt;
        height: inherit;
        font-size: 14px;
        padding-left: 7pt;
        padding-right: 7pt;
        border: solid 1px transparent;
        transition: all ease-in-out .25s;
        min-height: 36px;

        &.amg-subscribe-btn {
          background-color: $beigeLight;

          &:hover {
            background-color: $white;
            border: solid 1px $beigeLight;
          }
        }

        &.amg-log-in-btn {
          background-color: $highlightBtnColor;

          &:hover {
            background-color: $white;
            border: solid 1px $highlightBtnColor;
          }
        }

      }
    }

    @include media-breakpoint-up(md, false) {
      .amg-btn-wrapper {
        .btn {
          height: 40pt;
          font-size: 16px;
          padding-left: 17pt;
          padding-right: 17pt;
        }
      }
      .amg-title {
        font-size: $overlayTitleFontSize;
      }

      .amg-sub-title {
        font-size: $titleFontSize-md;
      }
    }

  }

  .amg-playlist-row {
    display: flex;
    padding: $grid-gutter-width/2;
    border-top: solid 1px $beigeLight;
    max-width: 100%;
    background-color: transparent;
    transition: background-color 0.333s ease-in-out;

    &:hover {
      background-color: black;

      .amg-playlist-thumbnail-wrapper {
        .amg-fixture-thumbnail {
          background-size: 120% auto;
        }
      }
    }

    > * {
      flex: 1;
      cursor: pointer;
    }

    &:first-child {
      margin-top: 0;
    }

    .amg-middle {
      margin-left: $grid-gutter-width/2;

      > * {
        margin: 4pt 0;

        &:first-child {
          margin-top: 0;
        }

        &:last-child {
          margin-bottom: 0;
        }
      }
    }

    img {
      max-width: 100%;
    }
  }

  &.amg-live-player {
    .amg-playlist-row {
      .amg-left {
        display: none;
      }

      .amg-middle {
        margin-left: 0;
      }

      .amg-right {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        margin: auto 4pt;
        flex: 1;
      }
    }

    .amg-playlist-item {
      cursor: pointer;

      amg-thumbnail {
        display: none;
      }

      amg-live-icons {
        flex: none;
      }

      .amg-title {
        color: $textColor;
      }
    }
  }

  // Medium and up screens
  @include media-breakpoint-up(md, false) {
    flex-direction: row;
    padding-left: $grid-gutter-width/2;
    .amg-video-player {
      margin-top: $grid-gutter-width/2;
    }
    .amg-player-content-wrapper {
      flex: 1;
      flex-grow: 3;
      justify-content: flex-start;
    }

    amg-playlist {
      flex: 1;
      flex-shrink: 1;
    }
  }

  // XL and down screens
  @include media-breakpoint-between(md, xl, false) {
    .amg-sidebar {
      .amg-playlist-row {
        flex-direction: column;

        .amg-left {
          margin-bottom: $grid-gutter-width/2;
          width: auto;
        }

        .amg-middle {
          margin: 0;

        }
      }
    }
  }
}

.amg-sidebar-header {
  color: $textColor;
  background-color: $playlistHeaderBgColor;
  padding: $grid-gutter-width/2 $grid-gutter-width $grid-gutter-width/2 $grid-gutter-width/2;
  display: flex;
  text-transform: uppercase;
  justify-content: space-between;

  .amg-title {
    min-width: 94px;
  }

  .amg-title,
  .amg-subtitle {
    font-size: $descriptionFontSize-md - $descriptionFontSize-md/4;
  }

  .amg-autoplay-wrapper {
    .amg-autoplay {
      font-size: $labelFontSize - $labelFontSize/4;
    }
  }

  .amg-autoplay-wrapper {
    align-self: flex-end;
    display: flex;
    align-content: center;
    width: 136pt;
    margin-left: auto;
    align-items: center;
    font-weight: 600;
    margin-right: 16px;
    @include media-breakpoint-up(md) {
      margin-right: 0px;
    }
  }

  .amg-collapse-toggler {
    display: flex;
    font-size: $toggleSize;
    line-height: $toggleSize/2;
    color: $beigeLight;
    justify-content: center;
    align-items: center;
    @include media-breakpoint-up(md, false) {
      display: none;
    }
  }

  .amg-title {
    color: $beigeLight;
  }

  .amg-autoplay {
    font-size: $labelFontSize;
    margin-left: auto;
  }


  @include media-breakpoint-between(md, xl, false) {
    .amg-autoplay-wrapper {
      margin: $grid-gutter-width/4 0 0 auto;

      .amg-autoplay {
        margin: 0 auto 0 0;
      }
    }
  }

}
