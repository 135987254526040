.account.register.step1 {
  .payments {
    .col-md-10 {
      .col-md-4 { // vouchers
        flex: none;
        max-width: initial;
        .subscription-plan-card {
          border: none;
        }
      }
    }
    div.col-lg-8 {


      #main-content {
        form:nth-child(2) { // Packages
          padding-bottom: $section-separator;
          border-bottom: $section-separator-border;

          .row .row > div {
            border: $section-separator-border;
            margin-bottom: $section-separator;

            &:last-child {
              margin-bottom: 0;
            }
          }

          .subscription-plan-card {
            width: 100%;
            text-align: center;
            padding: 5px;


            h2,
            button {
              margin: $section-separator auto;
              display: block;
            }
          }
        }

        form#voucher-form {
          margin-top: $section-separator;

          .form-group {
            margin-bottom: 0;
          }

          h2 {
            font-size: 18px;
            margin-bottom: 0;
          }

          .input-group {
            margin-top: 10px;

            display: flex;

            input {
              flex-grow: 1;
            }

            span {
              padding-left: 20px;

              button {
                padding-top: 0;
                padding-bottom: 0;
                line-height: 40px;
              }
            }
          }
        }
      }


      @include media-breakpoint-up(md) {
        #main-content {
          form:nth-child(2) { // Packages
            .row .row {
              display: flex;
              height: 100%;

              > div {
                width: 50%;

                margin-bottom: 0;

                &:first-child {
                  margin-right: 10px;
                }

                &:last-child {
                  margin-left: 10px;
                }
              }
            }
          }
        }
      }
    }
  }
}
.account.freeregistration,
.account.register {
  .payments {
    position: relative;
    &:before {
      position: absolute;
      display: block;
      content: "";
      top: 0;
      bottom: 0;
      width: calc(100vw - 17px);
      left: 50%;
      margin-left: -50vw;
      background: url("~src/assets/images/background-payments.png") bottom center no-repeat white;
      background-size: contain;
      z-index: -1;
    }
  }
}
