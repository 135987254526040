#page-container {
  display: block;
  min-height: calc(100vh - 141px);

  > *:not(app-home):not(app-video) > amg-wp-page > amg-wp-section:not(.navigation-container) {

    @include make-container-amg();
  }


  .amg-page {
    > * {
      &.leeds-carousel {
        overflow: hidden;

        &,
        .amg-section-children {
          padding-right: 0;
        }
      }
    }
  }


  @include media-breakpoint-up(md) {

    min-height: calc(100vh - 94px);

    [data-page='home'] & {
      min-height: 100vh;
    }
  }

  @include media-breakpoint-up(lg) {

    min-height: calc(100vh - 124px);
  }

}

@import 'home-page';
@import 'browse-page';
@import 'faq-page';
@import 'live-page';
@import 'video-player';
@import 'fixtures-page';
@import 'subscribe-page';
@import 'other-pages';
@import 'payments/payments';
