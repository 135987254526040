.navigation-container-tabs {
  justify-content: space-between;
  padding: 0;

  .amg-section-title {
    margin: auto;
    justify-self: center;
    &:before {
      content: "";
      display: block;
    }
  }

  .nav-link {
    font-weight: bold;
    font-size: 17px;
  }

  /*.amg-section-title:before,
  .amg-section-children {
    min-height: 50px;
  }*/

  .amg-section-children {
    overflow-x: auto;
    scrollbar-color: $whiteLight $blueMed;
    scrollbar-width: thin;
    //height: auto;
    width: auto;
    max-width: 100%;
    height: auto;
    margin: 0 auto;
    justify-content: initial;

    .amg-section-child {
      width: auto;
      height: auto;
      justify-content: flex-end;

      .amg-action-link {
        min-width: 160px;

        .amg-link-container {
          width: 100%;

          .nav-link {
            width: 100%;
            border-top-left-radius: 10px;
            border-top-right-radius: 10px;
            border-bottom-width: 0;
            padding-left: 20px;
            padding-right: 20px;

            &.active {
              color: $whiteLight;
              background-color: $blueMed;
              border-color: $blueMed;
            }
          }
        }
      }
    }
  }
}
