$borderRadius: 16pt;
$thumbnailPlayIconSize: 35px;

.amg-card-profile {
  border: 0;
  cursor: pointer;
  color: $blueDark;

  &,
  .card-img-top {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    background-color: transparent;
    border-radius: $borderRadius;
    overflow: hidden;
  }

  .card-img-top {
    box-shadow: inset 0pt 3pt 6pt 0pt transparentize($color: #000000, $amount: .84);
    position: relative;
    padding-top: 100%;

    &:before {
      content: "";
      position: absolute;
      bottom: 10px;
      left: 10px;
      background: url('~src/assets/images/card-video-play.svg') center no-repeat transparent;
      width: $thumbnailPlayIconSize;
      height: $thumbnailPlayIconSize;
      background-size: contain;
    }

    img {
      display: none;
    }
  }

  .card-body {
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap;
    padding-left: 0;
    padding-right: 0;
    div {
      font-size: 17px;

      &.number,
      &.name-first,
      &.name-last,
      &.position {
        text-transform: uppercase;
      }

      &.number {
        font-size: 32px;
        font-style: italic;
        color: $blueMed;
        order: 1;
        width: 100%;
        flex: none;
        text-align: center;

        &:before {
          content: "";
          background: url('~src/assets/images/icon-shirt.svg') center no-repeat transparent;
          background-size: contain;
          padding: 0 25px 0 0;
        }
      }

      &.name-first,
      &.name-last {
        width: auto;
      }

      &.name-first {
        font-weight: 500;
        font-size: 26px;
        order: 2;
        text-align: right;
        padding-right: 8px;
      }

      &.name-last {
        font-size: 26px;
        font-weight: bold;
        order: 3;
        text-align: left;
        padding-left: 8px;
      }

      &.position {
        display: none;
      }

      @include media-breakpoint-up(md) {
        font-size: 17px;

        &.number {
          font-size: 32px;
        }

        &.name-first {
          font-size: 26px;
        }

        &.name-last {
          font-size: 26px;
        }
      }
    }
  }
}
