.banner-free-access {

  .amg-action-link{
    width: 100%;

  }

  @include media-breakpoint-up(md) {
    >.amg-section-children {
      .amg-section {

        .amg-section-title,
        .amg-section-description {
          width: 140%;
        }

        .amg-section-children {
          width: 60%;
          margin-left: 40%;
        }
      }
    }
  }
}
