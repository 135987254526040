.account.checkout {

  .payments {

    div.col-lg-8 {

      .container #main-content {

        .well {

          p:last-child {
            border-top: $section-separator-border;
            margin-top: $section-separator;
          }
        }

        > .row {
          margin-bottom: $section-separator * 2;
         padding-bottom: $section-separator;
         border-bottom: $section-separator-border;

          &:before {
            display: block;
            content: "1. You have selected";
            font-size: 18px;
            font-weight: bold;
            color: #d71011;
            margin-bottom: $section-separator;
          }

          &:last-child {
            border-bottom: none;
            margin-bottom: 0;
            padding-bottom: 0;

            &:before {
              content: "2. Select a method of payment";
            }
          }
        }
      }
    }
  }
}
