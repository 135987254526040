amg-template-builder {
  // TODO: Adjust once the elements have been updated...

  .amg-template {
    padding: 1em;

    .amg-logo-wrapper {
      text-align: center;
    }

    amg-form-builder {
      form {
        margin-top: 2em;

        .amg-form-field {
          margin-top: 0.5em;
          width: 100%;
          padding: 0.375rem 0.75rem;
          font-size: 1rem;
          line-height: 1.5;
          background-color: #FFFFFF;
          background-clip: padding-box;
          border: 1px solid #495057;
          border-radius: 0.25rem;
        }

        button {
          margin-top: 0.5em;
          display: inline-block;
          font-weight: 400;
          text-align: center;
          vertical-align: middle;
          user-select: none;
          padding: 0.375rem 0.75rem;
          font-size: 1rem;
          line-height: 1.5;
          border-radius: 0.25rem;
          transition: color 0.15s ease-in-out,
            background-color 0.15s ease-in-out,
            border-color 0.15s ease-in-out,
            box-shadow 0.15s ease-in-out;

          @extend .basic-button;
        }
      }
    }

    .amg-cta-btn-wrapper {
      margin-top: 0.5em;
    }
  }
}

amg-template-builder {
  position:relative;
  .amg-form-error {
    position: absolute;
    left: 0;
    right: 0;
    bottom: -48px;
    text-align: center;
    background-color: #ffadb0;
    border-radius: 0 0 0.3rem 0.3rem;
    padding: 16px;
    font-size: 16px;
    color: #3e3e3e;
  }
  .close {
    position: absolute;
    right: 16px;
    top: 16px;
  }
  .amg-cta-btn {
    text-transform: uppercase;
  }

  amg-call-to-action {
    display: block;
    margin-top: 0;
    margin-bottom: $grid-gutter-width;
    font-size: 14px;

    &:last-child {
      margin-top: $grid-gutter-width;
      margin-bottom: 16px;

      .amg-cta-description {
        margin-top: 0;
        margin-bottom: $grid-gutter-width;
        font-size: 14px;
      }

      .amg-cta-btn {
        border-radius: 50pt;
        color: $whiteLight;
        border-style: solid;
        border-color: $beigeLight;
        background-color: $beigeLight;
        padding: 0.375rem 0.75rem;

        &:hover {
          text-decoration: none;
        }
      }
    }
  }
}
